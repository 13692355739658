/* eslint-disable react/prop-types */
import { useState } from "react";
import { Grid, Paper } from "@mui/material";
import { StageCards, Activites } from './BottomSection';

export const BottomSectionPop = ({
    cropData, pop, setPop,
    selectedCrop, allCropDetails,
    setAllCropDetails }) => {
    const [selectedStage, setSelectedStage] = useState("");
    return <Grid
        item
        container
        marginTop="1rem"
        xs={12}
        component={Paper}
        elevation={5}
        spacing={1}
        sx={{
            padding: '0.5rem',
            minHeight: '50vh'
        }}
    >
        <StageCards
            stages={pop.stages}
            pop={pop}
            setPop={setPop}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage} />
        <Activites
            selectedCrop={selectedCrop}
            pop={pop}
            setPop={setPop}
            activitesList={cropData}
            selectedStage={selectedStage}
            allCropDetails={allCropDetails}
            setAllCropDetails={setAllCropDetails}
        />
    </Grid>
} 