const initialState = {
    farmerByCon: {}
  };
  const farmerReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FARMER_DATA':
        return {
          ...state,
          farmerByCon: action.payload
        };
      default:
        return state;
    }
  };
  
  export default farmerReducer;
  