/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ranchApi } from '../api';
// import { partnerList } from './Partnerlist';
import { AutocompleteBmV2 } from "../@material-extend";
import api from "../api/api";

const statusList = [
  "ALL",
  "UNCONFIRMED",
  "REWORK",
  "CONFIRMED"
]

const grasslandProjects = [
  {label: '2887 - Northern Mexico Grasslands', value: '2887' },
  {label: '3291 - South America Grasslands', value: '3291' },
  {label: '2892 - Central Mexico Grasslands', value: '2892' },
  {label: '3910 - Colombia Grasslands', value: '3910' },
  {label: '4424 - Costa Rica Grasslands', value: '4424' },
  {label: '3340 - Grassland Restoration in East Africa', value: '3340' },
  {label: '1111 - Mangolia Grasslands', value: '1111' },
  {label: '1001 - Test Project', value: '1001' }
]

export const HomeTopBar = ({setRanchList, status, setStatus, setAllData}) => {
  const [allProject, setAllProject] = useState([]);
  const [allPartner, setAllPartner] = useState([]);
  const [fileteredPartners, setFilteredPartners] = useState([]);
  const [partner, setPartner] = useState();
  const [project, setProject] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    getAllProjectsAndPartners();
}, [])

  const getAllProjectsAndPartners = async () => {
    try {
        const { data } = await api.get("/getallpartner")
        const alltempPartner = [];
        // const allTempProject = [];
        const { partners, projects } = data;
        partners.sort((a, b) => +a.id - +b.id)

        partners.forEach((ele) => {
            if(ele.type === 'RANCHER_PARTNER') {
              alltempPartner.push({label: `${ele.id} - ${ele.name}`, value: `${ele.id}`});
            }            
        });

        // TODO: Nevil: get all grassland projects only directly from backend
        // projects.forEach(({ id, label }) => {
        //     allTempProject.push({label: `${id} - ${label}`, value: `${id}`});
        // })

        setAllProject(grasslandProjects);
        setAllPartner(alltempPartner);
    } catch (e) {
        console.log(e);
    }
}

const onProjectChange = (_, value) => {
  if (!value) {
    return;
  }
  setProject(value)
  setPartner({label: 'ALL', value: 'ALL'})
}

const onPartnerChange = async (_, value) => {
  if (!value) {
      return;
  }
  setPartner(value)
}
  
  const onSearch = () => {
    setIsSubmitting(true)
    if(!partner?.value){
      alert("Please select a partner")
      setIsSubmitting(false)
      return
    }

    if(!project?.value){
      alert("Please select a Project")
      setIsSubmitting(false)
      return
    }

    ranchApi
    .get(`home?id=${partner.value}`)
    .then(({ data }) => {setRanchList(data.ranchCompleteDetail);
      setAllData({...data})
      setIsSubmitting(false)})
    .catch((err) => {console.log(err);
      alert("failed")
      setIsSubmitting(false)});
  }

  return (
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h4" style={{ margin: 10 }}>
              Ranches
            </Typography>
          </Grid>
          <div
            style={{
              width: '90%',
              margin: '1rem 0 0 0',
              display: 'flex',
              alignItems: 'center'
            }}
          >
              <FormControl >
                <AutocompleteBmV2
                  label="Project"
                  list={allProject}
                  value={project}
                  onChange={onProjectChange}
                  style={{
                    marginLeft: '2rem'
                  }}
                />
                
              </FormControl>
            
          
              <FormControl >
                <AutocompleteBmV2
                    label="Partner"
                    list={allPartner}
                    value={partner}
                    onChange={onPartnerChange}
                    style={{
                      marginLeft: '1rem'
                    }}
                  />
              </FormControl>
           
          
              {/* <FormControl>
                <InputLabel style={{ marginLeft: '1rem'}}>Status</InputLabel>
                <Select value={status} label="Status" onChange={(event) => {setStatus(event.target.value)}}
                  style={{
                    minWidth: '100px',
                    marginLeft: '1rem'
                  }}>
                  <MenuItem key="all" value="none">
                    Select Status
                  </MenuItem>
                  {statusList.map((ele, index) => (
                    <MenuItem key={index} value={ele}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                bgcolor: '#87C45E',
                marginLeft: '1rem'
              }}
              onClick={onSearch}
            >
              Search
            </LoadingButton>
            </div>
        </Grid>    
  );
};
