/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import GroupIcon from '@mui/icons-material/Group';
import CoPresent from '@mui/icons-material/CoPresent';
import Grain from '@mui/icons-material/Grain';
import axios from 'axios';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CardBM } from '../components/_dashboard/app';
import { getDetailDayWise } from '../components/GridColumns';
import Page from '../components/Page';
import { DateModal } from '../utils/DateModal';
import { TableHeader } from '../components/TableHeaders';
import { DatePickerBM } from '../components/DatePicker';
import { BarBm } from '../components/charts/BarBm';
import './table.css';

export const SearchByMultiDatePartner = () => {
    const { user } = useSelector((store) => store.userRoot);
    const [loading, setLoading] = useState(false);
    const [activityHour, setActivityHour] = useState([])
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [calDateFrom, setCalDateFrom] = useState(null);
    const [calDate, setCalDate] = useState(null);
    const [status, setStatus] = useState({});


    const submit = () => {
        const modaledDate1 = DateModal(calDateFrom);
        const modaledDate2 = DateModal(calDate);
        getDayWiseStatus(modaledDate1, modaledDate2)
    }

    const getDayWiseStatus = async (date1, date2) => {
        setLoading(true)
        const partnerId = user.partnerID;

        try {
            const { data } = await axios.get(`https://6r45sekgpj.execute-api.ap-south-1.amazonaws.com/api/search_betw_date_partner?parnerId=${partnerId}&date1=${date2}&date2=${date1}`, {
                headers: {
                    authorizationToken: user.token
                }
            });
            setRows(data.fieldRepByDate);
            setStatus(data.status)
            setActivityHour(data.halfHoursWiseByDate);
        } catch (error) {
            setRows([])
        }
        setLoading(false);
    }


    return (
        <Page
            style={{
                display: 'flex',
                minHeight: '100%',
                flexDirection: "column"
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="ACTIVE REPS"
                        color="linear-gradient(to left, #93F9B9, #1D976C)"
                        ICONS={CoPresent}
                        count={rows.length}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="Number of FARMS"
                        color="linear-gradient(to left, #a8e063, #56ab2f)"
                        ICONS={Grain}
                        count={status.TotalFarm}

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="Total AREA (ACRES)"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        ICONS={AgricultureIcon}
                        count={status.totalArea}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="ONBOARDED FARM"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        ICONS={AgricultureIcon}
                        count={status.CONFIRMED_FARM}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="RECEIVED FARM"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        ICONS={AgricultureIcon}
                        count={status.UNCONFIRMED_FARM}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="REWORK FARM"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        ICONS={AgricultureIcon}
                        count={status.REWORK_FARM}
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    width: "100%",
                    margin: "3rem auto 1.5rem auto",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        width: "60%",
                        margin: "3rem 0 0 0",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <DatePickerBM
                        value={calDateFrom}
                        setDate={(date) => setCalDateFrom(date)}
                        label="From"
                        maxDate={new Date(calDate)}
                    />
                    <DatePickerBM
                        value={calDate}
                        setDate={(date) => setCalDate(date)}
                        label="To"
                    />
                    <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                        onClick={submit}
                    >
                        Submit
                    </LoadingButton>
                </div>

            </div>
            <>
                {loading ? (
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}
                    >
                        <CircularProgress size={100} />
                    </Box>
                ) : rows.length !== 0 && !loading && (
                    <Box
                        style={{
                            flexGrow: 1,
                            height: '90vh',
                            justifyContent: "center"
                        }}
                    >
                        <TableHeader label="REP ACTIVITY" Icon={GroupIcon} />
                        <BarBm
                            data={activityHour} x="time" y1="Farm"
                            yLabel="Number Of Farms"
                            xaxies={false}
                        />
                        <DataGrid
                            style={{ "margin": "2rem 0" }}
                            rows={rows}
                            onError="No Farmer Added"
                            density="compact"
                            columns={getDetailDayWise()}
                            pageSize={pageSize}
                            onPageSizeChange={(a) => setPageSize(a)}
                            rowsPerPageOptions={[20, 50, 100]}
                            getRowClassName={({ row }) =>
                                row.status === 'CONFIRMED' ? 'confirmed' : 'unconfirmed'
                            }
                            disableSelectionOnClick
                            components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                    </Box>
                )
                }
                {rows.length === 0 && !loading &&
                    <div
                        style={{ "textAlign": 'center' }}
                    >No New Data Added</div>}
            </>
        </Page>
    );
};
