/* eslint-disable react/prop-types */
import { HeadingAndTextField } from '../ConserationRanch/HeadingAndTextField';
import { MultipleHeadingAndTextField } from '../ConserationRanch/MultipleHeadingAndTextField';
import { HeadingAndAutoComplete } from './HeadingAndAutoComplete';
import { sourceOfEletricity } from './sourceOfEletricity';

export const Electricity = ({ electricity, setElectricity, disabled }) => {

  const handleChange = (value, name) => {
    const newValue = { ...electricity };
    newValue[name] = value
    setElectricity({ ...newValue });
  };

  const twoFields = [
    {
      label: 'Power Output of one panel',
      size: 'medium',
      id: 'powerOutput',
      unit: 'Kw',
      type: 'number'
    },
    {
      label: 'No. of Panels',
      size: 'small',
      id: 'noOfPanels',
      type: 'number'
    }
  ];


  const heading3 = 'Source of Electricity for electric fence';
  const heading = 'Average Electricity consumption per month';
  const heading1 =
    'If fossil fuels are used for electricity, average number of litres of fossil fuel consumed per month';
  const heading2 = 'Solar Electricity Details';

  return (
    <>
      <HeadingAndAutoComplete
        disabled={disabled}
        list={sourceOfEletricity}
        handleChange={handleChange}
        heading={heading3}
        name='source'
        value={electricity.source}
        textFieldSize="large"
      />

      <HeadingAndTextField
        disabled={disabled}
        name='avgElectricity'
        value={electricity.avgElectricity}
        handleChange={handleChange}
        heading={heading}
        textFieldSize="medium"
        unit='kWh'
        type='number'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={electricity.noOfLtrs}
        name='noOfLtrs'
        handleChange={handleChange}
        heading={heading1}
        textFieldSize="medium"
        unit='litres'
        type='number'
      />
      <MultipleHeadingAndTextField
        disabled={disabled}
        value={electricity}
        heading={heading2}
        noOfFields={twoFields}
        setFields={handleChange}
      />
    </>
  );
};
