/* eslint-disable react/prop-types */
import { TextField, Stack } from '@mui/material';
import dayjs from 'dayjs';

export const CalenderBm = ({ date, setDate }) => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const handleStartDateChange = (event) => {
        const startDate = event.target.value;
        setDate([startDate, date[1]]);
    };

    const handleEndDateChange = (event) => {
        const endDate = event.target.value;
        setDate([date[0], endDate]);
    };

    return (
        <Stack direction='row' >
            <TextField
                type="date"
                value={dayjs(date[0]).format('YYYY-MM-DD')}
                label="Start Date"
                onChange={handleStartDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    max: date[1] || currentDate,
                }}
            />
            <TextField
                sx={{ margin: '0 1rem' }}
                type="date"

                label="End Date"
                value={dayjs(date[1]).format('YYYY-MM-DD')}
                onChange={handleEndDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: date[0],
                    max: currentDate
                }}
            />
        </Stack>
    );
};


