/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { List } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import palette from 'src/theme/palette';
import { DocTypeCards } from './DocTypeCards';
// import { HelmetBm } from 'src/components/@material-extend';


const docList = [
  {
    primary: "Identity Documents",
    secondary: "identity documents",
    Icon: PermIdentityIcon
  },
  {
    primary: "Land Documents",
    secondary: "land documnets",
    Icon: CropLandscapeIcon
  },
  {
    primary: "Agreement",
    secondary: "agreement",
    Icon: AddIcon
  },
  {
    primary: "Management Plan",
    secondary: "management plan",
    Icon: AddIcon
  },
  {
    primary: "Other Documents",
    secondary: "other documents",
    Icon: AddIcon

  }
]

export const DocTypeList = ({ active, setActive }) => {

  return (
    <List sx={{
      padding: "1rem 1.5rem",
      bgcolor: palette.grey[200],
      borderRadius: '10px',
      minHeight: '90vh'
    }}
    >
      {/* <HelmetBm title={docList[active].primary} title2="Document upload" /> */}
      {
        docList.map((ele, index) => <DocTypeCards
          Icon={ele.Icon}
          primary={ele.primary}
          key={index}
          setActive={() => setActive(index)}
          active={index === active}
        />
        )
      }
    </List>
  );
}
