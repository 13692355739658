/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, Button, Stack, Paper, IconButton } from '@mui/material';
import { FreeAutoCompleteText } from 'src/components/@material-extend/FreeAutoCompleteText';
import { InputText, ModalBm, CheckboxBm, TabsBM, TypographyBm } from 'src/components/@material-extend';
import { useSnakBarTran } from 'src/hook';

const questionTypes = [
    { label: "Text", value: "Text" },
    { label: "MultiPicker", value: "multiPicker" },
    { label: "Optional", value: "Optional" },
    { label: "Calender", value: "calender" },
    { label: "Image", value: "image" }
]

export const AddActivityQuestion = ({
    showModal,
    activityName,
    onSubmit,
    data,
    addQuestion,
    setShowModal }) => {
    const { showErrorSnackbar } = useSnakBarTran()
    const [isMandatory, setIsMandatory] = useState(false);
    const [isNumeric, setNumeric] = useState(false);
    const [questionType, setQuestionType] = useState('Text');
    const [question, setQuestion] = useState("");
    const [options, setOptions] = useState([]);
    const [activeTab, setActiveTab] = useState(1);

    const onSubmitQuestion = () => {
        if (!question) {
            showErrorSnackbar({ text: "Question not added" })
            return
        }
        if ((questionType === 'Optional' || questionType === 'multiPicker') && !options.length) {
            showErrorSnackbar({ text: "optional not added" })
            return
        }
        const questionDetails = {
            isAnswerNumeric: isNumeric,
            isMandatory,
            question,
            type: questionType,
        }

        if (questionType === 'multiPicker') {
            const newOptions = options.map(ele => ({ label: ele, value: ele }))
            questionDetails.multiOption = newOptions;
        } else if (questionType === 'Optional') {
            questionDetails.options = options
        }

        const newQuestionList = [...data.questionsList || []];
        newQuestionList.push(questionDetails)
        onSubmit(newQuestionList)
        setQuestion()
        setOptions([])
        setActiveTab(1)
    }

    const deleteQuestion = (questionNumber) => {
        const newQuestionList = [...data.questionsList || []];
        newQuestionList.splice(questionNumber, 1);
        onSubmit(newQuestionList)
    }

    return <ModalBm
        showModal={!!showModal}
        title={`${activityName} (Add Questions)`}
        setShowModal={() => setShowModal("")}>
        <TabsBM
            value={activeTab}
            handleChange={(v) => {
                if (v === 0 && !addQuestion) {
                    return
                }
                setActiveTab(v)
            }}
            fullWidth
            labelComponents={[
                {
                    label: 'Add Question',
                    Component: (
                        <FormControl>
                            <FormLabel>Question Type</FormLabel>
                            <RadioGroup row value={questionType} onChange={(_, value) => setQuestionType(value)}>
                                {questionTypes.map(({ label, value }, index) => <FormControlLabel key={index}
                                    value={value}
                                    control={<Radio />}
                                    label={label}
                                    labelPlacement="end"
                                />)}
                            </RadioGroup>
                            <InputText
                                multiline
                                value={question}
                                onChange={({ target }) => setQuestion(target.value)}
                                rows={3}
                                sx={{
                                    my: '1rem'
                                }}
                                label="Question?" />
                            {questionType === 'Optional' || questionType === 'multiPicker' ?
                                <FreeAutoCompleteText value={options || []}
                                    handleChange={(v) => setOptions(v,)}
                                    list={[]} /> : null
                            }
                            <CheckboxBm value={isMandatory}
                                label="Mark as Mandatory"
                                onChange={() => setIsMandatory(!isMandatory)} />
                            <CheckboxBm
                                value={isNumeric}
                                onChange={() => setNumeric(!isNumeric)}
                                label="is answer numeric?" />
                            <Button variant='contained'
                                sx={{ width: '8rem', margin: '1rem auto' }} onClick={onSubmitQuestion}>
                                Submit
                            </Button>
                        </FormControl>
                    )
                },
                {
                    label: 'Added Questions',
                    Component: <>
                        {data.questionsList ?
                            data.questionsList.map((ele, index) => <Stack my='1rem' p='1rem' key={ele.question} component={Paper} elevation={5} flexDirection='row' justifyContent='space-between'>
                                <Stack>
                                    <TypographyBm>
                                        Question: {ele.question}
                                    </TypographyBm>
                                    <TypographyBm>
                                        Question Type: {ele.type}
                                    </TypographyBm>
                                    <TypographyBm>
                                        Is Numeric?: {(ele.isNumeric || false).toString()}
                                    </TypographyBm>
                                    <TypographyBm>
                                        Is Mandatory?: {(ele.isMandatory || false).toString()}
                                    </TypographyBm>
                                    {ele.Options ?
                                        <FreeAutoCompleteText disabled list={ele.options} /> : null
                                    }
                                </Stack>
                                <Stack>
                                    <IconButton onClick={() => deleteQuestion(index)}>
                                        <DeleteForeverIcon color='error' />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            ) : <TypographyBm>No Question Added</TypographyBm>}
                    </>
                }]} />
    </ModalBm>
}