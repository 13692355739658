/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

export const EditControl = () => {
    const map = useMap();

    useEffect(() => {
        const leafletContainer = map;
        leafletContainer.pm.enableGlobalEditMode()

        leafletContainer.pm.addControls({
            drawMarker: false,
            position: "bottomright",
            drawCircleMarker: false,
            drawRectangle: false,
            cutPolygon: false,
            editPolygon: true,
            editable: false,
            drawText: false,
            drawCircle: false,
            dragMode: false,
            drawPolyline: false,
            rotateMode: false,
            removalMode: false,
            drawPolygon: false,
            editControls: false
        });

        leafletContainer.pm.setGlobalOptions({ pmIgnore: false, allowSelfIntersection: true });

        return () => {
            leafletContainer.pm.removeControls();
            leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
        };
    }, [map]);

    return null;
} 