export const countries = [
    { label: 'Argentina', value: 'AR' },
    { label: 'Brazil', value: 'BR' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Kenya', value: 'KE' }
  ];
  
  export const countryLatLng = (value) => {
  
    const listOfLatLng = {
      AR: { lat: -38.416096, lng: -63.6167 },
      BR: { lat: -14.235, lng: -51.9253 },
      UY: { lat: -32.5228, lng: -55.7658 },
      MX: { lat: 19.432608, lng: -99.133209 },
      IN: { lat: 20.5937, lng: 78.9629 },
      PY: { lat: 23.4425, lng: 58.4438 },
      KE: { lat: 0.0236, lng: 37.9062 }
    };
  
    return listOfLatLng[value] || { lat: 52.3676, lng: 4.9041 };
  }
  