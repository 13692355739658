/* eslint-disable react/prop-types */
import { MapContainer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { latLngBounds } from "leaflet";
import { googleMapsApi } from "../../../components/api"


export const GoogleMap = ({ children, setMap, width }) => {
    const center = {
        lat: 23.5120,
        lng: 80.3290
    }

    return (
        <MapContainer
            style={{ height: "100%", width: width || '100%'  }}
            zoom={16}
            center={center}
            bounds={latLngBounds(center)}
            whenReady={setMap}
        >
            <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                    apiKey: googleMapsApi,
                    libraries: ["places"]
                }}
                type='hybrid'
            />

            {children}
        </MapContainer >
    );
}