/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react"
import { TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useNavigate } from "react-router-dom";
import { NoAuthTemplate } from "../layouts/NoAuthTemplaate";
import axios from '../components/api/api';

export const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const history = useNavigate()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const onSubmit = async () => {
        if (!email || !email.length > 6 || !email.includes("@")) {
            setError(true);
            return;
        }
        setLoading(true)
        try {
            const { data } = await axios.post('/forgotPassword', { email })
            if (data.statusCode === 401) {
                throw new Error("failed")
            } 
            history('../resetPassword', { state: data })
        } catch (e) {
            setError(true);
        }
        setLoading(false);
    }


    return <NoAuthTemplate headin1="Reset Password" heading2="Please Enter Your Registered Email">

        <TextField
            value={email}
            onChange={(e) => {
                setError(false);
                setEmail(e.target.value);
            }}
            label="Email"
            error={error}
            helperText={error && "Invalid Email"}
        />
        <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
                margin: "2rem auto",
            }}
            loading={loading}
            onClick={onSubmit}
        >
            Submit
        </LoadingButton>
    </NoAuthTemplate>

}