/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { Button, Stack } from '@mui/material';
import { ModalBm } from 'src/components/@material-extend';

export const SubmitConfirmation = ({
  showModal,
  setShowModal,
  onSubmit
}) => {

  const onFinalSubmission = (value) => {
    onSubmit(value)
    setShowModal(false)
  }

  return (
    <ModalBm
      showModal={showModal}
      title="Final Question Submission ?"
      setShowModal={() => setShowModal(false)}
    >
      <Stack flexDirection='row' justifyContent='space-around'>
        <Button variant='contained' onClick={() => onFinalSubmission(true)}>
          Yes
        </Button>
        <Button variant='contained' color='error' onClick={() => onFinalSubmission(false)}>
          No
        </Button>
      </Stack>
    </ModalBm>
  );
};
