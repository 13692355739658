import axios from "../../components/api/api";
import { ranchApi } from "../../components/api";

const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common.authorization = token;
        ranchApi.defaults.headers.common.Authorization = token;
    } else {
        delete axios.defaults.headers.common.authorization;
        delete ranchApi.defaults.headers.common.Authorization;
    }
};
export default setAuthToken;
