/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ImagesBM } from 'src/components/imagesBM';
import { CommentsModal } from 'src/layouts/fieldFarmerDetails/CommentsModal';
import { HeaderAndCommentsIcon } from 'src/layouts/fieldFarmerDetails/HeaderAndCommentsIcon';
import { SelectAndInput } from 'src/layouts/fieldFarmerDetails/SelectAndInput';
import { getImagesURL } from 'src/utils/getURL';
import { PDFBM } from './PDFBM';

export const LandRecordPath = ({
  path,
  dis,
  header,
  keyValue,
  commentList,
  comments,
  setComments,
  isVerificationReady,
  onChange
}) => {
  const [modal, setModal] = useState(false);
  const [landImgPath, setLandImgPath] = useState([]);
  const [pdfPath, setPdfPath] = useState([]);
  const user = useSelector((store) => store.userRoot.user);
  const readonly = user?.readonly;
  useEffect(() => {
    if (path) {
      getUrl();
      getPdfUrl();
    }
  }, [path]);

  const getUrl = async () => {
    let imgPath = [];

    if (dis) {
      imgPath = await getImagesURL(path, dis);
    } else {
      imgPath = await getImagesURL([path]);
    }
    setLandImgPath(imgPath);
  };

  const getPdfUrl = () => {
    if (!path.includes('.pdf')) {
      return;
    }
    const pdfPath = [];
    if (dis) {
      for (const ele of path.split(dis)) {
        if (ele.includes('.pdf')) {
          pdfPath.push(ele);
        }
      }
    } else {
      pdfPath.push(path);
    }
    setPdfPath(pdfPath);
  };
  return (
    <Paper
      sx={{
        padding: "1rem",
        marginTop: "0.5rem",
        border: comments.length ? "2px solid red" : null
      }}
      elevation={10}
    >
      <HeaderAndCommentsIcon
        isVerificationReady={isVerificationReady}
        header={header}
        modal={modal}
        setModal={setModal}
        comments={comments}
        setComments={setComments}
        commentList={commentList}
        readonly={readonly}
      />
      {keyValue ? (
        <SelectAndInput
          elevation={1}
          sx={{
            padding: "0rem",
            margin: "auto"
          }}
          onChange={(key, value) => onChange(key, value)}
          editKeyValue={keyValue}
        />
      ) : null}
      {landImgPath.map((ele, index) => (
        <Box
          key={`${index}jd`}
          sx={{
            width: "100%"
          }}
        >
          <ImagesBM url={ele} />
        </Box>
      ))}
      {pdfPath.map((ele, index) => (
        <Box
          key={`${index}jd`}
          sx={{
            width: "100%"
          }}
        >
          <PDFBM url={ele} />
        </Box>
      ))}

      <CommentsModal
        modal={modal}
        setModal={setModal}
        comments={comments}
        setComments={setComments}
        commentList={commentList}
      />
    </Paper>
  );
};