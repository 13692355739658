/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import Area from '@turf/area';
import { polygon } from '@turf/helpers';
import { FlipPolygon } from './FlipPolygon';

export const AreaOfPolygon = (boundarys, noFlip = false) => {
  if (!boundarys.length) {
    return;
  }
  let areas = 0;
  for (let boundary of boundarys) {
    try {
      let newPolygon;
      if (noFlip) {
        newPolygon = polygon(boundary);
      } else {
        newPolygon = polygon([FlipPolygon(boundary[0])]);
      }

      const area = Area(newPolygon);
      areas += + (area / 4047);
    } catch (error) {
      console.log(error);
    }
  }

  return areas.toFixed(2);
};