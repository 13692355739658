import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { NoAuthTemplate } from "../layouts/NoAuthTemplaate"
import api from "../components/api/api";

export const ResetPasswort = () => {
    const { state: mobile } = useLocation();
    const history = useNavigate()
    const [code, setCode] = useState("");
    const [newPassword, setNewpassword] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [passError, setPassError] = useState(false);

    if (!mobile) {
        return <Navigate to='/login' replace />
    }

    const onSubmit = async () => {
        if (!code || code.length < 4) {
            setError(true);
            return;
        }
        if (newPassword !== password || password.length < 8) {
            setPassError(true)
            return;
        }
        setLoading(true);
        try {
            const { data } = await api.post('/resetPassword', { code, newPassword, email: mobile })
            if (data.statusCode === 401) {
                throw new Error("failed")
            }
            history("../login")
        } catch (error) {
            setError(true)
        }
        setLoading(false)

    }

    return <NoAuthTemplate headin1="Reset Password" heading2={
        <div>

            Verification Code is send on
            <strong>
                {mobile}
            </strong>
        </div>

    }
    >
        <TextField
            sx={{
                margin: "2rem 0 0 0"
            }}
            value={code}
            onChange={(e) => {
                setError(false);
                setCode(e.target.value);
            }}
            label="Verification Code"
            error={error}
            helperText={error && "Invalid Code"}
        />
        <TextField
            sx={{
                margin: "2rem 0 0 0"
            }}
            value={newPassword}
            onChange={(e) => {
                setPassError(false);
                setNewpassword(e.target.value);
            }}
            label="New Password"
            type="password"
            error={passError}
            helperText={passError && "Password doesn't match or Password is of minimum 8 letter"}
        />
        <TextField
            sx={{
                margin: "2rem 0 0 0"
            }}
            value={password}
            onChange={(e) => {
                setPassError(false);
                setPassword(e.target.value);
            }}
            label="Confirm Password"
            type="password"
            error={passError}
            helperText={passError && "Password doesn't match or Password is of minimum 8 letter"}
        />
        <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
                margin: "2rem auto",
            }}
            loading={loading}
            onClick={onSubmit}
        >
            Submit
        </LoadingButton>
    </NoAuthTemplate>


}