/* eslint-disable react/prop-types */

import { Paper, IconButton } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from "@mui/material/colors"
import { ModalBM } from "./ModalBM"
import { FieldMap } from "./FieldMap"


export const AllFieldMapWrap = ({ mapModal, getAllCoor, allCoordinate }) => <ModalBM modelUp={mapModal} handelModal={getAllCoor}>
    <Paper>
        <IconButton onClick={getAllCoor}>
            <CancelIcon sx={{ color: red[500] }} fontSize="large" />
        </IconButton>
        <p
            style={{
                textAlign: 'center'
            }}
        >
            Please zoom in or zoom out to find more fields
        </p>
        <div
            style={{
                textAlign: 'center',
                color: "#ff66a3"
            }}
        >
            Farm in red color are under Review
        </div>
        <p
            style={{
                textAlign: 'center',
                color: "#00ff00"
            }}
        >
            Farm in Green color are Approved
        </p>
        <FieldMap
            coordinates={allCoordinate}
            zoom={15}
            style={{
                width: '100%',
                height: '90%'
            }}
        />
    </Paper>
</ModalBM>