const initialState = {
  home: {}
};
const repReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_HOME_DATA':
      return {
        ...state,
        home: action.payload
      };
    case 'DELETE_HOME_DATA':
      return initialState;
    default:
      return state;
  }
};

export default repReducer;
