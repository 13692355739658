/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import api from "../components/api/api";

export const getImagesURL = async (paths, dis) => {
	if (!paths) {
		return
	}
	const urlPath = [];
	if (dis) paths = paths.split(dis);

	for (const ele of paths) {
		try {
			if (!ele.includes('.pdf')) {
				const { data } = await api.get(`/signedUrl?path=public/${ele}`);
				urlPath.push(data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	return urlPath;
};
