/* eslint-disable react/prop-types */
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';


export const SelectBm = ({ label, list, size, disabled, containerSx, menuSx, noTranc, ...other })=> (
    <FormControl size={size} sx={containerSx}>
      <InputLabel id={label}>{(label)}</InputLabel>
      <Select
        labelId={label}
        id={label}
        label={(label)}
        {...other}
        disabled={disabled}
      >
        {list.map((ele, index) => {
          let label;
          let value = ele;
          if (ele.label) {
            label = ele.label;
            value = ele.value;
          }
          return (
            <MenuItem value={value} selected={index === 0} key={index} sx={menuSx}>
              {noTranc ? (label || value) : (label || value)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

