/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { Autocomplete, Stack, Chip } from '@mui/material';
import { getRandomColor } from '../../utils/RandomColor';
import { InputText } from './InputText';

export const FreeAutoCompleteText = ({ list, handleChange, value, disabled }) => {
  return (
    <Stack>
      <Autocomplete
        multiple
        disabled={disabled}
        onChange={(_, v) => handleChange(v)}
        id="tags-filled"
        options={list.map((option) => option)}
        freeSolo
        value={value}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              sx={{
                color: '#000',
                bgcolor: getRandomColor()
              }}
              label={option}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <InputText
            {...params}
            variant="outlined"
            placeholder="Type"
            style={{
              backgroundColor: '#F1F1F1'
            }}
          />
        )}
      />
    </Stack>
  );
};
