/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import { SelectBm } from 'src/components/@material-extend';
import { HeadingAndTextField } from '../ConserationRanch/HeadingAndTextField'
import { ContainerCenter } from '../../../components/@material-extend/ContainerCenter';
import { GrassLandDegradation } from './GrassLandDegradation';

export const OptionalRanch = ({ value, handleChange, disabled }) => {

  const heading1 = "According to you, has there been a change in rain fall and temperature patterns over the last 20 years/informal timeline?";
  const heading2 = "What is the type of ownership of the ranch? (Private, community etc)";
  const heading3 = "Has there been an event of controlled burning in your ranch? If yes, please provide details.";
  const heading4 = "Have you removed any shrubs or invasive species?";
  const heading5 = "What are typical grass species found in your ranch? Are these perennial?";
  const heading6 = "Does wild animals graze in your land? If yes, please provide the type and count of the animals.";

  const onChange = (newValue, name) => {

    if (disabled) {
      return
    }

    const allValue = { ...value };
    allValue[name] = newValue;

    handleChange({ ...allValue })
  }

  return <ContainerCenter >
    <GrassLandDegradation
      disabled={disabled}
      name='grassLand'
      handleChange={onChange}
      value={value.grassLand || ""}
    />
    <Grid container item direction="column" sx={{width:'100%',paddingBottom:'2%',paddingTop:'4%', paddingLeft:'15%'}}>
    <SelectBm
     size="large"
     label="Select practice start year"
     fullWidth
     sx={{
         bgcolor: '#F1F1F1',
         width:'22%',
     }}
     value={value.practicedate}
     onChange={(event) => onChange(event.target.value, "practicedate")}
     list={['2024','2023','2022','2021','2020','2019','2018']}
     disabled={disabled}
    />
    </Grid>
    <Grid container item direction="column">
    <Grid sx={{width:'100%'}}>
    <HeadingAndTextField
      disabled={disabled}
      toggler
      name='rainfall'
      value={value.rainfall || ""}
      handleChange={onChange}
      heading={heading1}
      textFieldSize="large"
      placeholder='If Yes, Please describe'
    />
    <HeadingAndTextField
      disabled={disabled}
      name='ownership'
      value={value.ownership || ""}
      handleChange={onChange}
      heading={heading2}
      textFieldSize="large"
    />

     <HeadingAndTextField
      disabled={disabled}
      toggler
      name='burningpractice'
      value={value.burningpractice || ""}
      handleChange={onChange}
      heading={heading3}
      textFieldSize="large"
      placeholder='If Yes, Please describe'
    />
     <HeadingAndTextField
      disabled={disabled}
      toggler
      name='shrubs'
      value={value.shrubs || ""}
      handleChange={onChange}
      heading={heading4}
      textFieldSize="large"
      placeholder='If Yes, Please describe'
    />
     <HeadingAndTextField
      disabled={disabled}
      name='grassspecies'
      value={value.grassspecies || ""}
      handleChange={onChange}
      heading={heading5}
      textFieldSize="large"
    />
     <HeadingAndTextField
      disabled={disabled}
      toggler
      name='graze'
      value={value.graze || ""}
      handleChange={onChange}
      heading={heading6}
      textFieldSize="large"
      placeholder='If Yes, Please describe'
    />
    <HeadingAndTextField
      disabled={disabled}
      name='others'
      value={value.others || ""}
      handleChange={onChange}
      heading="Others"
      textFieldSize="large"
      placeholder="e.g. building a milk cooling plant for the community to use"
    />
    </Grid>
  </Grid>
  </ContainerCenter>
}

