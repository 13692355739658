/* eslint-disable react/prop-types */
import { Paper, Grid, IconButton, } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from "@mui/material/colors";
import { DetailEle } from "../DetailEle";
import { TableHeader } from "../TableHeaders";


export const FarmStat = ({ stat, handelModal }) => {
    const {
        totalArea: TOTALAREA,
        totalFarm: TOTALFARM,
        receivedFarm: UNCONFIRMEDFARM,
        reworkFarm: REWORKFARM,
        onboardedFarm: CONFIRMEDFARMS,
        re_subFarm: RESUBFarm,
        receivedArea: UNCONFIRMEDAREA,
        reworkArea: REWORKAREA,
        onboardedArea: CONFIRMEDAREA,
        re_subArea: RESUBFarmAREA,
        verifiedArea: VerifiedAREA,
        verifiedFarm: VerifiedFarm,
    } = stat;


    return <Paper sx={{
        margin: '80px auto 0 auto',
        padding: '1rem',
        overflow: 'hidden',
        backgroundColor: "#fff",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }}>
        <Grid container justifyContent="space-between">
            <IconButton onClick={handelModal}>
                <CancelIcon sx={{ color: red[500] }} fontSize="large" />
            </IconButton>
        </Grid>
        <div >
            <TableHeader label="AREA (ACRE) WISE STATISTICS" />
            <div style={{ display: 'flex', justifyContent: "center" }}>
                <DetailEle label="TOTAL" value={TOTALAREA} />
                <DetailEle label="ONBOARDED" value={CONFIRMEDAREA} />
                <DetailEle label="RECEIVED" value={UNCONFIRMEDAREA} />
                <DetailEle label="REWORK" value={REWORKAREA} />
                <DetailEle label="RESUB" value={RESUBFarmAREA} />
            </div>
            <div style={{ display: 'flex', justifyContent: "center" }}>
                <DetailEle label="Verified" value={VerifiedAREA} />
            </div>
            <TableHeader label="FARM WISE STATISTICS" />
            <div style={{ display: 'flex', justifyContent: "center" }}>
                <DetailEle label="TOTAL" value={TOTALFARM} />
                <DetailEle label="ONBOARDED" value={CONFIRMEDFARMS} />
                <DetailEle label="RECEIVED" value={UNCONFIRMEDFARM} />
                <DetailEle label="REWORK" value={REWORKFARM} />
                <DetailEle label="RESUB" value={RESUBFarm} />
            </div>
            <div style={{ display: 'flex', justifyContent: "center" }}>
                <DetailEle label="Verified" value={VerifiedFarm} />
            </div>
        </div>

    </Paper>
}