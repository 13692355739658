export const cattle = [
    "Angus",
    "Belted Galloway",
    "Brahman",
    "Charolais",
    "Dexter",
    "Gelbvieh",
    "Hereford",
    "Holstein",
    "Limousin",
    "Piedmontese",
    "Red angus",
    "Scottish highland",
    "Shorthorn",
    "Simmental",
    "Texas longhorn",
    "Watusi",
]