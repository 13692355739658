import { LoginForm } from '../components/authentication/login';
import { NoAuthTemplate } from '../layouts/NoAuthTemplaate';

export default function Login() {
  return (
    <NoAuthTemplate headin1="Login into Boomitra Partner Dashboard" heading2=" Enter your details below.">
      <LoginForm />
    </NoAuthTemplate>
  );
}
