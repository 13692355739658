/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import l, { circleMarker, latLngBounds, polygon, polyline, popup, } from 'leaflet';
import { AreaOfPolygon } from '../../utils/AreaPolygon';




export const MultiPolygonWithEdit = ({ coordinates, setCoor, pointCoor }) => {
  const map = useMap();

  const onClickRow = (id) => {
    const urlQuery = new URLSearchParams(window.location.search);
    const url = window.location.pathname;
    console.log(url);
    const queryUrl = `?id=${id}&pid=${urlQuery.get('pid')}`
    window.open(url + queryUrl, '_blank')
  }

  useEffect(() => {
    if (!coordinates) {
      return;
    }

    let parsedCoordinates = coordinates;

    if (typeof coordinates === 'string') {
      parsedCoordinates = JSON.parse(coordinates);
    }

    const allCoor = [];
    for (const ele of parsedCoordinates) {
      const { coor, color, pmIgnore, center } = ele;

      if (center) {
        coor[0].forEach((ele) => {
          allCoor.push(ele);
        });
      }

      const newPolygon = polygon(coor)

        .setStyle({
          color: color || "white",
          weight: 3,
          pmIgnore: pmIgnore !== false
        })
        .addTo(map)
        .on('click', function () {
          if (ele.id) {
            onClickRow(ele.id)
            return;
          }
          popup({
            closeButton: false
          }).setContent(ele.id)
            .setLatLng(this.getBounds().getCenter())
            .setContent(`<p>${AreaOfPolygon([coor], !true)} acres</p>`)
            .openOn(map);
        });
      if (pmIgnore === false) {
        newPolygon.on('pm:edit', ({ layer }) => {
          const { geometry } = layer.toGeoJSON();
          const { coordinates } = geometry;
          setCoor(coordinates)
        });
        const center = newPolygon.getCenter()
        circleMarker(center, { radius: 2, color: 'red' }).addTo(map);
        if (pointCoor) {
          pointCoor.forEach((ele, index) => {
            circleMarker(ele, { radius: 2, color: 'white' }).addTo(map)
            const verificationPoint = new l.LatLng(ele[0], ele[1])
            polyline([center, verificationPoint], {
              color: 'red',
              weight: 3,
              opacity: 0.5
            }).setStyle({
              pmIgnore: true,
              color: index + 1 === pointCoor.length ? "blue" : 'white'
            })
              .addTo(map)
              .on("click", function () {
                popup({
                  closeButton: false
                })
                  .setLatLng(this.getCenter())
                  .setContent(`<p>${center.distanceTo(verificationPoint).toFixed(2)} meters </p>`)
                  .openOn(map);
              })
          })
        }
      }

    }
    if (allCoor.length) {
      const bbox = latLngBounds(allCoor);
      map.fitBounds(bbox);
    }

    return () => {
      map.eachLayer((layer) => {
        if (layer instanceof l.Polygon && !(layer instanceof l.Rectangle)) {
          layer.remove();
        }
      });
    };
  }, [coordinates]);

  return null;
};
