/* eslint-disable react/prop-types */
export const CustomTooltipBarBm = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const { payload: data } = payload[0]
        return (
            <div style={{
                backgroundColor: "#fff",
                padding: '1rem'
            }}>
                <p>{label}</p>
                <p style={{ color: "#ea6a47" }}> Number Of Farms : {data.totalfarm} ({data.totalarea?.toFixed(2)} acres)
                </p>
                <p style={{
                    color: "#05AA02"
                }}> ONBOARDED Farms {data.onboarded} ({data.onboardedarea?.toFixed(2) || 0} acres)  </p>
                <p style={{
                    color: "#004752"
                }}>
                    VERIFIED Farms : {data.verified} ({data.verifiedarea?.toFixed(0) || 0} acres)
                </p>
                <p style={{
                    color: "#ffcc00"
                }}> RECEIVED Farms: {data.received} ({data.receivedarea?.toFixed(2) || 0} acres)  </p>
                <p style={{
                    color: "#199ae0"
                }}> REWORK Farms: {data.rework} ({data.reworkarea?.toFixed(0) || 0} acres)  </p>
                <p style={{
                    color: "#B284BE"
                }}> RESUB Farms : {data.re_sub} ({data.re_subarea?.toFixed(0) || 0} acres)  </p>
                <p style={{
                    color: "#004752"
                }}>
                    Active REPS : {data.activerep}
                </p>
            </div >
        );
    }

    return null;
};