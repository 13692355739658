import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import searchIcon from '@iconify/icons-ic/search';
import fileSearchOutlined from '@iconify/icons-ant-design/file-search-outlined';
import sharpElevator from '@iconify/icons-ic/sharp-elevator';
import mapFill from '@iconify/icons-eva/map-fill';
import plusCircleFill from '@iconify/icons-eva/plus-circle-fill';
import fileIcon from '@iconify/icons-eva/file-fill';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = (superman,readonly) => {
  const config = [
    {
      title: 'Dashboard',
      path: '/dashboard/AllDashBoard',
      icon: getIcon(pieChart2Fill)
    },
    {
      title: 'Search between dates',
      path: '/dashboard/searchbetweenDate',
      icon: getIcon(peopleFill)
    },
    {
      title: 'Validation',
      path: '/dashboard/Validation',
      icon: getIcon(peopleFill)
    },
    {
      title: 'Partner wise dashboard',
      path: '/dashboard/app',
      icon: getIcon(pieChart2Fill)
    },
    {
      title: 'REP Activity',
      path: '/dashboard/SearchbyDate',
      icon: getIcon(searchIcon)
    },
    {
      title: 'Search field',
      path: '/dashboard/SearchFieldByRep',
      icon: getIcon(fileSearchOutlined)
    },
    {
      title: 'REP',
      path: '/dashboard/REP',
      icon: getIcon(peopleFill)
    },
    {
      title: 'MAP',
      path: '/dashboard/MAP',
      icon: getIcon(mapFill)
    },
    {
      title: 'Farm status',
      path: '/dashboard/farmStatus',
      icon: getIcon(mapFill)
    },
    {
      title: 'Ranch Validation',
      path: '/dashboard/ranchValidation',
      icon: getIcon(mapFill)
    }
  ];

  // readonly will never be same as superman
  if(readonly){
    config.push(
      {
        title: 'Super user',
        path: '/dashboard/Superman',
        icon: getIcon(sharpElevator)
      }
    );
  }

  if (superman) {
    config.push(
      {
        title: 'Package of Practice',
        path: '/dashboard/pop',
        icon: getIcon(mapFill)
      },
      {
        title: 'POP Reports',
        path: '/dashboard/popReports',
        icon: getIcon(fileIcon)
      },
      {
        title: 'Onboarding Ranch',
        path: '/dashboard/Onboarding',
        icon: getIcon(plusCircleFill)
      },
      {
        title: 'Login details',
        path: '/dashboard/loginDet',
        icon: getIcon(sharpElevator)
      },
      {
        title: 'Survey Dashboard',
        path: '/dashboard/survey',
        icon: getIcon(sharpElevator)
      },
      {
        title: 'Super user',
        path: '/dashboard/Superman',
        icon: getIcon(sharpElevator)
      }
    );
  }

  return config;
};

export default sidebarConfig;
