/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { useState } from "react";
import { Grid, Paper, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { HeaderAndCommentsIcon } from './HeaderAndCommentsIcon';
import { CommentsModal } from './CommentsModal';

export const ListDetails = ({ header, keyValue, sx, elevation, commentList, comments = [], setComments, isVerificationReady }) => {
    const [modal, setModal] = useState(false);
    const { readonly } = useSelector((store) => store.userRoot.user);

    return <Paper elevation={elevation || 10} sx={{
        padding: '1rem',
        marginTop: '0.2rem',
        width: '100%',
        border: comments.length ? '2px solid red' : null,
        ...sx
    }}>
        <Grid container width='100%'>
            <HeaderAndCommentsIcon
                isVerificationReady={isVerificationReady}
                modal={modal}
                setModal={setModal}
                comments={comments}
                setComments={setComments}
                commentList={commentList}
                header={header}
                readonly={readonly}
            />
            <Grid item sx={{
                padding: '0.4rem'
            }} >
                {Object.keys(keyValue).map((ele, index) =>
                    <div key={index}>
                        <Typography variant="body1" fontSize='0.8rem' sx={{
                            margin: '0.6rem 0',
                            wordBreak: 'break-word'
                        }}>
                            <strong>
                                {ele} :
                            </strong> {` ${keyValue[ele]}` || 'N/A'}
                        </Typography>
                    </div>
                )}
            </Grid>
        </Grid>
        <CommentsModal
            modal={modal}
            setModal={setModal}
            comments={comments}
            setComments={setComments}
            commentList={commentList}
        />
    </Paper>
}