/* eslint-disable valid-typeof */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CoPresent from '@mui/icons-material/CoPresent';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Grain from '@mui/icons-material/Grain';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { Box, TextField, Grid, Button } from '@mui/material';
import api from '../components/api/api';
import { SearchFieldByDateMobColumn } from '../components/GridColumns';
import Page from '../components/Page';
import { DateModal } from '../utils/DateModal';
import { TableHeader } from '../components/TableHeaders';
import { FarmerUserDetail } from '../components/details/FarmerUserDetail';
import { FarmerDetails } from '../components/details/FarmerDetails';
import { FieldDetails } from '../components/details/FieldDetails';
import { ModalBM } from '../components/ModalBM';
import { CardBM } from '../components/_dashboard/app';
import { validateMobile } from '../utils/transForm';
import { DatePickerBM } from '../components/DatePicker';
import { AllFieldMapWrap } from '../components/AllFieldMapWrap';
import './table.css';

let totalArea = 0;
let farmer = new Set();
let allCoordinate = [];
let id;
export const SearchFieldByDateMob = () => {
    const { state } = useLocation();
    const [error, setError] = useState("")
    const [errorMobile, setErrorMobile] = useState(false);
    const { user } = useSelector((store) => store.userRoot);
    const [mobile, setMobile] = useState("")
    const [rep, setrep] = useState({})
    const [farmerModal, setFarmerModal] = useState(false)
    const [fieldModal, setFieldModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [fieldDetail, setFieldDetail] = useState()
    const [farmerDetail, setFarmerDetail] = useState()
    const [rows, setRows] = useState([]);
    const [mapModal, setmapModal] = useState(false);
    const [calDate, setCalDate] = useState(new Date());

    const getAllCoor = () => {
        if (allCoordinate.length === 0 || (state && id !== state.mobile) || id !== mobile) {
            id = state ? state.mobile : mobile;
            allCoordinate = [];
            rows.forEach((row) => {
                const { polygon, center, } = row.field_markers;
                allCoordinate.push({ polygon, center, status: row.status, famerName: `${row.farmerGivenName} ${row.farmerFamilyName}`, land_area: row.landArea });
            });
        }
        setmapModal(!mapModal);
    };

    useEffect(() => {
        totalArea = 0;
        farmer = new Set();
        if (state && Object.keys(state).length !== 0) {
            setMobile(state.mobile);
            setCalDate(state.date);
            onSearch(state.mobile, state.date, state.contractorID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFieldData = async (date, mobile, contractorID) => {

        totalArea = 0;
        const partnerId = user.partnerID;
        try {
            setRows([])
            setLoading(true)
            setrep({})
            setError("")
            let url = `/searchFarmByRepAndDate?date=${date}&parnerId=${partnerId}&mobile=${mobile}`;
            url += contractorID ? `&contractorID=${contractorID}` : "";
            const { data } = await api.get(url);
            const dataType = typeof data;
            if (dataType === typeof ("Ajay")) {
                setError(data)
            } else {
                setrep(data.ContratorDetail)
                if (typeof (data.fieldDetail) === typeof ("Ajay")) {
                    setError(data.fieldDetail)
                } else {
                    const filterData = []
                    data.fieldDetail.forEach((ele) => {
                        filterData.push(ele);
                        totalArea += ele.landArea;
                        farmer.add(ele.farmerUserId)
                        
                    })
                    setRows(filterData);
                }
            }
           
        } catch (error) {
            setRows([])
        }
        setLoading(false);
    }

    const onSearch = (mobile2, date2, contractorID) => {
        let rawMobile = mobile;
        if (mobile2) {
            rawMobile = mobile2
        }

        const modalMobile = validateMobile(rawMobile)
        if (!modalMobile) {
            setErrorMobile(true)
            return
        }
        let date = calDate;
        if (date2) {
            date = date2
        }
        const modaledDate = DateModal(date);
        getFieldData(modaledDate, modalMobile, contractorID);
    }

    const fieldDetailSetter = (row) => {
        setFieldDetail(row);
        setFieldModal(!fieldModal);
    };

    const farmerDetailSetter = (row) => {
        // const farmerDetails = TransFormFarmer(row)
        setFarmerDetail(row)
        setFarmerModal(!farmerModal)
    }

    return (
        <Page
            style={{
                display: 'flex',
                minHeight: '100%',
                flexDirection: "column"
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <CardBM
                        label="Number of FARMERS"
                        color="linear-gradient(to left, #93F9B9, #1D976C)"
                        ICONS={CoPresent}
                        count={farmer.size}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <CardBM
                        label="Number of FARMS"
                        color="linear-gradient(to left, #a8e063, #56ab2f)"
                        ICONS={Grain}
                        count={rows.length}

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <CardBM
                        label="Total AREA (ACRES)"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        ICONS={AgricultureIcon}
                        count={totalArea}
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    margin: "2rem 0",
                }}
            >
                <DatePickerBM
                    value={calDate}
                    setDate={(value) => setCalDate(value)}
                />
                <TextField
                    placeholder='REP Mobile Number...'
                    label="Mobile Number"
                    sx={{
                        margin: "0 1rem"
                    }}
                    value={mobile}
                    error={errorMobile}
                    helperText={errorMobile && "Invalid Mobile Number"}
                    onChange={(e) => {
                        setErrorMobile(false);
                        setMobile(e.target.value)
                    }
                    }
                />
                <Button
                    sx={{
                        color: '#000',
                        background: '#fcba03',
                        margin: '0.5rem 0',
                        height: "2.5rem"
                    }}
                    onClick={() => onSearch()}
                    variant="contained">
                    Search
                </Button>
            </div>
            <FarmerUserDetail label="REP DETAIL" userDetail={rep} />
            <h1
                style={{
                    textAlign: "center"
                }}
            >
                {error}
            </h1>
            <>
                {loading ? (
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}
                    >
                        <CircularProgress size={100} />
                    </Box>
                ) : rows.length !== 0 && !loading && (
                    <Box
                        style={{
                            flexGrow: 1,
                            height: '90vh',
                            justifyContent: "center"
                        }}
                    >
                        <TableHeader label="FARM ADDED BY REP" Icon={AgricultureIcon} />
                        <Button
                            variant="contained"
                            onClick={getAllCoor}
                            sx={{
                                color: '#000',
                                background: '#fcba03',
                                margin: '0.5rem 0'
                            }}
                        >
                            All Fields Map
                        </Button>
                        <DataGridPro
                            rows={rows}
                            density="comfortable"
                            columns={SearchFieldByDateMobColumn(fieldDetailSetter, farmerDetailSetter)}
                            pageSize={50}
                            disableSelectionOnClick
                        />
                    </Box>
                )
                }
                {rows.length === 0 && !loading && !error &&
                    <div
                        style={{ "textAlign": 'center' }}
                    >Please Enter Rep Mobile Number and Date for Farm Data </div>}
            </>
            <ModalBM modelUp={fieldModal} handelModal={() => setFieldModal(!fieldModal)}>
                <FieldDetails row={fieldDetail} handelModal={() => setFieldModal(!fieldModal)} />
            </ModalBM>
            <ModalBM modelUp={farmerModal} handelModal={() => setFarmerModal(!farmerModal)}>
                <FarmerDetails
                    row={farmerDetail}
                    handelModal={() => setFarmerModal(!farmerModal)}
                />
            </ModalBM>
            <AllFieldMapWrap
                getAllCoor={getAllCoor}
                mapModal={mapModal}
                allCoordinate={allCoordinate}
            />
        </Page>
    );
};
