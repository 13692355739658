/* eslint-disable react/prop-types */
import { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const BasicSelect = ({ leaderIds, filterLeaderId, value }) => {
    const [leader, setLeader] = useState(value || '');

    const handleChange = (event) => {
        setLeader(event.target.value);
        filterLeaderId(event.target.value)
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel >Leaders</InputLabel>
                <Select
                    value={leader}
                    label="Leader Id"
                    onChange={handleChange}
                >
                    <MenuItem
                        key="all"
                        value="all">
                        All
                    </MenuItem>
                    {
                        leaderIds.map(ele => <MenuItem
                            key={ele}
                            value={ele}>
                            {ele}
                        </MenuItem>)
                    }
                </Select>
            </FormControl>
        </Box>
    );
}