/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react'
import { Stack } from '@mui/material';
import api from 'src/components/api/api';
import { InputText, ModalBm, UploadFile, ButtonBm } from 'src/components/@material-extend';
import popApi from 'src/components/api/popApi';

export const ActivityContentModal = ({
  showModal,
  setShowModal,
  onSubmit,
  activityName,
  selectedStage,
  data
}) => {
  const folderName = 'POPDoc';
  const [fileKey, setFileKey] = useState('')
  const [desc, setDesc] = useState('');
  const [days, setDays] = useState(0);

  const imgPathUrl = (key) => `https://b-aps1-s3-pop-content.s3.ap-south-1.amazonaws.com/${key}`


  useEffect(() => {
    setDesc(data.description);
    setDays(data.days);
    setFileKey(data.fileKey);
  }, [data])

  return (
    <ModalBm
      showModal={!!showModal}
      title={`${selectedStage} : ${activityName}`}
      setShowModal={() => setShowModal("")}>
      <Stack justifyContent='center' alignItems='center' spacing={1}>
        <UploadFile
          api={popApi}
          url='/pop/uploadDocs'
          onUploadSucessed={(key) => setFileKey(key)}
          folder={folderName}
          fileType={['.jpg', '.png', '.jpeg']}
          getObject={false}
          height={fileKey ? '20vh' : '30vh'}
        />
        {data.fileKey ? <img
          src={imgPathUrl(fileKey)}
          alt="photos"
          width={350}
          height={350}
          style={{
            objectFit: 'contain'
          }}
        /> : null}
        <InputText
          fullWidth
          label="Description"
          variant="outlined"
          placeholder="Description"
          required
          multiline
          rows={3}
          value={desc}
          onChange={({ target }) => setDesc(target.value)}
          sx={{ bgcolor: '#F1F1F1', borderRadius: 1 }}
        />
        <InputText
          required
          variant="outlined"
          placeholder="Enter number of days"
          label="Enter number of days"
          style={{
            backgroundColor: '#F1F1F1',
          }}
          value={days}
          type='number'
          onChange={({ target }) => setDays(target.value)}
          unit='days from sowing date'
        />
        <ButtonBm
          style={{
            backgroundColor: '#004752',
            width: '60%',
          }}
          onClick={() => onSubmit(desc, days, fileKey)}
        >
          Add Content
        </ButtonBm>
      </Stack>
    </ModalBm>
  );
};
