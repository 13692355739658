/* eslint-disable react/prop-types */
import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const DataGridIconMenu = ({ list, onClick, params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (v) => {
        if (v) {
            const { row } = params
            onClick({ ...row, type: v });
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                variant='contained'
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >{
                    list.map((ele, index) => <MenuItem key={index} onClick={() => handleClose(ele)}>{ele}</MenuItem>
                    )
                }
            </Menu>
        </div>
    );
}