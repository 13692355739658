export const horses = [
    "Mangalarga Marchador",
    "Puerivan Paso",
    "Paso Fino",
    "Falabella",
    "Criollo",
    "Chilean Horse",
    "Campeiro",
    "Campolina",
    "Brazilian Sport Horse",
    "Pampa"
]
