/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import { saveAs } from 'file-saver'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Button, IconButton } from "@mui/material";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import CloseIcon from "@mui/icons-material/Close";
import FitScreenIcon from '@mui/icons-material/FitScreen';
import { ModalBM } from "src/components/ModalBM";

let rotation = 0;
export const ImagesBM = ({ url }) => {
	const imageEl = useRef(null);
	const [modal, setModal] = useState(false);

	const onRotate = (val) => {
		if (!val) {
			rotation = 0;
		} else {
			rotation += val + 20;
			if (rotation > 360) {
				rotation = 0;
			} else if (rotation < 0) {
				rotation = 360;
			}
		}
		imageEl.current.style.transform = `rotate(${rotation}deg)`;
	};

	const img = (style) => <img
		src={url}
		alt="photos"
		ref={imageEl}
		style={{
			margin: "0 auto",
			width: '100%',
			height: '100%',
			...style
		}}
	/>

	const downloadImage = () => {
		const filename = 'image.jpg';
		fetch(url)
			.then(response => response.blob())
			.then(blob => {
				saveAs(blob, filename);
			})
			.catch(error => {
				console.error('Error downloading the image:', error);
			});
	}

	return (
		<TransformWrapper
			initialScale={1}
			initialPositionX={0}
			initialPositionY={0}
		>
			{({ zoomIn, zoomOut, resetTransform }) => (
				<>
					<div style={{
						float: 'right',
						zIndex: 2000,
					}}>
						<Button onClick={downloadImage}>
							Download
						</Button>
						<IconButton onClick={() => {
							setModal(true)
							resetTransform()
						}} >
							<FitScreenIcon />
						</IconButton>
					</div>
					<TransformComponent
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div style={{
							width: "60vw",
							height: "40vh"
						}}>
							{img()}
						</div>

					</TransformComponent>
					<ModalBM handelModal={() => setModal(false)} modelUp={modal} sx={{
						height: '90vh',
						overflow: 'auto',
						width: '40vw',
						display: 'flex',
						justifyContent: 'center'
					}}>
						<div>
							<TransformComponent>
								{img({
									margin: "0 auto",
									width: '40vw',
									height: '90vh',
								})}
							</TransformComponent>
							<Controlles
								{...{
									zoomIn, zoomOut, resetTransform, onRotate, color: '#fff', sx: {
										zIndex: 1000,
										position: 'fixed',
										bottom: '10%',
										left: '45%',
										backgroundColor: '#000'
									}
								}}
							/>
						</div>
					</ModalBM>
					<Controlles
						{...{ zoomIn, zoomOut, resetTransform, onRotate, color: '#000' }}
					/>
				</>
			)}
		</TransformWrapper>
	);
};

const Controlles = ({ zoomIn, zoomOut, resetTransform, color, onRotate, sx }) => <div
	style={{
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		...sx,
	}}
>
	<IconButton variant="contained" onClick={() => onRotate(5)}>
		<RotateRightIcon sx={{
			color
		}} />
	</IconButton>
	<IconButton variant="contained" onClick={() => zoomIn()}>
		<ZoomInIcon sx={{
			color
		}} />
	</IconButton>
	<IconButton
		variant="contained"
		onClick={() => {
			resetTransform();
			onRotate();
		}}
	>
		<CloseIcon sx={{
			color
		}} />
	</IconButton>
	<IconButton variant="contained" onClick={() => zoomOut()}>
		<ZoomOutIcon sx={{
			color
		}} />
	</IconButton>
	<IconButton variant="contained" onClick={() => onRotate(-5)}>
		<RotateLeftIcon sx={{
			color
		}} />
	</IconButton>
</div>