/* eslint-disable react/prop-types */
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton } from '@mui/material';
import { TypographyBm , ModalBm } from '../../components/@material-extend';





const typographyStyle = {
  textAlign: 'center',
  fontWeight: 'normal',
  color: '#6F6C90'
}

export const InfoModal = () => {
  const [showInfoModal, setShowInfoModal] = useState(false)

  return (
    <>
      <IconButton
        aria-label="delete"
        size="large"
        sx={{ color: 'white' }}
        onClick={() => setShowInfoModal(true)}
      >
        <InfoIcon />
      </IconButton>
      <ModalBm
        showModal={showInfoModal}
        setShowModal={() => setShowInfoModal(!showInfoModal)}
        title="Tutorial"
      >
        <Box>
          <div
            style={{ paddingTop: '1%', paddingLeft: '1%', justifyContent: 'center', display: 'flex' }}
          >
            <iframe
              src="https://www.youtube.com/embed/E91dIVr100Q"
              title="Tutorial Video"
              width="100%"
              height="350px"
              allowFullScreen
            />
          </div>
          <div style={{ margin: '8vh 8vh 0 8vh' }}>
            <TypographyBm
              variant="text"
              sx={typographyStyle}
            >
              To mark your ranch on the map, you can either :
            </TypographyBm>
            <br /><br />
            <TypographyBm
              variant="text"
              sx={typographyStyle}
            >
              1. Use the draw tool on the map to draw your ranch
            </TypographyBm>
            <br />
            <TypographyBm
              variant="text"
              sx={typographyStyle}
            >
              2. Upload KML file of your ranch/ranches
            </TypographyBm>{' '}
            <br />
            <TypographyBm
              variant="text"
              sx={typographyStyle}
            >
              Please watch the video to understand how to upload KML file of your ranch or mark your
              ranch area using the draw polygon tool.
            </TypographyBm>
          </div>
        </Box>
      </ModalBm>
    </>
  );
};
