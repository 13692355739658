/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { Box, Grid, Paper, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { red } from '@mui/material/colors';
import GrainIcon from '@mui/icons-material/Grain';
import CancelIcon from '@mui/icons-material/Cancel';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FlakyIcon from '@mui/icons-material/Flaky';
import GrassIcon from '@mui/icons-material/Grass';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import WaterIcon from '@mui/icons-material/Water';
import PetsIcon from '@mui/icons-material/Pets';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import NumbersIcon from '@mui/icons-material/Numbers';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RuleIcon from '@mui/icons-material/Rule';
import { DetailEle } from '../DetailEle';
import { TableHeader } from '../TableHeaders';
import css from './farmerDetails.css';
import { getImagesURL } from '../../utils/getURL';
import { Chips } from '../RenderChip';
import { ImagesBM } from '../imagesBM';
import { FieldMap } from '../FieldMap';




export const CompleteDetails = ({ row, handelModal }) => {
    const classes = css();
    const [idProof, setIdProof] = useState([]);
    const [signature, setSignature] = useState([]);

    useEffect(() => {
        const getUrl = async () => {
            const newidProof = await getImagesURL([row.id_photo_path]);
            const newsignature = await getImagesURL([row.signature_photo_path]);
            setIdProof(newidProof);
            setSignature(newsignature);
        };
        getUrl();
    }, [row]);
 

    useEffect(() => {
        if (!handelModal) {
            return;
        }
        const close = (e) => {
            if (e.keyCode === 27) {
                handelModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const [landImgPath, setLandImgPath] = useState([]);
    let { polygon, center, status, land_area } = row.field_markers;

    land_area = row.land_area;
    status = row.status

    const coordinates = [{ polygon, center, status, land_area }];

    useEffect(() => {
        const getUrl = async () => {
            const newlandImgPath = await getImagesURL(
                row.land_record_photo_path,
                "|",
            );
            setLandImgPath(newlandImgPath);
        };
        getUrl();
    }, [row]);

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <Paper className={classes.Container}>
                {handelModal &&
                    <IconButton onClick={
                        () => {
                            if (handelModal) {
                                handelModal()
                            }
                        }
                    }>
                        <CancelIcon sx={{ color: red[500] }} fontSize="large" />
                    </IconButton>
                }
                <Grid container justifyContent="space-between">
                    <TableHeader label="FARMER DETAILS" Icon={PermIdentityIcon} />
                    <Grid item />
                </Grid>
                <Grid container justifyContent="space-around">
                    <DetailEle label="Farmer Status" Icon={FlakyIcon} value={row.status} />
                    <DetailEle label="Onboarded On" Icon={DoubleArrowIcon} value={row.createdAt} />
                </Grid>
                <Box border={1} className={classes.Box}>
                    <div className={classes.row}>
                        <DetailEle
                            label="Name"
                            Icon={AccountBoxIcon}
                            value={`${row.given_name} ${row.family_name}`}
                        />
                        <DetailEle label="Mobile Number" Icon={LocalPhoneIcon} value={row.phone_number} />
                        <DetailEle label="Farm Area In Acre" Icon={GrassIcon} value={row.area} />
                        <DetailEle
                            label="Address"
                            Icon={LocationOnIcon}
                            value={`${row.address1}, ${row.town}, ${row.state}, ${row.country}`}
                        />
                        <DetailEle label={row.id_type} Icon={BadgeIcon} value={row.id_number} />
                        <DetailEle label="Bank Account Number" Icon={QrCode2Icon} value={row.bank_account} />
                        {row.bank_code && (
                            <DetailEle label="IFSC Number" Icon={AccountBalanceIcon} value={row.bank_code} />
                        )}
                        {row.bank_account_holder_first_name && row.bank_account_holder_last_name && (
                            <DetailEle label="Bank Account Holder Name" Icon={AccountBalanceIcon} value={`${row.bank_account_holder_first_name} ${row.bank_account_holder_last_name}`} />
                        )}
                    </div>
                </Box>
                {row.internal_comment && <Box border={1} className={classes.Box}>
                    <TableHeader
                        label="Boomitra Comments"
                        style={{
                            alignSelf: 'flex-start'
                        }}
                        Icon={InsertCommentIcon}
                    />
                    <Chips
                        dataArray={row.internal_comment}
                    />
                </Box>}
                <Box>
                    <h3 style={{ textAlign: "center" }}>Id Proof</h3>
                    {idProof.map((ele, index) => (
                        <div
                            key={index}
                            style={{
                                border: "1rem solid black",
                                overflow: "scroll",
                            }}
                        >
                            <ImagesBM url={ele} />
                        </div>
                    ))}
                </Box>
                <Box>
                    <h3 style={{ textAlign: "center" }}>Signature</h3>
                    {signature.map((ele, index) => (
                        <div
                            key={index}
                            style={{
                                border: "1rem solid black",
                                overflow: "scroll",
                            }}
                        >
                            <ImagesBM url={ele} />
                        </div>
                    ))}
                </Box>
            </Paper>
            <Paper className={classes.Container}>
                <Grid container justifyContent="space-between">
                    <TableHeader
                        label="FARM DETAILS"
                        style={{
                            alignSelf: 'flex-start'
                        }}
                        Icon={GrainIcon}
                    />
                    <Grid item />
                </Grid>
                <Grid container justifyContent="space-around">
                    <DetailEle label="Farmer Status" Icon={FlakyIcon} value={row.status} />
                    <DetailEle label="Onboarded On" Icon={DoubleArrowIcon} value={row.createdAt} />
                </Grid>
                <Box border={1} className={classes.Box}>
                    <div className={classes.row}>
                        <DetailEle
                            label="Farm Area"
                            Icon={GrassIcon}
                            value={`${+ row.land_area.toFixed(2)} 
          ${row.land_area > 1 ? 'Acres' : 'Acre'} `}
                        />
                        <DetailEle
                            label="Practice Started On"
                            Icon={DoubleArrowIcon}
                            value={`${row.practiceStartDate} `}
                        />
                        {row.crops_before && (
                            <Chips
                                dataArray={row.crops_before}
                                Icon={InsertInvitationIcon}
                                label="Crop Before Practice Started"
                            />
                        )}
                        {row.crops_after && (
                            <Chips
                                dataArray={row.crops_after}
                                Icon={EventAvailableIcon}
                                label="Crop After Practice Started"
                            />
                        )}
                        {row.new_practices && (
                            <Chips dataArray={row.new_practices} Icon={FiberNewIcon} label="New Practices" />
                        )}
                        {row.recent_practice_improvements && (
                            <DetailEle
                                label="Recent Practice Improvement"
                                Icon={PlusOneIcon}
                                value={row.recent_practice_improvements}
                            />
                        )}
                        {row.compost_flag === 'Yes' && (
                            <DetailEle
                                label="Compost Rate"
                                Icon={SettingsInputCompositeIcon}
                                value={`${row.compost_rate} kg / year`}
                            />
                        )}
                        <DetailEle label="Grazing" Icon={PetsIcon} value={row.grazing_flag} />
                        <DetailEle label="Flooding" Icon={WaterIcon} value={row.flooding_flag} />
                        {row.tillage_flag === 'Yes' && (
                            <DetailEle
                                label="Tillage Depth"
                                Icon={TripOriginIcon}
                                value={`${row.tillage_depth} cm`}
                            />
                        )}
                        {row.tillage_flag === 'Yes' && (
                            <DetailEle
                                label="Tillage Frequency"
                                Icon={NumbersIcon}
                                value={`${row.tillage_frequency}
        times / year`}
                            />
                        )}
                        {row.irrigation_flag === 'Yes' && (
                            <DetailEle
                                label="Irrigation Type"
                                Icon={BorderBottomIcon}
                                value={row.irrigation_type}
                            />
                        )}
                        {row.irrigation_flag === 'Yes' && (
                            <DetailEle
                                label="Irrigation rate"
                                Icon={CalendarViewWeekIcon}
                                value={`${row.irrigation_rate} times / year`}
                            />
                        )}
                        {row.manure_flag === 'Yes' && (
                            <DetailEle
                                label="Manure rate"
                                Icon={CalendarViewWeekIcon}
                                value={`${row.manure_rate} kg / year`}
                            />
                        )}
                        {row.synthetic_fertilizer_rate && (
                            <DetailEle
                                label="Synthetic Fertilizer rate"
                                Icon={CalendarViewWeekIcon}
                                value={`${row.synthetic_fertilizer_rate} kg / year`}
                            />
                        )}
                    </div>
                </Box>
                {row.internal_comment && row.internal_comment !== "\n\n" && <Box border={1} className={classes.Box}>
                    <TableHeader
                        label="Boomitra Comments"
                        style={{
                            alignSelf: 'flex-start'
                        }}
                        Icon={InsertCommentIcon}
                    />
                    <Chips
                        dataArray={row.internal_comment}
                    />
                </Box>}
                <Box>
                    <TableHeader
                        label="FARM ON MAP"
                        style={{
                            alignSelf: 'flex-start'
                        }}
                        Icon={LocationOnIcon}
                    />
                    <FieldMap
                        coordinates={coordinates}
                        style={{
                            width: '80%'
                        }}
                    />
                </Box>
                <Box>
                    <h3 style={{ textAlign: "center" }}>Land Related Documents</h3>
                    {landImgPath.map((ele, index) => (
                        <div
                            key={index}
                            style={{
                                border: "1rem solid black",
                                overflow: "scroll",
                            }}
                        >
                            <ImagesBM url={ele} />
                        </div>
                    ))}
                </Box> 
                {row.updated_by_id &&
                    <Box>
                        <TableHeader
                            label="VALIDATION"
                            style={{
                                alignSelf: 'flex-start'
                            }}
                            Icon={RuleIcon}
                        />
                        <div className={classes.row}>
                            <DetailEle label="Validated By" Icon={HowToRegIcon} value={row.updated_by_id} />
                            <DetailEle label="Validated on" Icon={DomainVerificationIcon} value={new Date(row.updatedAt).toDateString().slice(4)} />
                        </div>
                    </Box>
                }
            </Paper>
        </div >
    );
};
