/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { TypographyBm } from '../TypographyBm';
import { InputText } from '../../../components/@material-extend/InputText';
// eslint-disable-next-line import/no-useless-path-segments
import { ToggleButtonBM } from '../../../components/@material-extend/ToggleButtonBM';

export const HeadingAndTextField = ({ value,
  handleChange,
  textFieldSize,
  heading,
  toggler,
  name,
  type,
  unit,
  sx, placeholder, disabled }) => {

  const [toggleValue, setToggleValue] = useState(false);

  const size = {
    small: 2,
    medium: 3,
    large: 6
  };

  useEffect(() => {
    if (value && value.length && toggler) {
      setToggleValue(true);
    }
  }, [value])


  const onToggle = () => {
    if (disabled) {
      return
    }
    setToggleValue(!toggleValue);
    if (toggleValue) {
      handleChange('', name)
    } else {
      handleChange('*', name)
    }
  }

  return (
    <Box sx={{ flexGrow: 1, paddingLeft: '15%', paddingTop: '3%', ...sx }}>
      <Grid container spacing={5}>
        <Grid item>
          <TypographyBm color="#404D61" width="18vw" fontWeight={500} fontSize={17} fontFamily='DM Sans'>
            {heading}
          </TypographyBm>
        </Grid>

        <Grid item xs={size[textFieldSize]} rowGap={2}>
          {toggler && (
            <ToggleButtonBM
              selected={toggleValue}
              toggleSelected={onToggle}
            />
          )}

          {toggleValue && toggler && (
            <InputText
              disabled={disabled}
              name={name}
              placeholder={textFieldSize === 'large' && toggler && placeholder}
              rows={textFieldSize === 'large' ? 2 : 1}
              value={value.replace('*', '') || ''}
              onChange={({ target }) => toggler ? handleChange(target.value, target.name) : handleChange('No')}
              unit={unit}
              style={{
                backgroundColor: '#F1F1F1'
              }}
              type={type}
              multiline={!type}
            />
          )}

          {!toggler && (
            <InputText
              disabled={disabled}
              name={name}
              rows={textFieldSize === 'large' ? 2 : 1}
              value={value || ''}
              onChange={({ target }) => handleChange(target.value, target.name)}
              unit={unit}
              style={{
                backgroundColor: '#F1F1F1'
              }}
              placeholder={placeholder}
              type={type}
              multiline={!type}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
