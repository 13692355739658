/* eslint-disable react/prop-types */
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { useCallback, useState } from 'react';
import { RenderActiveShape } from './PieRenderActiveShape';

const COLORS = ['#05AA02', '#004752', "#ffcc00", "#199ae0", "#B284BE"];
export const PieBM = ({ data, nameKey, dataKey, key3 }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const keyList = [dataKey];

  return (
    <ResponsiveContainer height={300} width="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={<RenderActiveShape keyList={keyList} key3={key3} />}
          onMouseEnter={onPieEnter}
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          innerRadius={70}
          outerRadius="65%"
          fill="#8884d8"
          dataKey={dataKey}
          nameKey={nameKey}
          paddingAngle={1}
          fillOpacity={0.8}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};
