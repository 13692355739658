/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import GrassIcon from '@mui/icons-material/Grass';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GrainIcon from '@mui/icons-material/Grain';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import CoPresent from '@mui/icons-material/CoPresent';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import api from '../components/api/api';
import { CardBM } from '../components/_dashboard/app';
import Page from '../components/Page';
import { DateModal } from '../utils/DateModal';
import { TableHeader } from '../components/TableHeaders';
import { DatePickerBM } from '../components/DatePicker';
import { BarBm } from '../components/charts/BarBm';
import { userLoginHelper } from '../redux/actions/userAction';

export const InternalDayWise = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const store = useSelector((store) => store);
    const [loading, setLoading] = useState(true);
    const [details, setdetails] = useState([]);
    const [calDate, setCalDate] = useState(null);

    const onBarClick = async (row) => {
        const partnerId = row.partnerID
        try {
            if (!partnerId || partnerId.length !== 4) {
                throw new Error("Invalid")
            }
            const { user } = store.userRoot;
            const [superman, readonly] = [user?.userDetails?.userType === 'ADMIN', user?.userDetails?.userType === 'ADMIN_READ_ONLY']
            const mobile = (user.userDetails.phone_number).replaceAll("+", "") || "";
            const { data } = await api.get(`/getPartnerForSwitcher?mobile=${mobile}&partnerId=${partnerId}`)

            if (!data || !data.id) {
                throw new Error("invalid")
            }
            user.userDetails.partnerName = data.name
            const storeData = {
                token: user.token,
                partnerID: data.id,
                userDetails: { ...user?.userDetails, userType: user?.userDetails?.userType },
                partnerDetail: data,
                superman,
                readonly
            };
            dispatch(userLoginHelper(storeData));
            dispatch({ type: "DELETE_HOME_DATA", payload: data });
            dispatch({ type: "DELETE_REP_DATA", payload: data });
            navigate('../app');

        } catch (error) {
            alert(error)
        }
    }

    const setDate = (value) => {
        const modaledDate = DateModal(value);
        getDayWiseStatus(modaledDate)
        setCalDate(value);
    }

    const getDayWiseStatus = async (date) => {
        setLoading(true)

        try {
            const { data } = await api.get(`/dateWiseStatus?date=${date}`);
            setdetails(data);
        } catch (error) {
            setdetails([])
        }
        setLoading(false);
    }

    useEffect(() => {
        let date = '2022-01-20';
        if (state) {
            setDate(state)
            date = DateModal(state);
        } else {
            date = DateModal(new Date());
            setDate(new Date())
        }
        getDayWiseStatus(date)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page
            style={{
                display: 'flex',
                minHeight: '100%',
                flexDirection: "column"
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={CoPresent}
                        label="ACTIVE REPS"
                        count={details?.TotalDetails?.ActiveRep || 0}
                        color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={AgricultureIcon}
                        label="NUMBER OF FARMERS"
                        count={details?.TotalDetails?.TotalFarmer || 0}
                        color="linear-gradient(to right, #f8b500, #fceabb)"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        label="NUMBER OF FARMS"
                        count={details?.TotalDetails?.TOTALFARM || 0}
                        color="linear-gradient(to left, #93F9B9, #1D976C)"
                        ICONS={GrainIcon}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={GrassIcon}
                        label="TOTAL AREA (ACRES)"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        count={details?.TotalDetails?.TOTALAREA || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={GroupIcon}
                        label="ACTIVE PARTNER"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        count={details?.TotalDetails?.ActivePartner}
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    width: "100%",
                    margin: "3rem auto 1.5rem auto",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        width: "30%",
                    }}
                >
                    <DatePickerBM
                        value={calDate}
                        setDate={setDate}
                    />
                </div>
            </div>
            <>
                {loading &&

                    <div>
                        <CircularProgress size="large" />
                    </div>
                }

                {Object.keys(details).length !== 0 && !loading &&
                    <>
                        <TableHeader label="Hour Wise Status" Icon={DonutLargeIcon} />
                        <BarBm data={details.getDailyWiseEntry} x="time" y1="Farm"
                            yLabel="Number Of Farms"
                            xaxies={false}
                            barAreaClick={onBarClick}
                        />
                        <TableHeader label="Partner Wise RESUB" Icon={DonutLargeIcon} />
                        <BarBm data={details.getResubPartnerWise} x="partnerID" y1="TotalFARM"
                            yLabel="TotalFARM"
                            xaxies={false}
                            y1color="#B284BE"
                            barAreaClick={onBarClick}
                        />
                        <TableHeader label="Partner Wise Status" Icon={DonutLargeIcon} />
                        <BarBm
                            data={details.partnerWiseDetails}
                            x="partnerID"
                            y1="CONFIRMED_FARM"
                            y3="UNCONFIRMED_FARM"
                            y4="REWORK_FARM"
                            y5="RESUB_FARM"
                            yLabel="Number Of Farms"
                            xaxies={false}
                            barAreaClick={onBarClick}
                        />
                    </>
                }
                {Object.keys(details).length === 0 && !loading &&
                    <div
                        style={{ "textAlign": 'center' }}
                    >No New Data Added</div>}
            </>
        </Page>
    );
};
