/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`BM-${index}`} {...other}>
      {value === index && <Box
        padding="0.2rem"
      >{children}</Box>}
    </div>
  );
};
