/* eslint-disable react/prop-types */
import { Grid, Box } from '@mui/material';
import { TypographyBm } from '../TypographyBm';
import { FreeAutoCompleteText } from '../../../components/@material-extend/FreeAutoCompleteText';


export const HeadingAndAutoComplete = ({
  handleChange,
  textFieldSize,
  heading,
  list,
  value,
  name, disabled }) => {
  const size = {
    small: 2,
    medium: 3,
    large: 6
  };

  return (
    <Box sx={{ flexGrow: 1, paddingLeft: '15%', paddingTop: '3%' }}>
      <Grid container spacing={5}>
        <Grid item>
          <TypographyBm color="#404D61" width="18vw" fontWeight={500} fontSize={17} fontFamily='DM Sans'>
            {heading}
          </TypographyBm>
        </Grid>

        <Grid item xs={size[textFieldSize]} rowGap={2}>
          <FreeAutoCompleteText
            disabled={disabled}
            value={value || []}
            handleChange={(v) => handleChange(v, name)}
            list={list} />
        </Grid>
      </Grid>
    </Box>
  );
};
