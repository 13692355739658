/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { List } from '@mui/material';
import { ranchApi } from 'src/components/api';
import { DocCard } from './DocCard';
import { ButtonBm, ModalBm, LoadingBtnBm, InputText, TypographyBm, CircularProgessBm } from 'src/components/@material-extend';

let docData = {};
// TODO : reimplement
export function DocCardList({ id, activeType, refresh, setRefresh,
    status, rId, setTotalDoc, totalDoc }) {
    // const { showErrorSnackbar, showSuccessSnackbar } = useSnakBarTran();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [description, setDescription] = useState("")

    const getData = async (hardRefresh) => {
        try {
            setLoading(true);
            setList([]);
            if (refresh !== -1 || hardRefresh) {
                const ID = id.replace("ranch#", "");
                const RID = rId.replace("rancher#", "");
                const { data } = await ranchApi(`/getRanchById?id=${ID}&rancherid=${RID}`);
                docData = data.ranchDetails;
                setTotalDoc(+data.ranchDetails.TotalDoc)
            }

            if (!docData[activeType]) {
                setLoading(false);
                return;
            }

            let docs = JSON.parse(docData[activeType]);
            docs.reverse();
            setList([...docs]);

        } catch (error) {
            console.log(error);
            // showErrorSnackbar({ text: 'Failed to load uploaded document.' })
        } finally {
            setRefresh();
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [activeType, refresh !== -1])

    if (loading) {
        return <div style={{
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgessBm variant='indeterminate' size='10%' />
        </div>
    }

    if (list.length === 0 && !loading) {
        return <TypographyBm textAlign='center'
            margin='auto auto'
            component='div'
            height='50vh'>No document added
        </TypographyBm>
    }

    const onDeleteBtn = (i) => {
        const docData = list[i];
        setBtnLoading(false);
        setContent({
            imageId: docData.loc, title: ` Delete "${docData.fileName}"?`, title2: (<> <br />
                <TypographyBm sx={{ display: 'inline-block', marginTop: 3 }}>
                    Are you sure you want to delete
                </TypographyBm> <strong>{`${docData.fileName} ?`}
                </strong></>), id, userId: rId, type: activeType, delete: true
        });
    }

    const onDelete = async () => {
        try {
            setBtnLoading(true);
            await ranchApi.post('/editDeleteDoc?remove=1', { ...content, TotalDoc: +totalDoc - 1 });
            await getData(true);
            // showSuccessSnackbar({ text: 'Deleted successfully' })
        } catch (error) {
            // showErrorSnackbar({ text: 'Failed to delete' })
            console.log(error);
        } finally {
            setContent({});
            setBtnLoading(false);
        }
    }

    const onEdit = async () => {
        try {
            setBtnLoading(true);
            await ranchApi.post('/editDeleteDoc?remove=0', { ...content, description });
            await getData(true);
            // showSuccessSnackbar({ text: 'Updated' })
        } catch (error) {
            // showErrorSnackbar({ text: 'Failed to update' })
            console.log(error);
        } finally {
            setContent({});
            setBtnLoading(false);
        }
    }

    const modalClose = () => {
        setContent({})
        setDescription("")
    }

    const onEditBtn = (i) => {
        const docData = list[i];
        setBtnLoading(false);

        setContent({ imageId: docData.loc, title: 'Edit File Description', id, userId: rId, type: activeType, description: docData.description, edit: true });
        setDescription(docData.description);
    }

    return (
        <>
            <List sx={{
                padding: "1rem 1.5rem",
                borderRadius: '10px',
                height: '50vh',
                overflow: 'scroll',
            }}
            >
                {list.map((ele, index) => <DocCard
                    loc={ele.loc}
                    primary={ele.fileName}
                    secondary={ele.description}
                    status={status}
                    key={index}
                    onDelete={() => onDeleteBtn(index)}
                    onEdit={() => onEditBtn(index)}
                />)}
            </List>
            <ModalBm title={content.title} title2={content.title2} showModal={content.title && content.delete} setShowModal={modalClose} noDivder >
                <div style={{
                    display: 'flex',
                    justifyContent: 'right'
                }}>
                    <LoadingBtnBm color="error" variant='contained'
                        fullWidth={false}
                        style={{
                            boxShadow: 'none',
                            display: 'flex',
                            width: 56,
                            height: 36,
                            marginRight: '1rem',
                            background: '#E56647'
                        }}
                        onClick={onDelete}
                        loading={btnLoading}
                    >
                        Yes
                    </LoadingBtnBm>
                    <ButtonBm onClick={() => setContent({})} sxExtra={{ color: '#212B36', backgroundColor: '#FFFFFF', borderColor: 'rgba(145, 158, 171, 0.24)' }} variant="outlined" >
                        No
                    </ButtonBm>
                </div>
            </ModalBm>
            <ModalBm title={content.title} showModal={content.title && content.edit} setShowModal={modalClose} >
                <div>
                    <InputText
                        fullWidth
                        label="Description"
                        multiline
                        rows={2}
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                        sx={{ bgcolor: '#F1F1F1', borderRadius: 1, margin: '2rem auto' }}
                    />

                    <div style={{
                        display: 'flex',
                        justifyContent: 'right'
                    }}>
                        <LoadingBtnBm variant='contained'
                            fullWidth={false}
                            sx={{
                                marginRight: '1rem'
                            }}
                            onClick={onEdit}
                            loading={btnLoading}
                        >
                            Submit
                        </LoadingBtnBm>
                        <ButtonBm onClick={() => setContent({})} variant='text'>
                            Cancel
                        </ButtonBm>
                    </div>
                </div>
            </ModalBm>
        </>
    )

}
