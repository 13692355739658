/* eslint-disable react/jsx-no-undef */
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RightBar } from '../../layouts/AddRanch/RightBar'
import { MapAddField } from '../../layouts/AddRanch/MapAddField'

export const AddRanch = () => {
    const { state } = useLocation()
    const [coor, setCoor] = useState([]);
    const [uploadKey, setUploadKey] = useState("");
    let oldData = {}
    if (state !== null) {
        oldData = state
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, height: '100vh' }}>
                <MapAddField
                    coordinates={oldData.coordinates}
                    setCoor={setCoor}
                    setUploadKey={setUploadKey}
                />
            </div>
            <div style={{ flex: 0.4, overflow: 'hidden' }}>
                <RightBar
                    stateData={oldData || {}}
                    coord={coor}
                    uploadKey={uploadKey}
                />
            </div>
        </div>
    );
};
