/* eslint-disable react/prop-types */
import { LSSectionA } from './LSSectionA';
import { LSSectionB } from './LSSectionB';
import { LSSectionC } from './LSSectionC';
import { LSSectionD } from './LSSectionD';

export const LiveStockRanch = ({ value, handleChange, disabled }) => {

  const onChange = (newValue, name) => {

    if (disabled) {
      return
    }
    const allValue = { ...value };
    allValue[name] = newValue;
    handleChange({ ...allValue })
  }

  return <>
    <LSSectionA
      value={value.lsSectionA || ''}
      handleChange={(v) => onChange(v, 'lsSectionA')}
      disabled={disabled}
    />
    <LSSectionB
      allValue={value.lsSectionB || {}}
      handleChange={(v) => onChange(v, 'lsSectionB')}
      disabled={disabled}
    />
    <LSSectionD
      allValue={value.lsSectionD || {}}
      handleChange={(v) => onChange(v, 'lsSectionD')}
      disabled={disabled}
    />
    <LSSectionC
      allValue={value.lsSectionC || {}}
      handleChange={(v) => onChange(v, 'lsSectionC')}
      disabled={disabled}
    />
    
  </>
};
