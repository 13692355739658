/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#05AA02'
  }
});

export const TableHeader = ({ label, Icon, ...rest }) => {
  const classes = useStyle();
  return (
    <div className={classes.container} {...rest}>
      {Icon && (
        <Icon
          sx={{
            alignSelf: 'center',
            fontSize: '3rem',
            color: '#7F4200',
            margin: '0 1rem'
          }}
        />
      )}
      <h3>{label}</h3>
    </div>
  );
};
