/* eslint-disable react/prop-types */
import { Grid, Paper, Typography } from '@mui/material';

export const ListDetails = ({ header, keyValue }) => (
  <Paper
    elevation={10}
    sx={{
      padding: '1rem',
      marginTop: '0.5rem',
      width: '100%'
    }}
  >
    <Grid container width="100%">
      <Grid item xs={12}>
        <Typography variant="h6">{header}</Typography>
      </Grid>
      <Grid
        item
        sx={{
          padding: '0.4rem'
        }}
      >
        {Object.keys(keyValue).map((ele, index) => (
          <div key={index}>
            {keyValue[ele] && keyValue[ele] !== null && (
              <Typography
                variant="body1"
                fontSize="0.8rem"
                sx={{
                  margin: '0.6rem 0'
                }}
              >
                <strong>{ele} :</strong> {keyValue[ele]}
              </Typography>
            )}
          </div>
        ))}
      </Grid>
    </Grid>
  </Paper>
);
