/* eslint-disable import/no-unresolved */
import { fDateTime } from "src/utils/formatTime"

export const updateLog = (data, status, comments, CoorEdit, additional) => {
    const newLog = {
        timeOfValidation: fDateTime(new Date()),
        farmId: data.id,
        farmerId: data.farmer_userID,
        partnerID: data.partnerID,
        farmerName: `${data.farmer_user_given_name} ${data.farmer_user_family_name}`,
        farmerPhoneNumber: data.farmer_user_phone_number,
        REPNAME: `${data.contractor_user_given_name} ${data.contractor_user_family_name}`,
        landArea: data.land_area,
        status,
        CoorEdit: CoorEdit || false,
        comments: "None",
        additional: 'NO Change'
    }

    if (comments) {
        newLog.comments = comments.replaceAll(",", ".")
    }

    if (additional) {
        newLog.additional = JSON.stringify(additional)
    }

    const logs = JSON.parse(localStorage.getItem("validationLog") || "[]")

    if (logs.length === 0) {
        logs.push({
            timeOfValidation: "timeOfValidation",
            farmId: "farmId",
            farmerId: "farmerId",
            partnerID: 'partnerID',
            farmerName: 'farmerName',
            farmerPhoneNumber: "farmerPhoneNumber",
            REPNAME: "REPNAME",
            landArea: "landArea",
            status: "status",
            CoorEdit: "CoorEdit",
            comments: "comments",
            additional: "additional"
        })
    }

    logs.push(newLog);
    localStorage.setItem("validationLog", JSON.stringify(logs));
}