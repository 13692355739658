/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/prop-types */
import { HeadingAndTextField } from './HeadingAndTextField';
import {  MultipleHeadingAndTextField  } from './MultipleHeadingAndTextField';

export const SOE = ({ soeValue, setSOEValue, disabled }) => {

  const handleChange = (value, name) => {
    const newValues = { ...soeValue };
    newValues[name] = value;
    setSOEValue({ ...newValues });
  };

  const heading = 'Average Electricity consumption per month';
  const heading1 =
    'If fossil fuels are used for electricity, average number of litres of fossil fuel consumed per month';
  const heading2 = 'Solar Electricity Details';

  const twoFields = [
    {
      label: 'Power Output of one panel',
      size: 'medium',
      id: "powerOutput",
      unit: 'kW',
      type: 'number'
    },
    {
      label: 'No. of Panels',
      size: 'small',
      id: "noOfPanels",
      type: 'number'
    }
  ];


  return (
    <>
      <HeadingAndTextField
        disabled={disabled}
        value={soeValue.avgElectric}
        handleChange={handleChange}
        textFieldSize="small"
        heading={heading}
        name='avgElectric'
        unit='kWh'
        type='number'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={soeValue.noOfLtrs}
        handleChange={handleChange}
        textFieldSize="small"
        heading={heading1}
        name='noOfLtrs'
        unit='litres'
        type='number'
      />

      <MultipleHeadingAndTextField
        disabled={disabled}
        value={soeValue}
        heading={heading2}
        noOfFields={twoFields}
        setFields={handleChange}
      />
    </>
  );
};
