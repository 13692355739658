/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Paper, Box, } from "@mui/material";
import { useSelector } from 'react-redux';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { getCentroid } from "src/utils";
import { GoogleMap, MapCenter } from "../../components/rancherDashboardComponents/Map";
import { MultiPolygonWithEdit } from './MultiPolygonWithEdit';
import { EditControl } from "./EditControl";
import { HeaderAndCommentsIcon } from './HeaderAndCommentsIcon';
import { CommentsModal } from './CommentsModal';
import axiosApi from '../../components/api/api'

export const Map = ({ coor, height, label, commentList,
    comments, setComments, setEditCoor, points, isVerificationReady }) => {
    const [modal, setModal] = useState(false);
    const [polygonAround, setPolygonAround] = useState([]);
    const { readonly } = useSelector((store) => store.userRoot.user);
    const [verificationPoint, setVerificationPoint] = useState([]);

    useEffect(() => {
        getAllPolygonAround()
        pointsToLatLng()
    }, [])

    const pointsToLatLng = () => {
        if (!points) {
            return
        }
        let pointsSplit = points.split(" ")
        pointsSplit = pointsSplit.slice(1)

        const pointCoor = pointsSplit.map((ele) => {
            const newEle = ele.split(",");
            return [+newEle[1], +newEle[0]]
        });

        setVerificationPoint(pointCoor)
    }

    const getAllPolygonAround = async () => {
        try {
            if (!coor.length || !coor[0][0] || polygonAround.length) {
                setPolygonAround([...polygonAround])
                return
            }
            const activePolygon = {
                coor: coor[0],
                color: 'red',
                // pmIgnore: !(group && group.includes("cropLandVerification") && isVerificationReady),
                pmIgnore: false,
                center: true
            }
            let allPolygon = []
            // if (group && group.includes("cropLandVerification") && isVerificationReady) {
            const { lat, lng } = getCentroid(coor[0][0])
            const query = `lat=${lat}&lng=${lng}`
            const { data } = await axiosApi.get(`getPolygonInBound?${query}`)
            allPolygon = data.map(({ coor, id }) => ({
                coor: [coor],
                color: 'yellow',
                pmIgnore: true,
                center: false,
                id
            }))
            // }
            allPolygon.push(activePolygon)
            setPolygonAround(allPolygon)
        } catch (error) {
            alert("failed to find surrounding polygon")
            console.log(error);
        }
    }

    if (!coor) {
        return <h5>
            Loading...
        </h5>
    }

    return <Paper sx={{
        padding: '1rem',
        border: comments.length ? '2px solid red' : null,
    }}
        elevation={10}
    >
        <Box sx={{
            display: 'flex',
            padding: '1rem'
        }}>
            <FmdGoodIcon color='primary' fontSize='large' />
            <HeaderAndCommentsIcon
                isVerificationReady={isVerificationReady}
                modal={modal}
                setModal={setModal}
                comments={comments}
                setComments={setComments}
                commentList={commentList}
                header={label || `Polygon ${verificationPoint.length ? `(${verificationPoint.length + 1} attempt)` : ""}`}
                readonly={readonly}
            />

        </Box>
        <Box sx={{
            width: '100%',
            height: height || '50vh',
        }}>
            <GoogleMap >
                {polygonAround.length &&
                    <>
                        <MultiPolygonWithEdit
                            pointCoor={verificationPoint}
                            coordinates={polygonAround}
                            setCoor={(ele) => setEditCoor(ele)}
                        />
                    </>
                }
                <EditControl />
                <MapCenter />
            </GoogleMap>
        </Box>
        <CommentsModal
            modal={modal}
            setModal={setModal}
            comments={comments}
            setComments={setComments}
            commentList={commentList}
        />
    </Paper>

}