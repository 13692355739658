/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { AutocompleteBm, ModalBm } from "src/components/@material-extend";
import { Button, Stack } from "@mui/material"

export const CommentsModal = ({ modal, setModal, commentList, comments, setComments }) => {

    const onAddCommnets = () => {
        setModal(false)
    }

    return <ModalBm showModal={modal} title="Add Comments"  >
        <Stack spacing={2}
            justifyContent='center'
            alignItems='center'
            sx={{
                maxHeight: '50vh',
                width: '100%',
                padding: '1rem'
            }}>
            <AutocompleteBm
                sx={{ width: '100%' }}
                fullWidth
                disablePortal={false}
                chip
                multiple
                disableCloseOnSelect
                label="comments"
                list={commentList}
                value={comments}
                onChange={(_, value, type) => setComments(value, type)}
            />
            <Stack direction="row" spacing={3}>
                <Button variant="contained" fullWidth={false} onClick={onAddCommnets}>
                    Add and Close
                </Button>
            </Stack>
        </Stack>
    </ModalBm>
}