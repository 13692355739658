/* eslint-disable react/prop-types */
import { HeadingAndTextField } from '../ConserationRanch/HeadingAndTextField';
import { MultipleHeadingAndTextField } from '../ConserationRanch/MultipleHeadingAndTextField';

export const Practises = ({ practices, setPractices, disabled }) => {

  const handleChange = (value, name) => {
    const newValue = { ...practices };
    newValue[name] = value
    setPractices({ ...newValue });
  };

  const heading1 = 'Average Body Condition Score (BCS)? (1-5) (Optional)';
  const heading2 = 'Animal Welfare Practices Used (Optional)';
  const heading3 = 'Number of Permanent Paddocks';
  const heading4 = 'Portable Fence Paddocks, Average Number per year';
  const heading5 =
    'Changes to Management Practices/Investments since 2018 (e.g. 30 km of fence purchased, 5 more paddocks etc.)';
  const heading6Toggler = 'Are Wormers Used? Which? (Optional)';
  const heading7Toggler = 'At any given time, are there 10% of the animals below BCS 2? (Optional)';

  const heading6 = 'Permanent Fence';

  const twoFields = [
    {
      label: 'Non-electric',
      size: 'small',
      id: 'ne',
      unit: 'km',
      type: 'number'
    },
    {
      label: 'Electric',
      size: 'small',
      id: 'ee',
      unit: 'km',
      type: 'number'
    }
  ];

  return (
    <>
      <HeadingAndTextField
        disabled={disabled}
        name='AWP'
        value={practices.AWP}
        handleChange={handleChange}
        heading={heading2}
        textFieldSize="large"
        placeholder='Please describe'
      />
      <HeadingAndTextField
        disabled={disabled}
        name='wormer'
        value={practices.wormer}
        handleChange={handleChange}
        textFieldSize="large"
        toggler
        heading={heading6Toggler}
        placeholder='If yes, which?'
      />
      <HeadingAndTextField
        disabled={disabled}
        name='BCS'
        value={practices.BCS}
        handleChange={handleChange}
        textFieldSize="small"
        heading={heading1}
        type='number'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={practices.BCS2}
        name='BCS2'
        handleChange={handleChange}
        textFieldSize="large"
        heading={heading7Toggler}
        toggler
        placeholder="if Yes, please explain"
      />
      <MultipleHeadingAndTextField
        disabled={disabled}
        value={practices}
        heading={heading6}
        noOfFields={twoFields}
        setFields={handleChange}
      />
      <HeadingAndTextField
        disabled={disabled}
        value={practices.paddocks}
        name='paddocks'
        handleChange={handleChange}
        textFieldSize="small"
        heading={heading3}
        type='number'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={practices.portablePaddocks}
        name='portablePaddocks'
        handleChange={handleChange}
        textFieldSize="small"
        heading={heading4}
        type='number'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={practices.changeManagement}
        name='changeManagement'
        handleChange={handleChange}
        heading={heading5}
        textFieldSize="large"
        placeholder='Please describe'
      />
    </>
  );
};
