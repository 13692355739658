import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import api from '../components/api/api';
import { getLoginTableData } from '../components/GridColumns';
import { AutocompleteBm, LoadingBtnBm } from '../components/@material-extend';

export const LoginDetails = () => {
  const [loginData, setLoginData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState('ALL');
  const [tempData, setTempData] = useState();
  const [selectedRow, SetSelectedRow] = useState([]);
  const [noOfDay, setNoOfDay] = useState(0);
  const projectIdKey = {
    'ALL': 'All',
    'IGP': '2934',
    'SOVIN': '2889',
    'East Africa Grasslands': '3340',
    'East Africa Croplands': '3774',
    'Northern Mexico Grasslands': '2887',
    'South America Grasslands': '3291',
    "URVARA": "2890"
  }

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        const { data } = await api.get(`/getLoginDetails`);
        setLoginData(data);
        setTempData(data);
      } catch (err) {
        console.log('Error while getting data: ', err);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, []);

  const onProjectChange = (_, value) => {
    setProject(value);
    if (projectIdKey[value] && projectIdKey[value] !== "All") {
      const filteredResults = tempData.filter((item) => item.projectID === projectIdKey[value])
      setLoginData(filteredResults)
    } else if (projectIdKey[value] === "All") {
      setLoginData(tempData)
    }
  };

  const onEnable = async (isEnable) => {
    try {
      const allSelectedRowId = new Set(selectedRow);
      const allSelectedIdDetails = [];

      loginData.forEach(((ele) => {
        if (allSelectedRowId.has(ele.id)) {
          allSelectedIdDetails.push(ele.eMail)
        }
      }));

      await api.post(`/enableDisablePartner`, {
        id: allSelectedIdDetails,
        days: +noOfDay,
        enable: isEnable
      });
 
      SetSelectedRow([])
    } catch (err) {
      console.log(err);
      alert('failed')
    }
  }

  return (
    <>
      <Grid container justifyContent='space-between'>
        <AutocompleteBm
          label="Project"
          list={[
            'ALL',
            'IGP',
            'SOVIN',
            'URVARA',
            'East Africa Grasslands',
            'East Africa Croplands',
            'Northern Mexico Grasslands',
            'South America Grasslands'
          ]}
          defaultValue="ALL"
          value={project}
          onChange={onProjectChange}
          style={{ margin: 10 }}
        />
        {selectedRow.length ?
          <Grid item >
            <LoadingBtnBm
              fullWidth={false}
              style={{ backgroundColor: '#FF0000' }}
              onClick={() => onEnable(false)}
              variant='contained'
              sx={{ margin: '0 1rem' }}
            >
              Disable
            </LoadingBtnBm>
            <TextField
              type='number'
              value={noOfDay}
              onChange={({ target }) => {
                const { value } = target
                if (value >= 0) {
                  setNoOfDay(target.value)
                }
              }}
              label="No of Days" />
            <LoadingBtnBm
              fullWidth={false}
              onClick={() => onEnable(true)}
              variant='contained'
              sx={{ margin: '0 1rem' }}>
              Enable
            </LoadingBtnBm>
          </Grid> : null}
      </Grid>
      <Grid
        item
        sx={{
          height: '73vh'
        }}
      >
        <DataGrid
          components={{
            NoRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                No Field
              </Box>
            )
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1
            },
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
          rows={loginData || []}
          loading={loading}
          columns={getLoginTableData()}
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            const len = selectedRow.length
            if (len > 19 && newSelection.length > len) {
              alert("Cannot select more than this")
              return
            }
            SetSelectedRow(newSelection);
          }}
          selectionModel={selectedRow}
          initialState={{
            sorting: {
              sortModel: [{ field: 'full_name', sort: 'asc' }],
            },
          }}
        />
      </Grid>
    </>
  );
};
