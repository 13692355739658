/* eslint-disable react/prop-types */
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


export const DatePickerBM = ({ value, setDate, label, maxDate }) => <LocalizationProvider dateAdapter={AdapterDateFns}
>
    <DatePicker
        label={label || "Date"}
        value={value}
        disableFuture
        onChange={setDate}
        maxDate={maxDate || new Date()}
        renderInput={(params) => <TextField {...params} />}
    />
</LocalizationProvider>
