/* eslint-disable react/prop-types */
import { useState } from 'react';
import {
    Paper,
    Grid,
    IconButton,
    FormLabel,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    Stack,
    TextField,
    Button,
    FormGroup,
    Switch
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from "@mui/material/colors";
import { CalenderBm } from '../@material-extend/CalenderBm';
import { SelectBm } from '../@material-extend';

export const FilterMap = ({ handleFilter, handelModal, setFieldID, leaderIDs }) => {
    const [fid, setFid] = useState();
    const defaultDate = [new Date(), new Date()]
    const [filter, setFilter] = useState('ALL');
    const [dateFilter, setDateFilter] = useState(defaultDate)
    const [filterByDate, setFilterByDate] = useState(false);
    const [leaderId, setLeaderId] = useState("");

    const handleChange = () => {
        if (filterByDate) {
            handleFilter(filter === 'ALL' ? "" : filter, filterByDate, dateFilter, leaderId)
        } else {
            handleFilter(filter === 'ALL' ? "" : filter, undefined, undefined, leaderId)
        }
        handelModal()
    };

    const findField = () => {
        setFieldID(fid)
    }

    return (
        <Paper sx={{
            margin: '80px auto 0 auto',
            padding: '1rem',
            overflow: 'hidden',
            backgroundColor: "#fff",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Grid container justifyContent="space-between">
                <IconButton onClick={handelModal}>
                    <CancelIcon sx={{ color: red[500] }} fontSize="large" />
                </IconButton>
            </Grid>
            <FormControl>
                <FormLabel >STATUS</FormLabel>
                <RadioGroup
                    value={filter}
                    onChange={({ target }) => setFilter(target.value)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                    <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
                    <FormControlLabel value="ONBOARDED" control={<Radio />} label="ONBOARDED" />
                    <FormControlLabel value="RE_SUB" control={<Radio />} label="RE_SUB" />
                    <FormControlLabel value="RECEIVED" control={<Radio />} label="RECEIVED" />
                    <FormControlLabel value="REWORK" control={<Radio />} label="REWORK" />
                    <FormControlLabel value="VERIFIED" control={<Radio />} label="VERIFIED" />
                </RadioGroup>
            </FormControl>
            <Stack>
                <SelectBm
                    value={leaderId}
                    onChange={({ target }) => setLeaderId(target.value)}
                    label="Select Leader"
                    containerSx={{ width: '14rem' }}
                    list={leaderIDs} />
            </Stack>
            <Stack direction='row' spacing={3} margin='2rem auto'>
                <FormGroup onChange={() => setFilterByDate(!filterByDate)}>
                    <FormControlLabel
                        labelPlacement="start"
                        control={<Switch />} label="Filter By Date" />
                </FormGroup>
                {
                    filterByDate ?
                        <CalenderBm date={dateFilter} setDate={setDateFilter} /> : null
                }
            </Stack>
            <Stack direction='row' spacing={2} margin='2rem auto'>
                <Button variant='contained'
                    sx={{
                        width: '30vw'
                    }}
                    onClick={handleChange}>
                    Filter
                </Button>
            </Stack>
            <Stack direction='row' spacing={2} margin='2rem auto'>
                <TextField
                    label='Find Field By ID'
                    sx={{
                        width: '40vw'
                    }}
                    placeholder='Find Field By ID'
                    onChange={({ target }) => setFid(target.value)}
                />
                <Button variant='contained' onClick={findField}>
                    Find By ID
                </Button>
            </Stack>
        </Paper>
    );
}