/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { Button } from "@mui/material";


export const ButtonBm = ({ children, sxExtra, ...other }) =>  <Button
        variant="contained"
        sx={{
            ...sxExtra,
            boxShadow: 'none',
            '&:hover': {
                ...sxExtra
            }
        }}
        {...other}
    >
        {children}
    </Button>
   



