/* eslint-disable react/prop-types */
import { Typography } from "@mui/material";


export const TypographyBm = ({ children, ...other }) => (
<Typography {...other}>
        {(children)}
    </Typography>
    );
   
