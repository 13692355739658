import { CommentStatus } from '../layouts/fieldFarmerDetails/AllCommentSubmit';
import {
  commentId
} from '../layouts/fieldFarmerDetails/CommentsData';

export const safeConcat = (arr1, arr2,arr3) => {
  const safeArr1 = Array.isArray(arr1) ? arr1 : [];
  const safeArr2 = Array.isArray(arr2) ? arr2 : [];
  const safeArr3 = Array.isArray(arr3) ? arr3 : [];

  return [...safeArr1, ...safeArr2, ...safeArr3];
};

export const safeConcatTwo = (arr1, arr2) => {
  const safeArr1 = Array.isArray(arr1) ? arr1 : [];
  const safeArr2 = Array.isArray(arr2) ? arr2 : [];

  return [...safeArr1, ...safeArr2];
};

export function formatAllComments(farmComments, farmerComments) {
 
    const farmCommentsArr = safeConcat(
      farmComments?.RESOLVED,
      farmComments?.UNRESOLVED,
      farmComments?.DELETED
    );
    const farmerCommentsArr = safeConcat(
      farmerComments?.RESOLVED,
      farmerComments?.UNRESOLVED,
      farmerComments?.DELETED
    );

    return [...farmCommentsArr, ...farmerCommentsArr];

}

export const getUniqueComments = (newComments, oldComments) => {
  const uniqueCommentIds = new Set([]);
  
  newComments.forEach((el) => {
    if (el && Object.hasOwn(el, 'comment_id') && el.comment_id) {
      uniqueCommentIds.add(el.comment_id);
    }
  });

  const combinedArr = [...newComments];

  oldComments?.forEach((el) => {
    if (el && el.comment) {
      const commentKeys = Object.keys(commentId);
      const foundComment = commentKeys.find((obj) => obj.includes(el.comment.trim()));
      if(foundComment && !uniqueCommentIds.has(commentId[foundComment])) {
        uniqueCommentIds.add(commentId[foundComment]);
        combinedArr.push(el);
      }
    }
  });

  return combinedArr;
};


export const getOldCommentsStr =(farmCommentsStr, farmerCommentsStr)=>{

  let str="";
  if(farmCommentsStr)
    str += farmCommentsStr;

  if (farmerCommentsStr) 
    str += farmerCommentsStr;

  console.log(str)
  return str
}