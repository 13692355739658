/* eslint-disable react/prop-types */
import { TextField, InputAdornment } from "@mui/material";


export const InputText = ({ value,
    onChange,
    label,
    fullWidth,
    helperText,
    error,
    unit,
    type,
    placeholder,
    errorBgColor,
    preventNegative,
    ...other }) => {



    const transValue = (label)

    const transError = (helperText);

    return <TextField
        fullWidth={fullWidth || true}
        label={transValue || ''}
        value={value || ''}
        onChange={onChange}
        color="secondary"
        error={error}
        placeholder={(placeholder)}
        helperText={error && transError}
        FormHelperTextProps={{
            sx: { bgcolor: errorBgColor || '#F5F6F8', margin: 0 }
        }}
        onKeyPress={(event) => {
            if (preventNegative && (event?.key === '-' || event?.key === '+')) {
                event.preventDefault();
            }
        }}
        InputProps={{
            endAdornment: (unit &&
                <InputAdornment position="end" >
                    {(unit)}
                </InputAdornment>
            )
        }}
        sx={{ bgcolor: '#FFFFFF', borderRadius: 1, color: 'secondary' }}
        {...other}
        type={type}
    />
}