/* eslint-disable consistent-return */
/* eslint-disable no-useless-concat */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    Box,
    Alert,
    ListItemText as ListItemTextBm 
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { saveAs } from 'file-saver';
import UploadIcon from '@mui/icons-material/Upload';
import { ModalBm, UploadFile, InputText, LoadingBtnBm, ButtonBm, TypographyBm } from 'src/components/@material-extend/';
import { ranchApi } from "src/components/api";
import { useSelector } from 'react-redux';

const useStyle = makeStyles(() => ({
    link: {
        cursor: 'pointer',
        color: '#007AFF',
        display: 'inline',
        margin: '0 3px',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}));

let loc = [];
const partnerSet = new Set([
    "518720",
    "518439"
]);
const countrySet = new Set([
    "AR"
]);
let currfileName = [];

export const UploadDoc = ({ folder, id, PK, setRefresh, totalDoc }) => {
    const classes = useStyle();
    const { country, id: partnerId } = useSelector((store) => store.userRoot.user.partnerDetail
    );
    const [modal, setModal] = useState(false);
    const [description, setDescription] = useState('')
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [textDisabled, setTextDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setDescription("");
        setBtnDisabled(true);
        setTextDisabled(true);
        setLoading(false);
        setErrorMessage("");
        loc = [];
        currfileName = [];
        // currentQueryParameters(window.location.search)
    }, [modal])

    const onSubmit = async () => {
        try {
            if (btnDisabled) {
                setErrorMessage("description not added")
                return;
            }
            setLoading(true);
            const data = {
                type: folder, loc, description, id,
                fileName: currfileName, userId: PK, TotalDoc: +totalDoc || 0 + 1
            };
            await ranchApi.post('/AddRanch', data);
            setRefresh();
            setModal(!modal);
            setBtnDisabled(true);
            setDescription('');
            setLoading(false);
            setTextDisabled(true);
            // showSuccessSnackbar({ text: 'Documents saved successfully' })

        } catch (error) {
            // showErrorSnackbar({ text: 'Failed to save' })
            console.log(error);
        }

    }

    const handleChange = ({ target }) => {
        const { value } = target;
        setDescription(value)
        if (value === '') {
            setBtnDisabled(true)
            return;
        }
        if (errorMessage) {
            setErrorMessage("")
        }
        setBtnDisabled(false)
    }

    const onUploadSucessed = (fileLocation, fileName) => {
        loc = fileLocation;
        currfileName = fileName
        setTextDisabled(false);
    }

    const createKey = (docType) => {
        if (partnerId === '527273') {
            if (docType === 2) {
                return 'AgreementsDoc/Agreement-en-527273-AR-offer.pdf'
            }
            return 'AgreementsDoc/Agreement-en-527273-AR.pdf'
        }
        if (partnerSet.has(partnerId)) {
            return "AgreementsDoc/Agreement-en-3291-BR.pdf"
        } if (countrySet.has(country)) {
            return `AgreementsDoc/Agreement-en-` + `all-` + `AR.pdf`;
        }
    }

    const Download = async (docType) => {
        try {
            // showInfoSnackbar({ text: 'Downloading' })
            const key = createKey(docType);
            const locArray = key.split('/');
            const { data } = {}
            await ranchApi.post('/uploadUrl', { folder: locArray[0], expiryTime: 300, getObject: true, key });
            saveAs(data.uploadURL, "agreements.pdf");
        } catch (error) {
            console.log(error);
            // showErrorSnackbar({ text: 'Failed to downlaod' });
        }
    }

    return <Box>
        <ListItem sx={{
            bgcolor: "#fff",
            borderRadius: '10px',
            padding: '1rem',
            width: '70%',
            border: '5px dotted #F4F6F8',
            margin: '3rem auto'
        }}>
            <ListItemAvatar sx={{
                marginRight: "2rem"
            }}>
                <Avatar >
                    <UploadIcon fontSize='medium' />
                </Avatar>
            </ListItemAvatar>
            <ListItemTextBm primary="Select a file to Upload" style={{ color: '#000000' }} secondary="JPG, PNG, ZIP or PDF file" />
            <ButtonBm
                variant="outlined"
                style={{
                    border: "2px solid #74CAFF",
                    borderRadius: '6px',
                    color: "#74CAFF",
                    minHeight: "3rem",
                    padding: "0.8rem"
                }}
                onClick={() => setModal(!modal)}
            >
                Select File
            </ButtonBm>
        </ListItem>
        {folder === "AgreementsDoc" && (partnerSet.has(partnerId) || countrySet.has(country)) &&
            <Box sx={{
                width: '65%',
                margin: 'auto'
            }}>
                <TypographyBm>
                    Upload the signed copy of the below documents:
                </TypographyBm>
                <Box>
                    <TypographyBm className={classes.link} onClick={Download}>
                        1. Click here
                    </TypographyBm>
                    <TypographyBm sx={{ display: 'inline' }}>
                        here to download the agreement.
                    </TypographyBm>
                </Box>
                {partnerId === '527273' &&
                    <Box>
                        <TypographyBm className={classes.link} onClick={() => Download(2)}>
                            2. Click here
                        </TypographyBm>
                        <TypographyBm sx={{ display: 'inline' }}>
                            to download the Offer/Acceptance letter
                        </TypographyBm>
                    </Box>
                }
            </Box>}
        <ModalBm
            showModal={modal}
            setShowModal={() => setModal(!modal)}
            title='Upload Files'
        >
            <UploadFile
                fileSize={110010000}
                api={ranchApi}
                url='/getUploadUrl'
                noOfFile={10}
                onUploadSucessed={onUploadSucessed}
                folder={folder}
                onUploadError={() => setModal(!modal)}
                fileType={['image/*', '.pdf', '.zip', '.doc', '.docx', '.xls', '.xlsx',
                    '.xlsm', '.ods', '.txt', '.zip', '.png', '.jpg', '.jpeg', '.tif', '.tiff']}
            />
            <InputText
                disabled={textDisabled}
                fullWidth
                label="Description"
                multiline
                rows={2}
                value={description}
                onChange={handleChange}
                sx={{ bgcolor: '#F1F1F1', borderRadius: 1, margin: '0.5rem auto' }}
            />
            {errorMessage &&
                <Alert severity="error">{errorMessage}</Alert>
            }
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0.5rem auto'
            }}>
                <LoadingBtnBm
                    variant="contained"
                    fullWidth={false}
                    loading={loading}
                    onClick={onSubmit}>
                    Submit
                </LoadingBtnBm>
            </div>
        </ModalBm>
    </Box>
}