/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { PopStatusDetailGenerator } from './PopStatusDetailGenerator';
import { LoadingBtnBm } from 'src/components/@material-extend';


export const PopStatusDetails = ({ data, packageName, cropName }) => {
    const { name } = useSelector((store) => store.userRoot.user.partnerDetail);
    return <PDFDownloadLink
        document={<PopStatusDetailGenerator
            packageName={packageName}
            cropName={cropName}
            partnerName={name} data={data} />}
        fileName={`${packageName}_Boomitra.pdf`}>
        {({ loading }) => <LoadingBtnBm
            startIcon={<CloudDownloadIcon />}
            fullWidth={false}
            style={{ background: '#004752', marginTop: '0.5rem', width: '9vw', padding: '0.65rem' }}
            loading={loading}>
            Download
        </LoadingBtnBm>}
    </PDFDownloadLink>
}

