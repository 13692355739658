/* eslint-disable import/no-unresolved */
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Chip, Snackbar, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { SelectBm } from 'src/components/@material-extend';
import { CalenderBm } from 'src/components/@material-extend/CalenderBm';
import popReportApi from 'src/components/api/popReportApi';
import { AutocompleteBmV2 } from "../components/@material-extend";
import api from "../components/api/api";

export function PopReports() {
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [allPartner, setAllPartner] = useState([])
  const defaultDate = [
    new Date().toLocaleDateString('en-CA'),
    new Date().toLocaleDateString('en-CA')
  ];
  const [dateFilter, setDateFilter] = useState(defaultDate)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);  
  const ReportTypeEnum = Object.freeze({
    Creation: 'partnerWise',
    Distribution: 'repWise',
    Usage: 'farmerWise'
  });

  const [reportType, setReportType] = useState('Creation');  
  const GENERIC_ERROR = 'An Error Occurred';

  const onSubmit = async () => {
    if (!dateFilter[0] || !dateFilter[1]) {
      alert('Please select a date before submitting your search.');
      return;
    }

   await getPopReport();
  };

  const onClose = ()=>{
    setError(false);
  }

  useEffect(() => {
    getAllPartners()
}, [])

  const getAllPartners = async () => {
    try {
        const { data } = await api.get("/getallpartner")
        const alltempPartner = [];
        alltempPartner.push({label: 'ALL', value: 'ALL'});

        const { partners } = data;
        partners.sort((a, b) => +a.id - +b.id)
        
        partners.forEach((ele) => {
          if(ele.type === 'PARTNER') {
            alltempPartner.push({label:`${ele.id} - ${ele.name}`, value: `${ele.id}`});
          }
        });

        setAllPartner(alltempPartner);
    } catch (e) {
        console.log(e);
    }
}

const onPartnerChange = async (_, value) => {
  if (!value) {
      return;
  }
  if(value[0]?.value === 'ALL')
  {
    setSelectedPartners(allPartner);
    return
  }

  setSelectedPartners(value);
}
  const getPopReport = async()=>{
        try {
          if(!dateFilter)
            return

          if(!selectedPartners.length){
            setError('Please enter partner Id')
            return
          }

          const fromDate = dateFilter[0];
          const toDate = dateFilter[1];
          const partnersList = selectedPartners[0].value === 'ALL' ? selectedPartners.map((el) =>el.value).slice(1) : selectedPartners.map((el) =>el.value);
          setLoading(true);
          const { data } = await popReportApi.post(
            `/popReport?type=${ReportTypeEnum[reportType]}&fromDate=${fromDate}&toDate=${toDate}`,
            {
              partnerIDs: partnersList
            }
          );
          const url = data?.data?.url;

          if(data?.statusCode !== 200){
            setError(data.message);
            return;
          }

          if (url) {
            handleDownload(url);
          }
        } catch (err) {
          setError(err?.response?.data?.message ?? GENERIC_ERROR);
          console.log('Error while getting data: ', err);
        } finally {
          setLoading(false);
        }
  }

  const handleDownload = (s3Url) => {
    const link = document.createElement('a');
    link.href = s3Url;
    link.click();
  };

  return (
    <div>
      <div
        style={{
          width: '80%',
          margin: '3rem 0 0 0',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <CalenderBm date={dateFilter} setDate={setDateFilter} />
        <SelectBm
          value={reportType}
          onChange={({ target }) => {
            setReportType(target.value);
          }}
          list={Object.keys(ReportTypeEnum)}
          label="Select Report Type"
          containerSx={{ width: '20%' }}
          sx={{
            color: '#000',
            background: '#fff'
          }}
        />
          <AutocompleteBmV2
            list={allPartner}
            multiple
            onChange={onPartnerChange}
            label="Partner"
            style={{
              marginRight: '1rem',
              marginLeft: '1rem'
            }}
          />
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          onClick={onSubmit}
        >
          Download
        </LoadingButton>
      </div>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}
