import axios from '../../components/api/api';
import setAuthToken from '../helper/setAuthToken';
import setAuthTokenEdit from '../helper/setAuthTokenEdit';

export const userLoginHelper = (data) => ({
  type: 'SET_USERS_DATA',
  payload: data
});

const userLogoutHelper = (data) => ({
  type: 'DELETE_USERS_DATA',
  payload: data
});

export const userLogin = (loginCredentials, history) => async (dispatch) => {
  try {
    const { data } = await axios.post('/login', loginCredentials);
    console.log(data, '<<');
    // data.userType = 'ADMIN_READ_ONLY';

    const superman =  data.userType === 'ADMIN';
    const readonly = data.userType === 'ADMIN_READ_ONLY';
    // if (!superman) {
    //   throw new Error('get Out');
    // }
    const storeData = {
      token: data.data,
      partnerID: data.partnerId,
      userDetails: { ...data?.userDetails, userType: data?.userType },
      partnerDetail: data.partnerDetail,
      superman,
      readonly,
      group: data.group
    };
    setAuthToken(data.data);
    setAuthTokenEdit(data.data);

    dispatch(userLoginHelper(storeData));
    const storageData = { ...storeData };
    localStorage.setItem('JWT', JSON.stringify(storageData));
    history('/dashboard');
  } catch (err) {
    dispatch({
      type: 'SET_LOGIN_ERRORS',
      payload: 'Invalid Credentials'
    });
  }
};

export const userLogout = (history) => (dispatch) => {
  localStorage.removeItem('JWT');
  // for validation log clean up
  const logs = JSON.parse(localStorage.getItem('validationLog') || '[]');
  if (logs.length > 1) {
    if (window.confirm(`Deleting validation logs since it more than ${logs.length}.`)) {
      localStorage.removeItem('validationLog');
    }
  }

  setAuthToken(false);
  dispatch(userLogoutHelper({}));
  history('/');
};
