/* eslint-disable react/prop-types */

import { Card, CardContent, Typography } from '@mui/material';
import { customShortenNumber } from '../../utils/formatNumber';


export  function RancherCardNew({ label, count, IsText }) {
  return (
    <Card sx={{ minWidth: 250}}>
      <CardContent>
      <Typography variant="subtitle2" >
          {label}
        </Typography>
        <Typography variant="h3" style={{paddingTop: 16}}>{IsText ? count : customShortenNumber(count || 0)}</Typography>
        
      </CardContent>
    </Card>
  );
}
