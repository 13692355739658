export const state = {
    BR: [
      { label: 'Acre', value: 'Acre' },
      { label: 'Alagoas', value: 'Alagoas' },
      { label: 'Amapa', value: 'Amapa' },
      { label: 'Amazonas', value: 'Amazonas' },
      { label: 'Bahia', value: 'Bahia' },
      { label: 'Ceara', value: 'Ceara' },
      { label: 'Distrito Federal', value: 'Distrito Federal' },
      { label: 'Espirito Santo', value: 'Espirito Santo' },
      { label: 'Goias', value: 'Goias' },
      { label: 'Maranhao', value: 'Maranhao' },
      { label: 'Mato Grosso', value: 'Mato Grosso' },
      { label: 'Mato Grosso do Sul', value: 'Mato Grosso do Sul' },
      { label: 'Minas Gerais', value: 'Minas Gerais' },
      { label: 'Para', value: 'Para' },
      { label: 'Paraiba', value: 'Paraiba' },
      { label: 'Parana', value: 'Parana' },
      { label: 'Pernambuco', value: 'Pernambuco' },
      { label: 'Piaui', value: 'Piaui' },
      { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
      { label: 'Rio Grande do Norte', value: 'Rio Grande do Norte' },
      { label: 'Rio Grande do Sul', value: 'Rio Grande do Sul' },
      { label: 'Rondonia', value: 'Rondonia' },
      { label: 'Roraima', value: 'Roraima' },
      { label: 'Santa Catarina', value: 'Santa Catarina' },
      { label: 'Sao Paulo', value: 'Sao Paulo' },
      { label: 'Sergipe', value: 'Sergipe' },
      { label: 'Tocantins', value: 'Tocantins' }
    ],
    AR: [
      { label: 'Buenos Aires', value: 'Buenos Aires' },
      { label: 'Catamarca', value: 'Catamarca' },
      { label: 'Chaco', value: 'Chaco' },
      { label: 'Chubut', value: 'Chubut' },
      { label: 'Córdoba', value: 'Córdoba' },
      { label: 'Corrientes', value: 'Corrientes' },
      { label: 'Entre Ríos', value: 'Entre Ríos' },
      { label: 'Formosa', value: 'Formosa' },
      { label: 'Jujuy', value: 'Jujuy' },
      { label: 'La Pampa', value: 'La Pampa' },
      { label: 'La Rioja', value: 'La Rioja' },
      { label: 'Mendoza', value: 'Mendoza' },
      { label: 'Misiones', value: 'Misiones' },
      { label: 'Neuquén', value: 'Neuquén' },
      { label: 'Río Negro', value: 'Río Negro' },
      { label: 'Salta', value: 'Salta' },
      { label: 'San Juan', value: 'San Juan' },
      { label: 'San Luis', value: 'San Luis' },
      { label: 'Santa Cruz', value: 'Santa Cruz' },
      { label: 'Santa Fe', value: 'Santa Fe' },
      { label: 'Santiago del Estero', value: 'Santiago del Estero' },
      {
        label: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
        value: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur'
      },
      { label: 'Tucumán', value: 'Tucumán' }
    ],
    UY: [
      { label: 'Artigas', value: 'Artigas' },
      { label: 'Canelones', value: 'Canelones' },
      { label: 'Cerro-Largo', value: 'Cerro-Largo' },
      { label: 'Colonia', value: 'Colonia' },
      { label: 'Durazno', value: 'Durazno' },
      { label: 'Flores', value: 'Flores' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Lavalleja', value: 'Lavalleja' },
      { label: 'Maldonado', value: 'Maldonado' },
      { label: 'Montevideo', value: 'Montevideo' },
      { label: 'Paysandú', value: 'Paysandú' },
      { label: 'Río Negro', value: 'Río Negro' },
      { label: 'Rivera', value: 'Rivera' },
      { label: 'Rocha', value: 'Rocha' },
      { label: 'Salto', value: 'Salto' },
      { label: 'San José', value: 'San José' },
      { label: 'Soriano', value: 'Soriano' },
      { label: 'Tacuarembó', value: 'Tacuarembó' },
      { label: 'Treinta y Tres', value: 'Treinta y Tres' }
    ],
    IN: [{ label: 'Uttar Pradesh', value: 'Uttar Pradesh' }],
    MX: [
      { label: 'Aguascalientes', value: 'Aguascalientes' },
      { label: 'Baja California', value: 'Baja California' },
      { label: 'Baja California Sur', value: 'Baja California Sur' },
      { label: 'Campeche', value: 'Campeche' },
      { label: 'Chiapas', value: 'Chiapas' },
      { label: 'Chihuahua', value: 'Chihuahua' },
      { label: 'Coahuila', value: 'Coahuila' },
      { label: 'Colima', value: 'Colima' },
      { label: 'Durango', value: 'Durango' },
      { label: 'Guanajuato', value: 'Guanajuato' },
      { label: 'Guerrero', value: 'Guerrero' },
      { label: 'Hidalgo', value: 'Hidalgo' },
      { label: 'Jalisco', value: 'Jalisco' },
      { label: 'Mexico', value: 'Mexico' },
      { label: 'Michoacan', value: 'Michoacan' },
      { label: 'Morelos', value: 'Morelos' },
      { label: 'Nayarit', value: 'Nayarit' },
      { label: 'New Mexico', value: 'New Mexico' },
      { label: 'Nuevo Leon', value: 'Nuevo Leon' },
      { label: 'Oaxaca', value: 'Oaxaca' },
      { label: 'Puebla', value: 'Puebla' },
      { label: 'Queretaro', value: 'Queretaro' },
      { label: 'Quintana Roo', value: 'Quintana Roo' },
      { label: 'San Luis Potosi', value: 'San Luis Potosi' },
      { label: 'Sinaloa', value: 'Sinaloa' },
      { label: 'Tabasco', value: 'Tabasco' },
      { label: 'Tamaulipas', value: 'Tamaulipas' },
      { label: 'Tlaxcala', value: 'Tlaxcala' },
      { label: 'Veracruz', value: 'Veracruz' },
      { label: 'Yucatan', value: 'Yucatan' },
      { label: 'Zacatecas', value: 'Zacatecas' }
    ],
    KE: [
      { label: 'Mombasa', value: 'Mombasa' },
      { label: 'Kwale', value: 'Kwale' },
      { label: 'Kilifi', value: 'Kilifi' },
      { label: 'Tana River', value: 'Tana River' },
      { label: 'Lamu', value: 'Lamu' },
      { label: 'Taita/Taveta', value: 'Taita/Taveta' },
      { label: 'Garissa', value: 'Garissa' },
      { label: 'Wajir', value: 'Wajir' },
      { label: 'Mandera', value: 'Mandera' },
      { label: 'Marsabit', value: 'Marsabit' },
      { label: 'Isiolo', value: 'Isiolo' },
      { label: 'Meru', value: 'Meru' },
      { label: 'Tharaka-Nithi', value: 'Tharaka-Nithi' },
      { label: 'Embu', value: 'Embu' },
      { label: 'Kitui', value: 'Kitui' },
      { label: 'Machakos', value: 'Machakos' },
      { label: 'Makueni', value: 'Makueni' },
      { label: 'Nyandarua', value: 'Nyandarua' },
      { label: 'Nyeri', value: 'Nyeri' },
      { label: 'Kirinyaga', value: 'Kirinyaga' },
      { label: "Murang'a", value: "Murang'a" },
      { label: 'Kiambu', value: 'Kiambu' },
      { label: 'Turkana', value: 'Turkana' },
      { label: 'West Pokot', value: 'West Pokot' },
      { label: 'Samburu', value: 'Samburu' },
      { label: 'Trans Nzoia', value: 'Trans Nzoia' },
      { label: 'Uasin Gishu', value: 'Uasin Gishu' },
      { label: 'Elgeyo/Marakwet', value: 'Elgeyo/Marakwet' },
      { label: 'Nandi', value: 'Nandi' },
      { label: 'Baringo', value: 'Baringo' },
      { label: 'Laikipia', value: 'Laikipia' },
      { label: 'Nakuru', value: 'Nakuru' },
      { label: 'Narok', value: 'Narok' },
      { label: 'Kajiado', value: 'Kajiado' },
      { label: 'Kericho', value: 'Kericho' },
      { label: 'Bomet', value: 'Bomet' },
      { label: 'Kakamega', value: 'Kakamega' },
      { label: 'Vihiga', value: 'Vihiga' },
      { label: 'Bungoma', value: 'Bungoma' },
      { label: 'Busia', value: 'Busia' },
      { label: 'Siaya', value: 'Siaya' },
      { label: 'Kisumu', value: 'Kisumu' },
      { label: 'Homa Bay', value: 'Homa Bay' },
      { label: 'Migori', value: 'Migori' },
      { label: 'Kisii', value: 'Kisii' },
      { label: 'Nyamira', value: 'Nyamira' },
      { label: 'Nairobi City', value: 'Nairobi City' }
    ],
    PY: [
      { label: 'Alto Paraguay', value: 'Alto Paraguay' },
      { label: 'Alto Parana', value: 'Alto Parana' },
      { label: 'Amambay', value: 'Amambay' },
      { label: 'Asuncion', value: 'Asuncion' },
      { label: 'Boqueron', value: 'Boqueron' },
      { label: 'Caaguazu', value: 'Caaguazu' },
      { label: 'Caazapa', value: 'Caazapa' },
      { label: 'Canindeyu', value: 'Canindeyu' },
      { label: 'Central', value: 'Central' },
      { label: 'Concepcion', value: 'Concepcion' },
      { label: 'Cordillera', value: 'Cordillera' },
      { label: 'Guaira', value: 'Guaira' },
      { label: 'Itapua', value: 'Itapua' },
      { label: 'Misiones', value: 'Misiones' },
      { label: 'Neembucu', value: 'Neembucu' },
      { label: 'Paraguari', value: 'Paraguari' },
      { label: 'Presidente Hayes', value: 'Presidente Hayes' },
      { label: 'San Pedro', value: 'San Pedro' }
    ]
  };
  