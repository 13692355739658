/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import l, { geoJSON } from 'leaflet';
import shp from "shpjs";
// import { useSnakBarTran } from "src/hooks";
// import { GetS3ReadUrl } from 'src/utils';

export const ShapeFileMap = ({ fileName, setPolydata, }) => {
    const map = useMap();
    // const { showErrorSnackbar } = useSnakBarTran();

    const parseShpFile = async () => {
        try {

            const ext = fileName.substr(fileName.length - 3);
            if (ext !== "zip") {
                return
            }

            // const url = await GetS3ReadUrl('kml', fileName)
            const geo = geoJSON(
                { features: [], }, {
                style: {
                    color: 'red',
                    opacity: 0.5
                },

            }
            ).setZIndex(-1).addTo(map);

            const data = await shp(url);
            geo.addData(data);
            const allCoor = [];

            map.eachLayer(function (layer) {
                if (layer instanceof l.Polygon) {
                    const newCoor = layer.toGeoJSON().geometry.coordinates[0].map(ele => [ele[1], ele[0]]
                    )
                    if (newCoor.length < 2) {
                        layer.setStyle({
                            color: 'red'
                        })
                        layer.bindPopup("Inavlid Polygon", {
                            closeButton: false
                        })
                    } else {
                        allCoor.push([newCoor]);
                    }
                }
            });


            setPolydata([...allCoor]);


        } catch (error) {
            console.log(error);
            // showErrorSnackbar({ text: "Invalid zip(shape) file" });
        }
    }

    useEffect(() => {
        parseShpFile()
    }, [fileName]);

    return null;
}