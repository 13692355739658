/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import { useState } from "react";
import {
    Checkbox,
    FormControlLabel,
    Grid
} from "@mui/material";


export const RadioCheckBox = ({ checkedBoolean, setChecked, disabled }) => {

    const [checkedBox, setCheckedBox] = useState(checkedBoolean);

    const handleInterestCheckbox = (event) => {
        const newCheckedBox = {};
        Object.keys(checkedBoolean).map((ele) => {
            newCheckedBox[ele] = false;
        })
        setCheckedBox({ ...newCheckedBox, [event.target.value]: event.target.checked })
        setChecked(event.target.value, event.target.checked)
    }

    return (
        <Grid container marginBottom="1rem">
            {
                Object.keys(checkedBoolean).map((ele, index) => (
                    <Grid item key={index} xs={6}>
                        <FormControlLabel
                            sx={{
                                margin: "auto",
                                color: "#525A65"
                            }}
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    sx={{
                                        color: "#C3C7CE",
                                        '&.Mui-checked': {
                                            color: "#87C45E",
                                        }
                                    }}
                                    checked={checkedBox[ele]}
                                    value={ele}
                                    onChange={(event) =>
                                        handleInterestCheckbox(event)
                                    }
                                />
                            }
                            label={(ele)}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}