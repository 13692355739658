import React from 'react';
import { Button } from '@mui/material';

export const DataGridButton = (props) => {
  // eslint-disable-next-line react/prop-types
  const { label, params, onClick, disabled, color } = props;
  return (
    <strong>
      <Button
        disabled={disabled || false}
        variant="contained"
        size="small"
        style={{
          backgroundColor: color || '#fcba03',
          color: '#000'
        }}
        onClick={() => {
          // eslint-disable-next-line react/prop-types
          onClick(params.row);
        }}
      >
        {label}
      </Button>
    </strong>
  );
};
