/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Divider, Grid, Paper, Typography } from "@mui/material"

export const SizeOfPolygon = ({ reportedAcres, calculatedAcres }) => {

    return <Paper
        elevation={10}
        sx={{ padding: '1rem' }}>
        <Grid container  >
            {reportedAcres && <Grid xs={calculatedAcres ? 5.5 : 11} item
                justifyContent='center'
                alignItems='center'
            >
                <Typography textAlign='center'>
                    <strong>
                    {reportedAcres.toFixed(2)}
                    </strong>
                </Typography>
                <Typography textAlign='center'>
                    acres
                    (Self Reported)
                </Typography>
            </Grid>}
            {reportedAcres &&
                <Grid item xs={1}>
                    <Divider orientation="vertical" />
                </Grid>
            }
            {calculatedAcres &&
                <Grid xs={reportedAcres ? 5.5 : 11} item
                    justifyContent='center'
                    alignItems='center'>
                    <Typography textAlign='center'>
                        <strong>
                            {calculatedAcres}
                        </strong>
                    </Typography>
                    <Typography textAlign='center'>
                        acres (calculated )
                    </Typography>
                </Grid>}
        </Grid>
    </Paper>
}