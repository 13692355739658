import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../../redux/actions/userAction';

export default function LoginForm() {
  const navigate = useNavigate();
  const store = useSelector((store) => store);
  const userData = store.userRoot;
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (userData.isAuthenticated) navigate('/dashboard');
    if (userData.isError) setIsError(true);
    setIsSubmitting(false);
  }, [userData, navigate]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('place add your valid Email')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password should be minimum of 8 Charaters')
      .required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      dispatch(userLogin(values, navigate));
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        {isError && (
          <h4
            style={{
              color: '#f92479',
              width: '100%',
              padding: '1rem',
              textAlign: 'center'
            }}
          >
            Invalid Credentials
          </h4>
        )}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          style={{
            margin: '2rem auto auto auto'
          }}
        >
          Login
        </LoadingButton>
        <div style={{
          margin: "1rem auto",
          display: "flex",
          justifyContent: "flex-end"
        }}>
          <Link to="/forgotPassword">
            Forgot Password?
          </Link>
        </div>
      </Form>
    </FormikProvider>
  );
}
