import jwtDecode from 'jwt-decode';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import ScrollToTop from './components/ScrollToTop';
import store from './redux/store';
import setAuthToken from './redux/helper/setAuthToken';
import setAuthTokenEdit from './redux/helper/setAuthTokenEdit';
import { userLoginHelper, userLogout } from './redux/actions/userAction';
import { SnackbarCloseButton } from './components/@material-extend/SnackbarCloseButton';

if (window.localStorage.JWT) {
  let decoded = localStorage.getItem('JWT');
  decoded = JSON.parse(decoded);
  setAuthToken(decoded.token)
  setAuthTokenEdit(decoded.token)
  const jwt = decoded.token.split(".");
  const jwtDecoded = jwtDecode(`${jwt[0]}.${jwt[1]}.${jwt[2]}`);
  store.dispatch(userLoginHelper(decoded));
  const currentTime = Date.now() / 1000;
  if (jwtDecoded.exp < currentTime) {
    window.location.href = "/";
    store.dispatch(userLogout());
  }
}

export default function App() {
  LicenseInfo.setLicenseKey(
    '4dbf2303344b36a2fd31657c0f1396b8Tz01MzYyNSxFPTE2OTkwNzkzNTAxOTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        preventDuplicate
        action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      >
        <Router />
      </SnackbarProvider>
    </ThemeConfig>
  );
}
