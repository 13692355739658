/* eslint-disable react/prop-types */
import { HeaderDivider } from '../ConserationRanch/HeaderDivider';
import { RPA } from './RPA';
import { RPB } from './RPB';

export const RegPractices = ({ value, handelChange, disabled }) => {

    const onChange = (newValue, name) => {
        if (disabled) {
            return
        }
        const newAllValue = { ...value };

        newAllValue[name] = newValue;
        handelChange({ ...newAllValue });
    }

    return <div style={{
        marginBottom: '3rem'
    }}>
        <RPA
            disabled={disabled}
            allValues={value.RPA || {}}
            handleChange={(value) => onChange(value, 'RPA')}
        />
        <HeaderDivider title="Regenerative Practices" />
        <RPB
            disabled={disabled}
            allValues={value.RPB || {}}
            handleChange={(value) => onChange(value, 'RPB')}
        />
    </div>
}