/* eslint-disable arrow-body-style */
import React from 'react';
import {
  BarChart,
  CartesianGrid,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

// eslint-disable-next-line react/prop-types
export const BarBm = ({ data, x, y1, y2, y3, y4, y5, y1color, xaxies, barAreaClick, yLabel, CustomTooltip }) => {

  if (xaxies === undefined) {
    xaxies = true;
  }
  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart data={data}
        onClick={(val) => {
          if (!val || !val.activePayload || !barAreaClick) {
            return
          }
          const { activePayload } = val
          barAreaClick(activePayload[0].payload)
        }}
      >
        <XAxis dataKey={x} hide={xaxies && true} />
        <YAxis
          label={{ value: yLabel, angle: -90, offset: 10, position: 'insideBottomLeft' }}
        />
        <Legend />
        {CustomTooltip ?
          <Tooltip content={<CustomTooltip />} /> :
          <Tooltip />
        }
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        {y1 && <Bar
          dataKey={y1}
          stackId="a"
          name='Onboarded Farms'
          fill={y1color || "#05AA02"}
        />}
        {y2 && (
          <Bar
            dataKey={y2}
            stackId="a"
            name='Verified Farms'
            fill="#004752"
          />
        )}
        {y3 && (
          <Bar
            dataKey={y3}
            stackId="a"
            name='Received Farms'
            fill="#ffcc00"
          />)}
        {y4 && (
          <Bar
            dataKey={y4}
            stackId="a"
            name='Rework Farms'
            fill="#199ae0"
          />
        )}
        {y5 && (
          <Bar
            dataKey={y5}
            stackId="a"
            name='Resub Farms'
            fill="#B284BE"
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
