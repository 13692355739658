/* eslint-disable dot-notation */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
import { Page, Text, Document, StyleSheet, Image, View, Link } from '@react-pdf/renderer';

const imgPathUrl = (key) => `https://b-aps1-s3-pop-content.s3.ap-south-1.amazonaws.com/${key}`
export const PopDetailsPdfGenerator = ({ data, partnerName: name }) => {
    
    const { stages } = data;
    return (<Document>
        <Page style={styles.body}>
            <View style={styles.pageHeader} fixed >
                <View style={styles.pageHeaderL1} />
                <View style={styles.pageHeaderL2} />
                <View
                    style={{
                        alignContent: 'flex-start',
                        width: '50rem',
                        padding: 20
                    }}>
                    <Image style={styles.headerIcon} src='/static/illustrations/NewLogo.png' />
                </View>
            </View>
            <View style={{
                paddingHorizontal: 40,
            }}>
                <Text style={styles.packageName}>
                    Package of Practice name: {data.packageName}
                </Text>
                <Text style={styles.packageName}>
                    Partner name: {name}
                </Text>
                <Text style={styles.packageName}>
                    Crop Name: {data.cropName}
                </Text>
            </View>
            {stages.map((stage, index) => (
                <View key={stage + index} style={styles.section}>
                    <Text style={{
                        marginVertical: 4,
                        fontSize: 18,
                        textAlign: 'center',
                        textDecoration: 'underline'
                    }}>Stage {index + 1}: {stage} </Text>
                    <View style={styles.content}>
                        {data[stage]['sequence'].map((activity, index) => {
                            const { description, days, fileKey } = data[stage][activity];
                            return <View key={index + 'ac'}>
                                <View style={{
                                    marginVertical: 4,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                    <Text style={{
                                        fontSize: 16,
                                        textAlign: 'left',
                                        fontWeight: 'ultrabold',
                                        textDecoration: 'underline',
                                        marginRight: 3
                                    }}>
                                        Activity {String.fromCharCode(index + 65)}: {activity}
                                    </Text>
                                    <Text style={{
                                        fontSize: 12,
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        marginTop: 1
                                    }}>
                                        ({days}  days from sowing date)
                                    </Text>
                                </View>
                                <View style={{
                                    marginVertical: 4,
                                    marginHorizontal: 2
                                }}>
                                    <Text style={{
                                        fontSize: 10
                                    }}>
                                        {description}
                                    </Text>
                                </View>
                                <View style={{
                                    marginVertical: 4,
                                    marginHorizontal: 2
                                }}>
                                    <Image style={styles.activityImg} src={imgPathUrl(fileKey)} />
                                </View>
                            </View>
                        })}
                    </View>
                </View>
            ))}
            <View style={styles.footer} fixed >
                <View >
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `Page ${pageNumber} of ${totalPages}`
                    )} />
                </View>
                <View style={{
                    color: '#004752',
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    textDecoration: 'none',
                }}>
                    <Link style={styles.link} src='#Footnote'>
                        Copyright © 2023 Boomitra |
                    </Link>
                    <Link style={styles.link} src='https://boomitra.com/privacy-policy/'>
                        Privacy Policy |
                    </Link>
                    <Link style={styles.link} src='https://boomitra.com/terms-conditions/'>
                        Terms & Conditions
                    </Link>
                </View>
                <View>
                    <Image style={{
                        width: '40px',
                        height: '40px',
                    }} src='/favicon/favicon.ico' />
                </View>
            </View>
        </Page>
    </Document >
    )
};



const styles = StyleSheet.create({
    body: {
        paddingBottom: 50
    },
    cropName: {
        fontSize: 20,
        textAlign: 'center',
        textTransform: 'capitalize'
    },
    packageName: {
        fontSize: 12,
        textAlign: 'left',
        textTransform: 'capitalize',
        marginVertical: 4,
        fontWeight: 'ultrabold',
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
    },
    headerIcon: {
        width: '145px',
        height: '87px',
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        width: '100%',
        position: 'absolute',
        bottom: 10
    },
    pageNumber: {
        color: '#004752',
        fontSize: 12,
        marginTop: 10
    },
    pageHeader: {
        width: '100vw',
        height: '13vh'
    },
    pageHeaderL1: {
        height: '8vh',
        backgroundColor: '#004752',
        padding: 4
    },
    pageHeaderL2: {
        height: '1vh',
        backgroundColor: '#87C45E'
    },
    activityImg: {
        width: '300px',
        height: '200px',
        padding: 3
    },
    link: {
        color: '#004752',
        textDecoration: 'none',
        marginHorizontal: 2,
        marginTop: 10
    },
    section: {
        paddingHorizontal: 40
    }
});

