/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import { ContratorTables } from './pages/Rep';
import { FarmerTables } from './pages/Farmer';
import { FieldsTables } from './pages/Fields';
import { ForgotPassword } from './pages/forgotPassword';
import { ResetPasswort } from './pages/resetPassword';
import { AllDashboard } from './pages/AllDashBoard';
import { Superman } from './pages/Superman';
import { SearchByDate } from './pages/SearchByDate';
import { SearchFieldByDateMob } from './pages/SearchFieldByDateMob';
import NotFound from './pages/Page404';
import { InternalDayWise } from './pages/InternalDayWise';
import { SearchByMultipleDate } from './pages/SearchByMultipleDate';
import { SearchByMultiDatePartner } from './pages/searchByMultiDatePartner';
import { Validation } from './pages/Validation';
import { Map } from './pages/Map';
import { FieldByPartnerId } from './pages/FieldByPartnerId';
import { FarmStatus } from './pages/farmStatus';
import { RancherValidation } from './pages/Ranch/RancherValidation';
import { RanchDetail } from './pages/Ranch/RanchDetail';
import { RanchInfo } from './layouts/questionnaire/ranchInfo';
import { PartnerAccess } from './pages/partnerAccess';
import { Onboarding } from './pages/Ranch/onboarding';
import { AddRanch } from './pages/Ranch/AddRanch';
import { DocUpload } from './pages/Ranch/DocUpload';
import { LoginDetails } from './pages/LoginDetails'
import { SurveyPage } from './pages/SurveyPage';
import { FarmerAllField } from './pages/FarmerAllField';
import { PackageOfPractices } from './pages/PackageOfPracticesv2';
import { PopReports } from './pages/PopReports';

export default function Router() {
  const userDetail = useSelector((store) => store.userRoot);
  const { superman, isAuthenticated, user:{ readonly} } = userDetail
  return useRoutes([
    {
      path: '/dashboard',
      element:
        isAuthenticated && (superman || readonly) ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/AllDashBoard" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'REP', element: <ContratorTables /> },
        { path: 'farmers', element: <FarmerTables /> },
        { path: 'field', element: <FieldsTables /> },
        { path: 'Superman', element: <Superman /> },
        { path: 'SearchbyDate', element: <SearchByDate /> },
        { path: 'SearchFieldByRep', element: <SearchFieldByDateMob /> },
        { path: 'AllDashBoard', element: <AllDashboard /> },
        { path: 'InternalDayWise', element: <InternalDayWise /> },
        { path: 'search_betweenDatePartner', element: <SearchByMultiDatePartner /> },
        { path: 'searchbetweenDate', element: <SearchByMultipleDate /> },
        { path: 'Validation', element: <Validation /> },
        { path: 'fieldByPartnerId', element: <FieldByPartnerId /> },
        { path: 'farmStatus', element: <FarmStatus /> },
        { path: 'MAP', element: <Map /> },
        { path: 'RanchQuestionnaire', element: <RanchInfo /> },
        { path: 'ranchValidation', element: <RancherValidation /> },
        { path: 'ranchDetail', element: <RanchDetail /> },
        { path: 'partnerAccess', element: <PartnerAccess /> },
        { path: 'Onboarding', element: <Onboarding /> },
        { path: 'AddRanch', element: <AddRanch /> },
        { path: 'documents', element: <DocUpload /> },
        { path: 'loginDet', element: <LoginDetails /> },
        { path: 'survey', element: <SurveyPage /> },
        { path: 'farmerAllField', element: <FarmerAllField /> },
        { path: 'pop', element: <PackageOfPractices /> },
        { path: 'popReports', element: <PopReports /> },
        { path: '*', element: <NotFound /> }
      ]
    },
    {
      path: '/',
      element:
        !isAuthenticated && (!superman || !readonly) ? (
          <LogoOnlyLayout />
        ) : (
          <Navigate to="/dashboard" />
        ),
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: 'forgotPassword', element: <ForgotPassword /> },
        { path: 'resetPassword', element: <ResetPasswort /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
