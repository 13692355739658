/* eslint-disable consistent-return */
export const RegPracticesValidation = (regPractices, setActiveTab) => {
    if (regPractices && "RPA" in regPractices) {
        if (RPATemperature(regPractices.RPA.temperature, setActiveTab)) {
            return { error: true, message: "Yearly Temperature Range is invalid" }
        }
    }
    return { error: false }
}

const RPATemperature = (temperature, setActiveTab) => {
    if (temperature && "minTemp" in temperature && "maxTemp" in temperature) {
        const maxTemp = +temperature.maxTemp;
        const minTemp = +temperature.minTemp;

        if (minTemp > maxTemp) {
            setActiveTab(0)
            setTimeout(() => {
                const ele = document.querySelector(".temperatureQ1");
                if (ele) {
                    ele.classList.add('questionnaireError')
                    ele.focus();
                    setTimeout(() => {
                        ele.classList.remove('questionnaireError')
                    }, 2000);
                }
            }, 1000);
            return true
        }
    }


}