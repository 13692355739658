import { userLogout } from './userAction';
import axios from '../../components/api/api'

export const homeAction = (auth, history) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `statPartnerWise?partnerId=${auth.partnerID}`
    );
    dispatch({
      type: 'SET_HOME_DATA',
      payload: data
    });
  } catch (err) {
      dispatch(userLogout(history));
  }
};
