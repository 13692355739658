/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import { useState, useEffect } from 'react';
import { TabsBM } from 'src/components/@material-extend/TabsBM';
import { Header } from '../../layouts/questionnaire/Header';
import { ConserationRanch } from '../../layouts/questionnaire/ConserationRanch';
import { LiveStockRanch } from '../../layouts/questionnaire/LiveStockRanch';
import { ManagementRanch } from '../../layouts/questionnaire/ManagementRanch';
import { OptionalRanch } from '../../layouts/questionnaire/OptionalRanch';
import { RegPractices } from '../../layouts/questionnaire/regPractices';
import { Validation } from 'src/layouts/questionnaire/validation';
import { useNavigate } from 'react-router-dom';
import { ranchApi } from 'src/components/api';

export const RanchInfo = () => {
  const urlQuery = new URLSearchParams(window.location.search);
  let ranchInfo = {}
  let disabled = false;
  let otherDetails = {}
  if (urlQuery.get('ranchInfo')) {
    ranchInfo = JSON.parse(urlQuery.get('ranchInfo'));
    if (urlQuery.get('otherDetails')) {
      otherDetails = JSON.parse(urlQuery.get('otherDetails'));
      if (otherDetails.infoStatus !== 'draft') {
        disabled = true;
      }
    }
  }
  console.log(disabled, otherDetails);
  const [lastSaved, setLastSaved] = useState();
  const [optionalRanch, setOptionaRanch] = useState(ranchInfo.optionalRanch || {});
  const [managementRanch, setManagementRanch] = useState(ranchInfo.managementRanch || {});
  const [conserationRanch, setConserationRanch] = useState(ranchInfo.conserationRanch || {});
  const [liveStockRanch, setLiveStockRanch] = useState(ranchInfo.liveStockRanch || {});
  const [regPractices, setRegPractices] = useState(ranchInfo.regPractices || {});
  const [activeTab, setActiveTab] = useState(0);
  const [change, setChange] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (change === 0) {
      return;
    }
    const timer = setTimeout(() => {
      saveDraft()
      setChange(0)
    }, 2000);

    return () => {
      clearTimeout(timer);
    }
  }, [change])

  useEffect(() => {
    if (localStorage.ranchInfo && otherDetails.infoStatus === 'draft') {
      const ranchInfo = JSON.parse(localStorage.getItem('ranchInfo'));
      if (ranchInfo.id === otherDetails.SK) {
        updateState(ranchInfo);
      }
    }
  }, []);

  const updateState = (ranchInfo) => {
    setRegPractices(ranchInfo.regPractices || {})
    setOptionaRanch(ranchInfo.optionalRanch || {});
    setManagementRanch(ranchInfo.managementRanch || {});
    setConserationRanch(ranchInfo.conserationRanch || {});
    setLiveStockRanch(ranchInfo.liveStockRanch || {})
    setActiveTab(ranchInfo.activeTab || 0)
  }

  const saveData = async () => {
    setChange(0)
    const res = Validation({
      optionalRanch,
      managementRanch,
      conserationRanch,
      liveStockRanch,
      regPractices,
    }, setActiveTab)

    if (res.error) {
      alert(res.message);
      // showErrorSnackbar({ text: res.message });
      return
    }
    await uploadData('final')
  }
  const uploadData = async (infoStatus) => {

    const submitSurveyData = {
      optionalRanch,
      managementRanch,
      conserationRanch,
      liveStockRanch,
      infoStatus,
      regPractices,
      PK: otherDetails.SK,
      userId: otherDetails.PK
    }
    try {
      setLoading(true);
      await ranchApi.post('/AddRanch', { ...submitSurveyData });
      setLoading(false);
      if (infoStatus !== 'draft') {
        navigate(`../documents?TotalDoc=${otherDetails.totalDoc || 0}`, {
          state: {
            PK: submitSurveyData.userId,
            SK: submitSurveyData.PK,
            status: 'UNCONFIRMED'
          },
          replace: true
        });
      }
    } catch (e) {
      console.log(e);
      alert("Failed to save")
    }
  }

  const saveDraft = async () => {
    await uploadData('draft');
    localStorage.setItem('ranchInfo', JSON.stringify({
      optionalRanch,
      managementRanch,
      conserationRanch,
      liveStockRanch,
      regPractices,
      activeTab,
      id: otherDetails.SK,
      lastSaved: new Date().toString()
    }));
    setLastSaved(new Date())
  };

  return (
    <div style={{
      padding: '2rem'

    }}>
      <Header
        actionButton
        time={lastSaved}
        details={otherDetails}
        loading={loading}
        saveData={saveData}
        heading="Questionnaire"
      />
      <TabsBM
        value={activeTab}
        handleChange={(v) => setActiveTab(v)}
        fullWidth
        labelComponents={[
          {
            label: 'Regenerative Practices',
            Component: (
              <RegPractices
                value={regPractices}
                disabled={disabled}
                handelChange={(v) => {
                  setRegPractices(v)
                  setChange(change + 1)
                }}
              />
            )
          },
          {
            label: 'Livestock Type and Count',
            Component: (
              <LiveStockRanch
                disabled={disabled}
                value={liveStockRanch}
                handleChange={(v) => {
                  setLiveStockRanch(v)
                  setChange(change + 1)
                }}
              />
            )
          },
          {
            label: 'Management Practices',
            Component: (
              <ManagementRanch
                disabled={disabled}
                value={managementRanch}
                handleChange={(v) => {
                  setManagementRanch(v)
                  setChange(change + 1)
                }}
              />
            )
          },
          {
            label: 'Water Conservation',
            Component: (
              <ConserationRanch
                disabled={disabled}
                value={conserationRanch}
                handleChange={(v) => {
                  setConserationRanch(v)
                  setChange(change + 1)
                }}
              />
            )
          },
          {
            label: 'Optional Questions',
            Component: (
              <OptionalRanch
                disabled={disabled}
                value={optionalRanch}
                handleChange={(v) => {
                  setOptionaRanch(v)
                  setChange(change + 1)
                }}
              />
            )
          }
        ]}
      />
    </div>
  );
};
