const initialState = {
  user: {},
  isAuthenticated: false,
  isError: false,
  partnerID: '',
  superman: false
};
const userReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_USERS_DATA':
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        partnerID: action.payload.partnerID,
        isError: false,
        superman: action.payload.superman
      };
    case 'DELETE_USERS_DATA':
      return {
        ...state,
        user: action.payload,
        isAuthenticated: false,
        isError: false,
        partnerID: '',
        superman: false
      };
    case 'SET_LOGIN_ERRORS':
      return {
        ...state,
        user: action.payload,
        isAuthenticated: false,
        isError: true,
        partnerID: '',
        superman: false
      };
    case 'CHANGE_PARTNER_ID':
      return {
        ...state,
        partnerID: action.payload.partnerID,
      }
    default:
      return state;
  }
};

export default userReducer;
