/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-labels */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Typography, Grid, TextField, Stack } from '@mui/material';
import { Navigate } from 'react-router-dom';
import api from '../components/api/api';

const fields = [{
  id: "name",
  name: "name",
  label: "Full Name",
},
{
  id: "phone_Number",
  name: "phone_Number",
  label: "Phone Number",
  type: "number"
},
{
  id: "address",
  name: "address",
  label: "Address",
},
{
  id: "postal",
  name: "postal",
  label: "Postal Code",
  type: "number"
},
{
  id: "email",
  name: "email",
  label: "Email",
},
{
  id: "partnerId",
  name: "partnerId",
  label: "Partner Id",
},
{
  id: "user_type",
  name: "user_type",
  label: "User Type",
},
{
  id: "country",
  name: "country",
  label: "Country as IN / KE",
},
{
  id: "state",
  name: "state",
  label: "State",
}
]


export const PartnerAccess = () => {
  const { group } = useSelector((store) => store.userRoot.user);
  const [loading, setLoading] = useState(false);

  const partnerData = yup.object().shape({
    name: yup.string().trim().min(2, 'Please enter a valid Name').required('Name is required'),
    phone_Number: yup.string().trim().min(4, 'Please enter valid phone number').required(' Country code : Phone number is a required field'),
    email: yup.string().trim().required("Please  enter valid email"),
    address: yup.string().trim().required("Please enter address"),
    postal: yup.string().trim().required('Enter postal code'),
    partnerId: yup.string().trim().min(4, 'Please enter a valid partnerID').max(6, 'Please enter a valid partnerID').required('Enter Partner ID'),
    country: yup.string().trim().required("Please enter country"),
    state: yup.string().trim().required("Please enter state")

  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true)
      await api.post('/createUserPDB', values);
      resetForm()

    } catch (error) {
      console.log(error);
      alert('failed')
    } finally {
      setLoading(false)
    }

  }

  const formik = useFormik({
    initialValues: {
      name: '',
      phone_Number: '',
      email: '',
      address: '',
      postal: '',
      partnerId: '',
      user_type: '',
      country: '',
      state: ''
    },
    validationSchema: partnerData,
    onSubmit
  })



  if (!group || group && !group.includes('createUser')) {
    return <Navigate to='../app' replace />
  }


  return (

    <form onSubmit={formik.handleSubmit}>
      <Grid>
        <Grid>
          <Typography style={{ paddingLeft: "30%", fontSize: 30, paddingBottom: 30 }}>
            <strong>Partner Access</strong>
          </Typography>
        </Grid>
        <Grid style={{ paddingLeft: "10%"}}>
          <Stack spacing={3}>
          {fields.map((item, index) => <TextField
            id={item.id}
            name={item.name}
            label={item.label}
            type={item.type}
            style={{ width: "40%" }}
            value={formik.values[item.id]}
            onChange={formik.handleChange}
            key={index}
            error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
            helperText={formik.touched[item.id] && formik.errors[item.id]}
          />)
          }
          </Stack>
        </Grid>
        <Grid style={{ paddingLeft: "40%", paddingTop: 50 }} >
          <LoadingButton
            style={{backgroundColor:"#005249"}}
            loading={loading}
            variant="contained"
            type="submit">Submit</LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
