/* eslint-disable react/prop-types */
import { LoadingButton } from '@mui/lab';

export const LoadingBtnBm = ({ loading, children, ...other }) => <LoadingButton
    fullWidth
    type="submit"
    variant="contained"
    loading={loading}
    style={{
      boxShadow: 'none',
      padding: '20px 32px',
      background: '#6DAE43',
      height: '46px',
      bottom: '1px'
    }}
    {...other}
  >
    {(children)}
  </LoadingButton>
