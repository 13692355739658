/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { PopDetailsPdfGenerator } from './PopDetailsPdfGenerator';
import { LoadingBtnBm } from 'src/components/@material-extend';


export const PopDetailsPdf = ({ data }) => {
  const { name } = useSelector((store) => store.userRoot.user.partnerDetail);
  return <PDFDownloadLink
    document={<PopDetailsPdfGenerator
      partnerName={name} data={data} />}
    fileName={`${data.packageName}_Boomitra.pdf`}>
    {({ loading }) => <LoadingBtnBm
      startIcon={<CloudDownloadIcon />}
      fullWidth={false}
      style={{ background: '#004752', marginTop: '0.5rem', width: '9vw', padding: '0.65rem' }}
      loading={loading}>
      Download
    </LoadingBtnBm>}
  </PDFDownloadLink>
}

