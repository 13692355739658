/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable no-undef */
/* eslint-disable object-shorthand */
import { useEffect, useState } from 'react';
import { Control } from 'leaflet';
import { useMap } from 'react-leaflet';
import 'leaflet-google-places-autocomplete';

export const AutoSearch = () => {
    const map = useMap();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (window.google === undefined || ready) {
                return
            }
            new Control.GPlaceAutocomplete({
                callback: function (place) {
                    const loc = place.geometry.location;
                    map.setView([loc.lat(), loc.lng()], 18);
                },
                position: 'topleft'
            }).addTo(map);
            setReady(true)
        }, 1000)

    }, [window.google])

    return null
}