
/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux';

export const RBACAuth = ({ children, groupID }) => {
    const { group } = useSelector((store) => store.userRoot.user);

    // if (group && group.includes(groupID)) {
        return children;
    // }
    
    // return null;
} 