/* eslint-disable react/prop-types */
export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const { payload: data } = payload[0]
        return (
            <div style={{
                backgroundColor: "#fff",
                padding: '1rem'
            }}>
                <p>{label}</p>
                <p style={{ color: "#ea6a47" }}> Number Of Farms : {data["Total Farms"]} ({data.TOTALArea?.toFixed(0)} acres)
                </p>
                <p style={{
                    color: "#05AA02"
                }}> ONBOARDED Farms {data.ONBOARDED} ({data.ONBOARDEDAREA?.toFixed(0) || 0} acres)  </p>
                <p style={{
                    color: "#ffcc00"
                }}> RECEIVED Farms: {data.RECEVIED} ({data.RECEVIEDAREA?.toFixed(0) || 0} acres)  </p>
                <p style={{
                    color: "#199ae0"
                }}> REWORK Farms: {data.REWORK} ({data.REWORKAREA?.toFixed(0) || 0} acres)  </p>
                <p style={{
                    color: "#B284BE"
                }}> RESUB Farms : {data.RE_SUB} ({data.RE_SUBFARMAREA?.toFixed(0) || 0} acres)  </p>
                <p style={{
                    color: "#004752"
                }}>
                    VERIFIED Farms : {data.VERIFIED} ({data.VERIFIEDAREA?.toFixed(0) || 0} acres)
                </p>
            </div >
        );
    }

    return null;
};