/* eslint-disable react/prop-types */
import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { DetailEle } from './DetailEle';

export const Chips = ({ dataArray, label, Icon }) => (
  <Stack
    direction="row"
    spacing={0.4}
    style={{
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      margin: '1rem 0'
    }}
  >
    <DetailEle label={label} Icon={Icon} />
    {dataArray
      .map((ele, index) => {
        if (ele && ele !== 'undefined')
          return (
            <Chip
              label={ele.comment}
              style={{
                backgroundColor: '#000',
                fontWeight: 'bold',
                color: '#fff'
              }}
              key={index}
            />
          );
        return '';
      })}
  </Stack>
);
