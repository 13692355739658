/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable dot-notation */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { InputText, LoadingBtnBm, SelectBm, TypographyBm } from '../../components/@material-extend';
import { countries } from '../../data/countryData/countries';
import { state } from 'src/data/countryData/states';
import { AreaOfPolygon } from 'src/utils/AreaPolygon';
import * as Yup from 'yup';
import { ranchApi } from 'src/components/api';
import { stateLabel } from 'src/data/countryData/stateLabel';
import { Box, Divider, Stack } from '@mui/material';
import { SelectRancher } from './SelectRancher';
import { useNavigate } from 'react-router-dom';

const SurveyForm = ({ coord, uploadKey, stateData }) => {
  let area;
  // const { showSuccessSnackbar, showErrorSnackbar } = useSnakBarTran(); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(stateData.country || 'AR');
  const surveyFormSchema = Yup.object().shape({
    name: Yup.string().trim().min(2, 'Please enter a valid Name').required('Name is required'),
    address: Yup.string().trim().required('Address is a required field'),
    city: Yup.string().trim().required('City is a required field'),
    postal: Yup.string().trim().required('Enter postal code'),
  });
  const [selectedState, setSelectedState] = useState(stateData.state || 'Buenos Aires');
  const stateDesc = stateLabel[selectedCountry] ? stateLabel[selectedCountry] : 'State';
  const [rancherPK, setRancherPK] = useState(stateData.PK || '');
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: stateData.ranchName || '',
      ranchType: stateData.ranchType || 'Private',
      coordinates: '',
      years: '',
      address: stateData.address || '',
      postal: stateData.postal || '',
      city: stateData.city || '',
      partnerId: stateData.partnerId || ''
    },
    validationSchema: surveyFormSchema,
    onSubmit: async (values) => {
      if (!coord.length && (uploadKey === '' || undefined) && !stateData.ranchName) {
        alert('Please either draw ranch boundary or upload map file of ranch');
        return;
      }
      try {
        const navState = {
          name: values.name,
          area,
          unit: 'acres'
        };
        setIsSubmitting(true);
        const ranchData = {
          name: values.name,
          ranchType: values.ranchType,
          coordinates: JSON.stringify(coord),
          rainfall: values.rainfall,
          userID: rancherPK,
          state: selectedState,
          calcArea: area,
          status: 'UNCONFIRM',
          country: selectedCountry,
          address: values.address,
          postal: values.postal,
          city: values.city,
          unit: 'acres',
          area,
          fileKey: uploadKey,
          partnerId: values.partnerId
        };
        if (stateData.ranchName) {
          const editData = {
            PK: stateData.PK,
            SK: stateData.SK,
            status: stateData.status,
            state: selectedState,
            city: values.city,
            postal: values.postal,
            address: values.address,
            country: selectedCountry,
            ranchType: values.ranchType,
            ranchName: values.name,
          }
          await ranchApi.post('/EditRanchData', editData);
          navState['PK'] = stateData.PK;
          navState['SK'] = stateData.SK;
        } else {
          const { data } = await ranchApi.post('/AddRanch', ranchData);
          navState['PK'] = data.PK;
          navState['SK'] = data.SK;
        }
        const pk = navState.PK.replace('#', '%23');
        const sk = navState.SK.replace('#', '%23');
        const ranchDetail = JSON.stringify({ ranchName: navState.name, area: navState.area, unit: navState.unit, PK: pk, SK: sk, infoStatus: stateData.infoStatus || 'draft', totalDoc: stateData.TotalDoc || 0 });
        // showSuccessSnackbar({ text: 'Successfully saved' });
        if (!stateData.PK) {
          navigate(`../RanchQuestionnaire?ranchInfo={}&otherDetails=${ranchDetail}`)
        } else {
          navigate('../ranchValidation')
        }
      } catch (error) {
        console.log(error);
        let err = error?.request?.response;
        err = err.replaceAll('"', '');
        alert(err)
        // showErrorSnackbar({ text: err || 'Failed' });
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const handleStateSelectChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedState(value);
  };

  const handleCountrySelectChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedState(state[value][0]["value"]);
    setSelectedCountry(value);
  };
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  area = AreaOfPolygon(coord, true); 

  return (
    <div
      style={{
        display: 'grid',
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ marginTop: '-6%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <TypographyBm component='div' style={{
                fontSize: '1rem',
                fontFamily: 'Figtree',
                margin: '0.5rem auto',
                color: '#004752',
                fontWeight: '600',
              }}>
                Ranch Details
              </TypographyBm>
            </Box>
            <Divider />
            <Stack spacing={2} padding='1rem' sx={{
              overflowY: 'auto',
              maxHeight: '96vh',
              overflowX: 'hidden'
            }}>

              <InputText
                type="name"
                label="Ranch Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{ bgcolor: '#F1F1F1', borderRadius: 1 }}
                size="medium"
                errorBgColor="#fff"
              />
              <InputText
                type="name"
                label="Partner ID"
                {...getFieldProps('partnerId')}
                error={Boolean(touched.partnerId && errors.partnerId)}
                helperText={touched.partnerId && errors.partnerId}
                sx={{ bgcolor: '#F1F1F1', borderRadius: 1 }}
                size="medium"
                errorBgColor="#fff"
                disabled={stateData.partnerId}
              />
              <SelectRancher
                partnerId={getFieldProps('partnerId').value}
                defaultValue={stateData.PK}
                value={rancherPK}
                setValue={setRancherPK}
              />
              <InputText
                label="Ranch Area"
                name="area"
                value={stateData.area || area}
                sx={{ bgcolor: '#F1F1F1', borderRadius: 1 }}
                size="medium"
                disabled
                errorBgColor="#fff"
                unit='acres'
              />
              <SelectBm
                size="medium"
                label="Ranch Ownership"
                fullWidth
                {...getFieldProps('ranchType')}
                sx={{
                  bgcolor: '#F1F1F1',
                  borderRadius: 1
                }}
                list={selectedCountry === 'MX' ? ['Private', 'Ejido'] : ['Private', 'Community']}
              />
              <TypographyBm sx={{ color: '#525A65' }} variant={'h6'}>Ranch Address</TypographyBm>
              <InputText
                type="address"
                label="Address"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
                sx={{ bgcolor: '#F1F1F1', borderRadius: 1 }}
                size="medium"
                errorBgColor="#fff"
              />
              <InputText
                type="city"
                label="City"
                {...getFieldProps('city')}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
                sx={{ bgcolor: '#F1F1F1', borderRadius: 1 }}
                size="medium"
                errorBgColor="#fff"
              />
              <SelectBm
                size="medium"
                label={`Select ${stateDesc}`}
                fullWidth
                value={selectedState || state[selectedCountry][0].value}
                onChange={handleStateSelectChange}
                sx={{
                  bgcolor: '#F1F1F1',
                  borderRadius: 1
                }}
                list={state[selectedCountry] || []}
              />
              <InputText
                type="postal"
                label="Postal Code"
                {...getFieldProps('postal')}
                error={Boolean(touched.postal && errors.postal)}
                helperText={touched.postal && errors.postal}
                sx={{ bgcolor: '#F1F1F1', borderRadius: 1 }}
                size="medium"
                errorBgColor="#fff"
              />
              <SelectBm
                size="medium"
                label="Select Country"
                fullWidth
                value={selectedCountry}
                onChange={handleCountrySelectChange}
                sx={{
                  bgcolor: '#F1F1F1',
                  borderRadius: 1
                }}
                list={countries}
              />
              <div style={{ margin: '1rem auto', width: '85%', }}>
                <LoadingBtnBm
                  loading={isSubmitting}
                  type="submit"
                  style={{
                    fontSize: '1rem',
                    backgroundColor: '#005249',
                    boxShadow: 'none',
                  }}
                >
                  Save
                </LoadingBtnBm>
              </div>
            </Stack>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default SurveyForm;