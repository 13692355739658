/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
import { Box } from '@mui/material';
import { sortByDateComparator } from '../utils/sortData';
import { DataGridButton } from './DataGridButton';
import { DataGridMenu, DataGridIconMenu } from './@material-extend';
import { fDateTime } from '../utils/formatTime'


export const SearchRepKeys = [
  'createdat',
  'status',
  'given_name',
  'family_name',
  'phone_number',
  'totalarea',
  'totalfarm',
  'address',
  'town',
  'state',
  'country'
];

export const getContratorColumn = (onClickRow) => [
  {
    field: 'Rep Name',
    headerName: 'Rep Name',
    width: 150,
    valueGetter: (params) => `${params.row.given_name || ''} ${params.row.family_name || ''}`
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    width: 150
  },
  {
    field: 'District/County',
    headerName: 'District/County',
    width: 150,
    valueGetter: (params) => params.row.district || params.row.town
  },
  {
    field: 'createdat',
    headerName: 'Registered',
    width: 100,
    sortComparator: sortByDateComparator,
    valueGetter: (params) => new Date(params.row.createdat).toDateString().slice(4)
  },
  {
    field: 'totalarea',
    headerName: 'Total Onboarded Area',
    width: 150,
    renderCell: (params) => params.row.totalarea.toFixed(2) || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'totalfarm',
    headerName: 'Total Onboarded Farm',
    width: 150,
    renderCell: (params) => params.row.totalfarm || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'onboardedArea',
    headerName: 'Onboarded Area',
    width: 130,
    renderCell: (params) => params.row.onboardedArea.toFixed(2) || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'onboardedFarm',
    headerName: 'Onboarded Farms',
    width: 130,
    renderCell: (params) => +params.row.onboardedFarm || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'receivedArea',
    headerName: 'Received Area',
    width: 130,
    renderCell: (params) => params.row.receivedArea.toFixed(2) || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'receivedFarm',
    headerName: 'Received Farms',
    width: 150,
    renderCell: (params) => +params.row.receivedFarm || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'reworkArea',
    headerName: 'Rework Area',
    width: 130,
    renderCell: (params) => params.row.reworkArea.toFixed(2) || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'reworkFarm',
    headerName: 'Rework Farms',
    width: 120,
    renderCell: (params) => params.row.reworkFarm || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 're_subArea',
    headerName: 'Re_sub Area',
    width: 130,
    renderCell: (params) => +params.row.re_subArea.toFixed(2) || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 're_subFarm',
    headerName: 'Resubmitted Farms',
    width: 150,
    renderCell: (params) => +params.row.re_subFarm || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'verifiedFarm',
    headerName: 'Verified',
    width: 130,
    renderCell: (params) => +params.row.verifiedFarm || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'verifiedArea',
    headerName: 'Verified AREA',
    width: 130,
    renderCell: (params) => +params.row.verifiedArea.toFixed(2) || 0,
    align: 'center',
    type: "number"
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params) => <RenderStatus status={params.row.status} />
  },
  {
    field: 'UNCONFIRM',
    headerName: ' ',
    width: 100,
    renderCell: (params) =>
      params.row.status === 'UNCONFIRMED' ? (
        <DataGridIconMenu params={params} onClick={onClickRow} list={['Farmer’s List']} />
      ) : (
        <DataGridIconMenu
          params={params}
          onClick={onClickRow}
          list={['Farmer’s List']}
        />
      )
  }
];


export const SearchFarmerKey = [
  'fullname',
  'phone_number',
  'land_area',
  'status',
  'onboardedon',
];

export const getFarmerColumn = (onClickRow) => [
  {
    field: 'fullname',
    headerName: 'Farmer',
    width: 350
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    width: 250
  },
  {
    field: 'land_area',
    headerName: 'Area Registered',
    width: 100,
    renderCell: (params) => params.row.area
  },
  {
    field: 'Application Status',
    headerName: 'Application Status',
    width: 250,
    renderCell: (params) => <RenderStatus status={params.row.status} />
  },
  {
    field: 'onboardedon',
    headerName: 'Onboarded on',
    width: 200,
    valueGetter: (params) => new Date(params.row.onboardedon).toDateString().slice(4)
  },
  {
    field: '2',
    headerName: 'Details',
    renderCell: (params) => (
      <DataGridButton params={params} color=" #87C45E" label="Details" onClick={onClickRow} />
    )
  }
];

export const getFieldColumns = (onClickRow) => [
  {
    field: 'onBoardedOn',
    headerName: 'Onboarded on',
    width: 350
  },
  {
    field: 'land_area',
    headerName: 'Area Registered',
    width: 250,
    renderCell: (params) => params.row.area
  },
  {
    field: 'Application Status',
    headerName: 'Application Status',
    width: 350,
    renderCell: (params) => <RenderStatus status={params.row.status} />
  },
  {
    field: '2',
    headerName: 'Details',
    renderCell: (params) => (
      <DataGridButton params={params} color=" #87C45E" label="Details" onClick={onClickRow} />
    )
  }
];

export const SearchFarmKey = ['createdAt', 'practice_start_date', 'status', 'land_area'];

export const getFieldColumn = (fieldDetailSetter) => [
  {
    field: 'createdAt',
    headerName: 'Onboarded On',
    width: 150,
    sortComparator: sortByDateComparator
  },
  {
    field: 'practiceStartDate',
    headerName: 'Practice Started On',
    width: 150,
    sortComparator: sortByDateComparator
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150
  },
  {
    field: 'land_area',
    headerName: 'Area',
    width: 100,
    renderCell: (params) => params.row.land_area.toFixed(2)
  },
  {
    field: 'tillage_flag',
    headerName: 'Tillage',
    width: 80
  },
  {
    field: 'compost_flag',
    headerName: 'Compost',
    width: 80
  },
  {
    field: 'manure_flag',
    headerName: 'Manure',
    width: 80
  },
  {
    field: 'irrigation_flag',
    headerName: 'Irrigation',
    width: 80
  },
  {
    field: 'synthetic_fertilizer_flag',
    headerName: 'Synthetic Fertilizer',
    width: 100
  },
  {
    field: 'flooding_flag',
    headerName: 'Flooding',
    width: 80
  },
  {
    field: 'grazing_flag',
    headerName: 'Grazing',
    width: 80
  },
  {
    field: 'Detail',
    headerName: 'Detail',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="details" onClick={fieldDetailSetter} />
    )
  }
];


export const getAllFieldColumn = (fieldDetailSetter, farmerDetailSetter, toRep, toFarmer) => [
  {
    field: 'createdAt',
    headerName: 'Onboarded On',
    width: 150,
    sortComparator: sortByDateComparator
  },
  {
    field: 'practiceStartDate',
    headerName: 'Practice Started On',
    width: 150,
    sortComparator: sortByDateComparator
  },
  {
    field: 'fullName',
    headerName: 'REP Name',
    width: 160,
    valueGetter: (params) => `${params.row.REP_given_name || ''} ${params.row.REP_family_name || ''}`
  },
  {
    field: 'fullName2',
    headerName: 'Farmer Name',
    width: 160,
    valueGetter: (params) => `${params.row.Farmer2_given_name || ''} ${params.row.Farmer2_family_name || ''}`
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150
  },
  {
    field: 'land_area',
    headerName: 'Area',
    width: 100,
    renderCell: (params) => params.row.land_area.toFixed(2)
  },
  {
    field: 'Detail',
    headerName: 'Farm Detail',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="Farm" onClick={fieldDetailSetter} />
    )
  },
  {
    field: 'Detail farmer',
    headerName: 'Farmer Detail',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="Farmer" onClick={farmerDetailSetter} />
    )
  },
  {
    field: 'REPS',
    headerName: 'REP Detail',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="REP Status" onClick={toRep} />
    )
  },
  {
    field: 'farmer',
    headerName: 'Farmer Status',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="Farmer Status" onClick={toFarmer} />
    )
  }
];

export const getDetailDayWise = (toRep, toSearchField) => {
  const res = [
    {
      field: 'fullName',
      headerName: 'REP Name',
      width: 180,
      align: 'center',
      valueGetter: (params) => `${params.row.given_name || ''} ${params.row.family_name || ''}`
    },
    {
      field: 'phone_number',
      headerName: 'Phone',
      width: 150,
      align: 'center',
    },
    {
      field: 'town',
      headerName: 'District/County',
      width: 150,
      align: 'center',
    },
    {
      field: 'totalArea',
      headerName: 'Total Area',
      width: 150,
      align: 'center',
      renderCell: (params) => params.row.totalArea.toFixed(2)
      
    },
    {
      field: 'TotalFarm',
      headerName: 'Total Farm',
      width: 100,
      align: 'center',
    },
    {
      field: 'ONBOARDED_Area',
      headerName: 'ONBOARDED Area',
      width: 100,
      align: 'center',
      renderCell: (params) => params.row.ONBOARDED_Area.toFixed(2)
    },
    {
      field: 'ONBOARDED_FARM',
      headerName: 'ONBOARDED Farm',
      width: 100,
      align: 'center',
    },
    {
      field: 'RECEIVED_Area',
      headerName: 'RECEIVED Area',
      width: 130,
      renderCell: (params) => params.row.RECEIVED_Area.toFixed(2) || 0,
      align: 'center',
      type: "number"
    },
    {
      field: 'RECEIVED_FARM',
      headerName: 'RECEIVED Farm',
      width: 100,
      align: 'center',
    },
    {
      field: 'REWORK_Area',
      headerName: 'Rework Area',
      width: 130,
      renderCell: (params) => params.row.REWORK_Area.toFixed(2) || 0,
      align: 'center',
      type: "number"
    },
    {
      field: 'REWORK_FARM',
      headerName: 'Rework farm',
      width: 100,
      align: 'center',
    },
    {
      field: 'RE_SUB_Area',
      headerName: 'Resubmitted Area',
      width: 130,
      renderCell: (params) => params.row.RE_SUB_Area.toFixed(2) || 0,
      align: 'center',
      type: "number"
    },
    {
      field: 'RE_SUB_FARM',
      headerName: 'Resubmitted farm',
      width: 100,
      align: 'center',
    },
    {
      field: 'VERIFIED_FARM',
      headerName: 'Verified Farm',
      width: 130,
      renderCell: (params) => params.row.VERIFIED_FARM || 0,
      align: 'center',
      type: "number"
    },
    {
      field: 'VERIFIED_Area',
      headerName: 'Verified AREA',
      width: 130,
      renderCell: (params) => params.row.VERIFIED_Area.toFixed(2) || 0,
      align: 'center',
      type: "number"
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      align: 'center',
      renderCell: (params) => <RenderStatus status={params.row.status} />
    },
  ]

  // if (toSearchField) {
    res.push({
      field: 'Fields',
      headerName: 'Field Details',
      width: 150,
      renderCell: (params) => (
        <DataGridButton params={params} label="Fields Status" onClick={toSearchField} />
      )
    })
  // }

  return res
}

export const SearchFieldByDateMobColumn = (fieldDetailSetter, farmerDetailSetter, toFarmer) => [
  {
    field: 'created_at',
    headerName: 'Onboarded On',
    width: 150,
    valueGetter: (params) => new Date(params.row.createdAt).toDateString().slice(4),
    sortComparator: sortByDateComparator
  },
  {
    field: 'practiceStartDate',
    headerName: 'Practice Started On',
    width: 150,
    valueGetter: (params) => new Date(params.row.practiceStartDate).toDateString().slice(4),
    sortComparator: sortByDateComparator
  },
  {
    field: 'fullName2',
    headerName: 'Farmer Name',
    width: 160,
    valueGetter: (params) =>
      `${params.row.farmerGivenName || ''} ${params.row.farmerFamilyName || ''}`
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150
  },
  {
    field: 'landArea',
    headerName: 'Area',
    width: 100,
    renderCell: (params) => params.row.landArea.toFixed(1)
  },
  {
    field: 'Detail',
    headerName: 'Farm Detail',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="Farm" onClick={fieldDetailSetter} />
    )
  },
  {
    field: 'Detail farmer',
    headerName: 'Farmer Detail',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="Farmer" onClick={farmerDetailSetter} />
    )
  }
];

export const validationPartner = () => [
  {
    field: 'id',
    headerName: 'Partner Id',
    width: 100,
  },
  {
    field: 'name',
    headerName: 'Partner Name',
    width: 150,
  },
  {
    field: 'TOTAL_AREA',
    headerName: 'Total Area',
    width: 100,
  },
  {
    field: 'TOTAL_FARM',
    headerName: 'Total Farms',
    width: 100,
  },
  {
    field: 'TotalFarmer',
    headerName: 'Total Farmers',
    width: 120,
  },
  {
    field: 'CONFIRMED_AREA',
    headerName: 'Onboarded Area',
    width: 120,
  },
  {
    field: 'CONFIRMED_FARM',
    headerName: 'Onboarded Farms',
    width: 130,
  },
  {
    field: 'VerifiedArea',
    headerName: 'Verified Area',
    width: 100,
  },
  {
    field: 'VerifiedFields',
    headerName: 'Verified Farms',
    width: 130,
  },
  {
    field: 'UNCONFIRMED_AREA',
    headerName: 'Received Area',
    width: 120,
  },
  {
    field: 'UNCONFIRMED_FARM',
    headerName: 'Received Farms',
    width: 120,
  },
  {
    field: 'REWORK_AREA',
    headerName: 'Rework Area',
    width: 120,
  },
  {
    field: 'REWORK_FARM',
    headerName: 'Rework Farms',
    width: 120,
  },
  {
    field: 'RE_SUB_AREA',
    headerName: 'Resub Area',
    width: 120,
  },
  {
    field: 'RE_SUB_FARM',
    headerName: 'Resub Farms',
    width: 120,
  },
 

]

export const validationByDate = () => [
  {
    field: 'id',
    headerName: 'Date',
    width: 150,
    sortComparator: sortByDateComparator
  },
  {
    field: 'CONFIRMED_AREA',
    headerName: 'Onboarded Area',
    width: 150,
  },
  {
    field: 'CONFIRMED_FARM',
    headerName: 'Onboarded Farms',
    width: 150,
  },
  {
    field: 'UNCONFIRMED_AREA',
    headerName: 'Received Area',
    width: 150,
  },
  {
    field: 'UNCONFIRMED_FARM',
    headerName: 'Received Farms',
    width: 150,
  },
  {
    field: 'REWORK_AREA',
    headerName: 'Rework Area',
    width: 150,
  },
  {
    field: 'REWORK_FARM',
    headerName: 'Rework Farms',
    width: 150,
  },
]

export const validationByDateSearch = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
  },
  {
    field: "Total_Validation",
    headerName: 'TOTAL Validation',
    width: 150,
  },
  {
    field: 'CONFIRMED_FARM',
    headerName: 'ONBOARDED FARM',
    width: 150,
  },
  {
    field: 'REWORK_FARM',
    headerName: 'REWORK FARM',
    width: 150,
  },
  {
    field: 'INVALID_FARM',
    headerName: 'INVALID FARM',
    width: 150,
  },
  {
    field: 'UNCONFIRM_FARM',
    headerName: 'RECEIVED FARM',
    width: 150,

  },
  {
    field: 'updated_by_id',
    headerName: 'Contact',
    width: 250,
  },
]

export const getReSumitDayWise = (toRep, toSearchField) => [
  {
    field: 'fullName',
    headerName: 'REP Name',
    width: 250,
    valueGetter: (params) => `${params.row.given_name || ''} ${params.row.family_name || ''}`
  },
  {
    field: 'totalArea',
    headerName: 'Total Area',
    width: 150,
    renderCell: (params) => params.row.totalArea.toFixed(2)
  },
  {
    field: 'RE_SUB_FARM',
    headerName: 'Resubmitted farm',
    width: 150,
  },
  {
    field: 'phone_number',
    headerName: 'Phone',
    width: 150,
  },
  {
    field: 'address1',
    headerName: 'Address',
    width: 150,
  },
  {
    field: 'Fields',
    headerName: 'Field Details',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="Fields Status" onClick={toSearchField} />
    )
  },
  {
    field: 'REPS',
    headerName: 'REP Detail',
    width: 150,
    renderCell: (params) => (
      <DataGridButton params={params} label="REP Status" onClick={toRep} />
    )
  }
]

export const getVerifiedRepWise = (date) => {
  const verifiedRep = [
    {
      field: 'fullName',
      headerName: 'REP Name',
      width: 200,
    },
    {
      field: 'phone_number',
      headerName: 'Phone',
      width: 200
    },
    {
      field: 'onboardedArea',
      headerName: 'Total onboarded Area (acre)',
      width: 200,
      renderCell: (params) => params.row.onboardedArea.toFixed(1)
    },
    {
      field: 'onboardedFarm',
      headerName: 'Total onboarded Farm',
      width: 200,
      renderCell: (params) => +params.row.onboardedFarm
    },
    {
      field: 'verifiedArea',
      headerName: 'Verified Area (acre)',
      width: 200,
      renderCell: (params) => params.row.verifiedArea.toFixed(1)
    },
    {
      field: 'verifiedFarm',
      headerName: 'Verified Farm',
      width: 150,
      renderCell: (params) => +params.row.verifiedFarm
    }
  ];

  const verifiedRepWithDate = [
    {
      field: 'fullName',
      headerName: 'REP Name',
      width: 250
    },
    {
      field: 'phone_number',
      headerName: 'Phone',
      width: 200
    },
    {
      field: 'VerifiedArea',
      headerName: 'Verified Area (acre)',
      width: 200,
      renderCell: (params) => params.row.verifiedArea.toFixed(1)
    },
    {
      field: 'verifiedFarm',
      headerName: 'Verified Farm',
      width: 150
    }
  ];

  return date ? verifiedRepWithDate : verifiedRep;
};


export const getPartnerWiseVeri = (date) => {
  const all = [
    {
      field: 'id',
      headerName: 'Partner ID',
      width: 220,
    },
    {
      field: 'totalArea',
      headerName: 'Total Onboarded Area',
      width: 220,
      renderCell: (params) => params.row.onboardedArea.toFixed(0)
    },
    {
      field: 'onboardedFarm',
      headerName: 'Total Onboarded Farms',
      width: 220,
    },
    {
      field: 'verifiedArea',
      headerName: 'Total Verified Area',
      width: 220,
      renderCell: (params) => params.row.verifiedArea.toFixed(0)
    },
    {
      field: 'verifiedFarm',
      headerName: 'Total Verified Farms',
      width: 220,
    }
  ]

  const withDate = [
    {
      field: 'id',
      headerName: 'Partner ID',
      width: 300,
    },
    {
      field: 'VerifiedArea',
      headerName: 'Total Verified Area',
      width: 300,
      renderCell: (params) => params.row.verifiedArea.toFixed(0)
    },
    {
      field: 'verifiedFarm',
      headerName: 'Total Verified Farms',
      width: 300,
    }
  ]

  return date ? withDate : all;
}

export const getRancherColumn = (onClickRow) => [
  {
    field: 'ranchName',
    headerName: 'Ranch',
    width: 250
  },
  {
    field: 'fullName',
    headerName: 'Rancher Name',
    width: 250
  },
  {
    field: 'email',
    headerName: 'email',
    width: 250
  },
  {
    field: 'createdAt',
    headerName: 'Onboarded on',
    width: 140,
    sortComparator: sortByDateComparator,
  },
  {
    field: 'infoStatus',
    headerName: 'Questionnaire Status',
    width: 150,
    renderCell: (params) => (
      <div>
        <h4>{params.row.infoStatus || 'not started'}</h4>
      </div>
    ),
    // hide: true
  },
  {
    field: 'area',
    headerName: 'Area (acres)',
    width: 150
  },
  {
    field: 'TotalDoc',
    headerName: 'Total Doc Uploaded',
    width: 150,
    valueGetter: (params) => params.row.TotalDoc || 0,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => <RenderStatus status={params.row.status} />
  },
  {
    field: '2',
    headerName: 'Details',
    width: 300,
    renderCell: (params) => (
      <DataGridButton params={params} color="#87C45E" label="View Details" onClick={onClickRow} />
    )
  }
];


export const getRancherInsightTableColumn = (toInsightMap) => [
  {
    field: 'ranchName',
    headerName: 'Ranch',
    width: 300
  },
  {
    field: 'Rancher',
    headerName: 'Rancher',
    width: 300,
    renderCell: (params) => (
      <div>
        <h4>{params.row.fullName}</h4>
        <p>{params.row.email}</p>
      </div>
    )
  },
  {
    field: 'area',
    headerName: 'Area',
    width: 120
  },
  {
    field: 'city',
    headerName: 'State',
    width: 150
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params) => <RenderStatus status={params.row.status} />
  },
  {
    field: '2',
    headerName: 'Details',
    width: 300,
    renderCell: (params) => (
      <DataGridMenu
        label="Insights"
        params={params}
        onClick={toInsightMap}
        list={['Plant Health', 'Soil Moisture']}
      />
    )
  }
];

const RenderStatus = ({ status }) => {
  const color = {
    'READ_ONLY': 'rgba(145, 158, 171, 0.16)',
    'REWORK': 'rgba(255, 86, 48, 0.16)',
    Unconfirmed: 'rgba(247, 181, 38, 0.3)',
    Confirmed: 'rgba(135, 196, 94, 0.3)',
    UNCONFIRMED: 'rgba(247, 181, 38, 0.3)',
    CONFIRMED: 'rgba(135, 196, 94, 0.3)',
    'Read Only': 'rgba(145, 158, 171, 0.16)',
    'Rework Only': 'rgba(255, 86, 48, 0.16)',
    'Not Verified': 'rgba(255, 86, 48, 0.16)',
    Verified: 'rgba(135, 196, 94, 0.3)',
    Rework: 'rgba(145, 158, 171, 0.16)',
    RECEIVED: 'rgba(247, 181, 38, 1)',
    ONBOARDED: 'rgba(135, 196, 94, 1)',
    VERIFIED: 'rgba(135, 196, 94, 0.3)',
    ALL: 'rgba(4, 39, 41, 1)',
    RE_SUB: '#826AF9',
  };

  return (
    <Box
      sx={{
        backgroundColor: color[status] || color[color.Confirmed],
        padding: '0.5rem',
        borderRadius: '12px'
      }}
    >
      {status}
    </Box>
  );
};

export const SearchRancherKey = ['fullName', 'ranchName', 'email'];

export const getLoginTableData = () => [
  {
    field: 'full_name',
    headerName: 'Name',
    width: 200
  },
  {
    field: 'eMail',
    headerName: 'E-Mail',
    width: 250
  },
  {
    field: 'login_time',
    headerName: 'Last Login',
    width: 150,
    renderCell: (params) => params.row.login_time ? fDateTime(params.row.login_time) : "Data no available"
  },
  {
    field: 'partnerID',
    headerName: 'Partner Id',
    width: 150,
    renderCell: (params) => +params.row.partnerID,
    type: "number"
  },
  {
    field: 'state',
    headerName: 'State',
    width: 150
  },
  {
    field: 'disableDate',
    headerName: 'Disable Date',
    width: 150
  }
]

export const getSurveyTableColumns = (onClickRow) => [
  {
    field: 'farmer_name',
    headerName: 'Farmer Name',
    width: 200
  },
  {
    field: 'phone_number',
    headerName: 'Farmer Phone Number',
    width: 200
  },
  {
    field: 'partnerID',
    headerName: 'Partner Id',
    width: 200
  },
  {
    field: 'address',
    headerName: 'Farmer Address',
    width: 200
  },
  {
    field: '2',
    headerName: 'Details',
    width: 300,
    renderCell: (params) => (
      <DataGridButton params={params} color="#87C45E" label="View Details" onClick={onClickRow} />
    )
  }
]

export const getFarmerReportPOP = () => [
  {
    field: 'given_name',
    headerName: 'Contractor Name',
    width: 250,
    valueGetter: (params) => `${params.row.given_name || ''}${params.row.middle_name || ''} ${params.row.family_name || ''} `
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    width: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
  }
]

export const getREPReportPOP = () => [
  {
    field: 'given_name',
    headerName: 'REP Name',
    width: 250,
    valueGetter: (params) => `${params.row.given_name || ''}${params.row.middle_name || ''} ${params.row.family_name || ''} `
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    width: 220,
  },
  {
    field: 'farmer',
    headerName: 'Total Farmers',
    width: 125,
  },
  {
    field: 'activeFarmer',
    headerName: 'Farmers (Following Pop) ',
    width: 125,
  },
  {
    field: 'inactiveFarmer',
    headerName: 'Farmers (Following Pop)2 ',
    width: 125,
  }
]