/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react";
import { CircularProgessBm, SelectBm } from "src/components/@material-extend";
import { ranchApi } from "src/components/api";

export const SelectRancher = ({ value, setValue, defaultValue, partnerId }) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!partnerId) {
            setValue('')
            return
        }
        const len = partnerId.length;
        if (len < 4 || len === 5) {
            setValue('')
            return
        }

        getListOfRancher(partnerId)
    }, [partnerId])

    const getListOfRancher = async (partnerId) => {
        try {
            setLoading(true);
            const { data } = await ranchApi.get(`/getListOfRancher?partnerId=${partnerId}`);
            const newList = data.map(({ email, PK, fullName }) => {
                return {
                    label: `${fullName}-${email}`,
                    value: PK
                }
            })
            setList(newList);
            setValue(defaultValue || newList[0].value);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return <CircularProgessBm />
    }

    return <SelectBm
        containerSx={{
            width: '100%'
        }}
        sx={{
            bgcolor: '#F1F1F1',
            borderRadius: 1
        }}
        list={list}
        label="Rancher*"
        disabled={defaultValue ? true : false}
        value={value}
        onChange={({ target }) => setValue(target.value)}
    />
}