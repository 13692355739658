/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Grid, IconButton, Typography } from "@mui/material"
import { RBACAuth } from 'src/components/@material-extend';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';

export const HeaderAndCommentsIcon = ({ header, commentList, modal, setModal, comments, isVerificationReady, readonly = false }) => <Grid item container xs={12}>
    <Grid item >
        <Typography variant="h6">
            {header}
        </Typography>
    </Grid>
    <Grid flex={1} />
    {isVerificationReady ?
        <RBACAuth groupID="cropLandVerification">
            {commentList && commentList.length ?
                <Grid item  >
                    <IconButton onClick={() => readonly ? null : setModal(!modal)}>
                        <Typography color='red' variant="h4" sx={{ marginRight: '1rem' }}>
                            {comments.length}
                        </Typography>
                        {readonly ? <CommentsDisabledIcon /> : <AddCommentIcon /> }
                    </IconButton>
                </Grid> : null
            }
        </RBACAuth>
        : null}
</Grid>
