/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export const sortByDate = (data, dateField, reformate, dateField2) => {
  if (!dateField) return data;

  const sortedData = data.sort((a, b) => {
    const dateA = new Date(a[dateField]);
    const dateB = new Date(b[dateField]);

    // eslint-disable-next-line no-nested-ternary
    return dateB.getFullYear() !== dateA.getFullYear()
      ? dateB.getFullYear() - dateA.getFullYear()
      : dateB.getMonth() !== dateA.getMonth()
      ? dateB.getMonth() - dateA.getMonth()
      : dateB.getDate() - dateA.getDate();
  });

  if (!reformate) return data;

  sortedData.forEach((ele, index) => {
    ele[dateField] = new Date(ele[dateField]).toDateString().slice(4);
    if (dateField2) {
      dateField2.forEach((date) => {
        ele[date] = new Date(ele[date]).toDateString().slice(4);
      });
    }
    ele.cid = ele.id;
    ele.id = index;
  });

  return data;
};

export const sortByDateComparator = (a, b, _c, _d) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  // eslint-disable-next-line no-nested-ternary
  return dateB.getFullYear() !== dateA.getFullYear()
    ? dateB.getFullYear() - dateA.getFullYear()
    : dateB.getMonth() !== dateA.getMonth()
    ? dateB.getMonth() - dateA.getMonth()
    : dateB.getDate() - dateA.getDate();
};

export const sortByField = (rows, field) => {
  const sortedRows = rows.sort((r1, r2) => r1[field].localeCompare(r2[field]));
  return sortedRows;
};
