/* eslint-disable import/no-unresolved */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { Page, Text, Document, StyleSheet, Image, View, Link } from '@react-pdf/renderer';

export const PopStatusDetailGenerator = ({ data, partnerName: name, packageName, cropName }) => {
    return (<Document>
        <Page style={styles.body}>
            <View style={styles.pageHeader} fixed >
                <View style={styles.pageHeaderL1} />
                <View style={styles.pageHeaderL2} />
                <View
                    style={{
                        alignContent: 'flex-start',
                        width: '50rem',
                        padding: 20
                    }}>
                    <Image style={styles.headerIcon} src='/static/illustrations/NewLogo.png' />
                </View>
            </View>
            <View style={{
                paddingHorizontal: 40,
            }}>
                <Text style={styles.packageName}>
                    Partner name: {name}
                </Text>
                <Text style={styles.packageName}>
                    Package of Practice name: {packageName}
                </Text>
                <Text style={styles.packageName}>
                    Crop Name: {cropName}
                </Text>
            </View>
            <View style={styles.section}>
                {data.length > 0 && (
                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <View style={{ ...styles.tableCellH, width: '35%' }}>
                                <Text style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                }}>REP Name</Text>
                            </View>
                            <View style={{ ...styles.tableCellH, width: '25%' }}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                    }}
                                >Phone Number</Text>
                            </View>
                            <View style={{ ...styles.tableCellH, width: '13%' }}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                    }}
                                >Total Farmer</Text>
                            </View>
                            <View style={{ ...styles.tableCellH, width: '13.5%' }}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                    }}
                                >Active Farmer</Text>
                            </View>
                            <View style={{ ...styles.tableCellH, width: '13.5%' }}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                    }}
                                >Behind Schedule</Text>
                            </View>
                        </View>
                        {data.map((item, index) => (
                            <View
                                key={index}
                                style={[
                                    styles.tableRow,
                                    index % 2 === 0 ? styles.evenRow : styles.oddRow,
                                ]}
                            >
                                <View style={{ ...styles.tableCell, width: '35%' }}>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            fontSize: 10,
                                        }}
                                    >{`${item.given_name || ''} ${item.middle_name || ''} ${item.family_name || ''}`}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: '25%' }}>
                                    <Text style={{
                                        textAlign: 'center',
                                        fontSize: 10,
                                    }}>{item.phone_number}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: '13%' }}>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontSize: 10,
                                        }}
                                    >{item.totalFarmer || 0}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: '13.5%' }}>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontSize: 10,
                                        }}
                                    >{item.activeFarmer || 0}</Text></View>
                                <View style={{ ...styles.tableCell, width: '13.5%' }}>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontSize: 10,
                                        }}
                                    >{item.behindSchedule || 0}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                )}
            </View>
            <View style={styles.footer} fixed >
                <View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `Page ${pageNumber} of ${totalPages}`
                    )} />
                </View>
                <View style={{
                    color: '#004752',
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    textDecoration: 'none',
                }}>
                    <Link style={styles.link} src='#Footnote'>
                        Copyright © 2023 Boomitra |
                    </Link>
                    <Link style={styles.link} src='https://boomitra.com/privacy-policy/'>
                        Privacy Policy |
                    </Link>
                    <Link style={styles.link} src='https://boomitra.com/terms-conditions/'>
                        Terms & Conditions
                    </Link>
                </View>
                <View>
                    <Image style={{
                        width: '40px',
                        height: '40px',
                    }} src='/favicon/favicon.ico' />
                </View>
            </View>
        </Page>
    </Document >
    )
};



const styles = StyleSheet.create({
    body: {
        paddingBottom: 50
    },
    cropName: {
        fontSize: 20,
        textAlign: 'center',
        textTransform: 'capitalize'
    },
    packageName: {
        fontSize: 12,
        textAlign: 'left',
        textTransform: 'capitalize',
        marginVertical: 4,
        fontWeight: 'ultrabold',
    },
    headerIcon: {
        width: '145px',
        height: '87px',
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        width: '100%',
        position: 'absolute',
        bottom: 10
    },
    pageNumber: {
        color: '#004752',
        fontSize: 12,
        marginTop: 10
    },
    pageHeader: {
        width: '100vw',
        height: '13vh'
    },
    pageHeaderL1: {
        height: '8vh',
        backgroundColor: '#004752',
        padding: 4
    },
    pageHeaderL2: {
        height: '1vh',
        backgroundColor: '#87C45E'
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        padding: 10,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        marginBottom: 10,
    },
    tableRow: {
        flexDirection: 'row',
    },
    evenRow: {
        backgroundColor: '#F2F2F2',
        border: '1px solid black'
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
        border: '1px solid black'
    },
    tableHeader: {
        backgroundColor: '#d3d3d3',
        fontWeight: 'bold',
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        marginBottom: 5,
        padding: 8,
        fontSize: 10,
        border: '1px solid black',
        textAlign: 'center'
    },
    link: {
        color: '#004752',
        textDecoration: 'none',
        marginHorizontal: 2,
        marginTop: 10
    },
    tableCellH: {
        margin: 'auto',
        marginTop: 5,
        marginBottom: 5,
        padding: 8,
        border: '1px solid black',
    }
});

