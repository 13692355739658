/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/prop-types */
import { HeaderDivider } from './HeaderDivider';
import { Sources } from './Sources';
import { SOE } from './SOE';
import { DynamicFields } from './DynamicFields';


const distributionRequiredFields = [
  { name: 'Type of Pipe', id: 'ts', xs: 3.5 },
  { name: 'Diameter', id: 'size', xs: 2.5, unit: 'inches' },
  { name: 'Length', id: 'km', xs: 2, unit: 'km' }
];

const sourceTankRequiredFields = [
  { name: 'Type of Pipe', id: 'ts', xs: 3.5 },
  { name: 'Size', id: 'size', xs: 2.5, unit: 'litres' },
  { name: 'Count', id: 'cn', xs: 2 }
];

const drinkersRequiredFields = [
  { name: 'Type of Drinker', id: 'ts', xs: 3.5 },
  { name: 'Size', id: 'size', xs: 2.5, unit: 'litres' },
  { name: 'Count', id: 'cn', xs: 2 }
];

export const ConserationRanch = ({ value, handleChange, disabled }) => {

  const onChange = (newValue, name) => {
    if (disabled) {
      return
    }
    const allValue = { ...value };

    allValue[name] = newValue;

    handleChange({ ...allValue })
  }


  return (
    <div style={{
      marginBottom: "3rem"
    }}>
      <HeaderDivider title="Water Resources" />
      <Sources
        disabled={disabled}
        allValue={value.source || {}}
        setAllValue={(v) => onChange(v, 'source')}
      />
      <HeaderDivider title="Water Distribution" />
      <DynamicFields
        disabled={disabled}
        fields={value.distribution || [{
          ts: '',
          size: '',
          km: ''
        }]}
        requiredFields={distributionRequiredFields}
        setFields={(v) => onChange(v, 'distribution')}
      />
      <HeaderDivider title="Water Storage Tanks" />
      <DynamicFields
        disabled={disabled}
        fields={value.sourceTank || [{
          ts: '',
          size: '',
          cn: ''
        }]}
        requiredFields={sourceTankRequiredFields}
        setFields={(v) => onChange(v, 'sourceTank')}
      />
      <HeaderDivider title="Drinkers" />
      <DynamicFields
        disabled={disabled}
        fields={value.drinkers || [{
          ts: '',
          size: '',
          cn: ''
        }]}
        requiredFields={drinkersRequiredFields}
        setFields={(v) => onChange(v, 'drinkers')}
      />
      <HeaderDivider title="Source of Energy" />
      <SOE
        disabled={disabled}
        soeValue={value.soeValue || {}}
        setSOEValue={(v) => onChange(v, 'soeValue')}
      />
    </div>
  );
};
