/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Chip, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import AddCommentIcon from '@mui/icons-material/AddComment';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { LoadingButton } from "@mui/lab";
import { SelectBm } from "src/components/@material-extend";
import api from "src/components/api/api";
import { CommentsModal } from './CommentsModal';
import {
    polygonComments, farmerBankComments,
    farmerIdComments, farmerSigComments,
    landRecordComments, allComments,
    commentId
} from './CommentsData';
import { updateLog } from "./updateLog";

const ChipIconDeleteButton = ({ onDelete }) => (
    <IconButton onClick={onDelete}>
        <HighlightOffIcon />
    </IconButton>)

export const CommentType = Object.freeze({
  FARMER: "FARMER",
  FARM: "FARM",
});

export const CommentStatus = Object.freeze({
  RESOLVED: 'RESOLVED',
  UNRESOLVED: 'UNRESOLVED',
  DELETED: 'DELETED'
});

export const AllCommentSubmit = ({ comments, setComments, data, setData, }) => {
    const [status, setStatus] = useState('RECEIVED');
    const [monitoringPeriod, setMonitoringPeriod] = useState('RECEIVED');
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [allNewComments, setAllNewComments] = useState([]);
    const [nonAgriculturalBody, setNonAgriculturalBody] = useState(() => {
        const storedValue = localStorage.getItem(`nonAgriculturalBody_${data.id}`);
        return storedValue === "true";
    });
    const allReworkComments = useRef([]);
    const { readonly, userDetails} = useSelector((store) => store.userRoot.user);
    const user = useSelector((store) => store.userRoot.user);
    const onDelete = (deleteValue, key) => {
        const filterData = comments[key].filter((ele) => ele !== deleteValue)
        const newComments = { ...comments }
        newComments[key] = filterData
        setComments({ ...newComments })
    }

    const commentToString = () => {
        let commentStr = "";
        for (const key in comments) {
            for (const comment of comments[key]) {
                commentStr += `${comment},`
            }
        }
        return commentStr
    }


    const modifyToReworkComments = () => {
       for (const key in comments) {
         const type = ['farmerBank', 'farmerId', 'farmerSig'].includes(key)
           ? CommentType.FARMER
           : CommentType.FARM;

         comments[key].map((comment) => {
           const modifiedComment = {
             type,
             status: CommentStatus.UNRESOLVED,
             comment,
             comment_id: commentId[comment],
             created_at: new Date().toISOString(),
             created_by: userDetails?.id,
             resolved_at: null,
             resolved_by: null,
             deleted_at: null,
             deleted_by: null
           };
           allReworkComments.current.push(modifiedComment);
           return modifiedComment;
         });
       }

       return allReworkComments.current.join('.')
    };

 
    
    const handleCheckboxChange = () => {
        const newValue = !nonAgriculturalBody;
        setNonAgriculturalBody(newValue);
        localStorage.setItem(`nonAgriculturalBody_${data.id}`, newValue);
    };

    const onSubmit = async () => {
        try {
            setLoading(true)
            const commentStr= modifyToReworkComments();

            const allowedInvalidStatuses = ["RECEIVED", "REWORK", "RE_SUB"];
            if (status === 'INVALID' && !allowedInvalidStatuses.includes(data.status)) {
                alert("INVALID status can only be applied when the current farm status is RECEIVED, REWORK, or RESUB.");
                return;
            }

            await api.post("/updateCommentAndStatusV2", {
                reworkComments: allReworkComments.current,
                status,
                farmerId: data.farmerId,
                farmId: data.id,
                monitoringPeriod,
                nonAgriculturalBody
            })

            localStorage.setItem(`nonAgriculturalBody_${data.id}`, nonAgriculturalBody);

            console.log(comments,allComments, allReworkComments)
            let ONHOLD = true;
            if (status !== 'ONHOLD') {
                ONHOLD = false
            }
            setData({
                ...data, status,
                internal_comment: commentStr,
                ONHOLD,
                verified: false,
                partner_comment: ""
            })
            setComments({
                polygon: [],
                farmerBank: [],
                farmerId: [],
                farmerSig: [],
                landRecord: []
            });
            updateLog(data, status, commentStr)

            if (status === 'INVALID') {
                alert("The status has been successfully changed to INVALID.");
            }

        } catch (e) {
            console.log(e);
            alert("No hh")

        } finally {
            setLoading(false)
        }
    }

    const length = comments.polygon.length
        + comments.farmerBank.length
        + comments.farmerId.length
        + comments.farmerSig.length
        + comments.landRecord.length;

    return <Paper elevation={10} sx={{
        padding: '1rem',
        marginTop: '0.2rem',
        width: '100%',
        border: '2px solid red',
    }}>
        <Grid container width='100%'>
            <Grid item container xs={12}>
                <Grid item >
                    <Typography variant="h6">
                        New Comments
                    </Typography>
                </Grid>
                <Grid flex={1} />
                <Stack direction='row' >
                    <IconButton onClick={() => readonly ? null: setModal(true)}>
                        <Typography color='red' variant="h5" sx={{ marginRight: '0.3rem' }}>
                            {length}
                        </Typography>
                        {readonly? <CommentsDisabledIcon /> : <AddCommentIcon /> }
                    </IconButton>
                </Stack>
                <Grid item >
                    {status === 'ONBOARDED' ?
                        <SelectBm
                            value={monitoringPeriod}
                            onChange={({ target }) => setMonitoringPeriod(target.value)}
                            label="Select Monitoring Period"
                            containerSx={{ width: '14rem' }}
                            list={data.monitoringPeriod || []}
                        /> : null
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox 
                           checked={nonAgriculturalBody}
                           onChange={handleCheckboxChange}
                           disabled={readonly}
                        />
                        <Typography variant="body1">Non-Agricultural Body</Typography>
                    </Stack>
                </Grid>
            <Grid item container spacing={1} overflow='auto' className="hideScrollBar" sx={{
                padding: '0.2rem',
                marginTop: '1rem'
            }}>
                {comments.polygon.map((ele, index) => <Grid item key={index}>
                    <Chip
                        label={ele}
                        icon={<ChipIconDeleteButton onDelete={() => onDelete(ele, "polygon")} />}
                        variant="outlined"
                    />
                </Grid>
                )}
                {comments.farmerBank.map((ele, index) => <Grid item key={index}>
                    <Chip
                        label={ele}
                        icon={<ChipIconDeleteButton onDelete={() => onDelete(ele, "farmerBank")} />}
                        variant="outlined"
                    />
                </Grid>
                )}
                {comments.farmerId.map((ele, index) => <Grid item key={index}>
                    <Chip
                        label={ele}
                        icon={<ChipIconDeleteButton onDelete={() => onDelete(ele, "farmerId")} />}
                        variant="outlined"
                    />
                </Grid>
                )}
                {comments.farmerSig.map((ele, index) => <Grid item key={index}>
                    <Chip
                        label={ele}
                        icon={<ChipIconDeleteButton onDelete={() => onDelete(ele, "farmerSig")} />}
                        variant="outlined"
                    />
                </Grid>
                )}
                {comments.landRecord.map((ele, index) => <Grid item key={index}>
                    <Chip
                        icon={<ChipIconDeleteButton onDelete={() => onDelete(ele, "landRecord")} />}
                        label={ele}
                        variant="outlined"
                    />
                </Grid>
                )}
            </Grid>
            <Stack direction='row' spacing={2} sx={{
                marginTop: '1rem'
            }}>
                <SelectBm
                    value={status}
                    onChange={({ target }) => setStatus(target.value)}
                    list={[
                        ...(!length ? ["RECEIVED", "ONBOARDED"] : ["RECEIVED", "REWORK"]),
                        "INVALID"
                    ]}
                    disabled={readonly}
                />
                <LoadingButton loading={loading} variant="contained" disabled={readonly} onClick={readonly ? null :onSubmit}>
                    Submit
                </LoadingButton>
            </Stack>
        </Grid>
        <CommentsModal
            modal={modal}
            setModal={setModal}
            commentList={allComments}
            comments={allNewComments}
            setComments={(value) => {
                setAllNewComments(value)
                const oldComments = {
                    polygon: [],
                    farmerBank: [],
                    farmerId: [],
                    farmerSig: [],
                    landRecord: []
                }
                for (const newValue of value) {
                    if (polygonComments.has(newValue)) {
                        oldComments.polygon.push(newValue)
                    } else if (farmerBankComments.has(newValue)) {
                        oldComments.farmerBank.push(newValue)
                    } else if (farmerIdComments.has(newValue)) {
                        oldComments.farmerId.push(newValue)
                    } else if (farmerSigComments.has(newValue)) {
                        oldComments.farmerSig.push(newValue)
                    } else if (landRecordComments.has(newValue)) {
                        oldComments.landRecord.push(newValue)
                    }
                }
                setComments({ ...oldComments })
            }}
        />
    </Paper>
}