import axios from "axios";

const baseURL = {
    dev: 'https://j6po1ll8lj.execute-api.ap-south-1.amazonaws.com/dev',
    prod: 'https://96w07w28r0.execute-api.ap-south-1.amazonaws.com/prod',
    local: "http://localhost:8080/dev",
    dev1: 'https://a4l182miy6.execute-api.ap-south-1.amazonaws.com/dev-1/'
}


export default axios.create({ baseURL: baseURL.prod})