export const CropsLabel = [
  { label: 'broccoli', id: 100 },
  { label: 'carrots', id: 103 },
  { label: 'brussel_sprouts', id: 101 },
  { label: 'cabbage', id: 102 },
  { label: 'cauliflower', id: 104 },
  { label: 'celery', id: 105 },
  { label: 'garlic', id: 106 },
  { label: 'lettuce', id: 107 },
  { label: 'onions', id: 108 },
  { label: 'spinach', id: 109 },
  { label: 'radishes', id: 110 },
  { label: 'egg_plant', id: 200 },
  { label: 'sweet_peppers_bell_peppers', id: 201 },
  { label: 'tomato', id: 202 },
  { label: 'watermelon', id: 300 },
  { label: 'cantaloupe', id: 301 },
  { label: 'cucumber', id: 302 },
  { label: 'pumpkin_winter_squash', id: 303 },
  { label: 'squash_zucchini', id: 304 },
  { label: 'sweet_melons', id: 305 },
  { label: 'sweet_potato', id: 400 },
  { label: 'table_beet', id: 401 },
  { label: 'cassava', id: 402 },
  { label: 'parsnip', id: 404 },
  { label: 'potato', id: 405 },
  { label: 'turnip_rutabaga', id: 406 },
  { label: 'sugar_beet', id: 407 },
  { label: 'soybeans', id: 500 },
  { label: 'green_beans', id: 501 },
  { label: 'dry_beans', id: 502 },
  { label: 'chickpea', id: 503 },
  { label: 'fababean', id: 504 },
  { label: 'garbanzo', id: 505 },
  { label: 'green_gram_cowpeas', id: 506 },
  { label: 'groundnut_peanut', id: 507 },
  { label: 'lentil', id: 508 },
  { label: 'peas', id: 509 },
  { label: 'asparagus', id: 600 },
  { label: 'artichokes', id: 601 },
  { label: 'mint', id: 602 },
  { label: 'strawberries', id: 603 },
  { label: 'cotton', id: 700 },
  { label: 'flax', id: 701 },
  { label: 'sisal', id: 702 },
  { label: 'castorbean_ricinus', id: 800 },
  { label: 'rapeseed_canola', id: 801 },
  { label: 'safflower', id: 802 },
  { label: 'sesame', id: 803 },
  { label: 'sunflower', id: 804 },
  { label: 'rice', id: 900 },
  { label: 'barley', id: 901 },
  { label: 'oats', id: 902 },
  { label: 'spring_wheat', id: 903 },
  { label: 'winter_wheat', id: 904 },
  { label: 'field_maize_corn', id: 905 },
  { label: 'sweet_maize_corn', id: 906 },
  { label: 'millets', id: 907 },
  { label: 'sorghum', id: 908 },
  { label: 'alfalfa', id: 1000 },
  { label: 'bermuda', id: 1001 },
  { label: 'clover_hay_berseem', id: 1002 },
  { label: 'rye_grass_hay', id: 1003 },
  { label: 'sudan_grass_hay', id: 1004 },
  { label: 'grazing_pasture', id: 1005 },
  { label: 'turf_grass', id: 1006 },
  { label: 'sugarcane', id: 1100 },
  { label: 'stevia', id: 1101 },
  { label: 'tea', id: 1200 },
  { label: 'banana', id: 1201 },
  { label: 'cacao', id: 1202 },
  { label: 'coffee', id: 1203 },
  { label: 'date_palms', id: 1204 },
  { label: 'papaya', id: 1205 },
  { label: 'arecanut', id: 1206 },
  { label: 'palm_trees', id: 1207 },
  { label: 'pineapple', id: 1208 },
  { label: 'rubber_trees', id: 1209 },
  { label: 'coconut', id: 1210 },
  { label: 'mango', id: 1211 },
  { label: 'lychee', id: 1212 },
  { label: 'jujube', id: 1213 },
  { label: 'berries_bushes', id: 1300 },
  { label: 'grapes', id: 1301 },
  { label: 'hops', id: 1302 },
  { label: 'almonds', id: 1400 },
  { label: 'apples', id: 1401 },
  { label: 'cherries', id: 1402 },
  { label: 'pears', id: 1403 },
  { label: 'apricots', id: 1404 },
  { label: 'peaches', id: 1405 },
  { label: 'stone_fruit', id: 1406 },
  { label: 'avocado', id: 1407 },
  { label: 'citrus', id: 1408 },
  { label: 'cactus_pear', id: 1409 },
  { label: 'pomegranate', id: 1410 },
  { label: 'conifer_trees', id: 1411 },
  { label: 'kiwi', id: 1412 },
  { label: 'olives', id: 1413 },
  { label: 'pistachios', id: 1414 },
  { label: 'walnut_orchard', id: 1415 },
  { label: 'jojoba', id: 1416 },
  { label: 'cashew', id: 1417 },
  { label: 'jasmine', id: 1500 },
  { label: 'rose_hybrids', id: 1501 },
  { label: 'chrysanthemum', id: 1502 },
  { label: 'tuberose', id: 1503 },
  { label: 'marigold', id: 1504 },
  { label: 'crossandra_kanakambara', id: 1505 },
  { label: 'gomphrena_amaranth', id: 1506 },
  { label: 'hibiscus', id: 1507 },
  { label: 'nerium_oleander', id: 1508 },
  { label: 'barleria', id: 1509 },
  { label: 'celosia_cocks_comb', id: 1510 },
  { label: 'coronaria', id: 1511 },
  { label: 'champaca_manoranjan', id: 1512 },
  { label: 'ixora', id: 1513 },
  { label: 'periwinkle', id: 1514 },
  { label: 'porcupine_flower_vajradandhi', id: 1515 },
  { label: 'gladiolus', id: 1516 },
  { label: 'gerbera', id: 1517 },
  { label: 'carnation', id: 1518 },
  { label: 'crisantemo_pompom', id: 1519 },
  { label: 'hypericums', id: 1520 },
  { label: 'alstromeria_hybrids', id: 1521 },
  { label: 'gypsophilla', id: 1522 },
  { label: 'geraniums_scented', id: 1523 },
  { label: 'geraniums_garden', id: 1524 },
  { label: 'european_iris', id: 1525 },
  { label: 'japanese_iris', id: 1526 },
  { label: 'iris_louisiana_gybrids', id: 1527 },
  { label: 'california_poppy', id: 1528 },
  { label: 'mexican_tulip_poppy', id: 1529 },
  { label: 'oriental_poppy', id: 1530 },
  { label: 'perennial_cornflower', id: 1531 },
  { label: 'hybrid_lily', id: 1532 },
  { label: 'violets', id: 1533 },
  { label: 'shasta_daisy', id: 1534 },
  { label: 'Dahlia', id: 1535 },
  { label: 'turmeric', id: 1536 },
  { label: 'other', id: 1601 },
  { label: 'chia', id: 606 },
  { label: 'lemon', id: 607 },
  { label: 'macadamia', id: 608 },
  { label: 'Pepper', id: 1418 },
  { label: 'capsicum', id: 1419 },
  { label: 'Scallion (spring onions)', id: 111 },
  { label: 'kale', id: 112 },
  { label: 'amaranth', id: 113 },
  { label: 'black night shade', id: 114 },
  { label: 'thyme', id: 115 },
  { label: 'corriander', id: 116 },
  { label: 'Okra', id: 117 },
  { label: 'curry leaves', id: 118 },
  { label: 'dill', id: 119 },
  { label: 'chives', id: 120 },
  { label: 'rosemary', id: 121 },
  { label: 'ginger', id: 408 },
  { label: 'pyrethrum', id: 604 },
  { label: 'vertiver', id: 1007 }
];
