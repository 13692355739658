/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "@mui/material";

export const ConfirmModal = ({ cancel }) => <div
    style={{
        width: '40%',
        margin: '80px auto 0 auto',
        overflow: 'hidden',
        backgroundColor: "#fff",
        borderRadius: "10px",
        height: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }}
>
    <h3 style={{
        textAlign: "center"
    }}
    > Cannot change status for more than 20 REP's.</h3>
    <div
        style={{
            margin: "1rem auto 0 auto",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            padding: '0 1rem'
        }}
    >
        <Button onClick={cancel} variant="contained">
            OK
        </Button>
    </div>
</div>