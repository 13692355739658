/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { Stack, Typography, Box, Paper, TableRow, TableCell, TableHead, TableContainer, TableBody, Table } from '@mui/material';

export const Comments = ({ dataArray }) => {
    const sortedArr = dataArray.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
    return (
      <Paper
        elevation={12}
        sx={{
          height: '100%'
        }}
      >
        <Stack
          direction="column"
          spacing={0.4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: '1rem 2rem'
          }}
        >
          {/* <DetailEle label="Comments History" /> */}
          <Box justifyContent="center">
            <TableContainer component={Paper} style={{ width: '80%' }}>
              <Table style={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 700 }}>
                      COMMENT
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 700 }}>
                      STATUS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedArr.map((el, idx) => (
                    <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{ fontWeight: el?.status === 'UNRESOLVED' ? '700' : '400' }}
                      >
                        {el?.comment}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: el?.status === 'UNRESOLVED' ? '700' : '400' }}
                      >
                        {el?.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </Paper>
    );
};
