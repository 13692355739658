/* eslint-disable react/prop-types */
import { MapContainer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { latLngBounds } from 'leaflet';
import { googleMapsApi } from '../../api';

export const GoogleMap = ({ children, setMap, width }) => {
  const center = {
    lat: 23.512,
    lng: 80.329
  };

  return (
    <MapContainer
      style={{ height: '100%', width: width || '100%' }}
      zoom={16}
      center={center}
      bounds={latLngBounds(center)}
      whenReady={setMap}
    >
      <ReactLeafletGoogleLayer
        googleMapsLoaderConf={{
          apiKey: googleMapsApi,
          libraries: ['places']
        }}
        type="hybrid"
      />

      {children}
    </MapContainer>
  );
};
