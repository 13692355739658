import { RegPracticesValidation } from './RegPracticesValidation';

export const Validation = (value, setActiveTab) => {
    if (value && "regPractices" in value) {
        const res = RegPracticesValidation(value.regPractices, setActiveTab)
        if (res.error) {
            return res
        }
    }

    return { error: false }
}