/* eslint-disable react/prop-types */
import { Grid, Paper, TextField } from '@mui/material';
import { SelectBm } from '../../components/@material-extend';
import { ID_TYPE } from '../../data/FieldDetails/enums';

const keyLabels = {
  idType: "Select ID Type",
  idNumber: "Enter ID Number"
};

export const SelectAndInput = ({ editKeyValue, onChange }) => (
  <Paper sx={{ padding: "2rem" }} elevation={3}>
    <Grid container spacing={2}>
      {Object.keys(editKeyValue).map((key, index) => (
        <Grid item key={index}>
          {index === 0 ? (
            <SelectBm
              onChange={({ target }) => onChange("idType", target.value)}
              label={keyLabels[key]}
              containerSx={{ width: "14rem" }}
              list={Object.keys(ID_TYPE).map((key) => ({ label: ID_TYPE[key], value: key }))}
              value={editKeyValue[key]}
            />
          ) : (
            <TextField
              label={keyLabels[key]}
              name={key}
              onChange={({ target }) => onChange("idNumber", target.value)}
              value={editKeyValue[key]}
            />
          )}
        </Grid>
      ))}
    </Grid>
  </Paper>
);
