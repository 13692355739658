/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useState } from "react";
import { Grid } from "@mui/material";
import { SelectBm, LoadingBtnBm } from 'src/components/@material-extend';
import { PopDetailsPdf } from './PopDetailsPdf'
import { REPListModal } from './REPListModal';
import { REPStatModal } from './REPStatModal'

export const TopExistingPop = ({ selectedPop, setSelectedPop, selectedCrop, allCropDetails }) => {
    const [farmerModal, setFarmerModal] = useState(false); 
    const [repStatModal, setRepStatModal] = useState(false); 

    return <>
        <Grid item width="30%">
            <SelectBm
                value={selectedPop}
                onChange={({ target }) => setSelectedPop(target.value)}
                list={allCropDetails[selectedCrop].popId}
                label="Select Existing Package"
                containerSx={{ width: '100%' }}
            />
        </Grid>
        {selectedPop ?
            <Grid item width="20%">
                <REPListModal
                    selectedCrop={selectedCrop}
                    selectedPackage={selectedPop}
                    showModal={farmerModal}
                    setShowModal={setFarmerModal}
                    packageName={allCropDetails[selectedPop].packageName}
                />
                <LoadingBtnBm
                    fullWidth={false}
                    onClick={() => setFarmerModal(!farmerModal)}
                    sx={{ backgroundColor: '#004752', marginTop: '0.5rem' }}>
                    Assign to REPs
                </LoadingBtnBm>
            </Grid> : null
        }
        {selectedPop ?
            <Grid item width="20%">
                <REPStatModal
                    selectedPackage={selectedPop}
                    showModal={repStatModal}
                    setShowModal={setRepStatModal}
                    packageName={allCropDetails[selectedPop].packageName}
                    cropName={allCropDetails[selectedPop].cropName}
                />
                <LoadingBtnBm
                    fullWidth={false}
                    onClick={() => setRepStatModal(!farmerModal)}
                    sx={{ backgroundColor: '#004752', marginTop: '0.5rem' }}>
                    POP Status
                </LoadingBtnBm>
            </Grid> : null
        }
        {selectedPop ?
            <Grid item width="15%">
                <PopDetailsPdf
                    data={allCropDetails[selectedPop]} />
            </Grid> : null
        }
    </>
}