/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.2rem 0.3rem',
    color: '#000',
    margin: '0.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '0.1rem 0.1rem',
      margin: '0.5rem 0'
    }
  }
}));

export const DetailEle = ({ label, Icon, value, ...rest }) => {
  const classes = useStyle();
  return (
    <div className={classes.container} {...rest}>
      {Icon && (
        <Icon
          sx={{
            alignSelf: 'center',
            fontSize: '1.5rem',
            color: '#7F4200',
            margin: '0 0.3rem'
          }}
        />
      )}
      {
        label &&
        <h4>{label}:</h4>
      }
      {value && <h4 style={{ color: '#000' }}>&nbsp; {value || 0}</h4>}
    </div>
  );
};
