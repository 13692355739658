/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */

import { Grid } from '@mui/material'
import { RancherCardNew } from './RancherCardNew'

export const RanchdbStats = ({stat}) => {

return(

<Grid
        container
        spacing={2}
        sx={{
          marginBottom: '2rem'
        }}
      >
        <Grid item xs={3}>
          < RancherCardNew label="Onboarded Ranchers" count={stat.totalRancher} />
        </Grid>
        <Grid item xs={3}>
          <RancherCardNew label="Onboarded Ranches" count={stat.totalRanch} />
        </Grid>
        <Grid item xs={3}>
          <RancherCardNew label="Onboarded Area (acres)" count={(stat.totalArea ? Number.parseFloat(stat.totalArea).toFixed(0) : 0)} />
        </Grid>
        <Grid item xs={3}>
          <RancherCardNew label="Onboarded Area (hectares)" count={(stat.totalArea ? (Number.parseFloat(stat.totalArea) * 0.404686).toFixed(0) : 0)} />
        </Grid>
      </Grid>
)
}