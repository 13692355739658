/* eslint-disable react/prop-types */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

export function AutocompleteBm({ list, value, onChange, label, defaultValue, chip, ...other }) {
    return (
        <Autocomplete
            filterSelectedOptions
            disablePortal
            defaultValue={defaultValue}
            id="auto" 
            options={list}
            value={value}
            onChange={onChange}
            renderTags={chip ? (tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        key={index}
                        label={option}
                        variant="outlined"
                        {...getTagProps({ index })}
                    />
                )) : null
            }
            sx={{ width: 300 }}
            {...other}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
}
