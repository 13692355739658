import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { fShortenNumber } from '../../utils/formatNumber';

// eslint-disable-next-line react/prop-types
export function MultipleAreaChartBM({ data, key1, key2, key3, key4, key5, key6,name, onClick, ylabel, domain }) {
  return (
    <ResponsiveContainer height={300}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorall" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ea6a47" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#ea6a47" stopOpacity={0.5} />
          </linearGradient>
          <linearGradient id="colorapprove" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#2fba38" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#2fba38" stopOpacity={0.9} />
          </linearGradient>
          <linearGradient id="coloraction" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#42ffff" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#42ffff" stopOpacity={0.9} />
          </linearGradient>
          <linearGradient id="colorreview" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ffcc00" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#ffcc00" stopOpacity={0.9} />
          </linearGradient>
          <linearGradient id="colorresub" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#B284BE" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#B284BE" stopOpacity={0.9} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={name}
          hide
        />
        <YAxis
          {...{
            label: { value: ylabel || 'Number of Farms', angle: -90, offset: 17, position: 'insideBottomLeft' },
            tickFormatter: (a) => fShortenNumber(a),
            domain
          }}

        />
        <Tooltip />
        <Legend opacity={0.3} />
        <Area
          type="monotoneX"
          dataKey={key1}
          stackId="1"
          stroke="#ea6a47"
          fill="url(#colorall)"
        />
        {key2 && (
          <Area
            type="monotoneX"
            dataKey={key2}
            stackId="2"
            strokeWidth={2}
            stroke="#2fba38"
            fill="url(#colorapprove)"
          />
        )}
        {key3 && (
          <Area
            type="monotoneX"
            dataKey={key3}
            stackId="3"
            strokeWidth={1}
            fillOpacity={0.3}
            stroke="#004752"
            fill="url(#colorreview)"
          />
        )}
        {key4 && (
          <Area
            type="monotoneX"
            dataKey={key4}
            stackId="4"
            strokeWidth={1}
            fillOpacity={0.3}
            stroke="#ffcc00"
            fill="url(#coloraction)"
          />
        )}
        {key5 && (
          <Area
            type="monotoneX"
            dataKey={key5}
            stackId="5"
            strokeWidth={1}
            fillOpacity={0.3}
            stroke="#199ae0"
            fill="url(#colorresub)"
          />
        )}
        {key6 && (
          <Area
            type="monotoneX"
            dataKey={key6}
            stackId="6"
            strokeWidth={1}
            fillOpacity={0.3}
            stroke="#B284BE"
            fill="url(#colorresub)"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}
