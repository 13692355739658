/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { InputText } from '../../../components/@material-extend/InputText';
import { RadioCheckBox } from '../../../components/@material-extend/RadioCheckBox';
import { TypographyBm } from '../TypographyBm'


export const GrassLandDegradation = ({ handleChange, value, name, disabled }) => {
    const [radioChecked, setRadioChecked] = useState({
        Increased: false,
        Decreased: false,
        "No Change": false,
        "Don't Know": false
    })
    const [increased, setIncreased] = useState(false);
    const [increasedReason, setIncreasedReason] = useState();


    useEffect(() => {
        if (!value) {
            return
        }

        setIncreasedReason();

        let newRadioChecked = radioChecked;

        if (value.includes('Increased')) {
            newRadioChecked["Increased"] = true;
            let newValue = value.replace('Increased', '');
            newValue = newValue.replace(" ", '');
            setIncreasedReason(newValue);
            setIncreased(true);
            setRadioChecked({ ...newRadioChecked });
            return;
        }

        newRadioChecked[value] = true;

        setRadioChecked({ ...newRadioChecked })
        console.log(radioChecked);
    }, [value])

    const setChecked = (key, value) => {

        if (key === "Increased") {
            setIncreased(value);
        } else {
            setIncreased(false);
            if (value) {
                handleChange(key, name);
            }
        }
    }

    const setReason = ({ target }) => {
        const { value } = target
        setIncreasedReason(value);
        handleChange(`Increased ${value}`, name)
    }

    return <Grid container item
        justifyContent='center'
        spacing={2}
    >
        <Grid item xs={4}>
            <TypographyBm color="#404D61" fontWeight={500} fontSize={17} fontFamily='DM Sans' paddingLeft='6%' paddingTop='4%'>
                Has grassland degration and land use change (consversion to croplands) increased in last 20 years/timeline in general?
            </TypographyBm>
        </Grid>
        <Grid item xs={5} >
            <RadioCheckBox
                disabled={disabled}
                setChecked={setChecked}
                checkedBoolean={radioChecked}
            />
            {increased &&
                <InputText
                    disabled={disabled}
                    multiline
                    sx={{
                        bgcolor: '#f1f1f1',
                    }}
                    placeholder='If Increased, what are the reasons?'
                    rows={3}
                    value={increasedReason}
                    onChange={setReason}
                />
            }
        </Grid>
    </Grid>
}

