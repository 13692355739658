/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Button, Paper, Stack, Typography, IconButton } from '@mui/material';
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";
import api from "./api/api";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export const PDFBM = ({ url }) => {
    const [s3Url, setS3Url] = useState("")
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoom, setZoom] = useState(1.0);
    const scrollContainerRef = useRef();

    useEffect(() => {
        if (url) {
            getS3Url()
        }
    }, [url])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () =>
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );

    const getS3Url = async () => {
        try {
            const { data } = await api.get(`/signedUrl?path=public/${url}`);
            setS3Url(data)
        } catch (ele) {
            alert("failed")
        }
    }

    return <Stack component={Paper} elevation={20} mt='1rem'  >
        {s3Url ?
            <Box sx={{ alignItems: 'center', padding: '1rem' }} >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }} >
                    <Button variant='outlined' onClick={goToPrevPage}>Prev</Button>
                    <IconButton variant="outlined" onClick={() => setZoom(zoom + 0.1)}>
                        <ZoomInIcon />
                    </IconButton>
                    <IconButton
                        variant="outlined"
                        onClick={() => setZoom(1.0)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <IconButton variant="outlined" onClick={() => setZoom(zoom - 0.1 ? zoom - 0.1 : 0)}>
                        <ZoomOutIcon />
                    </IconButton>
                    <Button variant='outlined' onClick={goToNextPage}>Next</Button>
                </div>
                <div
                    ref={scrollContainerRef}
                    style={{
                        maxHeight: "600px",
                        overflowY: "auto"
                    }}>
                    <Document
                        file={s3Url}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            key={pageNumber}
                            pageNumber={pageNumber}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            renderMode="canvas"
                            scale={zoom}
                            width={Math.max(1050 * 0.8, 390)}
                        />
                    </Document>
                </div>
                <Typography textAlign='center' >
                    (Page {pageNumber} of {numPages})
                </Typography>
            </Box>
            : <Typography>
                Loading...
            </Typography>
        }
    </Stack>
};
