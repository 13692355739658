/* eslint-disable react/prop-types */
import { HeadingAndTextField } from '../ConserationRanch/HeadingAndTextField';

export const LSSectionA = ({ value, handleChange, disabled }) => {

  const heading = 'What is the theoretical livestock carrying capacity of the ranch (if known)';
  const oneFieldLarge = [
    {
      label: '',
      size: 'medium'
    }
  ];
  return (
    <>
      <HeadingAndTextField
        disabled={disabled}
        value={value}
        handleChange={handleChange}
        heading={heading}
        noOfFields={oneFieldLarge}
        unit='cows/ha'
      />
    </>
  );
};
