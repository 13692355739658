const initialState = {
  rep: {}
};
const repReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REP_DATA':
      return {
        ...state,
        rep: action.payload
      };
    case 'DELETE_REP_DATA':
      return initialState;
    default:
      return state;
  }
};

export default repReducer;
