/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
import { saveAs } from 'file-saver';
import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { ranchApi } from '../api';

const docList = [
  'KYCDoc', 'landDoc', 'AgreementsDoc', 'managementsPlanDoc', 'otherDoc'
]
const docVsNameMapping = {
  KYCDoc: "KYC Documents",
  landDoc: "Land Documents",
  AgreementsDoc: "Agreement Documents",
  managementsPlanDoc: 'Management Documents',
  otherDoc: 'Other Documents',

}
export const DocumentCards = ({ data }) => {
  const Download = async (fileDetails) => {
    try {
      const { loc, fileName } = fileDetails
      const locArray = loc.split('/');
      console.log(locArray);
      const { data } = await ranchApi.post('/docUrl', { folder: locArray[0], expiryTime: 300, getObject: true, key: loc });
      saveAs(data.uploadURL, fileName);
    } catch (error) {
      alert('Failed to downlaod');
    }
  }


  return (
    <Stack>
      {docList.map((ele, index) =>
        <Stack key={index} spacing={2}>
          <Typography variant='h5'>
            {docVsNameMapping[ele]}
          </Typography>
          {!data[ele] ? "No Documents Uploaded" : JSON.parse(data[ele]).map((l1, ind) =>
            <Stack key={ind + "12"} direction="row" spacing={4} sx={{
              padding: "0 2rem"
            }}>
              <Typography color="primary">
                {l1.fileName}
              </Typography>
              <IconButton onClick={() => Download(l1)}>
                <DownloadIcon />
              </IconButton>
            </Stack>)}
        </Stack>

      )}

    </Stack>
  );
};
