export const DateModal = (date) => {
  const jsDate = new Date(date);
  const month = jsDate.getMonth() + 1 > 9 ? jsDate.getMonth() + 1 : `0${jsDate.getMonth() + 1}`
  const getdate = jsDate.getDate() < 10 ? `0${jsDate.getDate()}` : jsDate.getDate();
  return `${jsDate.getFullYear()}-${month}-${getdate}`
}

export const getDaysArray = (data, defaultData, key) => {
  if (data.length) {
    data.reverse()
  }
  const s = new Date(data[0][key]);

  let d;
  let dateEle;
  let dataPointer = 0;
  const res = [];
  for (d = new Date(s);
    d <= new Date();
    d.setDate(d.getDate() + 1)) {
    dateEle = new Date(d).toDateString().slice(4);
    if (data[dataPointer] && data[dataPointer][key] === dateEle) {
      res.push(data[dataPointer])
      dataPointer += 1;
    } else {
      res.push(defaultData(dateEle))
    }
  }
  return res;
};