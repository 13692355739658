/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
/* eslint-disable react/prop-types */
import { useMap } from "react-leaflet";
import l from "leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { useEffect } from "react";


export const MapCenter = ({ center, cancel }) => {
    const map = useMap()

    useEffect(() => {
        l.control.zoom({ position: 'bottomright' }).addTo(map);

    }, [])

    useEffect(() => {

        if (cancel) {
            return;
        }

    }, [center])

    return <FullscreenControl
        forceSeparateButton
        position="bottomright"
        content="<p>--></p>"
    />;
}