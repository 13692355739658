/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import l, { latLngBounds, polygon, popup } from 'leaflet';
import { AreaOfPolygon } from '../../../utils/AreaPolygon';

export const MultiPolygon = ({ coordinates }) => {
  const map = useMap();

  useEffect(() => {
    if (!coordinates) {
      return;
    }
    let parsedCoordinates = coordinates;

    if (typeof coordinates === 'string') {
      parsedCoordinates = JSON.parse(coordinates);
    }

    const allCoor = [];
    for (const ele of parsedCoordinates) {
      const { coor, color } = ele;

      coor[0].forEach((ele) => {
        allCoor.push(ele);
      });

      polygon(coor[0]).setStyle({
        color: color || "white",
        weight: 3,
      })
        .addTo(map)
        .on('click', function () {
          popup({
            closeButton: false
          })
            .setLatLng(this.getBounds().getCenter())
            .setContent(`<p>${AreaOfPolygon([coor], true)} acres</p>`)
            .openOn(map);
          map.fitBounds(this.getBounds())
        });
    }
    if (allCoor.length) {
      const bbox = latLngBounds(allCoor);
      map.fitBounds(bbox);
    }

    return () => {
      map.eachLayer((layer) => {
        if (layer instanceof l.Polygon && !(layer instanceof l.Rectangle)) {
          layer.remove();
        }
      });
    };
  }, [coordinates]);

  return null;
};
