export const Crops = {
  100: "broccoli",
  103: "carrots",
  101: "brussel_sprouts",
  102: "cabbage",
  104: "cauliflower",
  105: "celery",
  106: "garlic",
  107: "lettuce",
  108: "onions",
  109: "spinach",
  110: "radishes",
  200: "egg_plant",
  201: "sweet_peppers_bell_peppers",
  202: "tomato",
  300: "watermelon",
  301: "cantaloupe",
  302: "cucumber",
  303: "pumpkin_winter_squash",
  304: "squash_zucchini",
  305: "sweet_melons",
  400: "sweet_potato",
  401: "table_beet",
  402: "cassava",
  404: "parsnip",
  405: "potato",
  406: "turnip_rutabaga",
  407: "sugar_beet",
  500: "soybeans",
  501: "green_beans",
  502: "dry_beans",
  503: "chickpea",
  504: "fababean",
  505: "garbanzo",
  506: "green_gram_cowpeas",
  507: "groundnut_peanut",
  508: "lentil",
  509: "peas",
  600: "asparagus",
  601: "artichokes",
  602: "mint",
  603: "strawberries",
  700: "cotton",
  701: "flax",
  702: "sisal",
  800: "castorbean_ricinus",
  801: "rapeseed_canola",
  802: "safflower",
  803: "sesame",
  804: "sunflower",
  900: "rice",
  901: "barley",
  902: "oats",
  903: "spring_wheat",
  904: "winter_wheat",
  905: "field_maize_corn",
  906: "sweet_maize_corn",
  907: "millets",
  908: "sorghum",
  1000: "alfalfa",
  1001: "bermuda",
  1002: "clover_hay_berseem",
  1003: "rye_grass_hay",
  1004: "sudan_grass_hay",
  1005: "grazing_pasture",
  1006: "turf_grass",
  1100: "sugarcane",
  1101: "stevia",
  1200: "tea",
  1201: "banana",
  1202: "cacao",
  1203: "coffee",
  1204: "date_palms",
  1205: "papaya",
  1206: "arecanut",
  1207: "palm_trees",
  1208: "pineapple",
  1209: "rubber_trees",
  1210: "coconut",
  1211: "mango",
  1212: "lychee",
  1213: "jujube",
  1300: "berries_bushes",
  1301: "grapes",
  1302: "hops",
  1400: "almonds",
  1401: "apples",
  1402: "cherries",
  1403: "pears",
  1404: "apricots",
  1405: "peaches",
  1406: "stone_fruit",
  1407: "avocado",
  1408: "citrus",
  1409: "cactus_pear",
  1410: "pomegranate",
  1411: "conifer_trees",
  1412: "kiwi",
  1413: "olives",
  1414: "pistachios",
  1415: "walnut_orchard",
  1416: "jojoba",
  1417: "cashew",
  1500: "jasmine",
  1501: "rose_hybrids",
  1502: "chrysanthemum",
  1503: "tuberose",
  1504: "marigold",
  1505: "crossandra_kanakambara",
  1506: "gomphrena_amaranth",
  1507: "hibiscus",
  1508: "nerium_oleander",
  1509: "barleria",
  1510: "celosia_cocks_comb",
  1511: "coronaria",
  1512: "champaca_manoranjan",
  1513: "ixora",
  1514: "periwinkle",
  1515: "porcupine_flower_vajradandhi",
  1516: "gladiolus",
  1517: "gerbera",
  1518: "carnation",
  1519: "crisantemo_pompom",
  1520: "hypericums",
  1521: "alstromeria_hybrids",
  1522: "gypsophilla",
  1523: "geraniums_scented",
  1524: "geraniums_garden",
  1525: "european_iris",
  1526: "japanese_iris",
  1527: "iris_louisiana_gybrids",
  1528: "california_poppy",
  1529: "mexican_tulip_poppy",
  1530: "oriental_poppy",
  1531: "perennial_cornflower",
  1532: "hybrid_lily",
  1533: "violets",
  1534: "shasta_daisy",
  1535: "Dahlia",
  1536: "turmeric",
  1601: "other",
  606: "chia",
  607: "lemon",
  608: "macadamia",
  1418: "Pepper",
  1419: "capsicum",
  111: "Scallion (spring onions)",
  112: "kale",
  113: "amaranth",
  114: "black night shade",
  115: "thyme",
  116: "corriander",
  117: "Okra",
  118: "curry leaves",
  119: "dill",
  120: "chives",
  121: "rosemary",
  408: "ginger",
  604: "pyrethrum",
  1007: "vertiver"
}
