import popApi from "../../components/api/popApi";
import axios from "../../components/api/editapi";
import popReportApi from '../../components/api/popReportApi';

const setAuthToken = token => {
    if (token) {
        const newToken = token.split(".");
        popApi.defaults.headers.common.Authorization = token;
        axios.defaults.headers.common.authorization = `${newToken[0]}.${newToken[1]}.${newToken[2]}`;
        popReportApi.defaults.headers.common.Authorization = token;

    } else {
        delete popApi.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common.authorization;
    }
};
export default setAuthToken;
