/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

export const UploadFile = ({
  folder,
  url,
  noOfFile,
  onUploadSucessed,
  fileType,
  getObject,
  onUploadError,
  fileSize,
  api,
  height
}) => {
  const [uppy, setUppy] = useState(null);
  let fileName = [];
  let key = [];

  useEffect(() => {
    const newUppy = new Uppy({
      id: 'uppy',
      restrictions: {
        maxFileSize: fileSize || 15000000,
        allowedFileTypes: fileType || ['image/*', '.pdf', '.zip'],
        maxNumberOfFiles: noOfFile || 1
      },
      autoProceed: false,
      debug: false
    })
      .use(AwsS3, {
        getUploadParameters: async (file) => {
          try {
            const extension = file.extension;
            const { data } = await api.post(url, {
              folder,
              getObject,
              extension
            });
            fileName.push(file.name);
            key.push(data.Key);
            return {
              method: 'PUT',
              url: data.uploadURL
            };
          } catch (err) {
            console.log(err);
          }
        }
      })
      .on('complete', () => {
        if (noOfFile && noOfFile > 1) {
          onUploadSucessed(key, fileName);
          return;
        }
        onUploadSucessed(key[0], fileName[0]);
      })
      .on('upload-error', () => {
        if (onUploadError) {
          onUploadError();
        }
      });

    setUppy(newUppy);
  }, []);

  return uppy && <Dashboard uppy={uppy} closeModalOnClickOutside
     height={height || "40vh"} style={{
      margin: 'auto',
      width: "100%"
    }} />
};
