/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

export const DocTypeCards = ({ Icon, primary, secondary, setActive, active }) => {

    return <ListItem onClick={setActive} sx={{
        bgcolor: active && "#fff",
        borderRadius: '10px',
        marginBottom: "1.5rem",
        padding: '1rem',
        cursor: 'pointer',
        color: '#111827'
    }}>
        <ListItemAvatar sx={{
            marginRight: "2rem"
        }}>
            <Avatar >
                <Icon fontSize='medium'  style={{color:'#111827'}} />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
}