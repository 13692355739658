export const drawLocal = (type) => ({
    tooltips: {
        firstVertex: (`Click to start drawing ${type}.`),
        finishPoly: (`Click first point to close this ${type}.`),
        continueLine: (`Click to continue drawing ${type}.`),
        placeMarker: (`Click to place ${type}.`),
        finishLine:  (`Click last point to finish this ${type}.`),
    },
    actions: {
        finish: ('Finish'),
        cancel: ('Cancel'),
        removeLastVertex: ('Delete last point')
    },
});