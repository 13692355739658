/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable import/no-unresolved */
import api from 'src/components/api/popApi';

export const ValidatePop = (pop) => {
    const { stages } = pop;
    for (let stage of stages) {
        const stageObj = pop[stage];
        const { sequence } = stageObj;
        if (!sequence.length) {
            throw new Error(`Please add activity in "${stage}".`)
        }
        for (const activityName of sequence) {
            const activity = stageObj[activityName];
            const { description, days, fileKey, } = activity;
            const questionsList = activity.questionsList;
            if (!description || days === 0 ) {
                throw new Error("Please enter content before submitting package.")
            } else if (!questionsList || !questionsList.length) {
                throw new Error(`Question are missing from ${activityName}`);
            }
        }
    }
}

export const SubmitPop = async (pop, cropId, popIds, cropList) => {
    try {
        pop.cropId = cropId
        for (const { label, value } of cropList) {
            if (value === cropId) {
                pop.cropName = label
                break;
            }
        }
        const body = {
            pop,
            popIds
        }
        if (!pop.SK) {
            const { data } = await api.post('/pop/savePOPdata', body);
            return data
        } else {
            pop.type = 'PackageUpdate'
            await api.post('/pop/updatePOPdata', pop);
        }
    } catch (error) {
        console.log(error);
        throw new Error("Fail to submit Pop")
    }
}