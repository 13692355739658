import { useEffect, useState } from 'react';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import { Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import GrassIcon from '@mui/icons-material/Grass';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GrainIcon from '@mui/icons-material/Grain';
import api from '../components/api/api';
import Page from '../components/Page';
import { validationPartner, validationByDate } from '../components/GridColumns';
import { CardBM } from '../components/_dashboard/app';
import { MultipleAreaChartBM } from '../components/charts/MultipleAreaChart';
import { BarBm } from '../components/charts/BarBm';
import { TableHeader } from '../components/TableHeaders';
import { userLoginHelper } from '../redux/actions/userAction';
import { ValidationByDate } from '../components/extends';

export function Validation() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const store = useSelector((store) => store);
    const [rep, setRep] = useState({});

    const onBarClick = async (row) => {
        const partnerId = row.partnerID
        try {
            if (!partnerId || partnerId.length !== 4) {
                throw new Error("Invalid")
            }
            const { user } = store.userRoot;
            const mobile = (user.userDetails.phone_number).replaceAll("+", "") || "";
            const { data } = await api.get(`/getPartnerForSwitcher?mobile=${mobile}&partnerId=${partnerId}`)
            const [superman, readonly] = [user?.userDetails?.userType === 'ADMIN', user?.userDetails?.userType === 'ADMIN_READ_ONLY']
            if (!data || !data.id) {
                throw new Error("invalid")
            }
            user.userDetails.partnerName = data.name
            const storeData = {
                token: user.token,
                partnerID: data.id,
                userDetails: { ...user?.userDetails, userType: user?.userDetails?.userType },
                partnerDetail: data,
                superman,
                readonly
            };
            dispatch(userLoginHelper(storeData));
            dispatch({ type: "DELETE_HOME_DATA", payload: data });
            dispatch({ type: "DELETE_REP_DATA", payload: data });
            navigate('../app');

        } catch (error) {
            alert(error)
        }
    }

    const getData = async () => {
        try {
            const { data } = await api.get("/validationStatus");
            setRep(data);
        } catch (error) {
            alert(error);
        }
    }

    useEffect(() => {
        getData()
    }, []);

    const onAreaClick = (a) => {
        navigate("../InternalDayWise", {
            state: a.payload.date
        })
    }

    return (
        <Page title="Dashboard || REPS">
            <Container maxWidth="xl">
                {Object.keys(rep).length !== 0 &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={2.4}>
                            <CardBM
                                ICONS={CoPresentIcon}
                                label="NUMBER OF FARMS"
                                count={rep.TotalDetails.Total_FARM}
                                color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.4}>
                            <CardBM
                                ICONS={AgricultureIcon}
                                label="TOTAL AREA (ACRES)"
                                count={rep.TotalDetails.Total_AREA}
                                color="linear-gradient(to right, #f8b500, #fceabb)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.4}>
                            <CardBM
                                label="ONBOARDED FARM"
                                count={rep.TotalDetails.CONFIRMED_FARM}
                                color="linear-gradient(to left, #93F9B9, #1D976C)"
                                ICONS={GrainIcon}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.4}>
                            <CardBM
                                ICONS={GrassIcon}
                                label="RECEIVED FARM"
                                color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                                count={rep.TotalDetails.UNCONFIRMED_FARM}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.4}>
                            <CardBM
                                ICONS={GrassIcon}
                                label="REWORK FARM"
                                color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                                count={rep.TotalDetails.REWORK_FARM}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TableHeader label="DAILY TRACK" Icon={AutoGraphIcon} />
                            <MultipleAreaChartBM
                                key2="CONFIRMED_FARM"
                                key5="REWORK_FARM"
                                name="date"
                                data={rep.getDailyWiseEntry}
                                onClick={onAreaClick}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TableHeader label="FARM STATUS ON PARTNER" Icon={LeaderboardIcon} />
                            <BarBm data={rep.partnerWiseDetails} x="partnerID" 
                                y1="CONFIRMED_FARM" 
                                y3="UNCONFIRMED_FARM"
                                y4="REWORK_FARM"
                                yLabel="Number Of Farms"
                                xaxies={false}
                                barAreaClick={onBarClick} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} height="60vh">
                            <TableHeader label="PARTNER WISE DETAILS" Icon={CoPresentIcon} />
                            <DataGrid
                                rows={rep.partnerWiseDetails}
                                onError="No Farmer Added"
                                density="comfortable"
                                columns={validationPartner()}
                                pageSize={50}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                disableSelectionOnClick
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} height="60vh" margin="2rem 0">
                            <TableHeader label="DATE WISE DETAILS" Icon={CoPresentIcon} />
                            <DataGrid
                                rows={rep.getDailyWiseEntry}
                                onError="No Farmer Added"
                                density="comfortable"
                                columns={validationByDate()}
                                pageSize={50}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                disableSelectionOnClick
                            />
                        </Grid>
                    </Grid>
                }
                <ValidationByDate />
            </Container>
        </Page>
    );
}
