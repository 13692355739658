/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from '@mui/material';

export const ModalBM = ({ children, modelUp, handelModal, sx }) => (
  <Modal
    open={modelUp}
    onClose={handelModal}
    sx={{
      overflow: 'scroll',
      // alignSelf: 'center',
      width: window.innerWidth < 678 ? '99vw' : '85vw',
      margin: 'auto auto',
      ...sx
    }}
  >
    {children}
  </Modal>
);
