/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable import/no-unresolved */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import api from 'src/components/api/popApi';
import { CircularProgessBm } from 'src/components/@material-extend';
import { useSnakBarTran } from 'src/hook';
import { TopSectionPop, BottomSectionPop, SubmitPop } from 'src/layouts/pop';

export const PackageOfPractices = () => {
  const { showErrorSnackbar } = useSnakBarTran()
  const [allCropDetails, setAllCropDetails] = useState({});
  const [selectedPop, setSelectedPop] = useState("");
  const [selectedCrop, setSelectedCrop] = useState('');
  const [pop, setPop] = useState({ stages: [] });
  const [loading, setLoading] = useState(true);
  const { partnerID } = useSelector((store) => store.userRoot.user);

  useEffect(() => {
    getCropData();
  }, [partnerID]);

  const getCropData = async () => {
    try {
      setAllCropDetails({})
      setLoading(true);
      const { data } = await api.get(`/pop/getPOPdata`);
      setAllCropDetails(data);
    } catch (err) {
      console.log(err);
      showErrorSnackbar({ text: "failed to fetch crop details" })
    } finally {
      setLoading(false);
    }
  };

  const onsubmitPop = async (pop) => {
    try {
      const data = await SubmitPop(pop, selectedCrop,
        allCropDetails[selectedCrop]['popId'], allCropDetails.cropList);
      if (data) {
        setPop(data)
        const newAllCropDetails = allCropDetails;
        newAllCropDetails[data.SK] = data;
        newAllCropDetails[selectedCrop]['popId'].push({ label: data.packageName, value: data.SK })
        setAllCropDetails({ ...newAllCropDetails })
        setSelectedPop(data.SK)
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  if (loading) {
    return <CircularProgessBm size={100} />;
  }


  return (
    <Grid container padding='0 2rem' >
      <TopSectionPop
        selectedCrop={selectedCrop}
        setSelectedCrop={setSelectedCrop}
        allCropDetails={allCropDetails}
        setAllCropDetails={setAllCropDetails}
        setSelectedPop={(v) => {
          setSelectedPop(v)
          setPop(allCropDetails[v])
        }}
        selectedPop={selectedPop}
        pop={pop}
        submitPop={onsubmitPop}
        setPop={setPop} />
      {pop.stages.length ?
        <BottomSectionPop
          selectedCrop={selectedCrop}
          cropData={allCropDetails[selectedCrop]}
          allCropDetails={allCropDetails}
          setAllCropDetails={setAllCropDetails}
          pop={pop}
          setPop={setPop}
        /> : null
      }
    </Grid>
  );
};
