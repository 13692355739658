/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { LoadScript, GoogleMap, Polygon, Marker, InfoWindow } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { statusColor } from 'src/theme/statusColor';
import greenMarker from '../marker-icons/green-marker.png';
import yellowMarker from '../marker-icons/yellow-marker.png';
import purpleMarker from '../marker-icons/purple-marker.png';
import blueMarker from '../marker-icons/blue-marker.png';
import redMarker from '../marker-icons/red-marker.png'
import darkGreenMarker from '../marker-icons/darkgreen-marker.png'

const useStyle = makeStyles({
  app: {
    textAlign: 'center',
    height: '85vh'
  },
  appMap: {
    height: '100%',
    margin: '0 auto'
  }
});

const GoogleMapLoad = ({ showInfo, center, zoom, PopUp, setShowInfo, coordinates, onClickMarker, newcenter }) => {
  const map = useRef(null)
  const { partnerID } = useSelector((store) => store.userRoot.user);

  useEffect(() => {
    if (map.current === null) {
      return
    }
    let oldMarker
    if (newcenter) {
      map.current.setCenter(newcenter)
      map.current.setZoom(20)
      oldMarker = new window.google.maps.Marker({ position: newcenter, map: map.current });
      console.log(oldMarker);
    }
  }, [newcenter])


  const onClickRow = (row) => {
    const url = window.location.href;
    const newUrl = url.replace('MAP', 'field/')
    const queryUrl = `?id=${row.id}&pid=${partnerID}`
    window.open(newUrl + queryUrl, '_blank')
  }

  const classes = useStyle();
  return <GoogleMap
    mapContainerClassName={classes.appMap}
    center={center}
    zoom={zoom || (window.innerWidth < 400 ? 16 : 18)}
    onLoad={(maps) => {
      map.current = maps
    }}

    options={{
      mapTypeId: 'satellite',
      scrollwheel: true,
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
      zoomControlOptions: { position: 0 }
    }}
  >
    {coordinates.map((coor, index) => {
      // eslint-disable-next-line camelcase
      const { polygon, status, center, land_area: landArea, famerName, id } = coor;

      if (polygon.length === 0) {
        console.log(id);
        return
      }
      const markerIcon = status === 'CONFIRMED' ? greenMarker :
        status === 'RE_SUB' ? purpleMarker : status === 'REWORK' ? blueMarker : status === 'ONHOLD' ? redMarker : status === 'VERIFIED' ? darkGreenMarker : yellowMarker
      return (
        <div key={index}>
          <Polygon
            path={polygon}
            options={{
              strokeColor: status === 'VERIFIED' ? '#fff' : statusColor[status],
              fillColor: status === 'VERIFIED' ? '#fff' : statusColor[status]
            }}
          />
          <Marker position={center} onClick={() => {
            if (onClickMarker) {
              onClickRow(coor)
              return;
            }
            const newShowInfo = [...showInfo];
            newShowInfo[index] = true;
            setShowInfo(newShowInfo);
          }
          }
            icon={markerIcon}
          >
            {showInfo[index] && (
              <InfoWindow
                onCloseClick={
                  () => {
                    const newShowInfo = [...showInfo];
                    newShowInfo[index] = false;
                    setShowInfo(newShowInfo);
                  }}
              >
                <>
                  {PopUp && <PopUp detail={coor} />}
                  {!PopUp &&
                    <div>
                      {famerName && <h4>
                        {`Farmer Name: ${famerName}`}
                      </h4>}
                      {landArea &&
                        <h4>{`Area: ${+landArea.toFixed(2)} acreas`}</h4>
                      }
                    </div>}
                </>
              </InfoWindow>
            )}
          </Marker>
        </div>
      );
    })}
  </GoogleMap>
}



export const FieldMap = ({ coordinates, zoom, PopUp, onClickMarker, newcenter, }) => {
  const classes = useStyle();
  const [showInfo, setShowInfo] = useState([]);
  const { center } = coordinates[0];

  useEffect(() => {
    const newShowInfo = Array(coordinates.length).fill(false);
    setShowInfo(newShowInfo);
  }, [coordinates.length])

  return (
    <div className={classes.app}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyBf-qloG7AMNMJx5k5vmEpo51WCMbIXVnc"
        language="en"
      >
        <GoogleMapLoad
          coordinates={coordinates}
          setShowInfo={setShowInfo}
          center={center}
          newcenter={newcenter}
          zoom={zoom}
          PopUp={PopUp}
          showInfo={showInfo}
          onClickMarker={onClickMarker}
        />
      </LoadScript>
    </div>
  );
};