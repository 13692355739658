/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { Checkbox, FormControlLabel, FormControl } from '@mui/material';
import { TypographyBm } from '../../layouts/questionnaire/TypographyBm';


export const CheckboxBm = ({ onChange, value, label, name, labelPlacement, disabled, textStyle, ...other }) => (
    <FormControl sx={{
        width: '90%',
        flexDirection: 'row',
    }}
        {...other}
    >
        <FormControlLabel
            value={value || false}
            control={<Checkbox
                disabled={disabled}
                checked={value || false}
                sx={{
                    color: "#C3C7CE",
                    '&.Mui-checked': {
                        color: "#87C45E",
                    }
                }}
            />}
            onChange={() => onChange(!value, name)}
            // TODO fix
            label={
                <TypographyBm
                    sx={{
                        width: '19vw',
                        fontSize: '18px',
                        color: '#3E4344',
                        fontFamily: 'DM Sans',
                        ...textStyle
                    }}
                >
                    {(label)}
                </TypographyBm>
            }
            labelPlacement={labelPlacement || "start"}
        />
    </FormControl>
);

