/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useState } from "react";
import { Grid } from "@mui/material"
import { LoadingBtnBm, } from 'src/components/@material-extend';
import { useSnakBarTran } from 'src/hook';
import { ValidatePop } from './ValidatePop';
import { CustomModal } from "./CustomModal";
import { SubmitConfirmation } from './SubmitConfirmation';

export const SubmitBtnPop = ({ pop, selectedPop, setPop, submitPop, existingPop }) => {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const { showErrorSnackbar } = useSnakBarTran();

    const onSubmitPop = async (pop) => {
        try {
            setLoading(true);
            ValidatePop(pop);
            await submitPop(pop);
        } catch (error) {
            showErrorSnackbar({ text: error.message });
        } finally {
            setLoading(false);
            setModal(false);
        }
    }

    const existingPackageName = existingPop.map(({ label }) => label);

    const onClickBtn = async (questionStatus) => {
        try {
            ValidatePop(pop);
            if (!pop.packageName) {
                setModal(true);
            } else {
                await onSubmitPop({ ...pop, questionStatus })
            }
        } catch (error) {
            showErrorSnackbar({ text: error.message });
        }
    }
    const handleAddPackageName = async (_, packageName) => {
        const newPop = { ...pop, packageName }
        setPop(newPop)
        await onSubmitPop(newPop)
    }

    const onConFirmation = (isConfirm) => {
        try {
            ValidatePop(pop);
            const questionStatus = isConfirm ? "final" : ""
            const newPop = { ...pop, questionStatus }
            setPop(newPop)
            onClickBtn(questionStatus)
        } catch (error) {
            showErrorSnackbar({ text: error.message });
        }
    }

    return <Grid item container width="20%">
        <SubmitConfirmation
            showModal={confirmationModal}
            setShowModal={setConfirmationModal}
            onSubmit={onConFirmation}
        />
        <CustomModal
            loading={loading}
            checkList={existingPackageName}
            showModal={modal}
            setShowModal={setModal}
            onSubmit={handleAddPackageName}
            modalType="Package" />
        <LoadingBtnBm
            loading={loading}
            fullWidth={false}
            onClick={() => setConfirmationModal(true)}
            sx={{ backgroundColor: '#004752', marginTop: '0.5rem' }}>
            {selectedPop.length ? "Update" : "Submit"}
        </LoadingBtnBm>
    </Grid>
} 