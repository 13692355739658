/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DetailEle } from "../DetailEle";

export const MapFieldPop = ({ detail }) => {

    const history = useNavigate();

    const {
        farmerID,
        contractorID,
        createdAt,
        contractorName,
        land_area,
        status,
        contratorPhone,
        contratorAddress,
        farmerPhone,
        famerName } = detail;

    const onClickContractorName = () => {
        history('../farmers', {
            state: {
                contractorID,
                fullName: contractorName,
                phone_number: contratorPhone,
                town: contratorAddress
            }
        });
    };

    const onClickFamerName = () => {
        history('../field', {
            state: {
                farmer_id: farmerID,
                fullName: famerName,
                phone_number: farmerPhone
            }
        });
    };

    return <>
        <DetailEle
            label="Onboarded On"
            value={createdAt}
        />
        <DetailEle
            label="Farmer Name"
            value={
                <Button
                    onClick={onClickFamerName}
                    sx={{
                        margin: "0",
                        height: "100%"
                    }}
                >
                    {famerName}
                </Button>
            }
        />
        <DetailEle
            label="REP Name"
            value={
                <Button
                    onClick={onClickContractorName}
                    sx={{
                        margin: "0"
                    }}>
                    {contractorName}
                </Button>
            }
        />
        <DetailEle
            label="acre"
            value={land_area}
        />
        <DetailEle
            label="Status"
            value={status}
        />
    </>
}