/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Paper, Tabs, Tab, Typography, Box, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';
import { Header, Map, SizeOfPolygon, ListDetails } from '../../components/rancherDashboardComponents/fieldFarmerDetails';
import { ranchApi } from '../../components/api'
import { CircularProgessBm } from '../../components/@material-extend';
import { DocumentCards, ViewQuestion } from '../../components/rancherDashboardComponents';


const statusList = ['UNCONFIRMED', 'CONFIRMED', 'REWORK']
export const RanchDetail = () => {
  const [data, setData] = useState({});
  const [coor, setCoor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [tabs, setTabs] = useState('All Details')
  const urlQuery = new URLSearchParams(window.location.search);
  const [selectedStatus, setSelectedStatus] = useState("none");
  const [comments, setComments] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { readonly } = useSelector((store) => store.userRoot.user);
  const navigate = useNavigate();

  const onSaveStatus = async () => {
    try {
      setStatusLoading(true);
      await ranchApi.post('/editRanch', {
        PK: "rancher#" + urlQuery.get('rid'),
        SK: "ranch#" + urlQuery.get('id'),
        status: selectedStatus,
        comments
      });
      setData({ ...data, status: selectedStatus, comments });
    } catch (error) {
      alert(error)
    } finally {
      setStatusLoading(false);
    }
  }
  const onAddDocuments = () => {
    navigate(`../documents?TotalDoc=${data.TotalDoc || 0}`, {
      state: {
        PK: data.PK,
        SK: data.SK,
        status: data.status
      }
    });
  }
  const onEditRanch = () => {
    navigate('../AddRanch', {
      state: data
    });
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const query = `?id=${urlQuery.get('id')}&rancherid=${urlQuery.get('rid')}`;
      const { data } = await ranchApi.get(`/getRanchById${query}`);
      console.log(data);
      delete data.userDetails.status
      setSelectedStatus(data?.ranchDetails.status)
      setData({ ...data.userDetails, ...data.ranchDetails });
      setCoor(JSON.parse(data.ranchDetails.coordinates));
      setSelectedStatus(data.ranchDetails?.status)
      setComments(data.comments || "");
    } catch (error) {
      console.log(error);
      alert('failed');
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchData()
  }, [])

  if (loading) {
    return <CircularProgessBm sx={{
      height: '100vh'
    }} />
  }
  console.log(data);

  const renderBody = {
    "All Details": <Grid item xs={9}>
      <Map coor={[coor]} height='57vh' label="Ranch Polygon" />
      <Paper
        elevation={10}
        sx={{
          padding: '1rem',
          marginTop: '0.5rem',
          width: '100%'
        }}
      >
        <Typography variant="h6" textAlign='center' sx={{
          margin: "1rem auto"
        }}>Documents</Typography>
        <DocumentCards data={data} />
      </Paper>
      <Paper
        elevation={10}
        sx={{
          padding: '1rem',
          marginTop: '0.5rem',
          width: '100%',

        }}
      >
        <Typography variant="h6" sx={{
          margin: "1rem auto",
        }}>Questionnaire (Status: {data?.infoStatus})</Typography>
        <ViewQuestion data={data} />
      </Paper>
      <Paper
        elevation={10}
        sx={{
          padding: '1rem',
          marginTop: '0.5rem',
          width: '100%'
        }}
      >
        <Typography variant="h6" marginBottom={3}>Comments</Typography>
        <Typography variant="p">
          {data.comments || "No comments"}
        </Typography>
        {data.partnerComments ?
          <>
            <Typography variant="h6" marginBottom={3}>Partner Comments</Typography>
            <Typography variant="p">
              {data.partnerComments || "No comments"}
            </Typography>
          </> : null
        }
        <TextField
          fullWidth
          multiline
          rows={5}
          style={{ marginTop: '2rem' }}
          onChange={({ target }) => setComments(target.value)}
          value={comments || ''} />
        <LoadingButton loading={statusLoading} onClick={onSaveStatus} variant="contained" style={{
          backgroundColor: readonly ? "" : "#00AB55",
          margin: "0.5rem 0.5rem",
          marginTop: '2rem',
        }}
        disabled={readonly}
        >
          Save
        </LoadingButton>
      </Paper>
    </Grid>
  }

  const onChange = (value) => {
    setSelectedStatus(value)
    setShowSaveButton(true)
  }

  return <>
    {!loading &&
      <Grid spacing={1} container sx={{
        margin: '2rem auto',
        marginTop: '1rem',
        width: '85vw',
        paddingBottom: '2rem'
      }}>
        <Grid item xs={12}>
          <Header name={
            `${data.fullName}, ${data.ranchName}`}
            status={data.status}
          />
          <Paper elevation={10} sx={{ width: '100%', bgcolor: '#F4F6F8', display: 'flex' }} >
            <div style={{ flex: 1 }} />
            <Tabs value={tabs}
              onChange={(_, v) => {
                setTabs(v)
              }} >
              {["All Details"].map(ele =>
                <Tab label={ele} key={ele} value={ele} />
              )}
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item container xs={3} maxHeight='75vh' >
            <Grid item xs={12}>
              <SizeOfPolygon
                calculatedAcres={data.unit === 'hectare' ?
                  (data.calculatedArea * 2.47).toFixed(0)
                  : data.calculatedArea}
              />
            </Grid>
            <Grid item xs={12}>
              <ListDetails
                header='Ranch Details'
                keyValue={{
                  Country: data.country,
                  State: data.state,
                  'Ranch Address': data.address,
                  city: data.city,
                  postal: data.postal,
                  Mobile: data.phone_number
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ListDetails
                header='Partner Details'
                keyValue={{
                  Project: data.projectId,
                  Partner: data.partnerId,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ListDetails
                keyValue={{
                  'Onboarded on': new Date(data.createdAt).toDateString().slice(4),
                  'Status Updated on': new Date(data.updatedAt).toDateString().slice(4)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={10}
                sx={{
                  padding: '1rem',
                  marginTop: '0.5rem',
                  width: '100%'
                }}
              >
                <Typography variant="h6">Status</Typography>
                <Box sx={{ minWidth: 120, marginTop: 3 }}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select value={selectedStatus} label="Partner" onChange={(event) => { onChange(event.target.value) }} disabled={readonly}>
                      <MenuItem key="all" value="none">
                        Select Status
                      </MenuItem>
                      {statusList.map((ele, index) => (
                        <MenuItem key={index} value={ele}>
                          {ele}
                        </MenuItem>
                      ))}
                    </Select>
                    {showSaveButton ? <LoadingButton loading={statusLoading} onClick={onSaveStatus} variant="contained" style={{
                      backgroundColor: "#00AB55",
                      margin: "0.5rem 0.5rem"
                    }}
                    >
                      Save
                    </LoadingButton> : null}
                  </FormControl>
                </Box>
              </Paper>
            </Grid>
            {!(['CONFIRMED', 'RESUB'].includes(data.status)) && data.doneBy === 'Internal' &&
              <Grid item xs={12}>
                <Paper elevation={10} sx={{
                  width: '100%',
                  margin: '1rem auto',
                }}>
                  <Button onClick={onAddDocuments} sx={{ margin: '1rem' }}>
                    Add Documents
                  </Button>
                  <Button onClick={onEditRanch}>
                    Edit Ranch
                  </Button>
                </Paper>
              </Grid>
            }
          </Grid>
          {renderBody[tabs]}
        </Grid>
      </Grid>
    }</>

    ;
};
