/* eslint-disable camelcase */
export const TransFormRep = (row) => {
    const { REP_status: status,
        REP_given_name: given_name,
        REP_family_name: family_name,
        REP_country: country,
        REP_postal_code: postal_code,
        REP_state: state,
        REP_town: town,
        REP_phone_number: phone_number,
        REP_address1: address1,
        contractorID
    } = row;

    const createdAt = new Date(row.REP_createdAt).toDateString().slice(4)

    return {
        given_name,
        family_name,
        createdAt,
        country,
        state,
        postal_code,
        town,
        phone_number,
        address1,
        contractorID,
        status
    }
}

export const TransFormFarmer = (row) => {

    const { Farmer1_bank_account: bank_account,
        Farmer1_bank_code: bank_code,
        Farmer1_id_number: id_number,
        Farmer1_id_type: id_type,
        Farmer1_bank_type: bank_type,
        Farmer2_status: status,
        Farmer2_given_name: given_name,
        Farmer2_family_name: family_name,
        Farmer2_country: country,
        Farmer2_state: state,
        Farmer2_postal_code: postal_code,
        Farmer2_town: town,
        Farmer2_phone_number: phone_number,
        Farmer2_address1: address1,
        Farmer1_bank_account_holder_first_name: bank_account_holder_first_name,
        Farmer1_bank_account_holder_last_name: bank_account_holder_last_name,
        farmerID: farmer_id,
        land_area: area,
        Farmer2_internal_comment: internal_comment,
        Farmer1_id_photo_path: id_photo_path,
        Farmer1_signature_photo_path: signature_photo_path
    } = row;

    const createdAt = new Date(row.Farmer2_createdAt).toDateString().slice(4)

    return { bank_account, farmer_id, bank_code, id_number, id_type, bank_type, status, given_name, family_name, createdAt, country, state, postal_code, town, phone_number, address1, area, internal_comment, id_photo_path, signature_photo_path, bank_account_holder_first_name, bank_account_holder_last_name }
}

export const validateMobile = (value) => {
    value = value.replace("+", "")
    const len = value.length
    // if (len < 10
    //     || len > 13 ||
    //     (len === 12 && value.substring(0, 2) !== "91")
    // ) {
    //     return false;
    // }
    if (len === 10) {
        value = `91${value}`;
    }

    return value;
}