/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import React from 'react';
import {
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
    ComposedChart,
    Bar
} from 'recharts';

// eslint-disable-next-line react/prop-types
export function ComplexChartBm({ data, key1, key2, key3, key4, key5, key6, name, onClick, AreaColor, CustomTooltip }) {
    
    return (
        <ResponsiveContainer height={300}>
            <ComposedChart data={data}
                onClick={(e) => {
                    if (!e || !onClick) return
                    const { activePayload } = e
                    onClick(activePayload[0])
                }
                }
            >
                <defs>
                    <linearGradient id="colorall" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#199ae0" stopOpacity={0.1} />
                        <stop offset="95%" stopColor="#199ae0" stopOpacity={0.5} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={name}
                    angle={90}
                    hide
                />
                <YAxis
                    label={{ value: 'Number of Farms', angle: -90, offset: 17, position: 'insideBottomLeft' }}
                />
                {CustomTooltip ?
                    <Tooltip content={<CustomTooltip />} /> :
                    <Tooltip />
                }
                <Legend opacity={0.3} />
                {key1 && <Area
                    type="monotoneX"
                    dataKey={key1}
                    stackId="1"
                    stroke={AreaColor || "#ea6a47"}
                    fill="url(#colorall)"
                />}
                {key2 && <Bar
                    dataKey={key2}
                    stackId="a"
                    fill="#05AA02"
                />}
                {key3 && <Bar
                    dataKey={key3}
                    stackId="a"
                    fill="#004752"
                />}
                {key4 && <Bar
                    dataKey={key4}
                    stackId="a"
                    fill="#ffcc00"
                />}
                {key5 && <Bar
                    dataKey={key5}
                    stackId="a"
                    fill="#199ae0"
                />}
                {key6 && <Bar
                    dataKey={key6}
                    stackId="a"
                    fill="#B284BE"
                />}

            </ComposedChart>
        </ResponsiveContainer>
    );
}
