/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Grid } from '@mui/material';
import { HeaderDivider } from 'src/layouts/questionnaire/ConserationRanch/HeaderDivider';
import { DocTypeList } from './DocTypeList';
import { UploadDoc } from './UploadDoc';
import { DocCardList } from './DocCardList';

const folderName = [
    'KYCDoc', 'landDoc', 'AgreementsDoc', 'managementsPlanDoc', 'otherDoc'
]

export const DocumentsUplaod = ({ id, status, rId }) => {
    const [refresh, setRefresh] = useState(0);
    const [active, setActive] = useState(0);
    const [totalDoc, setTotalDoc] = useState(0);

    return <Grid container spacing={0.9} marginBottom="1rem" >
        <Grid item xs={3.6}>
            <DocTypeList
                active={active}
                setActive={setActive}
            />
        </Grid>
        <Grid xs={7.5} item>
            {status !== 'CONFIRMED' ?
                <>
                    <UploadDoc
                        totalDoc={totalDoc}
                        PK={rId}
                        setRefresh={() => setRefresh(1)}
                        id={id}
                        folder={folderName[active]}
                    />
                    <div
                        style={{
                            height: '10vh'
                        }}
                    />
                </>
                : null
            }

            <HeaderDivider
                title='Successfully Uploaded'
            />
            <DocCardList
                setTotalDoc={setTotalDoc}
                totalDoc={totalDoc}
                activeType={folderName[active]}
                id={id}
                rId={rId}
                status={status}
                refresh={refresh}
                setRefresh={() => setRefresh(-1)}
            />
        </Grid>
    </Grid>

}