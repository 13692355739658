/* eslint-disable no-await-in-loop */
import api from '../../components/api/api';

let allData = {
    polygons: [],
    stat: {},
}
export const mapAction = (partnerId, leaderId, history) => async (dispatch) => {
    try {
        let nextToken = 0;
        allData = {
            polygons: [],
            stat: {},
        }
        while (nextToken || nextToken === 0) {
            let query = `partnerId=${partnerId}`;
            if (leaderId && leaderId !== 'All') {
                query += `&leaderId=${leaderId}`;
            }
            const { data } = await api.get(
                `polygon?${query}&page=${nextToken}`,
            );

            allData.polygons = [...allData.polygons, ...data.polygons];
            if (data.stat) {
                allData.stat = data.stat;
                allData.leaderIDs = data.partnerDivision || [];
            }
            if (!data.polygons.length) {
                nextToken += 1;
                break
            }
            nextToken += 1;
        }

        dispatch({
            type: 'SET_ALL_POLYGON_DATE',
            payload: allData
        });
    } catch (err) {
        // history('/404');
    }
};