/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
import { polygon } from "@turf/helpers";
import intersect from '@turf/intersect'



export const polyIntersecting = (newPolyGon, allPolygon, checkDouble) => {
    if (allPolygon.length === 0) {
        return false;
    }
    const mainPoly = polygon([newPolyGon]);
    let count = 0
    for (let poly of allPolygon) {
        const tempPoly = polygon(poly)

        const isIntersect = intersect(mainPoly, tempPoly);

        if (isIntersect !== null) {
            count++
            if (!checkDouble) {
                return true;
            }
        }

        if (count >= 2) {
            return true
        }
    }
    return false
}

