import api from '../../components/api/api';

export const farmerAction = (partnerID, id) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_FARMER_LOADING' })
    const { data } = await api.get(
      `/fieldByRep?repId=${id}&partnerId=${partnerID}`
    );

    dispatch({
      type: 'SET_FARMER_DATA',
      payload: data
    });

  } catch (err) {
    console.log(err);
  }
};
