/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(() => ({
  container: {
    width: "74px",
    backgroundColor: "#c4c4c4",
    cursor: "pointer",
    borderRadius: "3px",
    padding: "2px",
    height: "36px",
    position: "relative",
    marginBottom: '1rem'
  },
  dialogButton: {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: "#002b49",
    color: "white",
    padding: "8px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "38px",
    minWidth: "unset",
    borderRadius: "3px",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    left: "34px",
    transition: "all 0.3s ease"
  }
}));

export const ToggleButtonBM = ({ selected, toggleSelected }) => {
  const classes = useStyle();

  return (
    <div className={classes.container} onClick={toggleSelected}>
      <div
        className={classes.dialogButton}
        style={
          selected
            ? { backgroundColor: '#87C45E', left: '2px' }
            : {
              backgroundColor: '#E56647'
            }
        }
      >
        {selected ? 'YES' : 'NO'}
      </div>
    </div>
  );
};
