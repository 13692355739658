export const statusColor = {
    "CONFIRMED": "#87C45E",
    "UNCONFIRMED": "#F7B526",
    "REWORK": "#3DB0DE",
    "RE_SUB": "#9747FF",
    "VERIFIED": "#004752",
    "PARTNER_REWORK": "#3DB0DE",
    "PARTNER_RE_SUB": "#9747FF",
    "RESUB": '#9747FF',
    "ONBOARDED": "#87C45E",
    "RECEIVED": "#F7B526",
}