/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';

export const ContainerCenter = ({ children, item, ...other }) => (
  <Grid
    container
    item={item || false}
    padding="1rem 1.5rem"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...other}
  >
    {children}
  </Grid>
);
