import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  Container: {
    width: '100%',
    margin: '80px auto 0 auto',
    padding: '1rem',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '96%',
      padding: '0.2rem'
    }
  },
  Container2: {
    width: '95%',
    margin: '80px auto 0 auto',
    padding: '1rem'
  },
  Heading: {
    margin: '1rem auto',
    textAlign: 'center'
  },
  Box: {
    padding: '0.5rem',
    margin: '1rem',
    [theme.breakpoints.down('md')]: {
      padding: '0.1rem',
      margin: '0.2rem'
    }
  },
  paper: {
    margin: '0.5rem 0rem',
    padding: '0.5rem'
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& p': {
      marginRight: '0.3rem ',
      display: 'inline'
    },
    '& h6': {
      marginRight: '0.5rem',
      display: 'inline',
      color: '#2c387e'
    },
    '& span': {
      margin: '0.5rem'
    }
  },
  row2: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& p': {
      marginRight: '0.3rem',
      display: 'inline'
    },
    '& h6': {
      marginRight: '0.5rem',
      display: 'inline',
      color: '#2c387e'
    },
    '& span': {
      margin: '0.5rem'
    }
  },
  ButtonGrp: {
    margin: '2rem 0',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  approve: {
    borderRadius: '15px',
    padding: '0.5rem',
    color: 'white',
    background: 'linear-gradient(to right top, #051937, #00466b, #00799a, #00afbf, #55e7d8)'
  },
  reject: {
    borderRadius: '15px',
    padding: '0.5rem',
    color: 'white',
    background: 'linear-gradient(to right top, #f3e879, #fcc866, #fea85f, #fa8962, #ed6b6b)'
  }
}));

export default useStyle;
