/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { LoadingBtnBm, ModalBm } from 'src/components/@material-extend';
import { Stack,} from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { getFarmerReportPOP } from 'src/components/GridColumns';
import { PaginationBM } from 'src/components/Pagination';
import { QuickSearchToolbar } from 'src/components/QuickSearch';
import api from 'src/components/api/popApi';
import { useSnakBarTran } from 'src/hook';

let farmerData = []
export const REPListModal = ({
  showModal,
  setShowModal,
  selectedCrop,
  selectedPackage,
  packageName
}) => {
  const [searchText, setSearchText] = useState('');
  const [gridData, setGridData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnakBarTran();

  useEffect(() => {
    if (showModal && !farmerData.length) {
      onExistingFarmerBtnClick()
    }
  }, [showModal])

  const onExistingFarmerBtnClick = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(`/pop/getFarmerDataPop`);
      setGridData(data?.userDetails);
      farmerData = data.userDetails;
    } catch (err) {
      showErrorSnackbar({ text: 'Error: Failed to fetch data.' });
      console.log('Error while getting data: ', err);
    } finally {
      setLoading(false);
    }
  };

  const requestSearch = (searchValue) => {
    const farmerSearchKeys = [
      'given_name',
      'family_name',
      'middle_name',
      'phone_number',
      'status',
    ];
    setSearchText(searchValue);
    if (!searchValue || searchValue === '' || searchValue.length === 0) {
      setGridData([...farmerData]);
      return;
    }
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = farmerData.filter((row) => {
      return farmerSearchKeys.some((field) => {
        return searchRegex.test(row[field]?.toString().toLowerCase());
      });
    });
    const newRep = [...filteredRows];
    setGridData(newRep);
  };

  const escapeRegExp = (value) => {
    value = value.toLowerCase();
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  const onAssign = async () => {
    setIsSubmitting(true);
    try {
      const selectedContractors = rowSelectionModel.map((item) =>
        farmerData ? farmerData[item].fid : gridData[item].fid
      );
      const assignData = {
        contractors: selectedContractors,
        cropID: selectedCrop,
        popID: selectedPackage,
        packageName
      };
      await api.post('pop/assignPOPFarmer', assignData);
      showSuccessSnackbar({ text: "Successfuly assigned package to selected REPs." })
    } catch (err) {
      console.log('Error assigning package.', err);
      showErrorSnackbar({ text: "Unable to assign package to selected REPs." })
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalBm showModal={showModal}
      title="REPs List"
      setShowModal={() => setShowModal(!showModal)}>
      <Stack
        style={{
          height: '75vh',
        }}
      >
        <DataGrid
          components={{
            Pagination: PaginationBM,
            Toolbar: QuickSearchToolbar,
          }}
          loading={loading}
          columns={getFarmerReportPOP()}
          rows={gridData}
          pagination
          pageSize={100}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
          checkboxSelection
          onSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
        <LoadingBtnBm
          onClick={onAssign}
          style={{
            backgroundColor: '#004752',
            width: '60%',
            margin: '1rem auto'
          }}
          loading={isSubmitting}
          disabled={rowSelectionModel.length === 0}
        >
          Assign to Contractors
        </LoadingBtnBm>

      </Stack>
    </ModalBm>
  );
};
