/* eslint-disable dot-notation */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Typography, Grid, TextField, Stack, Autocomplete, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MuiTelInput } from 'mui-tel-input';
import { countries } from 'src/data/countryData/countries';
import { state as allState } from 'src/data/countryData/states'
import { SelectBm } from 'src/components/@material-extend';
import { ranchApi } from 'src/components/api';
import { stateLabel } from 'src/data/countryData/stateLabel';


export const Onboarding = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState({ label: 'Argentina', value: 'AR' })
  const [state, setState] = useState(allState['AR'][0].value);
  const [loading, setLoading] = useState(false);
  const stateDesc = stateLabel[country.value] ? stateLabel[country.value] : 'State';

  const rancherdata = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .min(2, 'Please enter a valid Name')
      .required('Name is required'),
    email: yup.string().trim()
      .email('Please  enter valid email')
      .required('Please  enter valid email'),
    phone_number: yup.string().min(9, 'Enter valid phone number').required('Please enter a valid phone number.'),
    partnerId: yup.string().min(4, 'Enter valid partner id').max(6, 'Enter valid partner id').required('Enter partner id'),
  });

  const onSubmit = async (value) => {
    try {
      setLoading(true);
      const allValue = { ...value, country: country.value, state };
      console.log(allValue);
      await ranchApi.post('/AddRancher', allValue)
      navigate('../AddRanch');
    } catch (error) {
      console.log(error);
      alert("Failed to add Rancher")
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      phone_number: '',
      email: '',
      address: '',
      postal: '',
      role: '',
      organistion: '',
      city: '',
      partnerId: '1002'
    },
    validationSchema: rancherdata,
    onSubmit,
  });

  const onClick1 = () => {
    navigate('../AddRanch');
  };

  return (
    <Stack sx={{
      margin: '0 auto'
    }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction='row' spacing={5} sx={{
          margin: '0 4rem',
          mb: '2rem',
        }}>
          <Grid flex={1} />
          <Typography style={{ fontSize: 30, }}>
            <strong>Add Rancher Details</strong>
          </Typography>
          <Grid flex={1} />
          <Button
            style={{ backgroundColor: '#004752', color: '#fff' }}
            onClick={onClick1}
          >
            Add Ranch
          </Button>
        </Stack>
        <Stack spacing={3} >
          <Stack alignItems='center'>
            <TextField
              required
              name='partnerId'
              label='Partner Id'
              style={{ width: '60%' }}
              value={formik.values['partnerId']}
              onChange={formik.handleChange}
              error={
                formik.touched['partnerId'] && Boolean(formik.errors['partnerId'])
              }
              helperText={formik.touched['partnerId'] && formik.errors['partnerId']}
            />
          </Stack>
          <Stack alignItems='center'>
            <TextField
              required
              name='fullName'
              label='Full Name'
              style={{ width: '60%' }}
              value={formik.values['fullName']}
              onChange={formik.handleChange}
              error={
                formik.touched['fullName'] && Boolean(formik.errors['fullName'])
              }
              helperText={formik.touched['fullName'] && formik.errors['fullName']}
            />
          </Stack>
          <Stack alignItems='center'>
            <MuiTelInput
              required
              style={{ backgroundColor: '#fff', width: '60%' }}
              FormHelperTextProps={{
                sx: { bgcolor: '#fff', margin: 0 }
              }}
              value={formik.values['phone_number']}
              onChange={formik.handleChange('phone_number')}
              onlyCountries={['AR', 'BR', 'UY', 'MX', 'PY', 'KE', 'US']}
              defaultCountry='AR'
              forceCallingCode
              disableFormatting
              label='Phone number'
              error={Boolean(formik.touched.phone_number && formik.errors.phone_number)}
              helperText={formik.touched.phone_number && formik.errors.phone_number}
            />
          </Stack>
          <Stack alignItems='center'>
            <TextField
              required
              name='email'
              label='Email'
              style={{ width: '60%' }}
              value={formik.values['email']}
              onChange={formik.handleChange}
              error={
                formik.touched['email'] && Boolean(formik.errors['email'])
              }
              helperText={formik.touched['email'] && formik.errors['email']}
            />
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='center'>
            <Autocomplete
              disablePortal
              fullWidth
              sx={{ bgcolor: '#fff', borderRadius: 1, width: '28%' }}
              options={countries}
              disableClearable
              defaultValue={country}
              onChange={(_, v) => {
                setCountry(v)
                setState(allState[v.value][0]["value"]);
              }}
              value={country}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Country"
                />
              )}
            />
            <SelectBm
              required
              label={`Select ${stateDesc}*`}
              value={state}
              fullWidth
              onChange={({ target }) => setState(target.value)}
              sx={{
                width: '23vw',
                borderRadius: 1
              }}
              list={allState[country.value] || []}
            />
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='center'>
            <TextField
              name='role'
              label='Role'
              style={{ width: '28%' }}
              value={formik.values['role']}
              onChange={formik.handleChange}
              error={
                formik.touched['role'] && Boolean(formik.errors['role'])
              }
              helperText={formik.touched['role'] && formik.errors['role']}
            />
            <TextField
              name='organistion'
              label='Organistion'
              style={{ width: '30%' }}
              value={formik.values['organistion']}
              onChange={formik.handleChange}
              error={
                formik.touched['organistion'] && Boolean(formik.errors['organistion'])
              }
              helperText={formik.touched['organistion'] && formik.errors['organistion']}
            />
          </Stack>
          <Stack alignItems='center'>
            <TextField
              name='address'
              label='Legal Address'
              style={{ width: '60%' }}
              value={formik.values['address']}
              onChange={formik.handleChange}
              error={
                formik.touched['address'] && Boolean(formik.errors['address'])
              }
              helperText={formik.touched['address'] && formik.errors['address']}
            />
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='center'>
            <TextField
              name='city'
              label='City'
              style={{ width: '28%' }}
              value={formik.values['city']}
              onChange={formik.handleChange}
              error={
                formik.touched['city'] && Boolean(formik.errors['city'])
              }
              helperText={formik.touched['city'] && formik.errors['city']}
            />
            <TextField
              name='postal'
              label='Postal Code'
              style={{ width: '30%', overflow: 'scroll' }}
              value={formik.values['postal']}
              onChange={formik.handleChange}
              error={
                formik.touched['postal'] && Boolean(formik.errors['postal'])
              }
              helperText={formik.touched['postal'] && formik.errors['postal']}
            />
          </Stack>
        </Stack>
        <Stack style={{
          margin: '2rem'
        }}>
          <LoadingButton
            style={{
              backgroundColor: '#005249',
              width: '40%',
              margin: 'auto'
            }}
            loading={loading}
            fullWidth
            variant="contained"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};
