/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { useState } from "react";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid, Typography, CardContent, Card, Stack, Box, Paper, IconButton } from "@mui/material"
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { ActivityContentModal } from './ActivityContentModal';
import { AddActivityQuestion } from './AddActivityQuestion';

export const StageCards = ({ stages, selectedStage, setSelectedStage, pop, setPop }) => {
    const [activeActivites, setActiveActivites] = useState("")
    const [questionModal, setQuestionModal] = useState("");
    const addQuestion = pop.questionStatus !== 'final';

    const onAddContent = (description, days, fileKey, questionsList) => {
        const newPop = { ...pop };
        const activitiesDetails = newPop[selectedStage][activeActivites || questionModal];

        if (!questionsList) {
            activitiesDetails.description = description
            activitiesDetails.days = days
            activitiesDetails.fileKey = fileKey
        } else {
            activitiesDetails.questionsList = questionsList;
        }
        newPop[selectedStage][activeActivites || questionModal] = { ...activitiesDetails }
        setPop(newPop);
        setActiveActivites("");
    }


    const handleDrag = (result) => {
        const items = Array.from(pop[selectedStage].sequence);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);
        const newPop = { ...pop };
        newPop[selectedStage].sequence = items;
        setPop(newPop);
    }

    return <Grid container item spacing={2} xs={8.5}>
        {activeActivites &&
            <ActivityContentModal
                showModal={activeActivites}
                setShowModal={setActiveActivites}
                activityName={activeActivites}
                onSubmit={onAddContent}
                data={pop[selectedStage][activeActivites]}
                selectedStage={selectedStage} />
        }
        {questionModal && <AddActivityQuestion
            addQuestion={addQuestion}
            onSubmit={(questionList) => onAddContent(undefined, undefined, undefined, questionList)}
            showModal={questionModal}
            setShowModal={setQuestionModal}
            activityName={questionModal}
            data={pop[selectedStage][questionModal]}
        />}
        <DragDropContext onDragEnd={handleDrag}>
            {stages.map((item, index) => {
                return (
                    <Grid item xs={3.9} container key={index}>
                        <Card
                            sx={{
                                width: '100%',
                                minHeight: '25vh',
                                '&:hover': {
                                    backgroundColor: '#00AB551A',
                                    cursor: 'pointer'
                                },
                                bgcolor: item === selectedStage ? '#00AB5566' : 'white'
                            }}
                            onClick={() => setSelectedStage(item)}>
                            <Droppable droppableId={item}
                            // isDropDisabled={item !== selectedStage}
                            >
                                {(provided) => (
                                    <CardContent {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                textTransform: 'capitalize',
                                                wordWrap: 'break-word'
                                            }}
                                            color="text.secondary"
                                            gutterBottom>
                                            {index + 1}: {item}
                                        </Typography>
                                        <Stack>
                                            {pop[item].sequence.map((ele, index) => {
                                                const { description, days, fileKey } = pop[item][ele]
                                                return <Draggable
                                                    // isDragDisabled={item !== selectedStage}
                                                    key={ele + index}
                                                    draggableId={ele + index}
                                                    index={index}>
                                                    {(provided) => (
                                                        <Box {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            my="0.2rem"
                                                            py='0.5rem'
                                                            component={Paper} elevation={3}>
                                                            <Stack color='gray' justifyContent='center' alignItems='center' flexDirection='row'
                                                            >
                                                                <IconButton onClick={() => setActiveActivites(ele)}>
                                                                    {(description && days !== 0 && fileKey) ?
                                                                        <AssignmentTurnedInIcon color="primary" /> : <PendingActionsIcon />}
                                                                </IconButton>
                                                                <Typography mx='0.5rem'>{ele}</Typography>
                                                                <IconButton onClick={() => setQuestionModal(ele)}>
                                                                    <NoteAddIcon />
                                                                </IconButton>
                                                            </Stack>
                                                            {provided.placeholder}
                                                        </Box>)}
                                                </Draggable>
                                            })}
                                        </Stack>
                                        {provided.placeholder}
                                    </CardContent>)}
                            </Droppable>
                        </Card>
                    </Grid>)
            })}
        </DragDropContext >
    </Grid >
}