/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import GroupIcon from '@mui/icons-material/Group';
import CoPresent from '@mui/icons-material/CoPresent';
import Grain from '@mui/icons-material/Grain';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import RepeatIcon from '@mui/icons-material/Repeat';
import { Box, Grid } from '@mui/material';
import { CalenderBm } from 'src/components/@material-extend/CalenderBm';
import api from '../components/api/api';
import { CardBM } from '../components/_dashboard/app';
import { getDetailDayWise, getReSumitDayWise } from '../components/GridColumns';
import Page from '../components/Page';
import { DateModal } from '../utils/DateModal';
import { TableHeader } from '../components/TableHeaders';
import { BarBm } from '../components/charts/BarBm';
import './table.css';

export const SearchByDate = () => {
    const { state } = useLocation();
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [reWorkDetails, setReWorkDetails] = useState([]);
    const [activityHour, setActivityHour] = useState([])
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [status, setStatus] = useState({});
    const defaultDate = [new Date(), new Date()]
    const [dateFilter, setDateFilter] = useState(defaultDate)

    const onClickRow = (row) => {
        history('../farmers', {
            state: row
        });
    };

    const setDate = (date) => {
        if (date && typeof date === 'string') {
            setDateFilter([new Date(date), new Date(date) ])
            getDayWiseStatus(date, date);
        } else {
            getDayWiseStatus(dateFilter[0], dateFilter[1]);
        }
    }

    const getDayWiseStatus = async (date1, date2) => {
        setLoading(true)
        const mDate1 = DateModal(date1);
        const mDate2 = DateModal(date2);

        try {
            const { data } = await api.get(`/getStatByDateAndPartner?fromDate=${mDate1}&toDate=${mDate2}`);
            setRows(data.fieldRepByDate);
            setStatus(data.status)
            setActivityHour(data.halfHoursWiseByDate);
           // setReWorkDetails(data.reworkStatus);
           
        } catch (error) {
            setRows([])
            // alert("Failed to find data");
        }
        setLoading(false);
    }

    const toSearchField = (row) => {
        history('../SearchFieldByRep', {
            state: {
                date: dateFilter[0],
                mobile: row.phone_number,
                contractorID: row.contractorID
            }
        });
    }
    useEffect(() => {
        if (state) {
            setDate(state)
        } else {
            setDate(new Date())
        }
    }, [])

    return (
        <Page
            style={{
                display: 'flex',
                minHeight: '100%',
                flexDirection: "column"
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="ACTIVE REPS"
                        color="linear-gradient(to left, #93F9B9, #1D976C)"
                        ICONS={CoPresent}
                        count={status.activeRep}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="Number of FARMS"
                        color="linear-gradient(to left, #a8e063, #56ab2f)"
                        ICONS={Grain}
                        count={status.TotalFarm}

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="Total AREA (ACRES)"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        ICONS={AgricultureIcon}
                        count={status.totalArea}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="ONBOARDED FARM"
                        color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                        ICONS={CheckCircleOutlineSharpIcon}
                        count={status.CONFIRMED_FARM}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="RESUBMITTED FARM"
                        color="linear-gradient(to right, #b05fce, #d9e7dd)"
                        ICONS={InstallDesktopIcon}
                        count={status.resubmitCount}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <CardBM
                        label="REWORK FARM"
                        color="linear-gradient(to right, #f8b500, #fceabb)"
                        ICONS={RepeatIcon}
                        count={status.REWORK_FARM}
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    width: "100%",
                    margin: "3rem auto 1.5rem auto",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        width: "40%",
                        display: 'flex'
                    }}
                >
                    <CalenderBm date={dateFilter} setDate={setDateFilter} />
                    <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                        onClick={setDate}
                    >
                        Submit
                    </LoadingButton>
                </div>
                <TableHeader label="REP ACTIVITY" Icon={GroupIcon} />
            </div>
            <>
                {loading ? (
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}
                    >
                        <CircularProgress size={100} />
                    </Box>
                ) : rows.length !== 0 && !loading && (
                    <Box
                        style={{
                            flexGrow: 1,
                            height: '90vh',
                            justifyContent: "center",
                            margin: "1rem 0"
                        }}
                    >
                        <BarBm
                            data={activityHour} x="time" y1="Farm"
                            yLabel="Number Of Farms"
                            xaxies={false}
                        />
                        <DataGrid
                            style={{ "margin": "2rem 0" }}
                            rows={rows}
                            onError="No Farmer Added"
                            density="compact"
                            columns={getDetailDayWise(onClickRow, toSearchField)}
                            pageSize={pageSize}
                            onPageSizeChange={(a) => setPageSize(a)}
                            rowsPerPageOptions={[20, 50, 100]}
                            disableSelectionOnClick
                            components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                    </Box>
                )
                }
                <div style={{ margin: "9rem " }} />
                {reWorkDetails.length !== 0 &&
                    <Box
                        style={{
                            flexGrow: 1,
                            height: '90vh',
                            justifyContent: "center",
                            margin: "4rem 0"
                        }}
                    >
                        <TableHeader label="RESUBMIT STATUS" Icon={InstallDesktopIcon} />
                        <DataGrid
                            style={{ "margin": "2rem 0" }}
                            rows={reWorkDetails}
                            onError="No Farmer Added"
                            density="comfortable"
                            columns={getReSumitDayWise(onClickRow, toSearchField)}
                            pageSize={pageSize}
                            onPageSizeChange={(a) => setPageSize(a)}
                            rowsPerPageOptions={[20, 50, 100]}
                            getRowClassName={({ row }) =>
                                row.status === 'CONFIRMED' ? 'confirmed' : 'unconfirmed'
                            }
                            disableSelectionOnClick
                        />
                    </Box>
                }
                {rows.length === 0 && !loading &&
                    <div
                        style={{ "textAlign": 'center' }}
                    >No New Data Added</div>}
            </>
        </Page>
    );
};
