/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import axios from 'axios';
import { Box, Modal, IconButton } from '@mui/material';
import { ranchApi } from 'src/components/api';
import { UploadFile, LoadingBtnBm, TypographyBm, ButtonBm } from '../../components/@material-extend'
import Upload from './upload.svg'


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: '73%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
};

export const UploadModal = ({ setUploadKey, setPolydata, setFileName }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = async () => {
    setIsSubmitting(true);
    const getObject = true;
    const expiryTime = 3600;
    const ext = key.substr(key.length - 3);
    if (ext === "zip") {
      setFileName(key);
      setIsSubmitting(false);
      setShowModal(false);
      return
    }
    const { data } = await ranchApi.post('/getUploadUrl', { folder, expiryTime, getObject, key });

    const url = `https://2bsgng3xpltybx3wmf3iaahzum0rvkal.lambda-url.ap-south-1.on.aws/?ext=${ext}&key=${key}`;
    try {
      const { data } = await axios.get(url);
      const arr = []
      data.forEach(ele => {
        if (typeof (ele) != "object") {
          return
        }
        if (ele.length < 4) {
          return
        }
        arr.push([ele.map(ele => {
          return [ele[1], ele[0]]
        })])
      });
      console.log('====================================');
      console.log(arr.length);
      console.log('====================================');
      setPolydata(arr);
    } catch (err) {
      console.log(err);
      // showErrorSnackbar({
      //   text: 'Error while uploading file.'
      // });
    }
    setUploadKey(data.Key);
    setIsSubmitting(false);
    setShowModal(false);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const { showErrorSnackbar } = useSnakBarTran();
  const folder = 'kml';
  const [key, setKey] = useState('');
  const onUploadSucessed = async (fileLocation) => {
    setKey(fileLocation);
  };
  return (
    <>
      <IconButton
        sx={{ color: 'white' }}
        onClick={() => setShowModal(true)}
      >
        <img src={Upload} />
      </IconButton>
      <Modal
        open={showModal}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <UploadFile
            api={ranchApi}
            url='/getUploadUrl'
            onUploadSucessed={onUploadSucessed}
            folder={folder}
            fileType={['.kml', '.kmz', '.zip']}
            getObject={false}
          />
          <TypographyBm
            variant="text"
            sx={{
              textAlign: 'center',
              fontWeight: 'normal',
              color: '#6F6C90'
            }}
          >
            We accept following formats : .kml, .kmz, .shp smaller than 10 MB
            If you have a shape file, it must be a .zip file containing .shp, .dbf and .prj files
          </TypographyBm>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '30px' }}>
            <ButtonBm
              variant="contained"
              onClick={() => setShowModal(false)}
              style={{
                width: '40%',
                color: '#005249'
              }}
            >
              Cancel
            </ButtonBm>
            <LoadingBtnBm
              loading={isSubmitting}
              style={{ background: '#377DFF', width: '40%', boxShadow: 'none' }}
              onClick={() => handleClose()}
              disabled={!key}
            >
              Continue
            </LoadingBtnBm>
          </div>
        </Box>
      </Modal>
    </>
  );
};
