/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { Grid, IconButton, Paper, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SelectBm } from 'src/components/@material-extend';
import { useSnakBarTran } from 'src/hook';
import api from 'src/components/api/popApi';
import { CropsLabel } from 'src/data/PackageOfPractices/cropLabels';
import { Crops } from 'src/data/PackageOfPractices/crops';
import { CustomModal } from './CustomModal';
import { SubmitBtnPop } from './SubmitBtnPop';
import { TopExistingPop } from './TopExistingPop';

export const TopSectionPop = ({
  allCropDetails,
  setAllCropDetails,
  setSelectedPop,
  selectedPop,
  selectedCrop,
  setSelectedCrop,
  pop,
  setPop,
  submitPop
}) => {
  const [addStageModal, setAddStageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showErrorSnackbar, showSuccessSnackbar } = useSnakBarTran();
  const [addCropModal, setAddCropModal] = useState(false);

  const handleAddStage = async (newStage, stageName) => {
    try {
      if (stageName.length > 64) {
        showErrorSnackbar({ text: 'Stage Name cannot have more than 64 characters' });
        return;
      }
      stageName = stageName.replaceAll(' ', '_');
      newStage.pop();
      newStage.push(stageName);
      const stageData = {
        type: 'StageUpdate',
        stages: newStage,
        SK: selectedCrop,
        stageName
      };
      setLoading(true);
      await api.post('/pop/updatePOPdata', stageData);
      const newAllCropDetails = { ...allCropDetails };
      newAllCropDetails[selectedCrop].stages = newStage;
      newAllCropDetails[selectedCrop][stageName] = [];
      setAllCropDetails(newAllCropDetails);
      setAddStageModal(false);
      showSuccessSnackbar({ text: 'Successfuly added stage to the crop.' });
    } catch (err) {
      console.log(err);
      showErrorSnackbar({ text: 'Unable to add stage to the crop.' });
    } finally {
      setLoading(false);
    }
  };

  const handleAddCrop = async (_, cropId) => {
    try {
      const stageData = {
        SK: cropId.toString(),
        cropName: Crops[cropId],
        popId: [],
        stages: []
      };
      setLoading(true);
      await api.post('/pop/createCropData', stageData);
      showSuccessSnackbar({ text: 'Successfuly added new crop.' });
      setAddCropModal(false);
    } catch (err) {
      console.log('Error while Submitting: ', err);
      showErrorSnackbar({ text: 'Error while adding the crop.' });
    } finally {
      setLoading(false);
    }
  };

  const onAddStageForPop = (newStages) => {
    const stages = pop.stages;
    const newPop = { ...pop, stages: newStages };
    if (stages.length > newStages.length) {
      const stageSet = new Set(newStages);
      for (const stage of stages) {
        if (!stageSet.has(stage)) {
          delete newPop[stage];
          break;
        }
      }
    } else {
      const newStage = newStages.slice(-1);
      newPop[newStage] = { sequence: [] };
    }
    setPop(newPop);
  };

  return (
    <Grid
      item
      container
      spacing={2}
      component={Paper}
      elevation={5}
      sx={{
        width: '90vw',
        height: '30vh',
        padding: '2rem'
      }}
    >
      <CustomModal
        loading={loading}
        checkList={selectedCrop ? allCropDetails[selectedCrop].stages : []}
        showModal={addStageModal}
        setShowModal={setAddStageModal}
        onSubmit={handleAddStage}
        modalType="Stage"
      />
      <CustomModal
        loading={loading}
        checkList={CropsLabel}
        showModal={addCropModal}
        setShowModal={setAddCropModal}
        onSubmit={handleAddCrop}
        modalType="Crop"
        autoComplete
      />
      <Grid item container spacing={2}>
        <Grid item container width="37%">
          <Grid item>
            <Tooltip title="Add Stage" placement="top-start">
              <IconButton size="large" onClick={() => setAddCropModal(true)}>
                <AddCircleOutlineIcon fontSize="4rem" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item width="80%">
            <SelectBm
              value={selectedCrop}
              onChange={({ target }) => {
                setSelectedCrop(target.value);
                setSelectedPop('');
                setPop({ stages: [] });
              }}
              list={allCropDetails.cropList || []}
              label="Select Crop"
              containerSx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid item container width="37%">
          <Grid item>
            <Tooltip title="Add Stage" placement="top-start">
              <IconButton
                size="large"
                onClick={() => {
                  if (!selectedCrop) {
                    showErrorSnackbar({ text: 'Please select a crop.' });
                    return;
                  }
                  setAddStageModal(true);
                }}
              >
                <AddCircleOutlineIcon fontSize="4rem" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item width="80%">
            <SelectBm
              value={pop.stages}
              onChange={({ target }) => onAddStageForPop(target.value)}
              list={selectedCrop.length ? allCropDetails[selectedCrop].stages : []}
              multiple
              label="Select Stage"
              containerSx={{
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
        {selectedCrop && pop.stages.length ? (
          <SubmitBtnPop
            pop={pop}
            existingPop={
              selectedCrop && allCropDetails[selectedCrop].popId
                ? allCropDetails[selectedCrop].popId
                : []
            }
            setPop={setPop}
            submitPop={submitPop}
            selectedPop={selectedPop}
          />
        ) : null}
      </Grid>
      {selectedCrop &&
        allCropDetails[selectedCrop].popId &&
        allCropDetails[selectedCrop].popId.length ? (
        <TopExistingPop
          setSelectedPop={setSelectedPop}
          selectedCrop={selectedCrop}
          allCropDetails={allCropDetails}
          selectedPop={selectedPop}
        />
      ) : null}
    </Grid>
  );
};
