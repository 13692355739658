/* eslint-disable import/no-unresolved */
import { Navigate, useLocation } from 'react-router-dom';
import { DocumentsUplaod } from 'src/layouts/DocumentsUpload';

export const DocUpload = () => {
  const { state } = useLocation(); 
  
  if (state === null) {
    return <Navigate to="../app" replace />;
  }  

  return (
      <DocumentsUplaod id={state.SK} rId={state.PK} status={state.status} />
  );
};
