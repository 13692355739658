/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { Stack, Autocomplete, TextField } from '@mui/material';
import { InputText, LoadingBtnBm, ModalBm } from 'src/components/@material-extend';

export const CustomModal = ({
  showModal,
  modalType,
  onSubmit,
  setShowModal,
  checkList,
  loading,
  autoComplete
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  // const { showErrorSnackbar } = useSnakBarTran();

  useEffect(() => {
    if (!loading && value && !showModal) {
      setValue('');
    }
  }, [loading]);

  const validateSubmit = () => {
    if (!value) {
      setError(true);
      return;
    }
    if (checkList.includes(value)) {
      alert(`"${value}" already a ${modalType}.`)
      return;
    }

    onSubmit([...checkList, value], value);
  };

  return (
    <ModalBm
      showModal={showModal}
      title={`Please enter the name for ${modalType} to add.`}
      setShowModal={() => setShowModal(false)}
    >
      <Stack>
        {autoComplete ? (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={checkList}
            sx={{ width: 500, height: 200 }}
            onChange={(_, val) => setValue(val.id)}
            renderInput={(params) => <TextField {...params} label={modalType} />}
          />
        ) : (
          <InputText
            variant="outlined"
            placeholder={`${modalType} Name`}
            style={{
              backgroundColor: '#F1F1F1'
            }}
            errorBgColor="#fff"
            value={value}
            error={error}
            helperText={`Please enter a ${modalType} name.`}
            onChange={({ target }) => {
              if (error) {
                setError(false);
              }
              setValue(target.value);
            }}
          />
        )}
        <LoadingBtnBm
          style={{
            backgroundColor: '#004752',
            height: '100%',
            width: '25%',
            margin: 'auto',
            marginTop: 10,
            float: 'right'
          }}
          loading={loading}
          onClick={validateSubmit}
        >
          {`Add ${modalType}`}
        </LoadingBtnBm>
      </Stack>
    </ModalBm>
  );
};
