/* eslint-disable react/prop-types */
import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import FlagIcon from '@mui/icons-material/Flag';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FlakyIcon from '@mui/icons-material/Flaky';

const useStyles = makeStyles({
  headContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  eleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.8rem 0.5rem',
    marginBottom: '0.4rem',
    '& h4': {
      marginLeft: '0.5rem',
      display: 'inline',
      color: '#000'
    },
    '& h3': {
      color: '#000'
    }
  },
  subHeadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.2rem 1rem'
  },
  icons: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    color: '#7F4200'
  },
  headIcon: {
    alignSelf: 'center',
    fontSize: '3rem',
    color: '#7F4200'
  },
  subBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
});

export const FarmerUserDetail = ({ label, userDetail }) => {
  const classes = useStyles();
  if (Object.keys(userDetail).length === 0) {
    return <></>
  }
  return (
    <Paper
      elevation={4}
      sx={{
        margin: '1rem 0.5rem',
        padding: '0.6rem'
      }}
    >
      <div className={classes.headContainer}>
        <AccountBoxIcon className={classes.headIcon} />
        <h3
        >
          {label.toUpperCase()}
        </h3>
      </div>
      <div className={classes.subBody}>
        {userDetail.givenName && (
          <div className={classes.eleContainer}>
            <BadgeIcon className={classes.icons} />
            <h3>Name:</h3>
            <h4>
              {`${userDetail.givenName.toUpperCase()} ${userDetail.familyName.toUpperCase()}`}
            </h4>
          </div>
        )}
        {userDetail.fullName && (
          <div className={classes.eleContainer}>
            <BadgeIcon className={classes.icons} />
            <h3>Name:</h3>
            <h4>
              {`${userDetail.fullName.toUpperCase()}`}
            </h4>
          </div>
        )}
        {userDetail.status && (
          <div className={classes.eleContainer}>
            <FlakyIcon className={classes.icons} />
            <h3>Status:</h3>
            <h4>{userDetail.status}</h4>
          </div>
        )}
        {userDetail.phoneNumber && (
          <div className={classes.eleContainer}>
            <PhoneAndroidIcon className={classes.icons} />
            <h3>Contact:</h3>
            <h4>{userDetail.phoneNumber}</h4>
          </div>
        )}
        {userDetail.address1 && (
          <div className={classes.eleContainer}>
            <HomeWorkIcon className={classes.icons} />
            <h3>Address:</h3>
            <h4>{userDetail.address1}</h4>
          </div>
        )}

        {userDetail.town && (
          <div className={classes.eleContainer}>
            <LocationCityIcon className={classes.icons} />
            <h3>Town:</h3>
            <h4>{userDetail.town}</h4>
          </div>
        )}
        {userDetail.state && (
          <div className={classes.eleContainer}>
            <LocationOnIcon className={classes.icons} />
            <h3>State:</h3>
            <h4>{userDetail.state.replaceAll('_', ' ')}</h4>
          </div>
        )}
        {userDetail.country && (
          <div className={classes.eleContainer}>
            <FlagIcon className={classes.icons} />
            <h3>Country:</h3>
            <h4>{userDetail.country}</h4>
          </div>
        )}
      </div>
    </Paper>
  );
};
