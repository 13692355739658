/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Box, Typography } from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { GoogleMap, MultiPolygon, MapCenter } from '../Map';

export const Map = ({ coor, height, label }) => {
  if (!coor) {
    return <h5>Loading...</h5>;
  }

  return (
    <Paper
      sx={{
        padding: '1rem'
      }}
      elevation={10}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '1rem'
        }}
      >
        <FmdGoodIcon color="primary" fontSize="large" />
        <Typography
          sx={{
            marginTop: '4px',
            marginLeft: '4px'
          }}
        >
          {label || 'Polygon'}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: height || '50vh'
        }}
      >
        <GoogleMap>
          {coor.length && <MultiPolygon coordinates={[{ coor }]} />}
          <MapCenter />
        </GoogleMap>
      </Box>
    </Paper>
  );
};
