/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { saveAs } from 'file-saver';
import { Grid, ListItem,ListItemText as ListItemTextBm } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { ranchApi } from 'src/components/api';
import { ButtonBm } from "src/components/@material-extend";
import palette from 'src/theme/palette';

const useStyle = makeStyles(() => ({
    link: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: '#007AFF'
        }
    }
}));

export const DocCard = ({ primary, secondary, onEdit, onDelete, loc, status }) => {
    const classes = useStyle();

    const Download = async () => {
        try {
            // showInfoSnackbar({ text: 'Downloading' })
            const locArray = loc.split('/');
            console.log(locArray);
            const { data } = {}
            await ranchApi.post('/getUploadUrl', { folder: locArray[0], expiryTime: 300, getObject: true, key: loc });
            saveAs(data.uploadURL, primary);
        } catch (error) {
            // showErrorSnackbar({ text: 'Failed to downlaod' });
            console.log(22);
        }
    }

    return <ListItem
        sx={{
            bgcolor: "#fff",
            marginBottom: "0.2rem",
            padding: '1rem',
            backgroundColor: palette.grey[200],
        }}
    >
        <ListItemTextBm
            className={classes.link}
            onClick={Download}
            primary={primary}
            secondary={secondary}
            sx={{
                width: '70%',
                overflow: 'scroll'
            }} />
        {status !== 'CONFIRMED' ?
            <Grid>
                <ButtonBm sxExtra={{
                    marginRight: '1rem'
                }}
                    onClick={onEdit}
                >
                    Edit
                </ButtonBm>
                <ButtonBm color="error"
                    onClick={onDelete}
                >
                    Delete
                </ButtonBm>
            </Grid> : null
        }
    </ListItem>
}