/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2, }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export const ModalBm = (
    { children,
        title,
        showModal,
        setShowModal, title2, contentSx, noDivder, ...other }) => <BootstrapDialog
            onClose={setShowModal}
            open={showModal || false}
            maxWidth="sm"
            {...other}
            fullWidth
        >
        <BootstrapDialogTitle onClose={setShowModal}>
            {(title)} {title2}
        </BootstrapDialogTitle>
        <DialogContent dividers={!noDivder} sx={contentSx}>
            {children}
        </DialogContent>
    </BootstrapDialog>

