/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { Box, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { SelectBm, ModalBm } from '../components/@material-extend';
import api from '../components/api/popApi';
import { getSurveyTableColumns } from '../components/GridColumns';

export const SurveyPage = () => {
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [listSurvey, setListSurvey] = useState([]);
  const [surveyDetails, setSurveyDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  useEffect(() => {
    const getData = async () => {
      try{
        const { data } = await api.get('/getSurveyDet');
        const filterList = data.map((item) => ({ label: item.surveyName, value: item.PK }));
        setListSurvey(filterList);
      }catch(err){
        alert("Unable to fetch data.")
      }
    };
    getData();
  }, []);

  const onSurveySelect = async (event) => {
    setLoading(true);
    try{
      setSelectedSurvey(event.target.value);
      const { data } = await api.get(`/getSurveyDet?PK=${event.target.value}`);
      const tempData = data.map((item, index) => ({ ...item, id: index }));
      setSurveyDetails(tempData);
    }catch(err){
      alert("Unable to fetch data.")
    }finally{
      setLoading(false);
    }
  };

  const onClickRow = (row) => {
    setCurrentRow(row);
    setShowModal(true);
  };
  
  return (
    <Box>
      <Typography variant="h4" style={{ margin: 10 }}>Survey Page</Typography>
      <ModalBm
        showModal={showModal}
        setShowModal={() => setShowModal(!showModal)}
        title="Survey Answers"
      >
        {currentRow
          ? Object.keys(currentRow?.questionAndAnswer).map((item, index) => (
              <Typography key={index}>
                <strong>{item} : </strong>
                {currentRow?.questionAndAnswer[item]}
              </Typography>
            ))
          : null}
      </ModalBm>
      <SelectBm
        size="medium"
        label="Select Survey"
        fullWidth
        value={selectedSurvey}
        onChange={onSurveySelect}
        sx={{
          bgcolor: '#F1F1F1',
          borderRadius: 1,
          marginBottom: 5
        }}
        list={listSurvey}
      />
      <Grid
        item
        style={{
          flexGrow: 1,
          height: '75vh',
          alignSelf: 'flex-end'
        }}
      >
        <DataGrid
          loading={loading}
          rows={surveyDetails || []}
          columns={getSurveyTableColumns(onClickRow)}
          pageSize={100}
          disableSelectionOnClick
        />
      </Grid>
    </Box>
  );
};
