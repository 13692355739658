/* eslint-disable react/prop-types */
import { Box, Grid } from '@mui/material';
import { TypographyBm } from '../TypographyBm';
import { HeaderDivider } from '../ConserationRanch/HeaderDivider';
import { InputText } from '../../../components/@material-extend/InputText';

const years = ["2022", "2021", "2020", "2019", "2018", "2017"]
export const LSSectionB = ({ allValue, handleChange, disabled }) => {


  const onChange = ({ target }) => {
    const newValue = { ...allValue };

    const { value, name } = target;

    newValue[name] = value;
    handleChange(newValue)
  }

  return <Grid item container>
    <HeaderDivider title="Livestock number" />
    <div style={{
      width: '80%',
    }}>
      <TypographyBm sx={{ paddingLeft: '12%', paddingTop: '3%', fontSize: '18px', fontWeight: '300', fontFamily: 'DM Sans' }} >
        Please mention livestock popluation of the ranch in the following years?
      </TypographyBm>
    </div>
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        columnGap: '1vw',
        paddingLeft: '15%',
        paddingTop: '2%'
      }}
    >
      {years.map(ele => (
        <InputText
          disabled={disabled}
          fullWidth={false}
          key={ele}
          sx={{
            bgcolor: '#f1f1f1',
            width: '8vw',
            borderRadius: 1
          }}
          name={ele}
          value={allValue[ele] || ""}
          label={ele}
          onChange={onChange}
          type='number'
        />
      ))}
    </Box>

  </Grid>
};
