/* eslint-disable react/prop-types */
import { Practises } from './Practises';
import { Electricity } from './Electricity';
import { HeaderDivider } from '../ConserationRanch/HeaderDivider'
import { DynamicFields } from '../ConserationRanch/DynamicFields';

const herdRequiredFields = [
  { name: 'Herd', id: 'hear', xs: 3.5 },
  { name: 'Grazing Days', id: 'gd', xs: 2 },
  { name: 'Rest Days', id: 'rd', xs: 2 }
];

export const ManagementRanch = ({ value, handleChange, disabled }) => {

  const onChange = (newValue, name) => {
    if (disabled) {
      return
    }
    const allValue = { ...value };
    allValue[name] = newValue;

    handleChange({ ...allValue })


  }

  return <div
    style={{
      marginBottom: "3rem"
    }}
  >
    <HeaderDivider title="Herd Details" />
    <DynamicFields
      disabled={disabled}
      fields={value.herds || [{
        hear: '',
        gd: '',
        rd: ''
      }]}
      requiredFields={herdRequiredFields}
      setFields={(v) => onChange(v, 'herds')}
    />
    <HeaderDivider title="Practices" />
    <Practises
      disabled={disabled}
      practices={value.practices || {}}
      setPractices={(v) => onChange(v, 'practices')}
    />
    <HeaderDivider title="Electricity" />
    <Electricity
      disabled={disabled}
      electricity={value.electricity || {}}
      setElectricity={(v) => onChange(v, 'electricity')}
    />
  </div>

}
