import { useEffect, useState } from 'react';
import { Grid, Container, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import GrassIcon from '@mui/icons-material/Grass';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GrainIcon from '@mui/icons-material/Grain';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import Page from '../components/Page';
import { CardBM } from '../components/_dashboard/app';
import { homeAction } from '../redux/actions/homeAction';
import { ComplexChartBm } from '../components/charts/ComplexChart';
import { PieBM } from '../components/charts/PieChart';
import { getDaysArray } from '../utils/DateModal';
import { BarBm } from '../components/charts/BarBm';
import { TableHeader } from '../components/TableHeaders';
import { CustomTooltip } from '../components/_dashboard/app/CustomTooltip';

let name = "";
export default function DashboardApp() {
  const navigate = useNavigate();
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const [rep, setRep] = useState({});
  const { user, isAuthenticated } = store.userRoot;

  useEffect(() => {
    const { home } = store.homeDetail;

    if (isAuthenticated && !home.body) {
      dispatch(homeAction(user, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const { home } = store.homeDetail;
    const { user } = store.userRoot;
    name = user.userDetails.givenName || "";
    if (home && home.dayWiseEntry && home.dayWiseEntry.length !== 0) {
      home.dayWiseEntry = getDailyWiseEntryWithBuffer(home.dayWiseEntry)
    }
    setRep(home);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.homeDetail, store.userRoot]);

  const onAreaClick = (a) => {
    navigate("../SearchbyDate", {
      state: a.payload.date
    })
  }

  const defaultData = (data) => (
    {
      "Total Farms": 0,
      "ONBOARDED": 0,
      "RECEVIED": 0,
      "REWORK": 0,
      "VERIFIED": 0,
      "RE_SUB": 0,
      date: data
    }
  )

  const getDailyWiseEntryWithBuffer = data => getDaysArray(data, defaultData, "date");


  const barAreaClick = (row) => {
    const url = window.location.href;
    const newUrl = url.replace('app', 'farmers')
    const queryUrl = `/?name=${`${row.name}`}&id=${row.id}&pid=${user.partnerID}`
    window.open(newUrl + queryUrl, '_blank')
  }

  const greet = () => {
    const today = new Date()
    const curHr = today.getHours()

    if (curHr < 12) {
      return 'Good morning'
    }
    if (curHr < 18) {
      return 'Good afternoon'
    }
    return 'Good evening'
  }

  return (
    <Page title="Dashboard || REPS">
      <Container maxWidth="xl">
        <Stack sx={{ mb: 5 }}>

          <Typography variant="h4" gutterBottom>
            {greet()}, {name && name[0].toUpperCase() + name.slice(1)}
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <CardBM
              ICONS={CoPresentIcon}
              label="NUMBER OF REPS"
              count={rep.totalRep}
              color="linear-gradient(to right, #4ca1af, #c4e0e5)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardBM
              ICONS={AgricultureIcon}
              label="NUMBER OF FARMERS"
              count={rep.totalFarmer}
              color="linear-gradient(to right, #f8b500, #fceabb)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardBM
              label="NUMBER OF FARMS"
              count={rep.totalFarm}
              color="linear-gradient(to left, #93F9B9, #1D976C)"
              ICONS={GrainIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardBM
              ICONS={GrassIcon}
              label="TOTAL AREA (ACRES)"
              color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
              count={rep.totalArea}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TableHeader label="DAILY TRACK ON REPS" Icon={AutoGraphIcon} />
            <ComplexChartBm
              CustomTooltip={CustomTooltip}
              key1="TOTALFARM"
              key2="ONBOARDED"
              key3="VERIFIED"
              key4="RECEVIED"
              key5="REWORK"
              key6="RE_SUB"
              name="date"
              data={rep.dayWiseEntry}
              onClick={onAreaClick}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TableHeader label="FARM STATUS" Icon={DonutLargeIcon} />
            <PieBM
              data={[
                {
                  status: 'ONBOARDED',
                  FARM: +rep.onboardedFarm
                },
                {
                  status: 'VERIFIED',
                  FARM: +rep.verifiedFarm
                },
                {
                  status: 'RECEVIED',
                  FARM: +rep.receivedFarm
                },
                {
                  status: 'REWORK',
                  FARM: +rep.reworkFarm
                },
                {
                  status: 'RE_SUB',
                  FARM: +rep.re_subFarm
                }
              ]}
              nameKey="status"
              dataKey="FARM"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <TableHeader label="AREA STATUS" Icon={DonutLargeIcon} />
            <PieBM
              data={[
                {
                  status: 'ONBOARDED',
                  ACRES: rep.onboardedArea
                },
                {
                  status: 'VERIFIED',
                  ACRES: rep.verifiedArea
                },
                {
                  status: 'RECEVIED',
                  ACRES: rep.receivedArea
                },
                {
                  status: 'REWORK',
                  ACRES: rep.reworkArea
                },
                {
                  status: 'RE_SUB',
                  ACRES: rep.re_subArea
                }
              ]}
              nameKey="status"
              dataKey="ACRES"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TableHeader label="FARM STATUS ON 15 ACTIVE REPS" Icon={LeaderboardIcon} />
            <BarBm
              data={rep.activeRepStat}
              x="name"
              y1="ONBOARDED"
              y2="VERIFIED"
              y3="RECEIVED"
              y4="REWORK"
              y5="RE_SUB"
              yLabel="Number Of Farms"
              barAreaClick={barAreaClick} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
