/* eslint-disable react/prop-types */
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CancelIcon from '@mui/icons-material/Cancel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FlakyIcon from '@mui/icons-material/Flaky';
import GrassIcon from '@mui/icons-material/Grass';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Box, Grid, IconButton, Paper } from '@mui/material';
import { red } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { getUniqueComments } from '../../utils/common';
import { getImagesURL } from '../../utils/getURL';
import { DetailEle } from '../DetailEle';
import { Chips } from '../RenderChip';
import { TableHeader } from '../TableHeaders';
import { ImagesBM } from '../imagesBM';
import css from './farmerDetails.css';

export const FarmerDetails = ({ row, handelModal }) => {
  const classes = css();
  const [idProof, setIdProof] = useState([]);
  const [signature, setSignature] = useState([]);
 const allReworkComments = row?.farmerReworkComments.UNRESOLVED;
 const oldCommentsArr = row.farmerInternalComments
   ? `${row.farmerInternalComments}`
       ?.trim()
       ?.split('.')
       .map((comment) =>
         comment !== 'null'
           ? {
               comment,
               status: ''
             }
           : null
       )
   : [];

  useEffect(() => {
    const getUrl = async () => {
      const newidProof = await getImagesURL([row.id_photo_path]);
      const newsignature = await getImagesURL([row.signature_photo_path]);
      setIdProof(newidProof);
      setSignature(newsignature);
    };
    getUrl();
  }, [row]);

  return (
    <Paper className={classes.Container}>
      <Grid container justifyContent="space-between">
        <IconButton onClick={handelModal}>
          <CancelIcon sx={{ color: red[500] }} fontSize="large" />
        </IconButton>
        <TableHeader label="FARMER DETAILS" Icon={PermIdentityIcon} />
        <Grid item />
      </Grid>
      <Grid container justifyContent="space-around">
        <DetailEle label="Farmer Status" Icon={FlakyIcon} value={row.farmerStatus} />
        <DetailEle
          label="Onboarded On"
          Icon={DoubleArrowIcon}
          value={new Date(row.farmerCreatedAt).toDateString().slice(4)}
        />
      </Grid>
      <Box border={1} className={classes.Box}>
        <div className={classes.row}>
          <DetailEle
            label="Name"
            Icon={AccountBoxIcon}
            value={`${row.farmerGivenName} ${row.farmerFamilyName}`}
          />
          <DetailEle label="Mobile Number" Icon={LocalPhoneIcon} value={row.farmerPhoneNumber} />
          <DetailEle label="Farm Area In Acre" Icon={GrassIcon} value={row.landArea} />
          <DetailEle
            label="Address"
            Icon={LocationOnIcon}
            value={`${row.farmerAddress}, ${row.farmerTown}, ${row.farmerState}, ${row.farmerCountry}`}
          />
        </div>
      </Box>
      {Boolean(allReworkComments?.length || oldCommentsArr.length) && (
        <Box border={1} className={classes.Box}>
          <TableHeader
            label="Boomitra Comments"
            style={{
              alignSelf: 'flex-start'
            }}
            Icon={InsertCommentIcon}
          />
          <Chips dataArray={getUniqueComments(allReworkComments, oldCommentsArr)} />
        </Box>
      )}
      <Box>
        <h3 style={{ textAlign: 'center' }}>Id Proof</h3>
        {idProof.map((ele, index) => (
          <div
            key={index}
            style={{
              border: '1rem solid black',
              overflow: 'scroll'
            }}
          >
            <ImagesBM url={ele} />
          </div>
        ))}
      </Box>
      <Box>
        <h3 style={{ textAlign: 'center' }}>Signature</h3>
        {signature.map((ele, index) => (
          <div
            key={index}
            style={{
              border: '1rem solid black',
              overflow: 'scroll'
            }}
          >
            <ImagesBM url={ele} />
          </div>
        ))}
      </Box>
    </Paper>
  );
};
