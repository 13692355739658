/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Box, Paper, Tabs, Tab, Grid, Typography } from '@mui/material';
import { getFarmerColumn, SearchFarmerKey } from '../components/GridColumns';
import { farmerAction } from '../redux/actions/farmerAction';
import { QuickSearchToolbar } from '../components/QuickSearch';
import './table.css';

let allFarmerDetails = [];
const mapping = {
  ALL: 'ALL',
  ONBOARDED: 'ONBOARDED',
  RE_SUB: 'RE_SUB',
  RECEIVED: 'RECEIVED',
  REWORK: 'REWORK',
  VERIFIED: "VERIFIED"
}
const color = {
  RECEIVED: 'rgba(247, 181, 38, 1)',
  ONBOARDED: 'rgba(135, 196, 94, 1)',
  VERIFIED: 'rgba(135, 196, 94, 0.3)',
  REWORK: 'rgba(61, 176, 222, 1)',
  ALL: 'rgba(4, 39, 41, 1)',
  RE_SUB: '#826AF9',
};
export const FarmerTables = () => {
  const urlQuery = new URLSearchParams(window.location.search);
  const [stat, setStat] = useState({});
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [tabs, setTabs] = useState('ALL');

  const onClickRow = (row) => {
    const url = window.location.pathname;
    const newUrl = url.replace('farmers', 'field')
    console.log(url);
    const queryUrl = `?id=${row.id}&pid=${urlQuery.get('pid')}`
    window.open(newUrl + queryUrl, '_blank')
  }

  useEffect(() => {
    const { farmer } = store.farmer;
    if (!farmer) {
      dispatch(farmerAction(urlQuery.get('pid'), urlQuery.get('id')));
    }

  }, [dispatch, urlQuery.get('pid')]);


  useEffect(() => {
    const { farmerByCon, loading } = store.farmer;
    if (loading) {
      setLoading(true);
      return
    }
    if (farmerByCon.fieldData) {
      allFarmerDetails = farmerByCon.fieldData;
      setRows(farmerByCon.fieldData);
      setStat(farmerByCon.statusStat)
      setLoading(false);
    }
  }, [store.farmer, store.farmer.loading]);


  if (!urlQuery.get('pid')) {
    return <Navigate to='../app' replace />
  }

  function escapeRegExp(value) {
    value = value.toLowerCase();
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (!searchValue || searchValue === '' || searchValue.length === 0) {
      setRows([...allFarmerDetails]);
      return;
    }
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = allFarmerDetails.filter((row) =>
      SearchFarmerKey.some((field) => searchRegex.test(row[field].toString().toLowerCase()))
    );
    if (filteredRows.length === 0) {
      setRows([...allFarmerDetails]);
    }
    setRows(filteredRows);
  };

  const filterByStatus = (status) => {
    setLoading(true);
    if (status === "ALL") {
      setRows([...allFarmerDetails]);
      setLoading(false);
      return;
    }
    if (status === 'Verified by farmer') {
      const fieldData = allFarmerDetails.filter(ele => ele.verified === 'Verified');
      setRows([...fieldData]);
      setLoading(false);
      return;
    }

    const fieldData = allFarmerDetails.filter(ele => ele.status === status);
    setRows([...fieldData]);
    setLoading(false);
  }


  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem 2.5rem'
      }}
      container
    >
      <Paper
        style={{
          flexGrow: 1,
          alignSelf: 'flex-end',
        }}
      >
        <Typography sx={{
          fontWeight: 'bold',
          marginTop: '2rem',
          marginBottom: '1rem'
        }}>
          Fields (REP : {urlQuery.get('name')})
        </Typography>

        <Paper sx={{ width: '100%', bgcolor: '#F4F6F8', padding: '1rem' }} >
          <Tabs value={tabs}
            onChange={(_, v) => {
              if (loading) {
                return
              }
              filterByStatus(v)
              setTabs(v)
            }} >
            {Object.keys(mapping).map(ele =>
              <Tab label={ele} key={ele} value={ele} icon={<Typography bgcolor={color[ele]} padding={0.8} sx={{ borderRadius: 0.5, fontWeight: 600 }} color={ele === 'Verified by farmer' ? 'black' : 'white'}>{(stat[ele]) && stat[ele]}  </Typography>} iconPosition='start' />
            )}
          </Tabs>
        </Paper>
        <Grid item sx={{
          height: '73vh',
        }}>
          <DataGrid
            components={{
              Toolbar: QuickSearchToolbar,
              NoRowsOverlay: () =>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}>No Field</Box>
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1
              }
            }}
            rows={rows || []}
            loading={loading}
            columns={getFarmerColumn(onClickRow)}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch('')
              }
            }}
            disableSelectionOnClick
          />
        </Grid>
      </Paper>
    </Grid>
  );
};
