/* eslint-disable react/prop-types */
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from './TabPannel';


export const TabsBM = ({ labelComponents, fullWidth, value, handleChange, ...other }) => {



  if (!labelComponents) {
    return <>None</>;
  }

  return (
    <Box sx={{ width: '100%' }} {...other}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value} onChange={(_, value) => handleChange(value)} variant={fullWidth ? 'fullWidth' : 'standard'}>
          {labelComponents.map((ele, index) => (
            <Tab
              label={(ele.label)}
              sx={{
                fontSize: '0.9rem',
                fontWeight: 700,
                fontFamily: 'Figtree',
                color: '#6B705C'
              }}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {labelComponents.map((ele, index) => (
        <TabPanel value={value} index={index} key={index} style={ele.sx} >
          {ele.Component}
        </TabPanel>
      ))}
    </Box>
  );
};
