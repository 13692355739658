/* eslint-disable no-useless-return */
/* eslint-disable prefer-const */
/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { latLngBounds } from "leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import l from 'leaflet'

export const MapCenter = ({ center, cancel }) => {
    let latitude = localStorage.getItem('latitude');
    let longitude = localStorage.getItem('longitude');
    const map = useMap()

    useEffect(() => {
        l.control.zoom({ position: 'bottomright' }).addTo(map);

    }, [])

    useEffect(() => {

        if (cancel) {
            return;
        }

        if (latitude && !center) {
            map.setView({ lat: latitude, lng: longitude })
            map.setZoom(20)
            return
        }

        if (center) {
            map.fitBounds([latLngBounds({ lat: latitude, lng: longitude })]);
            return;
        }

    }, [latitude, longitude, center])

    return <FullscreenControl
        forceSeparateButton
        position="bottomright"
        content="<p>--></p>"
    />;
}