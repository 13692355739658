/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/prop-types */
import { useEffect, } from "react";
import { useMap } from "react-leaflet";
import l, { latLngBounds , polygon } from "leaflet";


export const MultiPolygon = ({ coordinates, color, noPop, refresh }) => {
    const map = useMap();

    useEffect(() => {
        map.eachLayer(function (layer) {
            if (layer instanceof l.Polygon && !(layer instanceof l.Rectangle)) {
                layer.remove()
            }
        });
    }, [coordinates, refresh])

    useEffect(() => {
        if (!coordinates || !coordinates.length) {
            return;
        }
        let parsedCoordinates = coordinates;

        if (typeof coordinates === 'string') {
            parsedCoordinates = JSON.parse(coordinates);
        }
        const allCoor = [];
        for (let coor of parsedCoordinates) {
            coor[0].forEach((ele) => {
                allCoor.push(ele);
            });
            polygon(coor[0]).setStyle({ color: color || "white" }).addTo(map).on("click", function () {
                if (noPop) {
                    return
                }
                const bbox = latLngBounds(coor[0]);
                map.fitBounds(bbox);
            });
        }
        const bbox = latLngBounds(allCoor);
        map.fitBounds(bbox);
    }, [coordinates])

    return null
}
