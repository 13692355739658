/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/prop-types */
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Grid, Box } from '@mui/material';
import { TypographyBm } from '../TypographyBm';
import { InputText } from '../../../components/@material-extend/InputText';

export const MultipleHeadingAndTextField = ({
  value,
  heading,
  noOfFields,
  setFields, dash, disabled }) => {
  const size = {
    small: 2.2,
    medium: 3.5,
    large: 6
  };


  return (
    <Box sx={{ flexGrow: 1, paddingLeft: '15%', paddingTop: '3%' }}>
      <Grid container spacing={5}>
        <Grid item >
          <TypographyBm color="#404D61" width="18vw" fontWeight={500} fontSize={17} fontFamily='DM Sans'>
            {heading}
          </TypographyBm>
        </Grid>

        {noOfFields &&
          noOfFields.map((item, index) => {

            let xs = size[item.size]
            if (index === 0 && dash) {
              xs += 0.7;
            }

            return (
              <Grid item xs={xs} key={index} display='flex'>
                <InputText
                  disabled={disabled}
                  rows={item.size === 'large' ? 2 : 1}
                  id={item.id}
                  name={item.id}
                  value={value[item.id] || ''}
                  label={item.label || ''}
                  onChange={(e) => {
                    setFields(e.target.value, e.target.name);
                  }}
                  sx={{
                    bgcolor: '#F1F1F1',
                    borderRadius: 1
                  }}
                  unit={item.unit}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={item.type}
                />
                {
                  index === 0 && dash && <HorizontalRuleIcon sx={{
                    margin: 'auto 0rem auto 2rem'
                  }} />
                }
              </Grid>
            )
          })}
      </Grid>
    </Box>
  );
};
