/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import l from 'leaflet'
import { useMap } from 'react-leaflet';
import { drawLocal } from './drawLocal';
import "@geoman-io/leaflet-geoman-free";
import { polyIntersecting } from 'src/utils/polyIntersecting';
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

export const DrawControl = ({ setCoor }) => {
    const map = useMap();

    const getAllCoor = (newPoly, layer) => {
        const allCoor = [];
        map.eachLayer(function (layer) {
            if (layer instanceof l.Polygon && !(layer instanceof l.Rectangle)) {
                const newCoor = layer.toGeoJSON().geometry.coordinates[0].map(ele => [ele[1], ele[0]]
                )
                allCoor.push([newCoor]);
            }
        });

        if (newPoly && polyIntersecting(newPoly, allCoor, true)) {
            map.removeLayer(layer);
            alert('Invalid Polygon')
            return;
        }
        setCoor([...allCoor]);
    }

    const onCreated = ({ layer }) => {
        const newCoor = layer.toGeoJSON().geometry.coordinates[0].map(ele => [ele[1], ele[0]]
        )
        getAllCoor(newCoor, layer)
    }


    useEffect(() => {
        const leafletContainer = map;

        leafletContainer.pm.setLang( drawLocal('ranch'))

        leafletContainer.pm.Toolbar.changeControlOrder(['Draw Polygons', 'removalMode'])

        leafletContainer.pm.addControls({
            drawMarker: false,
            position: "bottomright",
            drawCircleMarker: false,
            drawRectangle: false,
            cutPolygon: false,
            editPolygon: false,
            editable: false,
            drawText: false,
            drawCircle: false,
            dragMode: false,
            drawPolyline: false,
            rotateMode: false,
        });

        leafletContainer.pm.setGlobalOptions({ pmIgnore: false, allowSelfIntersection: false });

        leafletContainer.on("pm:create", (e) => {
            if (e.layer && e.layer.pm) {
                const shape = e;
                onCreated({ layer: shape.layer })

            }
        });

        leafletContainer.on("pm:remove", () => {
            getAllCoor()
        });

        return () => {
            leafletContainer.pm.removeControls();
            leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
        };
    }, [map]);

    return null;
} 