/* eslint-disable arrow-body-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useState } from "react";
import {
    Grid, Paper, Typography,
    Tooltip, IconButton, Stack,
    ListItem,
    ListItemText,
    Checkbox,
    ListItemButton,
    ListItemIcon,
    List
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import api from 'src/components/api/popApi';
import { useSnakBarTran } from 'src/hook';
import { CustomModal } from '../CustomModal';

export const Activites = ({
    activitesList, selectedStage,
    selectedCrop, allCropDetails,
    pop, setPop,
    setAllCropDetails }) => {
    const [addActiviteModal, setAddActiviteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { showErrorSnackbar, showSuccessSnackbar } = useSnakBarTran();

    if (!selectedStage) {
        return null
    }
    const handleAddActivite = async (newActivites, newActivity) => {
        try {
            if (newActivity === selectedStage) {
                showErrorSnackbar({ text: 'Stage name and activity cannot be same.' });
                return
            }
            setLoading(true)
            const activityData = {
                type: 'ActivityUpdate',
                activities: newActivites,
                SK: selectedCrop,
                stageName: selectedStage
            };
            await api.post('/pop/updatePOPdata', activityData);
            const newAllCropDetails = { ...allCropDetails };
            newAllCropDetails[selectedCrop][selectedStage] = newActivites;
            setAllCropDetails(newAllCropDetails)
            setAddActiviteModal(false)
            showSuccessSnackbar({ text: 'Successfuly added new activity.' });
        } catch (error) {
            console.log(error);
            showErrorSnackbar({ text: 'Unable to add new activity.' });
        } finally {
            setLoading(false);
        }
    }
    const handleToggle = (ele) => {
        const newSequence = new Set(pop[selectedStage].sequence);
        const newPop = { ...pop };
        if (!newSequence.has(ele)) {
            newPop[selectedStage].sequence.push(ele);
            newPop[selectedStage][ele] = { description: "", days: 0, fileKey: '' }
        } else {
            delete pop[selectedStage][ele];
            const sequence = newPop[selectedStage].sequence;
            const indexDeleteEle = sequence.indexOf(ele);
            if (indexDeleteEle !== -1) {
                sequence.splice(indexDeleteEle, 1);
            }
            newPop[selectedStage].sequence = sequence;
        }
        setPop(newPop);
    }


    return <Grid item container component={Paper} elevation={5} xs={3.5} padding="1.5rem 1rem" >
        <CustomModal
            loading={loading}
            checkList={selectedStage ? activitesList[selectedStage] : []}
            showModal={addActiviteModal}
            setShowModal={setAddActiviteModal}
            onSubmit={handleAddActivite}
            modalType="Activity" />
        <Stack width='20vw'>
            <Typography variant="h6" marginLeft='0.5rem' textAlign='center' >
                List Of Activites
            </Typography>
            <Tooltip title="Add Activite" placement="top-start" >
                <IconButton onClick={() => {
                    setAddActiviteModal(true)
                }}>
                    <AddCircleOutlineIcon fontSize='2rem' />
                    <Typography variant="button">
                        Add Activite
                    </Typography>
                </IconButton>
            </Tooltip>
            <List>
                {pop[selectedStage] && activitesList[selectedStage].map((ele, index) => {
                    return <ListItem 
                        key={index}
                        divider
                        sx={{ boxShadow: 1, p: 0, m: 1 }}
                    >
                        <ListItemButton onClick={() => handleToggle(ele)} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={pop[selectedStage].sequence.indexOf(ele) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={ele} />
                        </ListItemButton>
                    </ListItem>
                })}
            </List>
        </Stack>
    </Grid>
}