import axios from "axios";

const url = {
    local: 'http://localhost:3000/dev',
    dev: 'https://527ubrmx6a.execute-api.us-east-2.amazonaws.com/dev/',
    prod: 'https://aiqxr29ww8.execute-api.us-east-2.amazonaws.com/prod/',
    prodTest: 'https://cg7iceiqt7.execute-api.us-east-2.amazonaws.com/prod-test/'
}

export default axios.create({ baseURL: url.prod })
