/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import GrassIcon from '@mui/icons-material/Grass';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import api from "../components/api/api";
import { AutocompleteBm, BasicSelect, CircularProgessBm } from "../components/@material-extend";
import { CardBM } from '../components/_dashboard/app';
import { getDaysArray, DateModal } from '../utils/DateModal';
import { TableHeader } from '../components/TableHeaders';
import { ComplexChartBm } from '../components/charts/ComplexChart';
import { getVerifiedRepWise, getPartnerWiseVeri } from "../components/GridColumns";

export const FarmStatus = () => {
    const [partner, setPartner] = useState("ALL");
    const [allPartner, setAllPartner] = useState(["loading"])
    const [allProject, setAllProject] = useState(["loading"]);
    const [tableLoader, setTableLoader] = useState(true);
    const [detail, setDetail] = useState();
    const [rows, setRows] = useState([]);
    const [leader, setLeader] = useState('all')
    const [activeDate, setActiveDate] = useState('');
    const [allLeader, setAllLeader] = useState([]);
    const [project, setProject] = useState("ALL")

    const onProjectChange = (_, value) => {
        setProject(value)
        setAllLeader([])
        setPartner('ALL')
        getActive("ALL", undefined, value)
        getRepWiseVeri(undefined, undefined, undefined, value)
    }

    useEffect(() => {
        getPartner()
        getActive("ALL")
        getRepWiseVeri("ALL")
    }, [])

    const getPartner = async () => {
        try {
            const { data } = await api.get("/getallpartner")
            const alltempPartner = [];
            const allTempProject = ['ALL'];
            const { partners, projects } = data;
            partners.sort((a, b) => +a.id - +b.id)

            alltempPartner.push("ALL");
            partners.forEach((ele) => {
                alltempPartner.push(`${ele.id} - ${ele.name}`);
            });

            projects.forEach(({ id, label }) => {
                allTempProject.push(`${id} - ${label}`)
            })

            setAllProject(allTempProject)
            setAllPartner(alltempPartner);
        } catch (e) {
            console.log(e);
        }
    }
    const getRepWiseVeri = async (partner, date, leader, project) => {
        try {
            setRows([])
            setTableLoader(true);
            let query = "?";
            if (project || partner !== "ALL") {
                if (project && project !== "ALL") {
                    query += `project=${project.slice(0, 4)}`
                }
                if (partner && partner !== "ALL") {
                    const id = partner.slice(0, 4);
                    query += `partnerId=${id}`
                }
                if (date) {
                    query += `&date=${date}`
                }
                if (leader && leader !== 'all') {
                    query += `&leaderID=${leader}`
                }
            } else if (partner === "ALL") {
                query += `partnerId=ALL`
                if (date) {
                    query += `&date=${date}`
                }
            }
            const { data } = await api.get(`/getStatVerifiedFarm${query}`)
            setRows([...data])
        } catch (error) {
            console.log(error);
            alert('error')
        } finally {
            setTableLoader(false);
        }
    }
    const getActive = async (partner, leader, project) => {
        try {
            let query = "";
            setDetail()
            if (partner !== "ALL") {
                const id = partner.slice(0, 4);
                query = `?id=${id}`
                if (leader && leader !== 'all') {
                    query += `&leaderId=${leader}`
                }
            } else if (project) {
                query = `?project=${project.slice(0, 4)}`
            }
            const { data } = await api.get(`/getVerifiedFarmStat${query}`);
            data.timeSeries = getDailyWiseEntryWithBuffer(data.timeSeries);
            setDetail(data)
            setAllLeader([...data.leaders])
        } catch (error) {
            console.log(error);
        }
    }

    const onChange = async (_, value) => {
        if (!value) {
            setPartner("");
            return
        }
        setActiveDate('')
        setRows([])
        await getActive(value)
        await getRepWiseVeri(value)
        setPartner(value)
    }

    const defaultData = (data) => (
        {
            "VerifiedFields": 0,
            ONBORADEDON: data,
            "VerifiedArea": 0
        }
    );

    const onChangeLeaderId = async (value) => {
        setLeader(value);
        setActiveDate('');
        await getActive(partner, value)
        await getRepWiseVeri(partner, undefined, value)
    }

    const getDailyWiseEntryWithBuffer = (data) => getDaysArray(data, defaultData, "ONBORADEDON")

    const objToArry = () => {
        const res = []
        if (!detail?.leaders.length) {
            return res;
        }
        detail?.leaders.forEach((ele) => {
            res.push(ele.leaderID)
        })


        return res
    }

    const onAreaClick = (a) => {
        const { payload } = a;
        setActiveDate(payload.ONBORADEDON)
        getRepWiseVeri(partner, DateModal(payload.ONBORADEDON), leader, project)
    }

    return <>
        <div
            style={{
                display: 'flex'
            }}
        >
            <AutocompleteBm
                label="Project"
                list={allProject}
                defaultValue="ALL"
                value={project}
                onChange={onProjectChange}
            />
            <AutocompleteBm
                list={allPartner}
                value={partner}
                onChange={onChange}
                label="Partner"
                defaultValue="ALL"
                style={{
                    marginRight: '1rem',
                    marginLeft: '1rem'
                }}
            />
                        {allLeader.length ?
                <BasicSelect
                    leaderIds={objToArry()}
                    filterLeaderId={onChangeLeaderId}
                /> : null
            }
        </div>
        {
            detail ?
                <Grid container spacing={1} sx={{ margin: "2rem 0", width: "100%" }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardBM
                            ICONS={GrassIcon}
                            label="Onboarded Fields"
                            color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                            count={detail.OnboardedFields}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardBM
                            ICONS={CoPresentIcon}
                            label="Verified Fields"
                            count={detail.VerifiedFields}
                            color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardBM
                            ICONS={GrassIcon}
                            label="Onboarded Acres"
                            color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                            count={detail.OnboardedArea}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardBM
                            ICONS={GrassIcon}
                            label="Verified Acres"
                            color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                            count={detail.VerifiedArea}
                        />

                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TableHeader label="DAILY TRACK" Icon={AutoGraphIcon} />
                        <ComplexChartBm
                            AreaColor='#05AA02'
                            key3="VerifiedArea"
                            key5="VerifiedFields"
                            name="ONBORADEDON"
                            data={detail.timeSeries}
                            onClick={onAreaClick}
                        />
                    </Grid>
                    <Box
                        style={{
                            flexGrow: 1,
                            height: '70vh',
                            justifyContent: "center",
                            margin: "4rem 0"
                        }}
                    >

                        <TableHeader label={`Partner Wise Stats ${activeDate && `(${activeDate})`}`} Icon={AutoGraphIcon} />
                        {!tableLoader ? rows.length ?
                            <DataGrid
                                style={{ "margin": "2rem 0" }}
                                rows={rows}
                                density="compact"
                                columns={partner !== "ALL" ? getVerifiedRepWise(activeDate) : getPartnerWiseVeri(activeDate)}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            /> : <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                No Data Added
                            </div> :
                            <CircularProgessBm />
                        }
                    </Box>
                </Grid> : <CircularProgessBm />
        }
    </>

}