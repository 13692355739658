/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { AutoSearch, MapCenter, DrawControl, GoogleMap, ShapeFileMap } from './mapComponent';
import { InfoModal } from './InfoModal';
import { UploadModal } from './UploadModal';
import { MultiPolygon } from './MultiPolygonAR';


export const MapAddField = ({ coordinates, setCoor, setUploadKey, fileName }) => {
    const [uploadCoor, setUploadCoor] = useState();
    const [key, setKey] = useState("");


    return <GoogleMap width='71vw' >
        {!uploadCoor && !coordinates &&
            <DrawControl setCoor={setCoor} />
        }
        <MultiPolygon
            coordinates={uploadCoor || coordinates} />
        <div style={{
            zIndex: 1000,
            position: 'absolute',
            bottom: '23%',
            right: '0.5%',
            display: 'grid',
            marginBottom: '2rem'
        }}>
            <InfoModal />
            {!fileName && !coordinates &&
                <UploadModal
                    setUploadKey={setUploadKey}
                    setFileName={(v) => {
                        setKey(v)
                        setUploadKey(v)
                    }}
                    setPolydata={(v) => {
                        setCoor(v);
                        console.log(v);
                        setUploadCoor(v);
                    }}
                />
            }
        </div>

        {(key || fileName) &&
            <ShapeFileMap
                fileName={key || fileName}
                setPolydata={(v) => {
                    setCoor(v);
                    setUploadCoor(v);
                }}
            />
        }
        <MapCenter
            cancel={coordinates}
        />
        <AutoSearch />
    </GoogleMap>
}