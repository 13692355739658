/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Box, Grid, Paper, IconButton } from '@mui/material';
import { red } from '@mui/material/colors';
import GrainIcon from '@mui/icons-material/Grain';
import CancelIcon from '@mui/icons-material/Cancel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FlakyIcon from '@mui/icons-material/Flaky';
import GrassIcon from '@mui/icons-material/Grass';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import WaterIcon from '@mui/icons-material/Water';
import PetsIcon from '@mui/icons-material/Pets';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import NumbersIcon from '@mui/icons-material/Numbers';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { Chips } from '../RenderChip';
import { FieldMap } from '../FieldMap';
import { TableHeader } from '../TableHeaders';
import { DetailEle } from '../DetailEle';
import { getImagesURL } from '../../utils/getURL';
import { ImagesBM } from '../imagesBM';
import css from './farmerDetails.css';
import { formatAllComments, getUniqueComments, safeConcatTwo } from '../../utils/common';

export const FieldDetails = ({ handelModal, row }) => {
  const { polygon, center, status, landArea } = row.field_markers;
  const [landImgPath, setLandImgPath] = useState([]);
  const allReworkComments = row?.farmReworkComments.UNRESOLVED

  const oldCommentsArr = row.internalComments
    ? `${row.internalComments}`
        ?.trim()
        ?.split('.')
        .map((comment) =>
          comment !== 'null'
            ? {
                comment,
                status: '',
              }
            : null
        )
    : [];

  useEffect(() => {
    const getUrl = async () => {
      const newlandImgPath = await getImagesURL(
        row.land_record_photo_path,
        "|",
      );
      setLandImgPath(newlandImgPath);
    };
    getUrl();
  }, [row]);


  const coordinates = [{ polygon, center, status, landArea }];

  const classes = css();
  const { farm_meta } = row
  const farmDetails = farm_meta[0];
  return (
    <Paper className={classes.Container}>
      <Grid container justifyContent="space-between">
        <IconButton onClick={handelModal}>
          <CancelIcon sx={{ color: red[500] }} fontSize="large" />
        </IconButton>
        <TableHeader
          label="FARM DETAILS"
          style={{
            alignSelf: 'flex-start'
          }}
          Icon={GrainIcon}
        />
        <Grid item />
      </Grid>
      <Grid container justifyContent="space-around">
        <DetailEle label="Farmer Status" Icon={FlakyIcon} value={row.status} />
        <DetailEle label="Onboarded On" Icon={DoubleArrowIcon} value={row.createdAt.slice(0, 10)} />
      </Grid>
      <Box border={1} className={classes.Box}>
        <div className={classes.row}>
          <DetailEle
            label="Farm Area"
            Icon={GrassIcon}
            value={`${+row.landArea.toFixed(1)} 
          ${row.landArea > 1 ? 'Acres' : 'Acre'}`}
          />
          <DetailEle
            label="Practice Started On"
            Icon={DoubleArrowIcon}
            value={`${new Date(farmDetails.practiceStartDate).toDateString().slice(4)}`}
          />
          {row.crops_before && (
            <Chips
              dataArray={farmDetails.crops_before}
              Icon={InsertInvitationIcon}
              label="Crop Before Practice Started"
            />
          )}
          {row.crops_after && (
            <Chips
              dataArray={farmDetails.crops_after}
              Icon={EventAvailableIcon}
              label="Crop After Practice Started"
            />
          )}
          {row.new_practices && (
            <Chips
              dataArray={farmDetails.new_practices}
              Icon={FiberNewIcon}
              label="New Practices"
            />
          )}
          {row.recent_practice_improvements && (
            <DetailEle
              label="Recent Practice Improvement"
              Icon={PlusOneIcon}
              value={farmDetails.recent_practice_improvements}
            />
          )}
          {row.compost_flag === 'Yes' && (
            <DetailEle
              label="Compost Rate"
              Icon={SettingsInputCompositeIcon}
              value={`${farmDetails.compost_rate} kg/year`}
            />
          )}
          <DetailEle label="Grazing" Icon={PetsIcon} value={farmDetails.grazing_flag} />
          <DetailEle label="Flooding" Icon={WaterIcon} value={farmDetails.flooding_flag} />
          {farmDetails.tillage_flag === 'Yes' && (
            <DetailEle
              label="Tillage Depth"
              Icon={TripOriginIcon}
              value={`${farmDetails.tillage_depth} cm`}
            />
          )}
          {farmDetails.tillage_flag === 'Yes' && (
            <DetailEle
              label="Tillage Frequency"
              Icon={NumbersIcon}
              value={`${farmDetails.tillage_frequency}
            times/year`}
            />
          )}
          {farmDetails.irrigation_flag === 'Yes' && (
            <DetailEle
              label="Irrigation Type"
              Icon={BorderBottomIcon}
              value={farmDetails.irrigation_type}
            />
          )}
          {farmDetails.irrigation_flag === 'Yes' && (
            <DetailEle
              label="Irrigation rate"
              Icon={CalendarViewWeekIcon}
              value={`${farmDetails.irrigation_rate} times/year`}
            />
          )}
          {farmDetails.manure_flag === 'Yes' && (
            <DetailEle
              label="Manure rate"
              Icon={CalendarViewWeekIcon}
              value={`${farmDetails.manure_rate} kg/year`}
            />
          )}
          {farmDetails.synthetic_fertilizer_rate && (
            <DetailEle
              label="Synthetic Fertilizer rate"
              Icon={CalendarViewWeekIcon}
              value={`${farmDetails.synthetic_fertilizer_rate} kg/year`}
            />
          )}
        </div>
      </Box>
      {Boolean(allReworkComments?.length || oldCommentsArr.length) && (
        <Box border={1} className={classes.Box}>
          <TableHeader
            label="Boomitra Comments"
            style={{
              alignSelf: 'flex-start'
            }}
            Icon={InsertCommentIcon}
          />
          <Chips dataArray={getUniqueComments(allReworkComments, oldCommentsArr)} />
        </Box>
      )}
      <Box>
        <TableHeader
          label="FARM ON MAP"
          style={{
            alignSelf: 'flex-start'
          }}
          Icon={LocationOnIcon}
        />
        <FieldMap
          coordinates={coordinates}
          style={{
            width: '80%'
          }}
        />
      </Box>
      <Box>
        <h3 style={{ textAlign: 'center' }}>Land Related Documents</h3>
        {landImgPath.map((ele, index) => (
          <div
            key={index}
            style={{
              border: '1rem solid black',
              overflow: 'scroll'
            }}
          >
            <ImagesBM url={ele} />
          </div>
        ))}
      </Box>
    </Paper>
  );
};
