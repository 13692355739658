/* eslint-disable react/prop-types */
import { Sector } from 'recharts';

export const RenderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    keyList,
    percent
  } = props;

  const displayList = keyList;

  return (
    <g>
      <text
        x={cx}
        y={cy - 40}
        dy={8}
        textAnchor="middle"
        fill="#000"
        dominantBaseline="central"
        fontSize="1em"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill="#000"
        dominantBaseline="central"
        fontSize="1em"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {displayList.map((key) => `${payload[key].toFixed(2)} ${key}`)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};
