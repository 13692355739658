/* eslint-disable react/prop-types */
import {
  Grid,
  Box,
  IconButton,
  FormControl,
} from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { FreeAutoCompleteText } from '../../../components/@material-extend/FreeAutoCompleteText';
import { SelectBm } from '../../../components/@material-extend/SelectBm';
import { InputText } from '../../../components/@material-extend/InputText';

export const DynamicFieldsCustom = ({ fields, setFields, list, type, id, selecttype, disabled }) => {
  const handleAdd = () => {
    const values = { ...fields };
    values[id].push({
      selecttype: '',
      count: '',
      type: []
    });
    setFields(values, id);
  };

  const handleRemove = (i) => {
    const values = { ...fields };;
    values[id].splice(i, 1);

    setFields(values, id);
  };

  const handleChange = (index, value, name) => {
    const values = { ...fields };
    values[id][index][name] = value;

    setFields(values, id);
  };

  return (
    <Box sx={{ flexGrow: 1, paddingTop: '3%' }}>
      {fields && fields[id] && fields[id].map((input, index) => (
        <Grid container spacing={5} key={index}>
          <Grid item xs={1}>
            {index === 0 && !disabled && (
              <IconButton color="secondary" onClick={handleAdd}>
                <AddCircleRoundedIcon sx={{ fontSize: 60, color: '#004752' }} />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={10} key={1}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                {
                  !selecttype ?
                    <SelectBm
                      disabled={disabled}
                      containerSx={{ marginTop: '0.5rem', width: '100%' }}
                      fullWidth
                      sx={{ backgroundColor: "#F1F1F1", borderRadius: 1 }}
                      label='Select Type'
                      onChange={({ target }) => handleChange(index, target.value, 'selecttype')}
                      value={input.selecttype}
                      list={type}
                    /> :
                    <FormControl fullWidth margin="dense">
                      <InputText
                        disabled={disabled}
                        fullWidth={false}
                        id="selecttype"
                        name="selecttype"
                        label="Select Type"
                        value={input.selecttype || ''}
                        onChange={({ target }) => {
                          handleChange(index, target.value, 'selecttype');
                        }}
                        style={{
                          backgroundColor: '#F1F1F1'
                        }}
                      />
                    </FormControl>
                }
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth margin="dense">
                  <InputText
                    disabled={disabled}
                    fullWidth={false}
                    id="count"
                    name="count"
                    label="Count"
                    value={input.count || ''}
                    onChange={({ target }) => {
                      handleChange(index, target.value, 'count');
                    }}
                    style={{
                      backgroundColor: '#F1F1F1'
                    }}
                    type='number'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} marginTop="0.5rem">
                <FreeAutoCompleteText
                  disabled={disabled}
                  value={input.type || []}
                  list={list || []}
                  handleChange={(e) => {
                    handleChange(index, e, 'type');
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {!disabled &&
            <Grid item xs={1}>
              <IconButton style={{ paddingTop: '1.4rem' }} onClick={() => handleRemove(index)}>
                <RemoveCircleOutlineRoundedIcon />
              </IconButton>
            </Grid>
          }
        </Grid>
      ))}
    </Box>
  );
};
