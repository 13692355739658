/* eslint-disable import/named */
/* eslint-disable react/prop-types */
import AddIcon from '@mui/icons-material/Add';
import { Grid, List, ListItemButton } from '@mui/material';
import { useState } from 'react';
import { ButtonBm } from '../../../components/@material-extend/ButtonBm';
import { HeaderDivider } from '../ConserationRanch/HeaderDivider';
import { DynamicFieldsCustom } from '../ConserationRanch/DynamicFieldCustom';

const defaultValue = {
  "cattle": [{
    selecttype: '',
    count: '',
    type: []
  }],
  "horse": [{
    selecttype: '',
    count: '',
    type: []
  }],
  "donkey": [{
    selecttype: '',
    count: '',
    type: []
  }],
  "sheep": [{
    selecttype: '',
    count: '',
    type: []
  }],
  "goat": [{
    selecttype: '',
    count: '',
    type: []
  }],
  "other": [{
    selecttype: '',
    count: '',
    type: []
  }]
}

Object.freeze(defaultValue)

export const AddCattleComp = ({ items, allValue, handleChange, disabled }) => {
  const [active, setActive] = useState('');

  const setFields = (values, id) => {
    if (values[id].length === 0) {

      handleChange(values)
      return
    }
    handleChange(values)
  };



  const addClick = (id, active) => {
    setActive(active);
    const newValue = { ...allValue }
    if (defaultValue[id].length === 0) {
      defaultValue[id].push({
        selecttype: '',
        count: '',
        type: []
      })
    }
    newValue[id] = defaultValue[id]
    handleChange({ ...newValue });
  };

  return (
    <>
      <Grid container spacing={1} sx={{ width: '75%' }}>
        {Object.values(items).map((data, index) => {
          let color = '#7DB446';
          if (allValue === undefined || !allValue[data.id] || !allValue[data.id].length) {
            color = '#004752';
          }
          return (
            <Grid item key={index} xs={4} style={{ paddingTop: '2%' }}>
              <ButtonBm
                variant="contained"
                sx={{
                  backgroundColor: color,
                  borderRadius: '25px',
                  minWidth: '25vh',
                  minHeight: '6vh',
                  fontSize: 18,
                  boxShadow: 'none',
                  display: disabled && 'none'
                }}
                onClick={() => addClick(data.id, data.buttonTitle)}
                key={index}
                id={data.id}
                startIcon={<AddIcon style={{ marginRight: '0.5vh' }} />}
              >
                {data.buttonTitle}
              </ButtonBm>
            </Grid>
          )
        })}
      </Grid>
      <List sx={{ width: '100%', }}>
        {items &&
          Object.values(items).map((data, index) => {
            let display = ''
            if (allValue === undefined || !allValue[data.id] || !allValue[data.id].length) {
              display = 'none'
            }
            return <div key={index} style={{
              width: '100%',
              display
            }}>
              <HeaderDivider title={data.header} />
              <DynamicFieldsCustom
                disabled={disabled}
                fields={allValue || defaultValue}
                setFields={setFields}
                list={data.list}
                type={data.type}
                selecttype={false || data.id === 'other'}
                id={data.id}
              />
              <ListItemButton autoFocus={active === data.buttonTitle} sx={{
                width: '1px'
              }} />
            </div>
          })}
      </List>
    </>
  );
};
