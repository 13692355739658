/* eslint-disable dot-notation */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { HeadingAndTextField } from '../ConserationRanch/HeadingAndTextField';
import { MultipleHeadingAndTextField } from '../ConserationRanch/MultipleHeadingAndTextField';

const label = [
    {
        label: "Average Yearly Rainfall",
        name: 'rainfall',
        unit: 'mm'
    },
    {
        label: "Yearly Temperature Range",
        name: 'temperature'
    },
    {
        label: "Years Practicing Sustainable Practices",
        name: 'sustainablePractices',
        unit: 'years'
    },
]

const twoFields = [
    {
        label: 'Min',
        size: 'small',
        id: 'minTemp',
        unit: '℃',
        type: 'number'
    },
    {
        label: 'Max',
        size: 'small',
        id: 'maxTemp',
        unit: '℃',
        type: 'number'
    }
];

export const RPA = ({ allValues, handleChange, disabled }) => {

    const onChange = (value, name) => {

        const newValue = { ...allValues };

        newValue[name] = value;
        handleChange(newValue);
    }

    const onChangeMultiple = (newValue, name) => {
        console.log(newValue, name);

        const values = allValues['temperature'] || {}

        values[name] = newValue;

        onChange(values, 'temperature');

    }

    return (
        <>
            {label.map((ele, index) => {
                return index !== 1 ? <HeadingAndTextField
                    key={index}
                    value={allValues ? allValues[ele.name] : ""}
                    textFieldSize="small"
                    handleChange={onChange}
                    heading={ele.label}
                    name={ele.name}
                    unit={ele.unit}
                    type='number'
                    disabled={disabled}
                /> : <MultipleHeadingAndTextField
                    noOfFields={twoFields}
                    value={allValues[ele.name] || {}}
                    dash
                    heading={ele.label}
                    setFields={onChangeMultiple}
                    key={index}
                    disabled={disabled}
                />

            })}

        </>
    );
};
