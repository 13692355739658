/* eslint-disable no-continue */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ModalBM } from '../components/ModalBM';
import Page from '../components/Page';
import { mapAction } from '../redux/actions/mapAction';
import { FarmStat } from '../components/details/farmStat';
import { FieldMap } from '../layouts/mapPage/FieldMap';
import { MapFieldPop } from '../components/details/MapFieldPop';
import { FilterMap } from '../components/details/FilterMap';
import './table.css';

export const Map = () => {
    const store = useSelector((store) => store);
    const dispatch = useDispatch();
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [filterModelUp, setFilterModelUp] = useState(false);
    const [modelUp, setModelUp] = useState(false);
    const [staticistic, setStaticistic] = useState();
    const [fieldcenter, setFieldcenter] = useState();
    const [leaderIDs, setLeaderIDs] = useState([]);

    useEffect(() => {
        const { user, isAuthenticated } = store.userRoot;
        const { polygons } = store.map;
        if (isAuthenticated && polygons.length === 0) {
            dispatch(mapAction(user.partnerID, undefined, history));
        }

    }, [dispatch, store.userRoot.user.partnerID]);

    useEffect(() => {
        const { polygons, stat, leaderIDs } = store.map;
        if (polygons.length !== 0) {
            setRows(polygons);
            setStaticistic(stat);
            setLoading(false);
            const leaders = leaderIDs.map(({ id }) => id);
            console.log(leaders);
            setLeaderIDs(leaders || [])
        }

    }, [store.map]);

    const handelModal = () => setModelUp(!modelUp);
    const handelFilterModal = () => setFilterModelUp(!filterModelUp);

    const panToField = (id) => {
        if (!window.google) {
            alert("Map not loaded yet")
            return
        }
        let data = {}
        for (const row of rows) {
            if (row.id === id) {
                data = row;
                break;
            }
        }
        if (!data.id) {
            alert("no such field")
            return
        }
        const { center } = data
        setFieldcenter(center)
        handelFilterModal()
    }

    const filterData = (filterStatus, filterByDate, filterDate, leaderId) => {
        const { polygons } = store.map;
        const filterPolygon = [];
        let startDate = null; let endDate = null
        if (filterByDate) {
            startDate = new Date(filterDate[0]);
            endDate = new Date(filterDate[1]);
        }
        for (const polygon of polygons) {
            if (leaderId && polygon.partnerDivisionId !== leaderId) {
                continue;
            }
            const date1 = polygon.updatedAt.split("T")
            const updateAt = new Date(date1[0]);
            if (filterStatus === "") {
                if (filterByDate) {
                    if (startDate <= updateAt && updateAt <= endDate) {
                        filterPolygon.push(polygon)
                    }
                } else {
                    filterPolygon.push(polygon)
                }
            } else if (filterStatus === polygon.status) {
                if (filterByDate) {
                    if (startDate <= updateAt && updateAt <= endDate) {
                        filterPolygon.push(polygon)
                    }
                } else {
                    filterPolygon.push(polygon)
                }
            }
        }
        setRows(filterPolygon)
    }

    return (
        <Page
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: '100%'
            }}
        >
            {loading ? (
                <Box>
                    <CircularProgress size={100} />
                </Box>
            ) : rows.length !== 0 ? (
                <Box
                    style={{
                        flexGrow: 1,
                    }}
                >
                    <FieldMap
                        coordinates={rows}
                        zoom={10}
                        PopUp={MapFieldPop}
                        onClickMarker
                        newcenter={fieldcenter}
                    />

                </Box>
            ) : (
                <div>No Field Added</div>
            )}
            <Box sx={{
                position: 'absolute',
                top: '15%'
            }}>
                <Button
                    variant="contained"
                    onClick={handelFilterModal}
                    sx={{
                        color: '#000',
                        background: '#fff',
                        margin: '0.5rem 0',
                        zIndex: 10,
                        position: 'fixed',
                        right: "60%",
                        up: "3%"
                    }}
                >
                    Filter
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        color: '#000',
                        background: '#fff',
                        margin: '0.5rem 0',
                        zIndex: 10,
                        position: 'fixed',
                        right: "45%",
                        up: "3%"
                    }}
                >
                    Polygon Count: {rows.length}
                </Button>
                <Button
                    variant="contained"
                    onClick={handelModal}
                    sx={{
                        color: '#000',
                        background: '#fff',
                        margin: '0.5rem 0',
                        zIndex: 10,
                        position: 'fixed',
                        right: "25%",
                        up: "3%"
                    }}
                >
                    statistics
                </Button>
                <ModalBM modelUp={modelUp} handelModal={handelModal}>
                    <FarmStat stat={staticistic} handelModal={handelModal} />
                </ModalBM>
                <ModalBM
                    modelUp={filterModelUp}
                    handelModal={handelFilterModal}>
                    <FilterMap
                        leaderIDs={leaderIDs}
                        setFieldID={panToField}
                        handelModal={handelFilterModal}
                        handleFilter={filterData} />
                </ModalBM>
            </Box>
        </Page>
    );
};