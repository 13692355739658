/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { Paper } from '@mui/material';
import SurveyForm from 'src/layouts/AddRanch/form';
import { useStyles } from './common';

export const RightBar = ({ stateData, uploadKey, coord }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Paper elevation={0} className={classes.root} key={0}>
        <SurveyForm
          stateData={stateData}
          uploadKey={uploadKey}
          coord={coord} />
      </Paper>
    </div>
  );
};

