/* eslint-disable react/prop-types */
import { Divider, Grid } from '@mui/material';
import { TypographyBm } from '../TypographyBm';

export const HeaderDivider = ({ title, ...other }) => (
  <Grid item width="100%" {...other}>
    <TypographyBm variant="h6" fontWeight="400" fontFamily="Figtree" paddingTop="3%" paddingLeft="1%">
      {title}
    </TypographyBm>
    <Divider variant="middle" />
  </Grid>
);
