import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const hidSideBarPath = new Set([
  '/dashboard/ranchDetail',
  '/dashboard/RanchQuestionnaire',
  '/dashboard/field/',
  '/dashboard/farmers/',
  '/dashboard/farmerAllField/',
  '/dashboard/AddRanch'
])

let superman = false;
export default function DashboardLayout() {
  const store = useSelector((store) => store.userRoot);
  const [partnerDetail, setPartnerDetail] = useState({});
  const [open, setOpen] = useState(false);
  const [parentStyle, setParentStyle] = useState({});
  const [hideBar, setHideBar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const { partnerDetail } = store.user;
    superman = store.superman;
    if (partnerDetail) {
      setPartnerDetail(partnerDetail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.partnerDetail, store]);

  useEffect(() => {

    const { pathname } = location;
    if (pathname === '/dashboard/MAP') {
      setParentStyle({
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0
      });
      return
    }
    if (hidSideBarPath.has(pathname)) {
      setParentStyle({
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
      });
      setHideBar(true);
      return;
    }
    setHideBar(false);
    setParentStyle({});
  }, [location]);

  return (
    <RootStyle>
      {!hideBar && (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar
            name={partnerDetail.name || ''}
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
            superman={superman}
          />
        </>
      )}
      <MainStyle style={parentStyle}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
