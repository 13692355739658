/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/prop-types */
import { HeadingAndTextField } from './HeadingAndTextField';

export const Sources = ({ allValue, setAllValue, disabled }) => {

  const handleChange = (value, name) => {
    let newValue = { ...allValue };
    newValue[name] = value;
    setAllValue({ ...newValue });
  };

  const oneFieldLarge = [
    {
      label: '',
      size: 'large'
    }
  ];

  const heading1 = 'What sources of water are available for animals to drink?';
  const heading2 = 'Keyline?';
  const heading3 = 'Trenches?';
  const heading4 = 'Gabions?';
  const heading5 = 'Dams?';
  const heading6 = 'Ponds?';

  return (
    <>
      <HeadingAndTextField
        disabled={disabled}
        value={allValue['SourceOfWater']}
        name='SourceOfWater'
        handleChange={handleChange}
        heading={heading1}
        noOfFields={oneFieldLarge}
        textFieldSize="large"
        placeholder='Please describe'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={allValue['Keyline']}
        name='Keyline'
        handleChange={handleChange}
        textFieldSize="large"
        heading={heading2}
        toggler
        placeholder='If Yes, Please Describe'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={allValue['Trenches']}
        name='Trenches'
        handleChange={handleChange}
        textFieldSize="large"
        heading={heading3}
        toggler
        placeholder='If Yes, Please Describe'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={allValue['Gabions']}
        name='Gabions'
        handleChange={handleChange}
        textFieldSize="large"
        heading={heading4}
        toggler
        placeholder='If Yes, Please Describe'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={allValue['Dams']}
        name='Dams'
        handleChange={handleChange}
        textFieldSize="large"
        heading={heading5}
        toggler
        placeholder='If Yes, Please Describe'
      />
      <HeadingAndTextField
        disabled={disabled}
        value={allValue['ponds']}
        name='ponds'
        handleChange={handleChange}
        textFieldSize="large"
        heading={heading6}
        toggler
        placeholder='If Yes, Please Describe'
      />
    </>
  );
};
