/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { getREPReportPOP } from 'src/components/GridColumns';
import { PaginationBM } from 'src/components/Pagination';
import { QuickSearchToolbar } from 'src/components/QuickSearch';
import api from 'src/components/api/popApi';
import { useSnakBarTran } from 'src/hook';
import { ModalBm } from 'src/components/@material-extend';
import { PopStatusDetails } from './PopStatusDetails';

let farmerData = []
export const REPStatModal = ({
  showModal,
  setShowModal,
  selectedPackage,
  packageName,
  cropName
}) => {
  const [searchText, setSearchText] = useState('');
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showErrorSnackbar } = useSnakBarTran();

  useEffect(() => {
    if (showModal && !farmerData.length) {
      onExistingFarmerBtnClick()
    } else {
      setLoading(false) 
    }
  }, [showModal])

  const onExistingFarmerBtnClick = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/pop/getFarmerStatPOP?popID=${selectedPackage}`);
      setGridData(data);
      farmerData = data;
    } catch (err) {
      showErrorSnackbar({ text: 'Error: Failed to fetch data.' });
      console.log('Error while getting data: ', err);
    } finally {
      setLoading(false);
    }
  };

  const requestSearch = (searchValue) => {
    const farmerSearchKeys = [
      'given_name',
      'family_name',
      'middle_name',
      'phone_number',
    ];
    setSearchText(searchValue);
    if (!searchValue || searchValue === '' || searchValue.length === 0) {
      setGridData([...farmerData]);
      return;
    }
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = farmerData.filter((row) => {
      return farmerSearchKeys.some((field) => {
        return searchRegex.test(row[field]?.toString().toLowerCase());
      });
    });
    const newRep = [...filteredRows];
    setGridData(newRep);
  };

  const escapeRegExp = (value) => {
    value = value.toLowerCase();
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };


  return (
    <ModalBm showModal={showModal}
      maxWidth='md'
      title={`${packageName} Uses Status`}
      setShowModal={() => setShowModal(!showModal)}>
      <Stack
        spacing={2}
        style={{
          height: '75vh',
        }}
      >
        <DataGrid
          components={{
            Pagination: PaginationBM,
            Toolbar: QuickSearchToolbar,
          }}
          loading={loading}
          columns={getREPReportPOP()}
          rows={gridData}
          pagination
          pageSize={100}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch('')
            }
          }}
        />
        {gridData.length ?
          <Stack alignItems='center' >
            <PopStatusDetails
              data={gridData}
              packageName={packageName}
              cropName={cropName}
            />
          </Stack> : null
        }
      </Stack>

    </ModalBm>
  );
};
