import React, {useState} from 'react';
import Page from '../../components/Page';
import { HomeTopBar, RanchTable } from '../../components/rancherDashboardComponents';

export const RancherValidation = () => {
    const [ranchList, setRanchList] = useState([])
    const [status, setStatus] = useState('ALL')
    const [allData, setAllData] = useState({});

    return(
    <Page
      style={{
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column'
      }}
    >
      <HomeTopBar setRanchList={setRanchList} status={status} setStatus={setStatus} setAllData={setAllData}/>
      <RanchTable ranchList={ranchList} setRanchList={setRanchList} status={status} allData={allData}/>
    </Page>)
  };
