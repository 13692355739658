import api from '../../components/api/api';

export const repAction = (params, history, onlyStats) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_REP_LOADING'
    });

    let data = {}
    if (!onlyStats) {

      let query = `partnerId=${params.partnerID}&stat=true`;
      if (params.activeLeader && params.activeLeader !== 'All') {
        query += `&leaderId=${params.activeLeader}`;
      }
      const repData = await api.get(
        `repList?${query}`
      );
      data = repData.data
    }

    dispatch({
      type: 'SET_REP_DATA',
      payload: { ...data, }
    });
  } catch (err) {
    history('/404');
  }
};