/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/prop-types */
import { Grid, Box, IconButton, FormControl } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { InputText } from '../../../components/@material-extend/InputText';

export const DynamicFields = ({ fields, requiredFields, setFields, disabled }) => {
  const handleAdd = () => {
    const values = [...fields];
    values.push({});
    setFields(values);
  };

  const handleRemove = (i) => {
    const values = [...fields];
    if (i !== 0) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const handleChange = (index, e, name) => {
    const values = JSON.parse(JSON.stringify([...fields]));

    values[index][name] = e.target.value;

    setFields(values);
  };

  return (
    <Box sx={{ flexGrow: 1, paddingLeft: '15%', paddingTop: '3%' }}>
      {fields.map((input, index) => (
        <Grid container spacing={5} key={index}>
          <Grid item xs={1}>
            {index === 0 && !disabled && (
              <IconButton color="secondary" onClick={handleAdd}>
                <AddCircleRoundedIcon sx={{ fontSize: 60, color: '#004752' }} />
              </IconButton>
            )}
          </Grid>

          {requiredFields.map((item, i) => {
            const value = input[item.id] || '';

            return (
              <Grid item xs={item.xs} key={i}>
                <FormControl fullWidth margin="dense">
                  <InputText
                    disabled={disabled}
                    id={item.id}
                    name={item.id}
                    label={item.name}
                    value={value}
                    onChange={(e) => {
                      handleChange(index, e, e.target.name);
                    }}
                    sx={{
                      bgcolor: '#F1F1F1',
                      borderRadius: 1
                    }}
                    unit={item.unit}
                    type={i !== 0 ? 'number' : 'text'}
                  />
                </FormControl>
              </Grid>
            )
          })}

          <Grid item xs={1}>
            {index !== 0 && !disabled && (
              <IconButton style={{ paddingTop: '1.4rem' }} onClick={() => handleRemove(index)}>
                <RemoveCircleOutlineRoundedIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
