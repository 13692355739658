/* eslint-disable valid-typeof */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { Box } from '@mui/material';
import api from '../components/api/api';
import { SearchFieldByDateMobColumn } from '../components/GridColumns';
import Page from '../components/Page';
import { TableHeader } from '../components/TableHeaders';
import { FarmerDetails } from '../components/details/FarmerDetails';
import { FieldDetails } from '../components/details/FieldDetails';
import { ModalBM } from '../components/ModalBM';
import { TransFormFarmer } from '../utils/transForm';
import { CompleteDetails } from '../components/details/completeDetails';
import './table.css';

let farmer = new Set();
export const FieldByPartnerId = () => {
    const [error, setError] = useState("")
    const { user } = useSelector((store) => store.userRoot);
    const history = useNavigate();
    const [farmerModal, setFarmerModal] = useState(false)
    const [compeleteModal, setCompleteModal] = useState(false)
    const [fieldModal, setFieldModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [fieldDetail, setFieldDetail] = useState()
    const [completeDetail, setCompleteDetail] = useState()
    const [farmerDetail, setFarmerDetail] = useState()
    const [rows, setRows] = useState([]);

    useEffect(() => {
        getFieldData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFieldData = async () => {

        farmer = new Set();
        const partnerId = user.partnerID;
        try {
            setRows([])
            setLoading(true)
            setError("")
            const url = `/getfieldbypartner?parnerId=${partnerId}`;
            const { data } = await api.get(url);
            const dataType = typeof data;
            if (dataType === typeof ("Ajay")) {
                setError(data)
            } else
                if (typeof (data) === typeof ("Ajay")) {
                    setError(data)
                } else {
                    setRows(data);
                    data.forEach((ele) => {
                        farmer.add(ele.Farmer1_userID)

                    })
                }

        } catch (error) {
            setRows([])
        }
        setLoading(false);
    }


    const toFarmer = (row) => {
        const farmerDetails = TransFormFarmer(row);
        history('../field', {
            state: farmerDetails
        });
    }

    const fieldDetailSetter = (row) => {
        setFieldDetail(row);
        setFieldModal(!fieldModal);
    };

    const farmerDetailSetter = (row) => {
        const farmerDetails = TransFormFarmer(row)
        setFarmerDetail(farmerDetails)
        setFarmerModal(!farmerModal)
    }

    const completeDetailSetter = (row) => {
        let completeDetails = TransFormFarmer(row)
        completeDetails = { ...completeDetails, ...row, phoneNumber: user.userDetails.phone_number }
        setCompleteDetail(completeDetails)
        setCompleteModal(!compeleteModal)
    }

    return (
        <Page
            style={{
                display: 'flex',
                minHeight: '100%',
                flexDirection: "column"
            }}
        >

            <h1
                style={{
                    textAlign: "center"
                }}
            >
                {error}
            </h1>
            <>
                {loading ? (
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}
                    >
                        <CircularProgress size={100} />
                    </Box>
                ) : rows.length !== 0 && !loading && (
                    <Box
                        style={{
                            flexGrow: 1,
                            height: '90vh',
                            justifyContent: "center"
                        }}
                    >
                        <TableHeader label="FARM" Icon={AgricultureIcon} />

                        <DataGrid
                            rows={rows}
                            onError="No Farmer Added"
                            density="comfortable"
                            columns={SearchFieldByDateMobColumn(fieldDetailSetter, farmerDetailSetter, toFarmer, completeDetailSetter)}
                            pageSize={100}
                            rowsPerPageOptions={[20, 50, 100]}
                            getRowClassName={({ row }) => row.status === 'CONFIRMED' ? 'confirmed' :
                                row.status === 'REWORK' ? 'invalide' :
                                    'unconfirmed'

                            }
                            disableSelectionOnClick
                        />
                    </Box>
                )
                }
                {rows.length === 0 && !loading && !error &&
                    <div
                        style={{ "textAlign": 'center' }}
                    >Refresh</div>}
            </>
            <ModalBM modelUp={fieldModal} handelModal={() => setFieldModal(!fieldModal)}>
                <FieldDetails row={fieldDetail} handelModal={() => setFieldModal(!fieldModal)} />
            </ModalBM>
            <ModalBM modelUp={farmerModal} handelModal={() => setFarmerModal(!farmerModal)}>
                <FarmerDetails
                    row={farmerDetail}
                    handelModal={() => setFarmerModal(!farmerModal)}
                />
            </ModalBM>
            <ModalBM modelUp={compeleteModal} handelModal={() => setCompleteModal(!compeleteModal)}>
                <CompleteDetails
                    handelModal={() => setCompleteModal(!compeleteModal)}
                    row={completeDetail}
                />
            </ModalBM>
        </Page>
    );
};
