/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { Button, Grid } from "@mui/material";

export const ViewQuestion = ({ data }) => {

  const onClickQuestionnaire = () => {
    const row = data;
    const url = window.location.pathname;
    const newUrl = url.replace('ranchDetail', 'RanchQuestionnaire');
    const pk = row.PK.replace('#', '%23');
    const sk = row.SK.replace('#', '%23');
    let infoStatus = row.infoStatus || 'draft';
    if (!data.doneBy
      || !data.doneBy === 'Internal'
      || data.status === 'CONFIRMED'
      || data.status === 'RESUB') {
      infoStatus = 'noEdit'
    }
    const ranchDetail = JSON.stringify({ ranchName: row.ranchName, area: row.area, unit: row.unit, PK: pk, SK: sk, infoStatus })

    const queryUrl = `?ranchInfo=${data.ranchInfo || '{}'}&otherDetails=${ranchDetail}`;
    window.open(newUrl + queryUrl, '_blank');
  };

  return (
    <Grid item xs={12}>
      <div>
        <Button style={{ marginLeft: 350, backgroundColor: "#004752" }} variant="contained" onClick={onClickQuestionnaire}>
          View Questionnaire
        </Button>
      </div>
    </Grid>
  );
}