export const polygonComments = new Set([
    "The boundary marked is incorrect, please go to map page.",
    "The boundary marked is overlapping, please go to map page.",
    "The area entered is incorrect, please go to map page.",
]);

export const farmerIdComments = new Set([
    "The ID image is blur, please go to ID page.",
    "The ID image is not valid document, please go to ID page.",
    "The ID number is incorrect, please go to ID page.",
]);


export const farmerSigComments = new Set([
    "The signature is blur, please go to signature page.",
    "The signature is not valid, please go to signature page.",
]);

export const landRecordComments = new Set([
  'The document uploaded is not valid, please go to land document page.',
  'The document uploaded is blur, please go to land document page.',
  'The document consent is missing, please go to land document page.',
  'Name in the land document not matching with Aadhar card. Please upload correct document.',
  'Total acres registered are missing in the land document. Please upload correct document.'
]);

export const farmerBankComments = new Set([
    "The first name is incorrect, please go to farmer details page.",
    "The last name is incorrect, please go to farmer details page.",
    "The address is incorrect, please go to farmer details page.",
    "The town/village is incorrect, please go to farmer details page.",
    "The postal code is incorrect, please go to farmer details page.",
    "The account is incorrect, please go to bank details page.",
]);

export const allComments = [
    "The boundary marked is incorrect, please go to map page.",
    "The boundary marked is overlapping, please go to map page.",
    "The area entered is incorrect, please go to map page.",
    "The first name is incorrect, please go to farmer details page.",
    "The last name is incorrect, please go to farmer details page.",
    "The address is incorrect, please go to farmer details page.",
    "The town/village is incorrect, please go to farmer details page.",
    "The postal code is incorrect, please go to farmer details page.",
    "The account is incorrect, please go to bank details page.",
    "The ID image is blur, please go to ID page.",
    "The ID image is not valid document, please go to ID page.",
    "The ID number is incorrect, please go to ID page.",
    "The signature is blur, please go to signature page.",
    "The signature is not valid, please go to signature page.",
    "The document uploaded is not valid, please go to land document page.",
    "The document uploaded is blur, please go to land document page.",
    "The document consent is missing, please go to land document page.",
    "Name in the land document not matching with Aadhar card. Please upload correct document.",
    "Total acres registered are missing in the land document. Please upload correct document."
]

export const commentId = {
  'The ID image is blur, please go to ID page.': '101',
  'The ID image is not valid document, please go to ID page.': '102',
  'The ID number is incorrect, please go to ID page.': '103',
  'The Front ID image is blur, please go to ID page.': '104',
  'The Back ID image is blur, please go to ID page.': '105',

  'The area entered is incorrect, please go to map page.': '201',
  'The boundary marked is incorrect, please go to map page.': '202',
  'The boundary marked is overlapping, please go to map page.': '203',

  'The first name is incorrect, please go to farmer details page.': '301',
  'The last name is incorrect, please go to farmer details page.': '302',
  'The address is incorrect, please go to farmer details page.': '303',
  'The town/village is incorrect, please go to farmer details page.': '304',
  'The postal code is incorrect, please go to farmer details page.': '305',
  'The state is incorrect, please go to farmer details page.': '306',
  'The district is incorrect, please go to farmer details page.': '307',

  'The document uploaded is not valid, please go to land document page.': '401',
  'The document uploaded is blur, please go to land document page.': '402',
  'The document consent is missing, please go to land document page.': '403',
  'The family tree missing, Please go to land document page.': '404',
  'Name in the land document not matching with Aadhar card. Please upload correct document.':'405',
  'Total acres registered are missing in the land document. Please upload correct document.':'406',

  'The signature is blur, please go to signature page.': '501',
  'The signature is not valid, please go to signature page.': '502',

  'The UPI details is incorrect, please go to bank details page.': '601',
  'The IFSC code in incorrect, please go to bank details page.': '602',
  'The account is incorrect, please go to bank details page.': '603',
  'The First name is incorrect, please go to bank details page.': '604',
  'The last name is incorrect, please go to bank details page.': '605'
};