import { makeStyles } from '@mui/styles';

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    marginTop: '2.5vh',
    flex: 1,
    flexDirection: 'column'
  },
  container: {
    '& div': {
      display: 'flex',
      padding: '0.4rem'
    },
    [theme.breakpoints.down('md')]: {
      width: '50px'
    }
  }
}));
