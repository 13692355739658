import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import api from '../components/api/api';
import { userLoginHelper } from '../redux/actions/userAction';
import setAuthToken from '../redux/helper/setAuthToken';
import setAuthTokenEdit from '../redux/helper/setAuthTokenEdit';

export const Superman = () => {
    const [partnerId, setPartnerId] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useNavigate();
    const store = useSelector((store) => store.userRoot);

    if (!store.superman && !store?.user?.readonly) {
        return <Navigate to='/login' replace />
    }

    const onSubmit = async () => {
        try {
            if (!partnerId) {
                throw new Error("Invalid")
            }
            setLoading(true);
            const { user } = store;
            const [superman, readonly] = [user?.userDetails?.userType === 'ADMIN', user?.userDetails?.userType === 'ADMIN_READ_ONLY']
            const { data } = await api.get(`/getPartnerForSwitcher?partnerId=${partnerId}`)
            if (!data || !data.id) {
                throw new Error("invalid")
            }
            user.userDetails.partnerName = data.name
            let newToken = user.token.split(".");
            newToken = `${newToken[0]}.${newToken[1]}.${newToken[2]}.${data.key}`
            setAuthToken(newToken)
            setAuthTokenEdit(newToken)
            const storeData = {
                group: user.group,
                token: newToken,
                partnerID: data.id,
                userDetails: { ...user?.userDetails, userType: user?.userDetails?.userType },
                partnerDetail: data,
                superman,
                readonly
            };
            localStorage.setItem('JWT', JSON.stringify(storeData));
            dispatch(userLoginHelper(storeData));
            dispatch({ type: "DELETE_HOME_DATA" });
            dispatch({ type: "DELETE_REP_DATA" });
            dispatch({ type: 'DELETE_POLYGON_DATE' })
            history('../app');

        } catch (error) {
            setError(true);
            console.log(error);
        }
        setLoading(false);

    }

    return <>
        <TextField
            label="Partner ID"
            value={partnerId}
            onChange={(e) => {
                setError(false);
                setPartnerId(e.target.value);
            }}
            error={error}
            helperText={error && "Invalid Partner Id"}
        />

        <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            sx={{
                marginLeft: "2rem",
            }}
            loading={loading}
            onClick={onSubmit}
        >
            Submit
        </LoadingButton>
    </>

}