/* eslint-disable react/prop-types */

import { Grid } from '@mui/material';
import { ContainerCenter } from '../../../components/@material-extend/ContainerCenter';
import { TypographyBm } from '../TypographyBm';
import { HeaderDivider } from '../ConserationRanch/HeaderDivider';
import { AddCattleComp } from './AddCattleComp';
import { cattle, horses, donkeys, sheep, goats } from '../../../data/FarmData'


const typeOfCattles = [
  'Bulls',
  'Cows',
  'Heifers',
  'Steers',
]

const typeOfHorses = [
  'Stallion', 'Mares', 'Horses']

const typeOfSheep = [
  'Ram', 'Ewes'
]

const typeOfDonkey = [
  'Jacks', 'Jennies'
]

const typeOfGoat = [
  'Bucks', 'Nannies'
]

const typeOfOthers = []

const comps = {
  cattle: {
    buttonTitle: 'Add Cattle',
    header: 'CATTLE',
    id: 'cattle',
    list: cattle,
    type: typeOfCattles,
  },
  horse: {
    buttonTitle: 'Add Horses',
    header: 'HORSE',
    id: 'horse',
    list: horses,
    type: typeOfHorses,
  },
  donkey: {
    buttonTitle: 'Add Donkeys',
    header: 'DONKEY',
    id: 'donkey',
    list: donkeys,
    type: typeOfDonkey,
  },
  sheep: {
    buttonTitle: 'Add Sheeps',
    header: 'SHEEP',
    id: 'sheep',
    list: sheep,
    type: typeOfSheep,
  },
  goat: {
    buttonTitle: 'Add Goats',
    header: 'GOAT',
    id: 'goat',
    list: goats,
    type: typeOfGoat,
  },
  other: {
    buttonTitle: 'Add Others',
    header: 'OTHER',
    id: 'other',
    list: [],
    type: typeOfOthers,

  }
};

export const LSSectionC = ({ allValue, handleChange, disabled }) => {


  const onChange = (values) => {
    handleChange({ ...values })
  }


  return (
    <Grid item container>
      <HeaderDivider title="Livestock number and count" />

      {!disabled &&
        <TypographyBm sx={{ paddingLeft: '11%', paddingTop: '3%', fontSize: '18px', fontWeight: '300', fontFamily: 'DM Sans' }} >
          Please add livestock type and count.
        </TypographyBm>
      }
      <ContainerCenter>
        <AddCattleComp disabled={disabled} items={comps} allValue={allValue} handleChange={onChange} />
      </ContainerCenter>

    </Grid>
  );
};