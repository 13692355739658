/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import { ButtonBm } from 'src/components/@material-extend/ButtonBm';
import { exportCSVFile } from 'src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../redux/actions/userAction';
import MenuPopover from '../../components/MenuPopover';


const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  }
];

export default function AccountPopover() {
  const { group } = useSelector((store) => store.userRoot.user);
  const history = useNavigate();
  const [partnerDetails, setPartnerDetails] = useState({});
  const dispatch = useDispatch();
  const store = useSelector((store) => store.userRoot);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const OnClickDownload = () => {
    const { user } = store
    const { phone_number } = user.userDetails;
    exportCSVFile(localStorage.getItem("validationLog") || "[]", phone_number);
  }

  useEffect(() => {
    const { user } = store
    if (user && user.userDetails) {
      const { userDetails } = user;
      let { givenName, familyName, state, country, } = userDetails;
      state = state.replaceAll("_", " ");
      const name = `${givenName} ${familyName}`;
      setPartnerDetails({ name, state, country });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.user]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src="/static/mock-images/avatars/avatar_default.jpg" alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {partnerDetails.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {partnerDetails.state}, {partnerDetails.country}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => dispatch(userLogout(history))}
          >
            Logout
          </Button>
        </Box>
        {group && group.includes('createUser') &&
          <Box sx={{ p: 2, pt: 1.5 }}>
            <ButtonBm
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => history('/Dashboard/partnerAccess')}
            >
              partner Access
            </ButtonBm>
          </Box>
        }
        {group && group.includes('cropLandVerification') &&
          <Box sx={{ p: 2, pt: 1.5 }}>
            <ButtonBm
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={OnClickDownload}
            >
              Download
            </ButtonBm>
          </Box>
        }
      </MenuPopover>
    </>
  );
}
