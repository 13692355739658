/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ButtonBm, CircularProgessBm, TypographyBm, ModalBm } from 'src/components/@material-extend';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useNavigate } from 'react-router-dom';

export const Header = ({ details, saveData, heading, loading, time, actionButton }) => {
  const [modal, setModal] = useState(false)
  const navigate = useNavigate();

  const onClick = () => {
    if (details.infoStatus === 'final') {
      setModal(true);
    } else {
      saveData()
    }
  }


  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        padding: '0 2rem',
        marginBottom: '1rem'
      }}
    >
      <div style={{ paddingLeft: actionButton && details.status !== 'CONFIRMED' && '34%' }}>
        <TypographyBm variant="h3" component="div" style={{ color: '#004752' }}>
          {heading}
        </TypographyBm>
        {details &&
          <Typography component="div" variant="h6" textAlign="center" color="#B0B7C3">
            {(details.ranchName) || ''}, Area: {(details.area) || ''} {details.unit}
          </Typography>
        }
      </div>
      {actionButton && details.status !== 'CONFIRMED' &&
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flex: 1
          }}
        >
          <Stack direction="row" alignItems="center" >

            {loading ? <CircularProgessBm size={20}
              sx={{
                color: '#004752'
              }}
            /> : time && <CloudDoneIcon
              sx={{
                color: '#004752'
              }}
            />}
            <TypographyBm sx={{ margin: '1rem 0.5rem', }}>
              {loading ? "Saving" : time && "Draft saved"}
            </TypographyBm>
          </Stack>
          {details.infoStatus !== 'noEdit'
            &&
            <ButtonBm onClick={onClick} sxExtra={{ margin: '.5rem', backgroundColor: '#004752' }} variant="contained">
              {details.infoStatus === 'final' ? "Edit" : "Submit"}
            </ButtonBm>
          }
          {details.infoStatus === 'draft'  &&
            <ButtonBm onClick={() => navigate(`../documents?TotalDoc=${details.totalDoc}`, {
              state: details
            })} sxExtra={{ margin: '.5rem', backgroundColor: '#004752' }}>
              Skip
            </ButtonBm>
          }
        </Box>}
      <ModalBm
        open={modal}
        title={`Edit Questionnaire for ${details.ranchName} ?`}
        onClose={() => setModal(false)}>
        <Stack gap={5}>
          <Box>
            <TypographyBm sx={{
              display: 'inline-block'
            }}>
              Please send a request email from your registered email to
            </TypographyBm>
            <strong>
              ranchersupport@boomitra.com
            </strong>
            <TypographyBm sx={{
              display: 'inline-block',
              margin: '0 3px'
            }}>
              if you want to edit this questionnaire.
            </TypographyBm>
          </Box>
          <ButtonBm
            fullWidth={false}
            style={{
              border: 'solid 0.5px #000',
              width: '30%',
              marginLeft: 'auto'
            }}
            variant=''
            onClick={() => setModal(false)}>
            Ok
          </ButtonBm>
        </Stack>
      </ModalBm>
    </Stack>
  );
};