/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Paper, Typography } from "@mui/material"
import { statusColor } from '../../theme/statusColor';

export const Header = ({ name, status, }) => {

    return <Paper
        elevation={10}
        sx={{
            padding: '3rem',
            paddingBottom: '2rem',
            backgroundColor: statusColor[status],
            width: '100%',
            minHeight: '20vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
        <div style={{ flex: 1 }} />
        <Typography variant="h5" sx={{
            color: '#fff'
        }}>
            {name}
        </Typography>
        <Typography
            sx={{
                color: '#fff'
            }}
        >
            {(status)}
        </Typography>
    </Paper>
}