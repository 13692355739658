/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import CoPresent from '@mui/icons-material/CoPresent';
import Grain from '@mui/icons-material/Grain';
import GroupIcon from '@mui/icons-material/Group';
import { useSelector, useDispatch } from 'react-redux';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CalenderBm } from 'src/components/@material-extend/CalenderBm';
import api from '../components/api/api';
import { CardBM } from '../components/_dashboard/app';
import Page from '../components/Page';
import { DateModal } from '../utils/DateModal';
import { TableHeader } from '../components/TableHeaders';
import { BarBm } from '../components/charts/BarBm';
import { CustomTooltipBarBm } from '../components/_dashboard/app/CustomTooltipBarBm';
import { userLoginHelper } from '../redux/actions/userAction';
import setAuthToken from '../redux/helper/setAuthToken';
import setAuthTokenEdit from '../redux/helper/setAuthTokenEdit';
import './table.css';

export const SearchByMultipleDate = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState({});
    const dispatch = useDispatch();
    const history = useNavigate();
    const defaultDate = [new Date(), new Date()]
    const [dateFilter, setDateFilter] = useState(defaultDate)
    const store = useSelector((store) => store.userRoot);

    const setDate = () => {
        if (!dateFilter[0] || !dateFilter[1]) {
            alert("Please select a date before submitting your search.")
        }
        getDayWiseStatus(dateFilter[0], dateFilter[1]);
    }

    const onSubmit = async (row) => {
        const partnerId = row.id;
        try {
            if (!partnerId || partnerId.length !== 4) {
                throw new Error("Invalid")
            }
            setLoading(true);
            const { user } = store;
            const { data } = await api.get(`/getPartnerForSwitcher?partnerId=${partnerId}`)
            const [superman, readonly] = [user?.userDetails?.userType === 'ADMIN', user?.userDetails?.userType === 'ADMIN_READ_ONLY']

            if (!data || !data.id) {
                throw new Error("invalid")
            }
            user.userDetails.partnerName = data.name
            let newToken = user.token.split(".");
            newToken = `${newToken[0]}.${newToken[1]}.${newToken[2]}.${data.key}`
            setAuthToken(newToken)
            setAuthTokenEdit(newToken)
            const storeData = {
                group: user.group,
                token: newToken,
                partnerID: data.id,
                userDetails: { ...user?.userDetails, userType: user?.userDetails?.userType },
                partnerDetail: data,
                superman,
                readonly
            };
            localStorage.setItem('JWT', JSON.stringify(storeData));
            dispatch(userLoginHelper(storeData));
            dispatch({ type: "DELETE_HOME_DATA" });
            dispatch({ type: "DELETE_REP_DATA" });
            dispatch({ type: 'DELETE_POLYGON_DATE' })
            history('../app');

        } catch (error) {
            alert(error)
        }
        setLoading(false);
    }


    const getDayWiseStatus = async (date1, date2) => {
        setLoading(true)
        const mDate1 = DateModal(date1);
        const mDate2 = DateModal(date2);

        try {
            const { data } = await api.get(`/completeStatByDate?fromDate=${mDate1}&toDate=${mDate2}`);
            setRows(data);
        } catch (error) {
            setRows([])
        }
        setLoading(false);
    }

    return (
        <Page
            style={{
                display: 'flex',
                minHeight: '100%',
                flexDirection: "column"
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={CoPresent}
                        label="ACTIVE REPS"
                        count={rows.totalRep}
                        color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={AgricultureIcon}
                        label="NUMBER OF FARMERS"
                        count={rows.totalFarmer}
                        color="linear-gradient(to right, #f8b500, #fceabb)"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        label="NUMBER OF FARMS"
                        count={rows.totalfarms || 0}
                        color="linear-gradient(to left, #93F9B9, #1D976C)"
                        ICONS={Grain}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={Grain}
                        label="TOTAL AREA (ACRES)"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        count={rows.totalArea || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                        ICONS={GroupIcon}
                        label="ACTIVE PARTNER"
                        color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                        count={rows.totalPartner}
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    width: "60%",
                    margin: "3rem 0 0 0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <CalenderBm date={dateFilter} setDate={setDateFilter} />
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    onClick={setDate}
                >
                    Submit
                </LoadingButton>
            </div>
            {loading ?
                <div
                    style={{
                        width: "100%",
                        margin: "3rem 0 0 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <CircularProgress
                        size={100}
                    />
                </div>
                : Object.keys(rows).length !== 0 &&
                <Grid
                    container
                    style={{
                        width: "100%",
                        margin: "1rem auto",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Grid container item justifyContent="center">
                        <TableHeader label="Hour Wise Status" />
                    </Grid>
                    <Grid item container>
                        <BarBm data={rows.activityHour} x="time" y1="Farm"
                            yLabel="Number Of Farms"
                            xaxies={false}
                            barAreaClick={() => console.log("nn")}
                        />
                    </Grid>
                    <Grid container item justifyContent="center">
                        <TableHeader label="FARM STATUS ON PARTNER" />
                    </Grid>
                    <Grid item container>
                        <BarBm
                            CustomTooltip={CustomTooltipBarBm}
                            data={rows.activePartnerStat}
                            x="name"
                            y1="onboarded"
                            y2="verified"
                            y3="received"
                            y4="rework"
                            y5="re_sub"
                            yLabel="total"
                            xaxies={false}
                            barAreaClick={onSubmit} />
                    </Grid>
                </Grid>
            }
        </Page >
    );
};
