/* eslint-disable dot-notation */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import { TypographyBm } from '../TypographyBm';
import { InputText } from '../../../components/@material-extend/InputText';
import { CheckboxBm } from '../../../components/@material-extend/CheckBoxBm';

const label = [
    {
        label: "Rotational Grazing",
        name: 'rotationalGrazing'
    },
    {
        label: "Livestock Management",
        name: 'LivestockManagement'
    },
    {
        label: "Water Management",
        name: 'waterManagement'
    },
    {
        label: "Forage Management",
        name: 'forageManagement'
    },
    {
        label: "Fertilizer Management",
        name: 'fertilizerManagement'
    },
    {
        label: "Controlled Burning in Pastures",
        name: 'ControlledBurninginPastures'
    },
    {
        label: "KeyLine",
        name: 'KeyLine'
    },
    {
        label: 'Other',
        name: 'other'
    }
]


export const RPB = ({ allValues, handleChange, disabled }) => {

    const onChange = (value, name) => {
        console.log(value, name);

        const newValue = { ...allValues };

        newValue[name] = value;
        handleChange(newValue);
    }

    return (
        <Grid container >
            <Grid item sx={{
                marginBottom: "2rem"
            }} >
                <TypographyBm sx={{ paddingLeft: '3rem', paddingTop: '3%', fontSize: '20px', fontWeight: '500', fontFamily: 'DM Sans' }}>
                    Please select all the regenerative practices you are practicing.
                </TypographyBm>
            </Grid>
            <Grid item
                width='100%'
                direction='column'
                sx={{
                    marginLeft: '8vw'
                }}
                container justifyContent='center' alignItems='center'>
                {label.map((ele, index) => {
                    return <CheckboxBm
                        disabled={disabled}
                        key={index}
                        value={allValues ? allValues[ele.name] : ""}
                        onChange={onChange}
                        label={ele.label}
                        name={ele.name}
                        type='number'
                        style={{
                            marginBottom: ele.name === 'other' ? '1rem' : '2rem'
                        }}
                    />
                })}
                {allValues.other && <InputText
                    placeholder='Please describe'
                    disabled={disabled}
                    name={'otherDes'}
                    value={allValues ? allValues['otherDes'] : ""}
                    onChange={({ target }) => onChange(target.value, 'otherDes')}
                    rows={2}

                    style={{
                        backgroundColor: '#F1F1F1',
                        width: '35%'
                    }}
                    multiline
                />
                }
            </Grid>
        </Grid>
    );
};
