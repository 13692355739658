/* eslint-disable react/prop-types */
import { Box, CircularProgress } from "@mui/material"


export const CircularProgessBm = ({ size }) => <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
}}>
    <CircularProgress size={size || 100} />
</Box>