import { combineReducers } from 'redux';
import userReducer from './userReducer';
import partnerReducer from './partnerReducer';
import homeReducer from './homeReducer';
import repReducer from './repReducer';
import farmerReducer from './FarmerReducer';
import fieldReducer from './FieldsReducer';
import mapReducer from './mapReducer';

const rootReducer = combineReducers({
  userRoot: userReducer,
  partnerDetail: partnerReducer,
  homeDetail: homeReducer,
  allRep: repReducer,
  farmer: farmerReducer,
  fields: fieldReducer,
  map: mapReducer
});

export default (state, action) =>
  rootReducer(action.type === 'DELETE_USERS_DATA' ? undefined : state, action);
