const initialState = {
    polygons: [],
    lastUpdated: '',
    stat: {},
    leaderIDs: []
};
const mapReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_ALL_POLYGON_DATE':
            return {
                polygons: action.payload.polygons,
                lastUpdated: Date(),
                stat: action.payload.stat,
                leaderIDs: action.payload.leaderIDs
            };
        case 'UPDATE_POLYGON_DATE': {
            let { polygons } = state;
            polygons = [...polygons, ...action.payload.polygons]
            return {
                ...state,
                polygons,
                lastUpdated: Date()
            };
        }
        case 'DELETE_POLYGON_DATE':
            return initialState;
        default:
            return state;
    }
};

export default mapReducer;