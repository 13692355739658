/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

export function NoAuthTemplate({ children, headin1, heading2 }) {
    return (
        <RootStyle title="Login | Minimal-UI">
            <MHidden width="mdDown">
                <SectionStyle>
                    <img
                        src="/static/illustrations/illustration_login.png"
                        alt="login"
                        style={{
                            display: 'block',
                            width: '80%',
                            height: '30%',
                            margin: '4rem auto',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: '50%',
                            right: 0,
                            transform: 'translateX(-50%)'
                        }}
                    />
                    <img
                        src="
            /static/illustrations/boomitra-logo.png"
                        style={{ width: 400, height: 60, margin: '4rem auto 0 auto' }}
                        alt="login"
                    />
                </SectionStyle>
            </MHidden>
            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{ mb: 5 }}>
                        {headin1 &&
                            <Typography variant="h4" gutterBottom>
                                {headin1}
                            </Typography>
                        }
                        {heading2 &&
                            <Typography
                                sx={{
                                    color: 'text.secondary'
                                }}
                            >
                                {heading2}
                            </Typography>
                        }
                    </Stack>
                    {children}
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
